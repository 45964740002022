import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import DialogStyled from "../../../helpersComponents/PopupHelpers/dialogStyled";
import { DialogContentStyled } from "../../../helpersComponents/PopupHelpers/dialogContentStyled";
import DialogTitleStyled from "../../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/styles";
import AddEditBuildingPopupContent from "./AddEditBuildingPopupContent";

const AddEditBuildingPopup: React.FC<any> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const schema = Yup.object({
    buildingsGroup: Yup.object().nullable().required(t("field_required")),
    name: Yup.string().required(t("field_required")),
    description: Yup.string().required(t("field_required")),
    order: Yup.string().required(t("field_required")),
    showOnMap: Yup.boolean(),
    latitude: Yup.string().nullable(),
    longitude: Yup.string().nullable(),
    photo: Yup.mixed().nullable(),

    floors: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(t("field_required")),
          number: Yup.string().required(t("field_required")),
          isDefault: Yup.bool().required(t("field_required")),
          map: Yup.mixed().required(t("no_file_selected")),

          oryginalWidth: Yup.number(),
          oryginalHeight: Yup.number(),
          customWidth: Yup.string().nullable(),
          customHeight: Yup.string().nullable(),
          isVector: Yup.bool(),

          /*.test(
            "map_type",
            `${t(
              "only_the_following_types_are_supported"
            )}: .svg, png, .jpeg, .jpg, .bmp`,
            (value) => {
              return (
                value &&
                (value[0].type === "image/svg+xml" ||
                  value[0].type === "image/png" ||
                  value[0].type === "image/jpeg" ||
                  value[0].type === "image/jpg" ||
                  value[0].type === "image/bmp")
              );
            }
          ),*/
        })
      )
      .min(1, t("at_least_one_floor_required")),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      name: "",
      description: "",
      order: "0",
      showOnMap: false,
      latitude: "",
      longitude: "",
      photo: null,
      floors: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "floors",
  });

  const useStyles = makeStyles(() => ({
    dialog: {
      width: 1000,
      minHeight: 500,
      margin: 10,
    },
  }));

  const classes = useStyles();

  return (
    <DialogStyled
      fullWidth={true}
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {
          reset();
        },
        onEnter: () => {
          setErrorMessage("");
        },
      }}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitleStyled
        title={t("buildings")}
        subtitle={rowId ? t("editing_building") : t("adding_building")}
      />

      <DialogContentStyled>
        <AddEditBuildingPopupContent
          rowId={rowId}
          control={control}
          register={register}
          errors={errors}
          fields={fields}
          append={append}
          remove={remove}
          setValue={setValue}
          getValues={getValues}
          successCallback={successCallback}
          setErrorMessage={setErrorMessage}
          handleSubmit={handleSubmit}
          setIsFetching={setIsFetching}
          watch={watch}
          clearErrors={clearErrors}
        />
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onConfirmText={rowId ? t("confirm") : t("add")}
        onCancel={() => {
          closePopup();
        }}
        isLoading={isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default AddEditBuildingPopup;
