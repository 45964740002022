import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { useCallback, useEffect, useMemo, useState } from "react";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import Button from "../../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import moment from "moment";
import CheckboxStyled from "../../../helpersComponents/MaterialUi/Checkbox/checkbox";
import styles from "./OperationsHistory.module.scss";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import { profilesRoutesDictionary } from "./../../../ProfilesRouting/profilesDictionaries";
import { selectAuthUser } from "../../../../reducers/session";
import useUpdateSettings from "../../../../hooks/useUpdateSettings/useUpdateSettings";
import { truncateDecimals } from "../../../../HelpersFunctions/numbers/truncateDecimals";
import { secondsToHHmmWithout24HLimit } from "../../../../HelpersFunctions/dateAndTime/secondsToHHmmWithout24HLimit";
import PivotTable from "../../../helpersComponents/PivotTable/PivotTable";
import { IPivotTableField } from "../../../helpersComponents/PivotTable/type";

const OperationsHistory = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [selectedWorkers, setSelectedWorkers] = useState<any>([]);
  const [allWorkers, setAllWorkers] = useState<any>([]);
  const [allWorkersSelected, setAllWorkersSelected] = useState<boolean>(false);
  const [selectedWorkersString, setSelectedWorkersString] = useState<string>();

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  const [startDate, setStartDate] = useState<Date>(
    getBeginningOfDate(new Date(), "Year")
  );
  const [endDate, setEndDate] = useState<Date>(getEndOfDate(new Date(), "Day"));

  const [operationsHistoryBody, setOperationsHistoryBody] = useState<
    false | string
  >(false);

  const settings = useAppSelector(selectSettings);
  const [displayTimeInDecimal, setDisplayTimeInDecimal] =
    useState<boolean>(false);

  useEffect(() => {
    setDisplayTimeInDecimal(
      settings.PrzelozonyRCPHistoriaOperacjiWyswietlanieCzasuDziesietnie
        ?.value === "1"
    );
  }, [
    settings.PrzelozonyRCPHistoriaOperacjiWyswietlanieCzasuDziesietnie?.value,
  ]);

  const [postRequestBody, setPostRequestBody] = useState<any>(false);

  const getTimeDifferenceInSeconds = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      if (!startDate || !endDate) {
        return 0;
      }

      return (
        Math.abs(new Date(endDate).getTime() - new Date(startDate).getTime()) /
        1000
      );
    },
    []
  );

  const [ifUpdateSetting, setIfUpdateSetting] = useState<boolean>(false);
  const ifSettingsUpdated = useUpdateSettings(ifUpdateSetting);

  useEffect(() => {
    if (ifSettingsUpdated) {
      setIfUpdateSetting(false);
    }
  }, [ifSettingsUpdated]);

  const successCallbackSaveSettings = useCallback((response) => {
    setIfUpdateSetting(true);
  }, []);

  const [, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${
      profilesRoutesDictionary[authUser.currentProfile?.type]
    }/preferences`,
    method: "POST",
    body: postRequestBody,
    setBody: setPostRequestBody,
    contentType: "application/json",
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
    successCallback: successCallbackSaveSettings,
  });

  useEffect(() => {
    if (postRequestBody) {
      fetchAgainPostData();
    }
  }, [postRequestBody, fetchAgainPostData]);

  const [modifiedData, setModifiedData] = useState<any>([]);

  const successCallbackFetchingStateOperationsHistory = useCallback(
    (response) => {
      let result: any = [];
      if (response?.resJson) {
        for (let i = 0; i < response.resJson.length; i++) {
          result.push({
            employee: `${response.resJson[i].firstName} ${response.resJson[i].lastName}`,
            login: response.resJson[i].login,
            evidenceNumber: response.resJson[i].evidenceNumber,
            operationName: response.resJson[i].operationName,
            operationStartDate: moment(
              response.resJson[i].operationStartDate
            ).format("YYYY-MM-DD"),
            operationStartTime: moment(
              response.resJson[i].operationStartDate
            ).format("HH:mm:ss"),
            timeDuration: getTimeDifferenceInSeconds(
              response.resJson[i].operationStartDate,
              response.resJson[i].operationEndDate
            ),
          });
        }
      }

      setModifiedData(result);
    },
    [getTimeDifferenceInSeconds]
  );

  const [fetchingStateOperationsHistory, fetchAgainOperationsHistory] =
    useFetchOtherThanGET({
      path: "superior-time/operations-history",
      method: "POST",
      contentType: "application/json",
      body: operationsHistoryBody,
      disableErrorSnackbar: true,
      disableSuccessSnackbar: true,
      successCallback: successCallbackFetchingStateOperationsHistory,
    });

  const layoutSettingName = "layoutSuperiorTimeSummaryOperationsHistory";

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {fetchingStateOperationsHistory.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <Button
            onClick={() => {
              let selectedWorkersLocal = [...selectedWorkers];
              if (selectedWorkers.length === 0) {
                allWorkers.forEach((worker) => {
                  selectedWorkersLocal.push(worker.id);
                });
              }

              let selectedWorkersStringLocal: string = "";
              selectedWorkersLocal.forEach((selectedId) => {
                selectedWorkersStringLocal =
                  selectedWorkersStringLocal + selectedId.toString() + ",";
              });
              selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
                /,\s*$/,
                ""
              );
              setSelectedWorkersString(selectedWorkersStringLocal);
              updateLastSelectedWorkers();
            }}
          >
            {t("get_operations")}
          </Button>
        )}
      </div>
    </>
  );

  useEffect(() => {
    if (startDate && endDate) {
      let requestBodyObj = {
        dateFrom: new Date(startDate),
        dateTo: new Date(endDate),
        timeWorkersIds:
          selectedWorkersString?.split(",").map((e) => parseInt(e)) || [],
      };

      setOperationsHistoryBody(JSON.stringify(requestBodyObj));
    }
  }, [startDate, endDate, allWorkersSelected, selectedWorkersString]);

  useEffect(() => {
    if (startDate && endDate && selectedWorkersString) {
      fetchAgainOperationsHistory();
    }
  }, [startDate, endDate, selectedWorkersString, fetchAgainOperationsHistory]);

  const pivotTableFields: IPivotTableField[] = useMemo(
    () => [
      {
        dataField: "employee",
        area: "row",
        caption: t("employee"),
      },
      {
        dataField: "login",
        area: "row",
        caption: t("login"),
      },
      {
        dataField: "evidenceNumber",
        area: "row",
        caption: t("evidence_number"),
      },
      {
        dataField: "operationName",
        area: "row",
        caption: t("operation"),
      },
      {
        dataField: "operationStartDate",
        area: "row",
        caption: t("operation_start_date"),
      },
      {
        dataField: "operationStartTime",
        area: "row",
        caption: t("operation_start_time"),
      },
      {
        dataField: "timeDuration",
        area: "data",
        summaryType: "sum",
        caption: t("duration_time"),
        dataType: "number",
        customizeText: (el) => {
          if (el.value) {
            if (displayTimeInDecimal) {
              return truncateDecimals(
                moment.duration(el.value as any, "seconds").asHours(),
                2
              ).toString();
            }

            return secondsToHHmmWithout24HLimit(el.value);
          }

          return "00:00";
        },
      },
    ],
    [displayTimeInDecimal, t]
  );

  return (
    <div>
      <TopBanner pathName={t("operations_history")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName={layoutSettingName}
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            setAllWorkersSelected={setAllWorkersSelected}
          />
        </div>
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            keyboardDateTimePicker={true}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={confirmButtonRight}
          ></DatePickerRange>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStateOperationsHistory.isFetching}
            isError={fetchingStateOperationsHistory.isError}
            setIfFetchAgain={() => {
              if (fetchingStateOperationsHistory.isError) {
                fetchAgainOperationsHistory();
              }
            }}
          >
            <div>
              <div>
                <PivotTable
                  layoutSettingName={"superiorTimeOperationsHistoryPivotTable"}
                  fields={pivotTableFields}
                  data={modifiedData}
                  customActionsColumn={
                    <div>
                      <span className={styles.displayTimeInDecimalLabel}>
                        {t("display_time_in_decimal")}
                      </span>

                      <CheckboxStyled
                        checked={displayTimeInDecimal}
                        name="displayTimeInDecimal"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let selected = e.target.checked;
                          setDisplayTimeInDecimal(selected);

                          setPostRequestBody(
                            JSON.stringify({
                              name: "PrzelozonyRCPHistoriaOperacjiWyswietlanieCzasuDziesietnie",
                              value: selected ? "1" : "0",
                            })
                          );
                        }}
                        ifCircle={true}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default OperationsHistory;
