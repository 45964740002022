export function numberArraysHaveCommonElements(arr1, arr2) {
  const set1 = new Set(arr1);
  for (const element of arr2) {
    if (set1.has(element)) {
      return true;
    }
  }

  return false;
}

export function stringArraysHaveCommonElements(arr1, arr2, caseSensitive = true) {
  const set1 = new Set(caseSensitive ? arr1 : arr1.map(elem => elem.toLowerCase()));

  for (let elem of arr2) {
    const elementToCheck = caseSensitive ? elem : elem.toLowerCase();
    if (set1.has(elementToCheck)) {
      return true;
    }
  }

  return false;
}

export function getUniqueItemsByProperties (arr: Array<{}>) {
  return arr.filter((obj1, index, thisArray) => 
    index === thisArray.findIndex((obj2) => 
      JSON.stringify(obj2) === JSON.stringify(obj1)));
}