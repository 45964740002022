import { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectCurrentMapId,
  setMapTargetLocation,
} from "../../../reducers/sms/visualizationData";
import { stringArraysHaveCommonElements } from "../../../HelpersFunctions/arraysHelpers";
import useConfirm from "../../useConfirm/useConfirm";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSMSVisualizationDataContext } from "../../../HelpersFunctions/SMSVisualizationDataProvider";

function createMapsDictionary(buildings: ISMSBuilding[]): {
  [key: number]: string;
} {
  const mapDictionary = {};

  buildings.forEach((building) => {
    building.floors.forEach((floor) => {
      const mapId = floor.mapId;
      const mapValue = `${building.name}->${floor.name}`;
      mapDictionary[mapId] = mapValue;
    });
  });

  return mapDictionary;
}

const useSMSGoToVisualizationObject = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [visualizationObjectsToGo, setVisualizationObjectsToGo] = useState<
    ISMSVisualizationObjectToGo[]
  >([]);

  const currentMapId = useAppSelector(selectCurrentMapId);

  const {
    buildingsData,
    visualizationObjectsData,
    visualizationObjectsLayersInstancesData,

    /*isBuildingsLoading,
    isBuildingsError,

    isVisualizationObjectsLoading,
    isVisualizationObjectsError,*/
  } = useSMSVisualizationDataContext();

  const buildings = useMemo(() => buildingsData || [], [buildingsData]);

  const visualizationObjects = useMemo(
    () => visualizationObjectsData || [],
    [visualizationObjectsData]
  );

  const visualizationObjectsLayersInstances = useMemo(
    () => visualizationObjectsLayersInstancesData || [],
    [visualizationObjectsLayersInstancesData]
  );

  const goToLocation = useCallback(
    (objectToGo: ISMSVisualizationObjectToGo) => {
      if (currentMapId === objectToGo.mapId) {
        dispatch(
          setMapTargetLocation([
            objectToGo.posX,
            objectToGo.posY,
            objectToGo.zoom,
          ])
        );
      } else {
        let floorId = 0;
        let floorName = "";

        const foundInstance = visualizationObjectsLayersInstances.find(
          (el) => el.id === objectToGo.id
        );

        if (!foundInstance) return;

        for (let building of buildings) {
          for (let floor of building.floors) {
            if (floor.mapId === foundInstance.mapId) {
              floorId = floor.id;
              floorName = floor.name;
              break;
            }
          }

          if (floorName) break;
        }

        let dataToSend = {
          action: "GO_TO_VISUALIZATION_OBJECT",
          id: objectToGo.id,
        };

        history.push({
          pathname: `/sms-user/building-floors/${floorId}?menuItemName=${floorName}`,
          state: dataToSend,
        });
      }
    },
    [
      currentMapId,
      dispatch,
      history,
      buildings,
      visualizationObjectsLayersInstances,
    ]
  );

  const handleEvent = useCallback(
    (event: ISMSEvent) => {
      let tempVisualizationObjectsToGo: ISMSVisualizationObjectToGo[] = [];
      let mapsDictionary = createMapsDictionary(buildings);
      let eventTopics: string[] = event.linkedTopics?.split(";") || [];
      let foundObjects: ISMSVisualizationObject[] = [];

      for (let i = 0; i < visualizationObjects.length; i++) {
        let obj = visualizationObjects[i];
        let objTopics: string[] = [];

        for (let j = 0; j < obj.values.length; j++) {
          let value = obj.values[j];
          if (value.topic && !objTopics.includes(value.topic)) {
            objTopics.push(value.topic);
          }
        }

        for (let j = 0; j < obj.actions.length; j++) {
          let action = obj.actions[j];
          if (action.topic && !objTopics.includes(action.topic)) {
            objTopics.push(action.topic);
          }
        }

        if (stringArraysHaveCommonElements(eventTopics, objTopics, false)) {
          foundObjects.push(obj);
        }
      }

      for (let i = 0; i < foundObjects.length; i++) {
        let obj = foundObjects[i];

        for (let j = 0; j < obj.layers.length; j++) {
          let layer = obj.layers[j];

          for (let k = 0; k < visualizationObjectsLayersInstances.length; k++) {
            if (visualizationObjectsLayersInstances[k].layerId === layer.id) {
              let layerInstanceParameters = JSON.parse(
                visualizationObjectsLayersInstances[k].parameters
              );

              tempVisualizationObjectsToGo.push({
                id: visualizationObjectsLayersInstances[k].id,
                layerId: visualizationObjectsLayersInstances[k].layerId,
                mapId: visualizationObjectsLayersInstances[k].mapId,
                name: `${obj.name} [${
                  mapsDictionary[visualizationObjectsLayersInstances[k].mapId]
                }]`,
                posX: layerInstanceParameters.position_x,
                posY: layerInstanceParameters.position_y,
                zoom: layerInstanceParameters.zoom,
              });
            }
          }
        }
      }

      // Here we have complete list of objects to go
      if (tempVisualizationObjectsToGo.length === 0) {
        confirm({
          typeDialog: "info",
          text: `${t("no_objects_associated_with_event")}`,
          buttons: [{ buttonName: t("ok"), buttonId: 0 }],
        });
        return;
      }

      if (
        tempVisualizationObjectsToGo.length === 1 &&
        tempVisualizationObjectsToGo[0].mapId === currentMapId
      ) {
        goToLocation(tempVisualizationObjectsToGo[0]);
      } else {
        setVisualizationObjectsToGo(tempVisualizationObjectsToGo);
        setIsDialogOpen(true);
      }
    },
    [
      currentMapId,
      goToLocation,
      buildings,
      confirm,
      t,
      visualizationObjects,
      visualizationObjectsLayersInstances,
    ]
  );

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return {
    isDialogOpen,
    visualizationObjectsToGo,
    handleEvent,
    closeDialog,
    goToLocation,
  };
};

export default useSMSGoToVisualizationObject;
