import React, { useCallback, useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DatePickerStyled } from "../../../helpersComponents/MaterialUi/index";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";
import { ProfileType } from "../../../../enums/profileType";
import getDateWithoutTime from "../../../../HelpersFunctions/dateAndTime/getDateWithoutTime";
import { selectSettings } from "../../../../reducers/settings";
import getDifferenceInTwoDates from "../../../../HelpersFunctions/dateAndTime/getDifferenceInTwoDates";

const BackOffDaysFromHomeOfficePopup: React.FC<any> = ({
  row,
  onApplyChanges,
  onCancelChanges,
  open,
}: any) => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const [responseErrorMessage, setResponseErrorMessage] = useState<string>();
  const [putingPostingCircularProgress, setPutingPostingCircularProgress] =
    useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const applyChanges = useRef(onApplyChanges);

  const successCallbackAndClose = useCallback(() => {
    if (applyChanges.current) {
      applyChanges.current();
    }
  }, []);

  useEffect(() => {
    if (row && row.dateFrom && row.dateTo) {
      const currentTime = getDateWithoutTime(new Date());
      let requestTimeFrom = getDateWithoutTime(new Date(row.dateFrom));
      let requestTimeTo = getDateWithoutTime(new Date(row.dateTo));

      if (currentTime >= requestTimeFrom && currentTime <= requestTimeTo) {
        requestTimeFrom = currentTime;
      }

      setStartDate(requestTimeFrom);
      setEndDate(requestTimeTo);

      setMinDate(requestTimeFrom);
      setMaxDate(requestTimeTo);
    }
  }, [row]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path:
      profileType === ProfileType.SUPERIOR_TIME
        ? `superior-time/holiday-requests/${row?.id}/backoff-days`
        : `worker-time/${authUserId}/holiday-requests/${row?.id}/backoff-days`,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
    successCallback: successCallbackAndClose,
    getErrorMessage: setResponseErrorMessage,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (
      !fetchingStatePostData.isFetching &&
      (fetchingStatePostData.response?.status === 200 ||
        fetchingStatePostData.response?.status === 201)
    ) {
      setPutingPostingCircularProgress(false);
    } else if (fetchingStatePostData.response?.status === 400) {
      setPutingPostingCircularProgress(false);
    }
  }, [fetchingStatePostData, t]);

  const confirm = () => {
    setResponseErrorMessage("");
    const currentDate = getDateWithoutTime(new Date());

    if (startDate && endDate) {
      let currentStartDate = getDateWithoutTime(startDate);
      let currentEndDate = getDateWithoutTime(endDate);

      let requestTimeFrom = getDateWithoutTime(new Date(row.dateFrom));
      let requestTimeTo = getDateWithoutTime(new Date(row.dateTo));

      if (
        currentStartDate === requestTimeFrom &&
        currentEndDate === requestTimeTo
      ) {
        setResponseErrorMessage(
          t(
            "THE_NUMBER_OF_DAYS_TO_BE_BACKOFF_MUST_BE_LESS_THAN_THE_NUMBER_OF_DAYS_OF_THE_REQUEST"
          )
        );
        return;
      }

      if (profileType === ProfileType.SUPERIOR_TIME) {
        let timeSuperiorHomeOfficeBackoffDaysDaysBackNumber = isNaN(
          settings.PrzelozonyWycofanieDniWnioskuPracyZdalnejLiczbaDniWstecz
            ?.value
        )
          ? 3
          : parseInt(
              settings.PrzelozonyWycofanieDniWnioskuPracyZdalnejLiczbaDniWstecz
                ?.value
            );

        let dateDiffInDays =
          getDifferenceInTwoDates(currentStartDate, currentDate) /
            (1000 * 60 * 60 * 24) +
          1;

        if (
          currentDate > currentStartDate ||
          dateDiffInDays < timeSuperiorHomeOfficeBackoffDaysDaysBackNumber
        ) {
          setResponseErrorMessage(t("DATE_BACK_DAYS_LIMIT_EXCEEDED"));
          return;
        }
      }
    }

    if (row?.id && startDate && endDate) {
      setPutingPostingCircularProgress(true);
      const objToSend = {
        id: row.id,
        dateFrom: new Date(startDate),
        dateTo: new Date(endDate),
      };

      setBodyRequest(JSON.stringify(objToSend));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelChanges}
      TransitionProps={{
        onExited: () => {
          setResponseErrorMessage("");
          setPutingPostingCircularProgress(false);
        },
      }}
    >
      <DialogTitle className="dialogTitleGlobal">
        <div className="titlePopup">{t("backoff_days")}</div>
        <div className="description">
          {t("adding_home_office_backoff_days_request")}
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="selectDateHolidayPopup">
          <div>
            <div className="titleWidthHoliday">{t("date_from")}:</div>
            <div className="inputWidthHoliday">
              <DatePickerStyled
                view={["year", "month", "date"]}
                format="dd-MM-yyyy"
                date={startDate}
                setDate={setStartDate}
                minDate={minDate ? minDate : undefined}
                maxDate={maxDate ? maxDate : undefined}
              />
            </div>
          </div>
          <div>
            <div className="titleWidthHoliday">{t("date_to")}:</div>
            <div className="inputWidthHoliday">
              <DatePickerStyled
                view={["year", "month", "date"]}
                format="dd-MM-yyyy"
                date={endDate}
                setDate={setEndDate}
                minDate={minDate ? minDate : undefined}
                maxDate={maxDate ? maxDate : undefined}
                width={"100%"}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>

        {!putingPostingCircularProgress ? (
          <>
            <Button onClick={onCancelChanges} variant="outlined">{t("cancel")}</Button>
            <Button
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BackOffDaysFromHomeOfficePopup;
