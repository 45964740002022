import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import fetchClient from "../../../api/fetchClient";
import { ProfileType } from "../../../enums/profileType";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";

const fetchSMSBuildingsGroups = async (
  profileType: ProfileType
): Promise<ISMSBuildingsGroup[]> => {
  const endpoint =
    profileType === ProfileType.SMS_ADMINISTRATOR
      ? "sms-administrator/buildings-groups"
      : "sms-user/buildings-groups";

  return fetchClient<ISMSBuildingsGroup[]>(endpoint, { useAuth: true });
};

const useFetchSMSBuildings = () => {
  const queryClient = useQueryClient();
  const authUser = useAppSelector(selectAuthUser);
  const profileType = authUser.currentProfile?.type;

  useEffect(() => {
    if (
      profileType === ProfileType.SMS_ADMINISTRATOR ||
      profileType === ProfileType.SMS_USER
    ) {
      queryClient.prefetchQuery(["SMSBuildingsGroups", profileType], () =>
        fetchSMSBuildingsGroups(profileType as ProfileType)
      );
    }
  }, [profileType, queryClient]);

  return useQuery<ISMSBuildingsGroup[], Error>(
    ["SMSBuildingsGroups", profileType],
    () => fetchSMSBuildingsGroups(profileType as ProfileType),
    {
      enabled:
        profileType !== undefined &&
        (profileType === ProfileType.SMS_ADMINISTRATOR ||
          profileType === ProfileType.SMS_USER),
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export default useFetchSMSBuildings;
