import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import styles from "./positionOnMapViewer.module.scss";
import L from "leaflet";
import { useTranslation } from "react-i18next";
import TextFieldReactHookForm from "../helpersComponents/ReactHookFormInputs/TextField/textField";
import { useEffect } from "react";

const PositionOnMapViewer: React.FC<any> = ({ control, watch, setValue }) => {
  const { t } = useTranslation();
  const watchAllFields = watch();

  useEffect(() => {
    setValue("showOnMap", watchAllFields.showOnMap);
  }, [watchAllFields.showOnMap, setValue]);

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setValue("latitude", lat.toFixed(6));
    setValue("longitude", lng.toFixed(6));
  };

  const MapClickHandler = () => {
    useMapEvents({
      click: handleMapClick,
    });
    return null;
  };

  const markerIcon = new L.Icon({
    iconUrl: "https://unpkg.com/leaflet/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });

  return (
    <div className={styles.mapViewerContainer}>
      {watchAllFields.showOnMap && (
        <>
          <div>
            <div className={styles["grid-container"]}>
              <div className={styles["column"]}>
                <div>{t("position2")}</div>
              </div>

              <div className={styles["column"]}>
                <TextFieldReactHookForm
                  name="latitude"
                  type="number"
                  control={control}
                  label={t("latitude")}
                  required={true}
                />
              </div>

              <div className={styles["column"]}>
                <TextFieldReactHookForm
                  name="longitude"
                  type="number"
                  control={control}
                  label={t("longitude")}
                  required={true}
                />
              </div>
            </div>
          </div>

          <div>
            <MapContainer
              center={[52.06861, 19.47194]}
              zoom={5}
              style={{ height: "400px", width: "100%" }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MapClickHandler />
              <Marker
                position={[
                  watchAllFields.latitude || 0,
                  watchAllFields.longitude || 0,
                ]}
                icon={markerIcon}
              ></Marker>
            </MapContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default PositionOnMapViewer;
