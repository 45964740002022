import { SetStateAction, useCallback, useEffect, useState } from "react";
import { selectAuthUser } from "../../reducers/session";
import { selectSettings } from "../../reducers/settings";
import { useAppSelector } from "../../store/hooks";
import { ProfileType } from "../../enums/profileType";
import { profilesRoutesDictionary } from "../../components/ProfilesRouting/profilesDictionaries";
import useFetchOtherThanGET from "../fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useUpdateSettings from "../useUpdateSettings/useUpdateSettings";

export type T_WorkersSelectionPreset = {
  name: string;
  value: number[];
};
type T_WorkersSelectionPresets = T_WorkersSelectionPreset[];

const useWorkersSelectionPresets = () => {
  const settings = useAppSelector(selectSettings);
  const authUser = useAppSelector(selectAuthUser);
  const workerSelectionPresetsSettingName = `workersSelectionPresets_${authUser.currentProfile?.type}_${authUser.currentProfile?.subjectId}`;
  const [ifUpdateSetting, setIfUpdateSetting] = useState<boolean>(false);
  const [
    workersSelectionPresetsRequestBody,
    setWorkersSelectionPresetsRequestBody,
  ] = useState<any>(false);

  const get = () => {
    const presetsRAW = settings[workerSelectionPresetsSettingName];
    const presetsParsed = presetsRAW?.value ? JSON.parse(presetsRAW.value) : [];

    return presetsParsed as T_WorkersSelectionPresets;
  };

  const [presets, setPresets] = useState<T_WorkersSelectionPresets>(get());

  const update = (newVal: SetStateAction<T_WorkersSelectionPresets>) => {
    const body = {
      name: workerSelectionPresetsSettingName,
      value: JSON.stringify(
        typeof newVal === "function" ? newVal(presets) : newVal
      ),
    };
    setWorkersSelectionPresetsRequestBody(JSON.stringify(body));
    setPresets(() => (typeof newVal === "function" ? newVal(presets) : newVal));
  };

  const ifWorkerTimeId =
    authUser.currentProfile?.type === ProfileType.WORKER_TIME
      ? `/${authUser.currentProfile.subjectId}`
      : "";

  const successCallback = useCallback(() => {
    setIfUpdateSetting(true);
  }, []);

  const [, fetchAgainUpdateWorkersSelectionPresets] = useFetchOtherThanGET({
    path: `${
      profilesRoutesDictionary[authUser.currentProfile?.type]
    }${ifWorkerTimeId}/preferences`,
    method: "POST",
    body: workersSelectionPresetsRequestBody,
    setBody: setWorkersSelectionPresetsRequestBody,
    contentType: "application/json",
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (workersSelectionPresetsRequestBody) {
      fetchAgainUpdateWorkersSelectionPresets();
    }
  }, [
    workersSelectionPresetsRequestBody,
    fetchAgainUpdateWorkersSelectionPresets,
  ]);

  const ifSettingsUpdated = useUpdateSettings(ifUpdateSetting);

  useEffect(() => {
    setIfUpdateSetting(false);
  }, [ifSettingsUpdated]);

  return { presets, update };
};

export default useWorkersSelectionPresets;
