const sortWorkersByName = (workers: any[]) => {
    if (!Array.isArray(workers)) return [];

    const workersCopy = [...workers];
  
    return workersCopy.sort((a, b) => {
      const combinedNameA = a.lastName + a.firstName;
      const combinedNameB = b.lastName + b.firstName;

      return combinedNameA.localeCompare(combinedNameB);
    })
}

export default sortWorkersByName;