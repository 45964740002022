import { useTranslation } from "react-i18next";
import styles from "./VisualizationObjectLayers.module.scss";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import { ListItemIcon, ListItemText, TextField } from "@mui/material";
import SelectFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/SelectField/selectFIeld";
import { smsCompleteImageToBlob } from "../../../../../HelpersFunctions/imagesHelpers";
import ColorFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/ColorField/ColorFieldReactHookForm";

const VisualizationObjectImageLayersItem: React.FC<any> = ({
  index,
  control,
  getValues,
  setValue,
  watch,
  imagesData,
  clearErrors,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.inputsRow}>
        <div className={styles.inputWithTitleInRow}>
          <div className={styles.inputTitleInRow}>{t("image")}:</div>

          <div className={styles.inputFieldInRow}>
            <SelectFieldReactHookForm
              id={`layers.${index}.image`}
              name={`layers.${index}.image`}
              control={control}
              label={t("choose_image")}
              required={true}
              options={imagesData}
              clearErrors={clearErrors}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={t("choose_image")}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: params.inputProps.value && (
                        <div className={styles.imageListItemContainer}>
                          <img
                            src={URL.createObjectURL(
                              smsCompleteImageToBlob(
                                getValues(`layers.${index}.image`)
                              )
                            )}
                            alt={params.inputProps.value}
                            style={{
                              width: 64,
                              height: 64,
                              marginTop: 10,
                              marginRight: 8,
                            }}
                          />
                        </div>
                      ),
                    }}
                  />
                );
              }}
              renderOption={(props, option) => {
                return (
                  <li>
                    <div className={styles.imageListItemContainer}>
                      <div>
                        <ListItemIcon>
                          <img
                            src={URL.createObjectURL(
                              smsCompleteImageToBlob(props)
                            )}
                            alt={option.label}
                            style={{ width: 64, height: 64 }}
                          />
                        </ListItemIcon>
                      </div>
                      <div>
                        <ListItemText
                          className={styles.imageListItemLabel}
                          primary={props.name}
                        />
                      </div>
                    </div>
                  </li>
                );
              }}
              getOptionLabel={(option) => {
                return option.name;
              }}
              getOptionSelected={(option, value) => {
                return option.id === value.id;
              }}
              setValue={setValue}
              watch={watch}
            />
          </div>

          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("scale")}:</div>

            <div className={styles.inputFieldInRow}>
              <div className={styles.inputFieldInRow}>
                <TextFieldReactHookForm
                  id={`layers.${index}.image_scale`}
                  name={`layers.${index}.image_scale`}
                  control={control}
                  label={t("scale_in_percentage")}
                  type={"number"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.inputsRow}>
        <div className={styles.inputWithTitleInRow}>
          <div className={styles.inputTitleInRow}>{t("rotation_angle")}:</div>

          <div className={styles.inputFieldInRow}>
            <div className={styles.inputFieldInRow}>
              <div className={styles.inputFieldInRow}>
                <TextFieldReactHookForm
                  id={`layers.${index}.image_rotation_angle`}
                  name={`layers.${index}.image_rotation_angle`}
                  control={control}
                  label={t("rotation_angle")}
                  type={"number"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.inputWithTitleInRow}>
          <div className={styles.inputTitleInRow}>{t("color")}:</div>

          <div className={styles.inputFieldInRow}>
            <div className={styles.inputFieldInRow}>
              <div className={styles.inputFieldInRow}>
                <ColorFieldReactHookForm
                  id={`layers.${index}.image_color`}
                  name={`layers.${index}.image_color`}
                  control={control}
                  label={t("color")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.inputsRow}>
        <div className={styles.inputWithTitleInRow}>
          <div className={styles.inputTitleInRow}>{t("fill_opacity")}:</div>

          <div className={styles.inputFieldInRow}>
            <div className={styles.inputFieldInRow}>
              <div className={styles.inputFieldInRow}>
                <TextFieldReactHookForm
                  id={`layers.${index}.image_opacity`}
                  name={`layers.${index}.image_opacity`}
                  control={control}
                  label={t("fill_opacity")}
                  type={"number"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisualizationObjectImageLayersItem;
