import { Tab, Tabs, Tooltip } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { selectAuthUser } from "../../../../../reducers/session";
import { useAppSelector } from "../../../../../store/hooks";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { colorNameDictionary } from "../../../../ProfilesRouting/profilesDictionaries";
import formFieldsModel from "../FormModel/formFieldsModel";
import formInitialValues from "../FormModel/formInitialValues";
import validationSchema from "../FormModel/validationSchema";
import ACWorkerDataForm from "./ACWorkerDataForm/ACWorkerDataForm";
import CardForm from "./CardForm/CardForm";
import CompaniesForm from "./CompaniesForm/CompaniesForm";
import { ElevatorsCabinetsForm } from "./ElevatorsCabinetsForm/ElevatorsCabinetsForm";
import styles from "./FormTabs.module.scss";
import GroupsForm from "./GroupsForm/GroupsForm";
import MainForm from "./MainForm/MainForm";
import PermissionsForm from "./PermissionsForm/PermissionsForm";
import SpecialPermissionsForm from "./SpecialPermissionsForm/SpecialPermissionsForm";

const CenteredTabs = withStyles({
  flexContainer: {
    alignItems: "center",
    justifyContent: "space-between",
  },
})(Tabs);

interface IProps {
  rowId?: number;
  onSubmit: (values: AddACWorker) => void;
  isPreview?: boolean;
}

const FormTabs: React.FC<IProps> = ({ rowId, onSubmit, isPreview }) => {
  function TabPanel(props: {
    children?: React.ReactNode;
    index: number;
    value: number;
  }) {
    const { children, value, index, ...other } = props;

    return (
      <div
        className={styles.content}
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className={styles.childContent}>{children}</div>
        )}
      </div>
    );
  }

  const [valueTab, setValueTab] = useState(0);
  const handleChangeValueTab = (
    event: React.ChangeEvent<any>,
    newValue: number
  ) => {
    setValueTab(newValue);
  };

  const { t } = useTranslation();
  const authUserType = useAppSelector(selectAuthUser).currentProfile?.type;

  //Fetching from API
  const [
    departmentsList,
    fetchingStateDepartmentsList,
    fetchAgainDepartmentsList,
  ] = useFetchAndSetGET<Department[]>({
    path: "superior-ac/departments",
  });

  const [
    acSuperiorsList,
    fetchingStateACSuperiorsList,
    fetchAgainACSuperiorsList,
  ] = useFetchAndSetGET<ACUser[]>({
    path: "superior-ac/ac-superiors?isActive=true",
  });

  const [zonesList, fetchingStateZonesList, fetchAgainZonesList] =
    useFetchAndSetGET<Zone[]>({
      path: "superior-ac/zones?isActive=true",
    });

  const [
    meetingPlacesList,
    fetchingStateMeetingPlacesList,
    fetchAgainMeetingPlacesList,
  ] = useFetchAndSetGET<MeetingPlace[]>({
    path: "superior-ac/meeting-places?isActive=true",
  });

  const [companiesList, fetchingStateCompaniesList, fetchAgainCompaniesList] =
    useFetchAndSetGET<Company[]>({
      path: "superior-ac/ac-companies?isActive=true",
    });

  const [groupsList, fetchingStateGroupsList, fetchAgainGroupsList] =
    useFetchAndSetGET<Group[]>({
      path: "superior-ac/groups?isActive=true",
    });

  const getImage = async (acWorkerFetchedPhoto: string) => {
    const base64Response = await fetch(
      `data:image/png;base64,${acWorkerFetchedPhoto}`
    );
    const blob = await base64Response.blob();
    const file = new File([blob], "Photo");
    return file;
  };

  const modifyFetchedACWorker = useCallback((responseData: ACWorker) => {
    getImage(responseData.photo).then((res) => setPhoto(res));

    return responseData;
  }, []);

  const [
    acWorkerFetched,
    fetchingStateACWorkerFetched,
    fetchAgainACWorkerFetched,
  ] = useFetchAndSetGET<ACWorker>({
    path: `superior-ac/ac-workers/${rowId}?fullInfo=true`,
    startFetchOnInitial: rowId ? true : false,
    modifyResponseCallback: modifyFetchedACWorker,
  });

  const [
    elevatorsCabinetsList,
    fetchingStateElevatorsCabinetsList,
    fetchAgainElevatorsCabinetsList,
  ] = useFetchAndSetGET<ElevatorAndCabinet[]>({
    path: "superior-ac/elevators-cabinets",
  });

  const { formFields } = formFieldsModel;
  const validationSchemaObj = validationSchema(t);

  const isWorkerDataFormErrors = useCallback(
    (formikProps) => {
      if (
        formikProps.errors[formFields.firstName.name] ||
        formikProps.errors[formFields.lastName.name] ||
        formikProps.errors[formFields.position.name]
      )
        return true;

      return false;
    },
    [
      formFields.firstName.name,
      formFields.lastName.name,
      formFields.position.name,
    ]
  );

  const [photo, setPhoto] = useState<File | null>(null);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateDepartmentsList.isFetching ||
          fetchingStateACSuperiorsList.isFetching ||
          fetchingStateZonesList.isFetching ||
          fetchingStateMeetingPlacesList.isFetching ||
          fetchingStateCompaniesList.isFetching ||
          fetchingStateGroupsList.isFetching ||
          fetchingStateACWorkerFetched.isFetching ||
          fetchingStateElevatorsCabinetsList.isFetching
        }
        isError={
          fetchingStateDepartmentsList.isError ||
          fetchingStateACSuperiorsList.isError ||
          fetchingStateZonesList.isError ||
          fetchingStateMeetingPlacesList.isError ||
          fetchingStateCompaniesList.isError ||
          fetchingStateGroupsList.isError ||
          fetchingStateACWorkerFetched.isError ||
          fetchingStateElevatorsCabinetsList.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateDepartmentsList.isError) {
            fetchAgainDepartmentsList();
          }
          if (fetchingStateACSuperiorsList.isError) {
            fetchAgainACSuperiorsList();
          }
          if (fetchingStateZonesList.isError) {
            fetchAgainZonesList();
          }
          if (fetchingStateMeetingPlacesList.isError) {
            fetchAgainMeetingPlacesList();
          }
          if (fetchingStateCompaniesList.isError) {
            fetchAgainCompaniesList();
          }
          if (fetchingStateGroupsList.isError) {
            fetchAgainGroupsList();
          }
          if (fetchingStateACWorkerFetched.isError) {
            fetchAgainACWorkerFetched();
          }
          if (fetchingStateElevatorsCabinetsList.isError) {
            fetchAgainElevatorsCabinetsList();
          }
        }}
      >
        {(acWorkerFetched || rowId === undefined) &&
          departmentsList &&
          acSuperiorsList &&
          zonesList &&
          meetingPlacesList &&
          companiesList &&
          groupsList && (
            <Formik
              initialValues={formInitialValues(
                acWorkerFetched ? acWorkerFetched : null,
                meetingPlacesList,
                departmentsList,
                zonesList,
                acSuperiorsList,
                companiesList,
                groupsList,
                elevatorsCabinetsList
              )}
              validationSchema={validationSchemaObj}
              onSubmit={(values) => {
                const object = {
                  acWorker: { ...values },
                  photo: photo,
                } as AddACWorker;
                onSubmit(object);
              }}
            >
              {(formikProps) => {
                return (
                  <Form noValidate id="submitForm">
                    <div style={{ marginBottom: 10 }}>
                      <MainForm
                        formFields={formFields}
                        formikErrors={formikProps.errors}
                        acWorkerFetchedPhoto={acWorkerFetched?.photo}
                        photo={photo}
                        setPhoto={setPhoto}
                      />
                    </div>

                    <CenteredTabs
                      value={valueTab}
                      onChange={handleChangeValueTab}
                      variant="scrollable"
                      scrollButtons="on"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: `${colorNameDictionary[authUserType]}`,
                        },
                      }}
                    >
                      <Tab
                        label={t("employee_data")}
                        value={0}
                        icon={
                          isWorkerDataFormErrors(formikProps) &&
                          valueTab !== 0 ? (
                            <Tooltip title={t("invalid_data")!} placement="top">
                              <Error color="error" />
                            </Tooltip>
                          ) : (
                            <></>
                          )
                        }
                        color="white"
                      />

                      <Tab
                        label={t("companies")}
                        value={1}
                        icon={
                          formikProps.errors[formFields.companies.name] &&
                          valueTab !== 0 ? (
                            <Tooltip title={t("invalid_data")!} placement="top">
                              <Error color="error" />
                            </Tooltip>
                          ) : (
                            <></>
                          )
                        }
                      />

                      <Tab label={t("permission_groups")} value={2} />

                      <Tab label={t("permission")} value={3} />

                      <Tab
                        label={`${t("elevators")}/${t("cabinets")}`}
                        value={5}
                      />

                      <Tab label={t("card")} value={6} />

                      <Tab label={t("special_permissions")} value={7} />
                    </CenteredTabs>

                    <TabPanel value={valueTab} index={0}>
                      <ACWorkerDataForm
                        formFields={formFields}
                        setFieldValue={formikProps.setFieldValue}
                        departmentsList={departmentsList}
                        acSuperiorsList={acSuperiorsList}
                        zonesList={zonesList}
                        meetingPlacesList={meetingPlacesList}
                      />
                    </TabPanel>

                    <TabPanel value={valueTab} index={1}>
                      <CompaniesForm
                        formFields={formFields}
                        companiesList={companiesList}
                        formikValues={formikProps.values}
                        setFieldValue={formikProps.setFieldValue}
                        error={formikProps.errors[formFields.companies.name]}
                      />
                    </TabPanel>

                    <TabPanel value={valueTab} index={2}>
                      <GroupsForm
                        formFields={formFields}
                        groupsList={groupsList}
                        companiesList={companiesList}
                        formikValues={formikProps.values}
                        setFieldValue={formikProps.setFieldValue}
                      />
                    </TabPanel>

                    <TabPanel value={valueTab} index={3}>
                      <PermissionsForm
                        formFields={formFields}
                        formikValues={formikProps.values}
                        setFieldValue={formikProps.setFieldValue}
                        isPreview={isPreview}
                      />
                    </TabPanel>

                    <TabPanel value={valueTab} index={5}>
                      <ElevatorsCabinetsForm
                        elevatorsCabinetsList={elevatorsCabinetsList}
                        formFields={formFields}
                        formikValues={formikProps.values}
                        setFieldValue={formikProps.setFieldValue}
                      />
                    </TabPanel>

                    <TabPanel value={valueTab} index={6}>
                      <CardForm
                        formFields={formFields}
                        formikValues={formikProps.values}
                        setFieldValue={formikProps.setFieldValue}
                        isPreview={isPreview}
                      />
                    </TabPanel>

                    <TabPanel value={valueTab} index={7}>
                      <SpecialPermissionsForm
                        formFields={formFields}
                        formikValues={formikProps.values}
                        setFieldValue={formikProps.setFieldValue}
                        isPreview={isPreview}
                      />
                    </TabPanel>
                  </Form>
                );
              }}
            </Formik>
          )}
      </LoadingWrapper>
    </>
  );
};

export default FormTabs;
