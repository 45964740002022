import { useTranslation } from "react-i18next";
import styles from "./VisualizationObjectLayers.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnchor,
  faClone,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import SelectFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/SelectField/selectFIeld";
import SMSVisualizationObjectLayerType from "../../../../../enums/SMS/SMSVisualizationObjectLayerType";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import {
  selectVisualizationObjectsBuiltInStates,
  selectVisualizationObjectsLayersTypes,
} from "../../../../../reducers/sms/visualizationData";
import VisualizationObjectTextLayersItem from "./VisualizationObjectTextLayersItem";
import VisualizationObjectImageLayersItem from "./VisualizationObjectImageLayersItem";
import VisualizationObjectGraphLayersItem from "./VisualizationObjectGraphLayersItem";
import { useSMSVisualizationDataContext } from "../../../../../HelpersFunctions/SMSVisualizationDataProvider";

const VisualizationObjectLayersItem: React.FC<any> = ({
  index,
  control,
  register,
  setValue,
  insert,
  remove,
  getValues,
  clearErrors,
  watch,
}) => {
  const { t } = useTranslation();
  const layerTypes = useAppSelector(selectVisualizationObjectsLayersTypes);
  const { imagesData } = useSMSVisualizationDataContext();

  const statesValues = watch("states");
  const layersValues = watch("layers");

  const [statesList, setStatesList] = useState<any>([]);
  const visualizationObjectsBuiltInStates = useAppSelector(
    selectVisualizationObjectsBuiltInStates
  );

  useEffect(() => {
    let tempList: any = [];
    [...visualizationObjectsBuiltInStates].forEach((el) => tempList.push(el));

    if (statesValues) {
      for (let i = 0; i < statesValues.length; i++) {
        tempList.push({
          id: statesValues[i].virtualId,
          name: statesValues[i].name,
        });
      }
    }

    setStatesList(tempList);
  }, [visualizationObjectsBuiltInStates, statesValues, t]);

  return (
    <div className={styles.actionsMainContainer}>
      <div className={styles.layerItemTitle}>
        <p>
          {t("layer")}
          <FontAwesomeIcon
            className={styles.faTrash}
            icon={faTimesCircle}
            onClick={() => {
              remove(index);
            }}
          />
          <FontAwesomeIcon
            className={styles.faClone}
            icon={faClone}
            onClick={() => {
              let tempBaseAnchoredLayerId =
                layersValues[index].baseAnchoredLayerId;

              if (layersValues[index].isAnchoredLayer) {
                tempBaseAnchoredLayerId = layersValues[index].id;
              }

              insert(index + 1, {
                ...layersValues[index],
                id: 0,
                baseAnchoredLayerId: tempBaseAnchoredLayerId,
                isAnchoredLayer: !!tempBaseAnchoredLayerId,
              });
            }}
          />
        </p>
      </div>
      <div className={styles.layerItem}>
        {layersValues[index].isAnchoredLayer && (
          <FontAwesomeIcon className={styles.faAnchor} icon={faAnchor} />
        )}

        <div className={styles.inputsRow}>
          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("name")}:</div>
            <div className={styles.inputFieldInRow}>
              <TextFieldReactHookForm
                id={`layers.${index}.name`}
                name={`layers.${index}.name`}
                control={control}
                label={t("name")}
                required={true}
              />
            </div>
          </div>

          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("type")}:</div>
            <div className={styles.inputFieldInRow}>
              <SelectFieldReactHookForm
                id={`layers.${index}.type`}
                name={`layers.${index}.type`}
                control={control}
                label={t("choose_type")}
                required={true}
                options={layerTypes || []}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                getOptionSelected={(option, value) => {
                  return option.id === value.id;
                }}
                setValue={setValue}
                watch={watch}
                clearErrors={clearErrors}
              />
            </div>
          </div>
        </div>

        <div className={styles.inputsRow}>
          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>
              {t("displayed_in_states")}:
            </div>
            <div className={styles.inputFieldInRow}>
              <SelectFieldReactHookForm
                id={`layers.${index}.states`}
                name={`layers.${index}.states`}
                control={control}
                label={t("choose_state")}
                required={true}
                options={statesList || []}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                getOptionSelected={(option, value) => {
                  return (
                    option.id === value.id || option.id === value.virtualId
                  );
                }}
                setValue={setValue}
                clearErrors={clearErrors}
                watch={watch}
                multiple
                disableCloseOnSelect
              />
            </div>
          </div>

          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("order")}:</div>
            <div className={styles.inputFieldInRow}>
              <TextFieldReactHookForm
                id={`layers.${index}.order`}
                name={`layers.${index}.order`}
                type="number"
                control={control}
                label={t("order")}
                required={true}
              />
            </div>
          </div>
        </div>

        <div className={styles.inputsRow}>
          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("group")}:</div>
            <div className={styles.inputFieldInRow}>
              <TextFieldReactHookForm
                id={`layers.${index}.group`}
                name={`layers.${index}.group`}
                type="number"
                control={control}
                label={t("group")}
              />
            </div>
          </div>
        </div>

        {layersValues[index].type?.id ===
          SMSVisualizationObjectLayerType.IMAGE_LAYER && (
          <VisualizationObjectImageLayersItem
            index={index}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            imagesData={imagesData}
            clearErrors={clearErrors}
          />
        )}

        {layersValues[index].type?.id ===
          SMSVisualizationObjectLayerType.GRAPH_LAYER && (
          <VisualizationObjectGraphLayersItem control={control} index={index} />
        )}

        {layersValues[index].type?.id ===
          SMSVisualizationObjectLayerType.TEXT_LAYER && (
          <VisualizationObjectTextLayersItem
            control={control}
            index={index}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
          />
        )}
      </div>
    </div>
  );
};

export default VisualizationObjectLayersItem;
