import { useTranslation } from "react-i18next";
import styles from "./VisualizationObjectLayers.module.scss";
import { TextFieldReactHookForm } from "../../../../helpersComponents/ReactHookFormInputs";
import SelectFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/SelectField/selectFIeld";
import { useAppSelector } from "../../../../../store/hooks";
import { selectTextFonts } from "../../../../../reducers/sms/visualizationData";
import CheckboxWithLabelFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/CheckboxWithLabelField/checkboxWithLabelField";
import ColorFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/ColorField/ColorFieldReactHookForm";

const VisualizationObjectTextLayersItem: React.FC<any> = ({
  control,
  index,
  setValue,
  getValues,
  watch,
}) => {
  const { t } = useTranslation();
  const textFonts = useAppSelector(selectTextFonts);

  return (
    <div className={styles["grid-container"]}>
      {/*Tekst*/}
      <div className={styles["grid-item"]}>{t("text")}:</div>
      <div className={styles["grid-item"]}>
        <TextFieldReactHookForm
          id={`layers.${index}.text`}
          name={`layers.${index}.text`}
          control={control}
          label={t("text")}
        />
      </div>
      {/*Czcionka*/}
      <div className={styles["grid-item"]}>{t("text_font")}:</div>
      <div className={styles["grid-item"]}>
        {" "}
        <SelectFieldReactHookForm
          id={`layers.${index}.text_font`}
          name={`layers.${index}.text_font`}
          control={control}
          label={t("choose_font")}
          options={textFonts || []}
          getOptionLabel={(option) => {
            return option.name;
          }}
          getOptionSelected={(option, value) => {
            return option.id === value.id;
          }}
          setValue={setValue}
          watch={watch}
        />
      </div>
      {/*Kolor tekstu*/}
      <div className={styles["grid-item"]}>{t("text_color")}:</div>
      <div className={styles["grid-item"]}>
        <ColorFieldReactHookForm
          id={`layers.${index}.text_color`}
          name={`layers.${index}.text_color`}
          control={control}
          label={t("text_color")}
        />
      </div>
      {/*Kolor tła*/}
      <div className={styles["grid-item"]}>{t("text_background_color")}:</div>
      <div className={styles["grid-item"]}>
        <ColorFieldReactHookForm
          id={`layers.${index}.text_background_color`}
          name={`layers.${index}.text_background_color`}
          control={control}
          label={t("text_background_color")}
        />
      </div>
      {/*Rozmiar tekstu*/}
      <div className={styles["grid-item"]}>{t("text_size")}:</div>
      <div className={styles["grid-item"]}>
        <TextFieldReactHookForm
          id={`layers.${index}.text_size`}
          name={`layers.${index}.text_size`}
          control={control}
          label={t("text_size")}
          type={"number"}
        />
      </div>

      {/*Wypełnienie*/}
      <div className={styles["grid-item"]}>{t("fill_opacity")}:</div>
      <div className={styles["grid-item"]}>
        <TextFieldReactHookForm
          id={`layers.${index}.text_background_color_opacity`}
          name={`layers.${index}.text_background_color_opacity`}
          control={control}
          label={t("fill_opacity")}
          type={"number"}
        />
      </div>

      {/*Opcje tekstu*/}
      <div className={styles["grid-item"]}>{t("options")}:</div>
      <div className={styles["grid-item"]}>
        <div className={styles["options-container"]}>
          <CheckboxWithLabelFieldReactHookForm
            id={`layers.${index}.text_options_italics`}
            name={`layers.${index}.text_options_italics`}
            control={control}
            value={getValues("text_options_italics")}
            setValue={setValue}
            ifCircle={true}
            label={t("text_italics")}
          />
          <CheckboxWithLabelFieldReactHookForm
            id={`layers.${index}.text_options_bold`}
            name={`layers.${index}.text_options_bold`}
            control={control}
            value={getValues("text_options_bold")}
            setValue={setValue}
            ifCircle={true}
            label={t("text_bold")}
          />
          <CheckboxWithLabelFieldReactHookForm
            id={`layers.${index}.text_options_underline`}
            name={`layers.${index}.text_options_underline`}
            control={control}
            value={getValues("text_options_underline")}
            setValue={setValue}
            ifCircle={true}
            label={t("text_underline")}
          />
        </div>
      </div>

      <div className={styles["grid-item"]}>{t("scale")}:</div>
      <div className={styles["grid-item"]}>
        <TextFieldReactHookForm
          id={`layers.${index}.text_scale`}
          name={`layers.${index}.text_scale`}
          control={control}
          label={t("scale_in_percentage")}
          type={"number"}
        />
      </div>
    </div>
  );
};

export default VisualizationObjectTextLayersItem;
