import {
  faCheck,
  faDoorClosed,
  faDoorOpen,
  faMinusCircle,
  faThumbsUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StatusInPortsType } from "../../../Constants/statusInPortsType";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import computeDateRangeFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import { CheckboxStyled } from "../../helpersComponents/MaterialUi";
import PopupRequirements from "../../helpersComponents/PopupRequirements/PopupRequirements";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import RejectPopup from "../../pfso/OperationButtons/RejectPopup";
import PopupLetIn from "../../security/Notifications/PopupLetIn";
import Navigation from "../Navigation/navigationReception";
import RowDetailsCrewEntryDescent from "./RowDetails/RowDetailsCrewEntryDescent";
import RowDetailsNotifications from "./RowDetails/RowDetailsNotifications";
import RowDetailsServicesAndDeliveries from "./RowDetails/RowDetailsServicesAndDeliveries";
import styles from "./styles.module.scss";
import { TableRow } from "@devexpress/dx-react-grid";
import { Table } from "@devexpress/dx-react-grid-material-ui";

const ContentNotifications = () => {
  const { t } = useTranslation();

  const [openLetInGuestState, setOpenLetInGuestState] = useState<{
    isOpen: boolean;
    row: INotificationGuestsInPorts | null;
  }>({
    isOpen: false,
    row: null,
  });
  const handleCloseLetInGuest = useCallback(() => {
    setOpenLetInGuestState({
      isOpen: false,
      row: null,
    });
  }, []);

  const [openRequirementsPopup, setOpenRequirementsPopup] = useState<{
    isOpen: boolean;
    guestId: null | number;
  }>({
    isOpen: false,
    guestId: null,
  });
  const handleCloseRequirementsPopup = useCallback(() => {
    setOpenRequirementsPopup({
      isOpen: false,
      guestId: null,
    });
  }, []);

  const settings = useAppSelector(selectSettings);

  const columns = [
    { name: "notificationNumber", title: t("notification_number") },
    { name: "type", title: t("type") },
    { name: "confirmationStatusName", title: t("confirmation_status") },
    { name: "person", title: t("person") },
    { name: "dateFrom", title: t("date_from"), type: "date" },
    { name: "dateTo", title: t("date_to2"), type: "date" },
    { name: "company", title: t("company") },
    {
      name: "bh3Check",
      title: t("BH3"),
    },
    { name: "permissionZoneName", title: t("permission_zone") },
    { name: "requirements", title: t("requirements") },
  ];

  const companiesExcluded = settings["FirmyWykluczoneZeSzkolenia"]?.value
    ?.split(",")
    .map((company: string) => company.trim());

  const requirementsResult = useCallback(
    (row: INotificationGuestsInPorts) => {
      const companiesExcludedTraining = settings[
        "FirmyWykluczoneZeSzkolenia"
      ]?.value
        ?.split(",")
        .map((company: string) => company.trim());

      return companiesExcludedTraining?.includes(row.companyName) ? (
        <FontAwesomeIcon
          className={styles.iconExcluded}
          icon={faThumbsUp}
          size="2x"
        />
      ) : ((row.notificationId &&
          (row.typeOfVisitId === TypeOfVisit.COMPLETION_OF_THE_WORK ||
            row.typeOfVisitId === TypeOfVisit.REVISION)) ||
          row.deliveryServiceId) &&
        ((row.guestTrainingExpiryDate &&
          getEndOfDate(new Date(row.guestTrainingExpiryDate), "Day") <
            getEndOfDate(new Date(), "Day")) ||
          row.guestTrainingExpiryDate === null) ? (
        <FontAwesomeIcon
          className={styles.iconDisagree}
          icon={faTimes}
          size="2x"
          onClick={() => {
            setOpenRequirementsPopup({
              isOpen: true,
              guestId: row.guestId,
            });
          }}
        />
      ) : (
        <FontAwesomeIcon
          className={styles.iconAgree}
          icon={faCheck}
          size="2x"
        />
      );
    },
    [settings]
  );

  const modifyResponseCallback = useCallback(
    (responseData) => {
      const modified = responseData.map((row: INotificationGuestsInPorts) => {
        return {
          ...row,
          id: row.id,
          type:
            row.typeOfVisitId === TypeOfVisit.REVISION
              ? t("revisions_cfs_magazine")
              : row?.crewEntryDescentId
              ? row?.crewEntryDescentType === false
                ? t("crew_entry")
                : t("crew_descent")
              : row?.deliveryServiceId
              ? t("service_delivery")
              : t("notification_2"),
          person: `${row.guestFirstName} ${row.guestLastName}`,
          company: row.companyName ? row.companyName : "-",
          typeOfVisitName:
            row.typeOfVisitId === TypeOfVisit.BUSINESS &&
            row.isToPfsoAndShiftDirector
              ? t("application_for_training")
              : row.typeOfVisitName,
          permissionZoneName: row.permissionZoneName
            ? row.permissionZoneName
            : "-",
          targetDepartment: row.targetDepartmentName
            ? row.targetDepartmentName
            : "-",
          requirements: (
            <div className={styles.containerIconRequirements}>
              {requirementsResult(row)}
            </div>
          ),
          bh3Check: row?.notificationId ? (
            <CheckboxStyled checked={row.bH3 ? true : false} disabled />
          ) : (
            "-"
          ),
        };
      });
      return modified;
    },
    [t, requirementsResult]
  );

  const dateFrom = computeDateRangeFetch(
    getBeginningOfDate(new Date(), "Day", { extraDays: -2 })
  );
  const dateTo = computeDateRangeFetch(
    getBeginningOfDate(new Date(), "Day", { extraDays: 2 })
  );

  const [
    notificationsRows,
    fetchingStateNotifications,
    fetchAgainNotifications,
  ] = useFetchAndSetGET<INotificationGuestsInPorts[]>({
    path: `reception/notification-guests-in-ports?ConfirmationStatuses=4&ConfirmationStatuses=7&RegistrationNumber=false&DateFrom=${dateFrom}&DateTo=${dateTo}`,
    modifyResponseCallback: modifyResponseCallback,
  });

  let timeRefreshingNotifications: number = settings["CzasOdswiezaniaAwizacji"]
    ?.value
    ? settings["CzasOdswiezaniaAwizacji"].value
    : 300_000;

  const { handleChangeRefreshingTime } = useRefreshData(
    fetchAgainNotifications,
    timeRefreshingNotifications
  );

  const [isOpenRejectPopup, setIsOpenRejectPopup] = useState(false);
  const [rejectPathApi, setRejectPathApi] = useState("");

  const handleCloseRejectPopup = useCallback(() => {
    setIsOpenRejectPopup(false);
  }, []);

  const handleOpenRejectPopup = useCallback(() => {
    setIsOpenRejectPopup(true);
  }, []);

  const [tableRow, setTableRow] =
    useState<
      ({ row, tableRow, children, ...restProps }: ICustomRow) => JSX.Element
    >();

  interface ICustomRow {
    tableRow: TableRow;
    row: any;
    children: React.ReactNode;
  }

  const highlightedRows = useCallback(() => {
    const TableRow = ({
      row,
      tableRow,
      children,
      ...restProps
    }: ICustomRow): JSX.Element => {
      return (
        <Table.Row
          row={row}
          tableRow={tableRow}
          children={children}
          {...restProps}
          style={
            row.isGuestOnBlackList === true
              ? { backgroundColor: "#FFC4C4" }
              : {}
          }
        />
      );
    };
    return TableRow;
  }, []);

  useEffect(() => {
    if (notificationsRows) {
      setTableRow(() => highlightedRows());
    }
  }, [notificationsRows, highlightedRows]);

  return (
    <>
      <div>
        <TopBanner pathName={t("list_of_notifications")} />
      </div>
      <div>
        <Navigation />
      </div>
      <div>
        <TableComponentMemo
          columns={columns}
          rows={notificationsRows}
          customRow={tableRow}
          layoutSettingName="layoutReceptionNotificationsRecords"
          hideActionsAndVisibilityColumns={true}
          rowDetail={(data) => {
            const rowInfo = data?.row as INotificationGuestsInPorts;
            if (rowInfo.crewEntryDescentId) {
              return <RowDetailsCrewEntryDescent row={rowInfo} />;
            }
            if (rowInfo.deliveryServiceId) {
              return <RowDetailsServicesAndDeliveries row={rowInfo} />;
            }
            return <RowDetailsNotifications row={rowInfo} />;
          }}
          fetchingState={{
            isFetching:
              fetchingStateNotifications.isFetching ||
              notificationsRows === null,
            isError: fetchingStateNotifications.isError,
            fetchAgain: fetchAgainNotifications,
          }}
          actionsColumnUpdated={{
            manualRefreshButton: {
              ifShow: true,
              onClick: () => {
                fetchAgainNotifications();
                handleChangeRefreshingTime(timeRefreshingNotifications);
              },
            },
            customButton1: {
              name: t("let_in"),
              ifShow: true,
              customComponent: (row) => {
                return (!companiesExcluded?.includes(row.companyName) &&
                  (row.typeOfVisitId === TypeOfVisit.COMPLETION_OF_THE_WORK ||
                    row.typeOfVisitId === TypeOfVisit.REVISION ||
                    row.deliveryServiceId) &&
                  getEndOfDate(new Date(row.guestTrainingExpiryDate), "Day") <
                    getEndOfDate(new Date(), "Day")) ||
                  row.eventType === true ? (
                  <></>
                ) : (
                  <>
                    <Tooltip
                      title={
                        row?.crewEntryDescentType === true
                          ? t("let_out")
                          : t("let_in")
                      }
                    >
                      <Button
                        disabled={row.isGuestOnBlackList}
                        onClick={() => {
                          setOpenLetInGuestState({
                            isOpen: true,
                            row: row,
                          });
                        }}
                        style={{ fontWeight: "bold" }}
                      >
                        {row?.crewEntryDescentType === true ? (
                          <FontAwesomeIcon
                            icon={faDoorClosed}
                            style={{ fontSize: "20px", color: "red" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faDoorOpen}
                            style={{ fontSize: "20px", color: "green" }}
                          />
                        )}
                      </Button>
                    </Tooltip>
                  </>
                );
              },
            },
            removeButton: {
              ifShow: true,
              name: t("reject"),
              customComponent: (row: INotificationGuestsInPorts) => {
                return row.confirmationStatus === StatusInPortsType.LETTED_IN &&
                  row.guestVisitIsFinished !== true ? (
                  <></>
                ) : (
                  <Tooltip title={t("reject")}>
                    <Button
                      onClick={() => {
                        setIsOpenRejectPopup(true);
                        row.crewEntryDescentId
                          ? setRejectPathApi(
                              `reception/crew-entry-guest-change-let-in/${row.id}`
                            )
                          : row.deliveryServiceId
                          ? setRejectPathApi(
                              `reception/delivery-service-guest-change-let-in/${row.id}`
                            )
                          : setRejectPathApi(
                              `reception/notification-guest-change-let-in/${row.id}`
                            );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        style={{ fontSize: "20px", color: "red" }}
                      />
                    </Button>
                  </Tooltip>
                );
              },
            },
          }}
        />
      </div>
      <div>
        {openLetInGuestState.isOpen && (
          <PopupLetIn
            open={openLetInGuestState.isOpen}
            row={openLetInGuestState.row}
            onCancelChanges={handleCloseLetInGuest}
            successCallback={fetchAgainNotifications}
            pathConditional={(row: INotificationGuestsInPorts) =>
              row?.crewEntryDescentId
                ? `reception/crew-entry-guest-change-let-in/${row?.id}`
                : row?.deliveryServiceId
                ? `reception/delivery-service-guest-let-in/${row?.id}`
                : `reception/notification-guest-let-in/${row?.id}`
            }
            permissionZonesPathApi="reception/permission-zones"
            selectedPermissionZoneId={openLetInGuestState.row?.permissionZoneId}
            hidePermissionZone={
              openLetInGuestState.row?.crewEntryDescentId !== null
            }
            excludedFromTraining={companiesExcluded?.includes(
              openLetInGuestState.row?.companyName
            )}
            bh3={
              openLetInGuestState.row?.notificationId &&
              openLetInGuestState.row?.bH3 !== null
                ? openLetInGuestState.row?.bH3
                : null
            }
          />
        )}
      </div>
      <div>
        {isOpenRejectPopup && (
          <RejectPopup
            patchApi={rejectPathApi}
            isOpen={isOpenRejectPopup}
            successCallback={fetchAgainNotifications}
            closePopup={handleCloseRejectPopup}
            openPopup={handleOpenRejectPopup}
            isSecurity={true}
          />
        )}
      </div>
      <div>
        <PopupRequirements
          open={openRequirementsPopup.isOpen}
          guestId={openRequirementsPopup.guestId}
          pathGetGuestTrainingDateExpiry="reception/guest-training-date-expiry"
          pathUpdateTrainingDateExpiry="reception/guest-training-date-expiry"
          onCancelChanges={handleCloseRequirementsPopup}
          successCallback={fetchAgainNotifications}
        />
      </div>
    </>
  );
};

export default ContentNotifications;
