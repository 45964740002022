import { useDrag } from "react-dnd";
import styles from "./VisualizationObjectsExplorerObjectItem.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import { smsCompleteImageToBlob } from "../../../../../HelpersFunctions/imagesHelpers";
import VisualizationObjectsExplorerLayerItem from "./VisualizationObjectsExplorerLayerItem";

const VisualizationObjectsExplorerObjectItem: React.FC<
  IVisualizationObjectsExplorerObjectItem
> = ({
  id,
  name,
  description,
  icon,
  layers,
  onExpand,
  isExpanded,
  selectedLayer,
  setSelectedLayer,
}) => {
  const [, dragVisualizationObjectItem] = useDrag({
    type: "VISUALIZATION_OBJECT_ITEM",
    item: { id, type: "VISUALIZATION_OBJECT_ITEM" },
  });

  const btnExpandCollapseClick = () => {
    onExpand(id);
  };

  return (
    <div className={styles["visualization-object-main-container"]}>
      <div
        ref={dragVisualizationObjectItem}
        className={styles["visualization-object-item-container"]}
      >
        <div
          className={`${styles["visualization-object-item"]} ${styles["visualization-object-item-icon-container"]}`}
        >
          {icon && (
            <img
              src={URL.createObjectURL(smsCompleteImageToBlob(icon))}
              alt=""
              style={{ maxWidth: "100px", maxHeight: "30px" }}
            />
          )}

          {!icon && (
            <img
              src="/images/object_icon_36x36.png"
              alt=""
              style={{ maxWidth: "100px", maxHeight: "30px" }}
            />
          )}
        </div>
        <div
          className={`${styles["visualization-object-item"]} ${styles["visualization-object-item-name-container"]}`}
        >
          <Tooltip title={description || name}>
            <div className={styles["visualization-object-item-name"]}>
              {name}
            </div>
          </Tooltip>
        </div>
        <div
          className={`${styles["visualization-object-item"]} ${styles["caret-icon"]}`}
          onClick={btnExpandCollapseClick}
        >
          {isExpanded && <FontAwesomeIcon icon={faCaretUp} />}
          {!isExpanded && <FontAwesomeIcon icon={faCaretDown} />}
        </div>
      </div>
      <div className={styles["layers-container"]}>
        {isExpanded &&
          layers.map((layer) => (
            <VisualizationObjectsExplorerLayerItem
              key={layer.id}
              id={layer.id}
              name={layer.name}
              type={layer.type}
              groupItemsNumber={layer.groupItemsNumber}
              isSelected={selectedLayer === layer.id}
              setSelectedLayer={setSelectedLayer}
            />
          ))}
      </div>
    </div>
  );
};

export default VisualizationObjectsExplorerObjectItem;
