import { useQuery, useQueryClient } from "@tanstack/react-query";
import fetchClient from "../../../api/fetchClient";
import { ProfileType } from "../../../enums/profileType";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import { useEffect } from "react";

const fetchSMSVisualizationObjectsStatesGroups = async (
  profileType: ProfileType
): Promise<ISMSVisualizationObjectsStatesGroupsLibraryItem[]> => {
  const endpoint =
    profileType === ProfileType.SMS_ADMINISTRATOR
      ? "sms-administrator/visualization-objects-states-groups-library"
      : "sms-user/visualization-objects-states-groups-library";

  return fetchClient<ISMSVisualizationObjectsStatesGroupsLibraryItem[]>(
    endpoint,
    { useAuth: true }
  );
};

const useFetchSMSVisualizationObjectsStatesGroups = () => {
  const queryClient = useQueryClient();
  const authUser = useAppSelector(selectAuthUser);
  const profileType = authUser.currentProfile?.type;

  useEffect(() => {
    if (
      profileType === ProfileType.SMS_ADMINISTRATOR ||
      profileType === ProfileType.SMS_USER
    ) {
      queryClient.prefetchQuery(
        ["SMSVisualizationObjectsStatesGroups", profileType],
        () =>
          fetchSMSVisualizationObjectsStatesGroups(profileType as ProfileType)
      );
    }
  }, [profileType, queryClient]);

  return useQuery<ISMSVisualizationObjectsStatesGroupsLibraryItem[], Error>(
    ["SMSVisualizationObjectsStatesGroups", profileType],
    () => fetchSMSVisualizationObjectsStatesGroups(profileType as ProfileType),
    {
      enabled:
        profileType !== undefined &&
        (profileType === ProfileType.SMS_ADMINISTRATOR ||
          profileType === ProfileType.SMS_USER),
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export default useFetchSMSVisualizationObjectsStatesGroups;
