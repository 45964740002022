import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import AddEditVisualizationObjectsStatesGroupsPopup from "./Popups/AddEditVisualizationObjectsStatesGroupsPopup";
import { formatSizeInBytes } from "../../../../HelpersFunctions/stringUtils";

const VisualizationObjectsStatesGroupsLibrarySMSAdministrator = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [idToRemove, setIdToRemove] = useState(false);

  const columns = [
    { name: "name", title: t("name") },
    { name: "order", title: t("order") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "editedBy", title: t("edited") },
    { name: "editedAt", title: t("data_edited"), type: "date_hours" },
  ];

  const modifyResponseCallback = useCallback((responseData) => {
    const modified = responseData.map((row) => {
      return {
        ...row,
        fileSize: formatSizeInBytes(row.fileSize),
      };
    });
    return modified;
  }, []);

  const [
    visualizationObjectsStatesGroups,
    fetchingStatevisualizationObjectsStatesGroups,
    fetchAgainVisualizationObjectsStatesGroups,
  ] = useFetchAndSetGET({
    path: `sms-administrator/visualization-objects-states-groups-library`,
    modifyResponseCallback: modifyResponseCallback,
    startFetchOnInitial: true,
  });

  const [, fetchAgainRemoveVisualizationObjectsStatesGroup] =
    useFetchOtherThanGET({
      path: `sms-administrator/visualization-objects-states-groups-library/${idToRemove}`,
      method: "DELETE",
      body: idToRemove,
      setBody: setIdToRemove,
      successCallback: fetchAgainVisualizationObjectsStatesGroups,
    });

  useEffect(() => {
    if (idToRemove) {
      fetchAgainRemoveVisualizationObjectsStatesGroup();
    }
  }, [idToRemove, fetchAgainRemoveVisualizationObjectsStatesGroup]);

  return (
    <div>
      <TopBanner pathName={`${t("visualization_objects_states_groups")}`} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={
              fetchingStatevisualizationObjectsStatesGroups.isFetching
            }
            isError={fetchingStatevisualizationObjectsStatesGroups.isError}
            setIfFetchAgain={() => {
              fetchAgainVisualizationObjectsStatesGroups();
            }}
          >
            {!fetchingStatevisualizationObjectsStatesGroups.isFetching && (
              <TableComponent
                layoutSettingName="layoutSMSAdministratorVisualizationObjectsStatesGroupsLibrary"
                rows={visualizationObjectsStatesGroups || []}
                columns={columns}
                actionsColumnUpdated={{
                  popup: (props) => (
                    <AddEditVisualizationObjectsStatesGroupsPopup {...props} />
                  ),
                  successCallbackPopup:
                    fetchAgainVisualizationObjectsStatesGroups,
                  addButton: {
                    ifShow: true,
                  },
                  removeButton: {
                    ifShow: true,
                    name: t("delete"),
                    onClick: async (item) => {
                      let selectedbuttonId = await confirm({
                        text: `${t(
                          "confirm_delete_visualization_objects_states_group"
                        )}`,
                        buttons: [
                          { buttonName: t("remove"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });

                      if ((await selectedbuttonId) === 0) {
                        setIdToRemove(item.id);
                      }
                    },
                  },
                  editButton: {
                    ifShow: true,
                    name: t("edit"),
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default VisualizationObjectsStatesGroupsLibrarySMSAdministrator;
