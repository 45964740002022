import TextFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import TwoColumnsRowsContainer from "../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import Row from "../../../helpersComponents/PopupHelpers/row";
import RowTitle from "../../../helpersComponents/PopupHelpers/rowTitle";
import RowInput from "../../../helpersComponents/PopupHelpers/rowInput";
import SelectFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/SelectField/selectFIeld";
import { Tab, Tabs, Tooltip } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import BuildingFloors from "./BuildingFloors";
import { colorNameDictionary } from "./../../../ProfilesRouting/profilesDictionaries";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import { selectAuthUser } from "../../../../reducers/session";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import erro400getTranslatedErrorString from "../../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import styles from "./addEditBuildingPopupContent.module.scss";
import PositionOnMapViewer from "../../../PositionOnMapViewer/PositionOnMapViewer";
import CheckboxWithLabelFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/CheckboxWithLabelField/checkboxWithLabelField";
import { ListItemIcon, ListItemText, TextField } from "@mui/material";
import { smsCompleteImageToBlob } from "../../../../HelpersFunctions/imagesHelpers";
import { useSMSVisualizationDataContext } from "../../../../HelpersFunctions/SMSVisualizationDataProvider";

const AddEditBuildingPopupContent: React.FC<any> = ({
  rowId,
  control,
  register,
  errors,
  fields,
  append,
  remove,
  setValue,
  getValues,
  setErrorMessage,
  successCallback,
  handleSubmit,
  setIsFetching,
  watch,
  clearErrors,
}) => {
  const { t } = useTranslation();
  const authUserType = useAppSelector(selectAuthUser).currentProfile?.type;
  const settings = useAppSelector(selectSettings);
  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [valueTab, setValueTab] = useState<number>(0);
  const [buildingsGroupsLoaded, setBuildingsGroupsLoaded] = useState(false);
  const [buildingLoaded, setBuildingLoaded] = useState(false);
  const { mapsRefetch, imagesData } = useSMSVisualizationDataContext();

  const [
    buildingsGroups,
    fetchingStateBuildingsGroups,
    fetchAgainBuildingsGroups,
  ] = useFetchAndSetGET({
    path: `sms-administrator/buildings-groups`,
    startFetchOnInitial: true,
  });

  const [building, fetchingStateBuilding, fetchAgainBuilding] =
    useFetchAndSetGET({
      path: `sms-administrator/buildings/${rowId}`,
      startFetchOnInitial: false,
    });

  useEffect(() => {
    if (rowId) {
      fetchAgainBuilding();
    }
  }, [rowId, fetchAgainBuilding]);

  useEffect(() => {
    if (
      fetchingStateBuildingsGroups.isFetching &&
      fetchingStateBuildingsGroups.response?.status === 200
    ) {
      setBuildingsGroupsLoaded(true);
    }

    if (
      !fetchingStateBuilding.isFetching &&
      fetchingStateBuilding.response?.status === 200
    ) {
      setBuildingLoaded(true);
    }
  }, [
    fetchingStateBuilding.isFetching,
    fetchingStateBuilding.response?.status,
    fetchingStateBuildingsGroups.isFetching,
    fetchingStateBuildingsGroups.response?.status,
  ]);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    mapsRefetch();
  }, [successCallback, mapsRefetch]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t, setErrorMessage]
  );

  const [fetchingStateAddEditBuilding, fetchAgainAddEditBuilding] =
    useFetchOtherThanGET({
      path: `sms-administrator/buildings${rowId ? "/" + rowId : ""}`,
      method: rowId ? "PUT" : "POST",
      body: bodyRequest,
      setBody: setBodyRequest,
      successCallback: successCallbackAndClose,
      errorCallback,
      disableErrorSnackbar: true,
    });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditBuilding();
    }
  }, [bodyRequest, fetchAgainAddEditBuilding]);

  const TabPanel = useCallback(
    (props: { children?: React.ReactNode; index: number; value: number }) => {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          {...other}
        >
          {value === index && <div>{children}</div>}
        </div>
      );
    },
    []
  );

  const isBasicDataValidationErrors = useCallback(() => {
    if (
      (settings.WebSMSAdministratorUkryjPoleKolejnoscGrupyBudynkow !== "1" &&
        errors.buildingsGroup) ||
      (errors.name && errors.name?.ref?.name === "name") ||
      (errors.description && errors.description?.ref?.name === "description") ||
      errors.photo ||
      (errors.order && errors.order?.ref?.name === "order")
    ) {
      return true;
    }

    return false;
  }, [
    errors.buildingsGroup,
    errors.description,
    errors.name,
    errors.order,
    errors.photo,
    settings.WebSMSAdministratorUkryjPoleKolejnoscGrupyBudynkow,
  ]);

  const isFloorsDataValidationErrors = useCallback(() => {
    if (errors.floors) return true;
  }, [errors.floors]);

  const handleChangeValueTab = useCallback(
    (event: React.ChangeEvent<any>, newValue: number) => {
      setValueTab(newValue);
    },
    []
  );

  useEffect(() => {
    setIsFetching(fetchingStateAddEditBuilding.isFetching);
  }, [fetchingStateAddEditBuilding, setIsFetching]);

  const onSubmit = (data: any) => {
    let jsonDataToSend: any = {
      buildingsGroupId: null,
      imageId: data.photo?.id,
      name: data.name,
      description: data.description,
      order: data.order,
      showOnMap: data.showOnMap,
      latitude: data.latitude,
      longitude: data.longitude,
      floors: [],
    };

    if (
      settings.WebSMSAdministratorUkryjPoleKolejnoscGrupyBudynkow?.value !== "1"
    ) {
      jsonDataToSend.buildingsGroupId = data.buildingsGroup.id;
    }

    let formData = new FormData();

    data.floors.forEach((floor, index) => {
      jsonDataToSend.floors.push({
        id: floor.id,
        name: floor.name,
        number: floor.number,
        isDefault: floor.isDefault,
        mapFileIndex: index,
        oryginalWidth: floor.oryginalWidth,
        oryginalHeight: floor.oryginalHeight,
        customWidth: floor.customWidth,
        customHeight: floor.customHeight,
        isVector: floor.isVector,
      });

      formData.append(`requestBuildingFloorsMapsData_${index}`, floor.map[0]);
    });

    formData.append("requestBuildingData", JSON.stringify(jsonDataToSend));
    setBodyRequest(formData);
  };

  useEffect(() => {
    if (rowId && buildingsGroupsLoaded && buildingLoaded) {
      let imagesDataMap = new Map<number, ISMSCompleteImage>();

      if (imagesData && imagesData.length > 0) {
        imagesDataMap = imagesData.reduce((acc, obj) => {
          acc.set(obj.id, obj);
          return acc;
        }, new Map());
      }

      // Wczytujemy dane budynku
      if (building.buildingsGroupId) {
        let foundObj = buildingsGroups.find(
          (el) => el.id === building.buildingsGroupId
        );

        if (foundObj) {
          setValue("buildingsGroup", foundObj);
        }
      }

      setValue("name", building.name);
      setValue("description", building.description);
      setValue("order", building.order);
      setValue("showOnMap", building.showOnMap);
      setValue("latitude", building.latitude);
      setValue("longitude", building.longitude);

      if (building.imageId) {
        let tempImage = imagesDataMap.get(building.imageId);
        if (tempImage) {
          setValue("photo", tempImage);
        }
      }

      // Wczytujemy dane pięter
      if (building.floors) {
        for (let i = 0; i < building.floors.length; i++) {
          append({
            id: building.floors[i].id,
            name: building.floors[i].name,
            number: building.floors[i].number,
            isDefault: building.floors[i].isDefault,
            map: [
              {
                name: building.floors[i].map.fileName,
                size: building.floors[i].map.fileSize,
              },
            ],
            oryginalWidth: building.floors[i].map.oryginalWidth,
            oryginalHeight: building.floors[i].map.oryginalHeight,
            customWidth: building.floors[i].map.customWidth,
            customHeight: building.floors[i].map.customHeight,
            isVector: building.floors[i].map.isVector,
          });
        }
      }
    }
  }, [
    rowId,
    imagesData,
    buildingsGroupsLoaded,
    buildingLoaded,
    building?.buildingsGroupId,
    building?.description,
    building?.floors,
    building?.latitude,
    building?.longitude,
    building?.name,
    building?.order,
    building?.showOnMap,
    building?.imageId,
    setValue,
    buildingsGroups,
    append,
  ]);

  return (
    <LoadingWrapper
      isLodadingProgress={
        fetchingStateBuildingsGroups.isFetching ||
        fetchingStateBuilding.isFetching
      }
      isError={
        fetchingStateBuildingsGroups.isError || fetchingStateBuilding.isError
      }
      setIfFetchAgain={() => {
        if (fetchingStateBuildingsGroups.isError) {
          fetchAgainBuildingsGroups();
        }

        if (fetchingStateBuilding.isError) {
          fetchAgainBuilding();
        }
      }}
    >
      <form
        key={"add_building_form"}
        onSubmit={handleSubmit(onSubmit)}
        id="submitForm"
        noValidate
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          value={valueTab}
          onChange={handleChangeValueTab}
          TabIndicatorProps={{
            style: {
              backgroundColor: `${colorNameDictionary[authUserType]}`,
            },
          }}
          variant="fullWidth"
        >
          <Tab
            label={t("basic_data")}
            value={0}
            icon={
              isBasicDataValidationErrors() ? (
                <Tooltip title={t("invalid_data")!} placement="top">
                  <Error color="error" />
                </Tooltip>
              ) : (
                <></>
              )
            }
          />
          <Tab
            label={t("floors")}
            value={1}
            icon={
              isFloorsDataValidationErrors() ? (
                <Tooltip title={t("invalid_data")!} placement="top">
                  <Error color="error" />
                </Tooltip>
              ) : (
                <></>
              )
            }
          />
        </Tabs>

        <TabPanel value={valueTab} index={0}>
          <div className={styles["buildings-basic-data-main-container"]}>
            <div className={styles["buildings-basic-data-column"]}>
              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle width={"100px"}>{t("buildings_group")}:</RowTitle>
                  <RowInput>
                    <SelectFieldReactHookForm
                      name={"buildingsGroup"}
                      control={control}
                      label={t("choose_buildings_group")}
                      required={true}
                      options={buildingsGroups || []}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      getOptionSelected={(option, value) => {
                        return option.id === value.id;
                      }}
                      setValue={setValue}
                      watch={watch}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle width={"100px"}>{t("name")}:</RowTitle>
                  <RowInput>
                    <TextFieldReactHookForm
                      name={"name"}
                      control={control}
                      label={t("building_name")}
                      required={true}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle width={"100px"}>{t("description")}:</RowTitle>
                  <RowInput>
                    <TextFieldReactHookForm
                      name={"description"}
                      control={control}
                      label={t("building_description")}
                      required={true}
                      multiline
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle width={"100px"}>{t("photo")}:</RowTitle>
                  <RowInput>
                    <SelectFieldReactHookForm
                      id={`photo`}
                      name={`photo`}
                      control={control}
                      label={t("choose_image")}
                      required={true}
                      options={imagesData}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label={t("choose_image")}
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: params.inputProps.value && (
                                <div className={styles.imageListItemContainer}>
                                  <img
                                    src={URL.createObjectURL(
                                      smsCompleteImageToBlob(getValues("photo"))
                                    )}
                                    alt={params.inputProps.value}
                                    style={{
                                      width: 64,
                                      height: 64,
                                      marginTop: 10,
                                      marginRight: 8,
                                    }}
                                  />
                                </div>
                              ),
                            }}
                          />
                        );
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li>
                            <div className={styles.imageListItemContainer}>
                              <div>
                                <ListItemIcon>
                                  <img
                                    src={URL.createObjectURL(
                                      smsCompleteImageToBlob(props)
                                    )}
                                    alt={option.label}
                                    style={{ width: 64, height: 64 }}
                                  />
                                </ListItemIcon>
                              </div>
                              <div>
                                <ListItemText
                                  className={styles.imageListItemLabel}
                                  primary={props.name}
                                />
                              </div>
                            </div>
                          </li>
                        );
                      }}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      getOptionSelected={(option, value) => {
                        return option.id === value.id;
                      }}
                      setValue={setValue}
                      watch={watch}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              {settings.WebSMSAdministratorUkryjPoleKolejnoscGrupyBudynkow
                ?.value !== "1" && (
                <TwoColumnsRowsContainer>
                  <Row>
                    <RowTitle width={"100px"}>{t("order")}:</RowTitle>
                    <RowInput>
                      <TextFieldReactHookForm
                        name="order"
                        type="number"
                        control={control}
                        label={t("buildings_order")}
                        required={true}
                      />
                    </RowInput>
                  </Row>
                </TwoColumnsRowsContainer>
              )}
            </div>
            <div className={styles["buildings-basic-data-map-column"]}>
              <div>
                <CheckboxWithLabelFieldReactHookForm
                  id={"showOnMap"}
                  name={"showOnMap"}
                  control={control}
                  value={getValues("showOnMap")}
                  setValue={setValue}
                  ifCircle={true}
                  label={t("show_building_on_map")}
                />
              </div>
              <PositionOnMapViewer
                control={control}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel value={valueTab} index={1}>
          <BuildingFloors
            control={control}
            register={register}
            fields={fields}
            append={append}
            remove={remove}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            watch={watch}
            clearErrors={clearErrors}
          />
        </TabPanel>
      </form>
    </LoadingWrapper>
  );
};

export default AddEditBuildingPopupContent;
