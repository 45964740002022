import { Tab, Tabs, Tooltip } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { selectAuthUser } from "../../../../../reducers/session";
import { useAppSelector } from "../../../../../store/hooks";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { colorNameDictionary } from "../../../../ProfilesRouting/profilesDictionaries";
import formInitialValues from "../FormModel/formInitialValues";
import validationSchema from "../FormModel/validationSchema";
import styles from "./FormTabs.module.scss";
import GroupDataForm from "./GroupDataForm/GroupDataForm";
import GroupPermissions from "./GroupPermissions/GroupPermissions";
import GroupReaderTimeSettings from "./GroupReaderTimeSettingsForm/GroupReaderTimeSettings";

const CenteredTabs = withStyles({
  flexContainer: {
    alignItems: "center",
    justifyContent: "space-between",
  },
})(Tabs);

interface IProps {
  rowId?: number;
  isPreview?: boolean;
  onSubmit: (values) => void;
}

const FormTabs: React.FC<IProps> = ({ rowId, onSubmit, isPreview }) => {
  function TabPanel(props: {
    children?: React.ReactNode;
    index: number;
    value: number;
  }) {
    const { children, value, index, ...other } = props;

    return (
      <div
        className={styles.content}
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className={styles.childContent}>{children}</div>
        )}
      </div>
    );
  }

  const [valueTab, setValueTab] = useState(0);
  const handleChangeValueTab = (
    event: React.ChangeEvent<any>,
    newValue: number
  ) => {
    setValueTab(newValue);
  };

  const { t } = useTranslation();
  const authUserType = useAppSelector(selectAuthUser).currentProfile?.type;

  const [companies, fetchingStateCompanies, fetchAgainCompanies] =
    useFetchAndSetGET<Company[]>({
      path: "superior-ac/ac-companies",
    });

  const [groupFetched, fetchingStateGroupFetched, fetchAgainGroupFetched] =
    useFetchAndSetGET<IGroup>({
      path: `superior-ac/groups/${rowId}?permissions=true`,
      startFetchOnInitial: rowId ? true : false,
    });

  const validationSchemaObj = validationSchema(t);
  const isGroupDataFormErrors = useCallback((formikProps) => {
    if (formikProps.errors["name"] || formikProps.errors["company"])
      return true;

    return false;
  }, []);

  return (
    <div>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateCompanies.isFetching ||
          fetchingStateGroupFetched.isFetching
        }
        isError={
          fetchingStateCompanies.isError || fetchingStateGroupFetched.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateCompanies.isError) {
            fetchAgainCompanies();
          }
          if (fetchingStateGroupFetched.isError) {
            fetchAgainGroupFetched();
          }
        }}
      >
        {(groupFetched || rowId === undefined) && companies && (
          <Formik
            initialValues={formInitialValues(
              groupFetched ? groupFetched : null,
              companies
            )}
            validationSchema={validationSchemaObj}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {(formikProps) => {
              return (
                <Form noValidate id="submitForm">
                  <CenteredTabs
                    value={valueTab}
                    onChange={handleChangeValueTab}
                    variant="scrollable"
                    scrollButtons="on"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: `${colorNameDictionary[authUserType]}`,
                      },
                    }}
                  >
                    <Tab
                      label={t("data")}
                      value={0}
                      icon={
                        isGroupDataFormErrors(formikProps) && valueTab !== 0 ? (
                          <Tooltip title={t("invalid_data")!} placement="top">
                            <Error color="error" />
                          </Tooltip>
                        ) : (
                          <></>
                        )
                      }
                      color="white"
                    />

                    <Tab label={t("permission")} value={1} />

                    <Tab label={t("group_reader_time_settings")} value={2} />
                  </CenteredTabs>

                  <TabPanel value={valueTab} index={0}>
                    <GroupDataForm companies={companies}></GroupDataForm>
                  </TabPanel>

                  <TabPanel value={valueTab} index={1}>
                    <GroupPermissions
                      isPreview={isPreview}
                      formikValues={formikProps.values}
                      setFieldValue={formikProps.setFieldValue}
                    />
                  </TabPanel>

                  <TabPanel value={valueTab} index={2}>
                    <GroupReaderTimeSettings
                      isPreview={isPreview}
                      formikValues={formikProps.values}
                      setFieldValue={formikProps.setFieldValue}
                    />
                  </TabPanel>
                </Form>
              );
            }}
          </Formik>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default FormTabs;
