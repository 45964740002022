const doFetch = async (path: string, bodyPost: any) => {
  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  let language = JSON.parse(
    localStorage.getItem("languageAndOptionId") || "{}"
  );

  let res;
  try {
    res = await fetch(window.globalConfig.API_URL + "/api/" + path, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "bearer " + authUser.token,
        "Content-Type": "application/json",
        "Accept-Language": language ? language[0] : "pl",
      },
      body: JSON.stringify(bodyPost),
    });
  } catch (e) {
    throw res?.status;
  }

  if (res.ok) {
    return {
      status: res.status,
      location: res.headers.get("Location"),
    };
  } else if (res.status === 400) {
    return res.json();
  }
  throw res.status;
};

export default doFetch;
