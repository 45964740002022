import { useEffect, useRef } from "react";

const useCloudflareLoader = (initialUrl) => {
  const iframeRef = useRef<any>(null);

  useEffect(() => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";

    const handleLoad = () => {
      if (resolvePromise.current) {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow?.document;

        let challengeSolved: boolean = false;

        if (iframeDocument && iframeDocument.getElementById("root")) {
          challengeSolved = true;
        }

        resolvePromise.current(challengeSolved);
      }
    };

    // Obsłuż ewentualne błędy ładowania ramki
    const handleError = (error) => {
      // Odrzuć obietnicę, jeśli została ustawiona
      if (rejectPromise.current) {
        rejectPromise.current(error);
      }
    };

    // Dodaj obsługę zdarzeń load i error do iframe
    iframe.addEventListener("load", handleLoad);
    iframe.addEventListener("error", handleError);

    // Ustaw src iframe na określony URL
    iframe.src = initialUrl;

    // Dodaj iframe do DOM
    document.body.appendChild(iframe);

    // Zapisz referencję do iframe za pomocą useRef
    iframeRef.current = iframe;

    // Cleanup function
    return () => {
      // Usuń nasłuchiwacze zdarzeń po odmontowaniu komponentu
      iframe.removeEventListener("load", handleLoad);
      iframe.removeEventListener("error", handleError);
      // Usuń iframe z DOM
      document.body.removeChild(iframe);
    };
  }, [initialUrl]);

  // Użyj refów do przechowywania obietnicy
  const resolvePromise = useRef<any>(null);
  const rejectPromise = useRef<any>(null);

  const refreshIframe = async (newUrl) => {
    // Sprawdź, czy iframeRef.current jest zdefiniowany
    if (iframeRef.current) {
      // Ustaw nowy URL dla iframe
      //iframeRef.current.src = newUrl;
      iframeRef.current.reload(true);

      // Utwórz nową obietnicę
      const promise = new Promise<boolean>((resolve, reject) => {
        // Przypisz resolve i reject do refów
        resolvePromise.current = resolve;
        rejectPromise.current = reject;
      });

      return promise;
    }
  };

  return { refreshIframe };
};

export default useCloudflareLoader;
