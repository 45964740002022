import { InputHTMLAttributes, useRef } from "react";
import ButtonStyled from "../../Button/button";
import { useTranslation } from "react-i18next";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  variant?: "contained" | "outlined"
}

const InputFileButton = (props : Props) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement>(null); 

  const handleClick = event => {
    if (!hiddenFileInput.current) return
    hiddenFileInput.current.click();   
  };

  return (
    <>
      <ButtonStyled variant={props.variant} onClick={handleClick}>
        {t("select_files")}
      </ButtonStyled>
      <input
        type="file"
        ref={hiddenFileInput}
        style={{display:'none'}}
        {...props}    
        />
    </>
  );
};

export default InputFileButton;