import { useTranslation } from "react-i18next";
import CheckboxWithLabelFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/CheckboxWithLabelField/checkboxWithLabelField";
import TextFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import UploadFileFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/UploadFileField/uploadFileField";
import styles from "./buildingFloors.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { smsSupportedImagesFormats } from "../../../../Constants/smsSupportedImagesFormats";
import { useCallback, useEffect } from "react";

const BuildingFloorItem: React.FC<any> = ({
  fields,
  index,
  control,
  register,
  setValue,
  remove,
  getValues,
  watch,
  clearErrors,
}) => {
  const { t } = useTranslation();

  let watchedFile = watch(`floors.${index}.map`);

  useEffect(() => {
    if (!watchedFile || (watchedFile && watchedFile.length === 0)) {
      setValue(`floors.${index}.oryginalWidth`, 0);
      setValue(`floors.${index}.oryginalHeight`, 0);
      setValue(`floors.${index}.customWidth`, 0);
      setValue(`floors.${index}.customHeight`, 0);
      setValue(`floors.${index}.isVector`, false);
    }
  }, [watchedFile, setValue, index]);


  const handleFileChange = useCallback(
    (file) => {
      clearErrors(`floors.${index}.map`);

      if (file) {
        setValue(`floors.${index}.oryginalWidth`, 0);
        setValue(`floors.${index}.oryginalHeight`, 0);
        setValue(`floors.${index}.customWidth`, 0);
        setValue(`floors.${index}.customHeight`, 0);
        setValue(`floors.${index}.isVector`, false);

        const reader = new FileReader();

        const processSVG = (text) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, "image/svg+xml");
          const svgElement = doc.getElementsByTagName("svg")[0];

          if (svgElement) {
            setValue(`floors.${index}.oryginalWidth`, 0);
            setValue(`floors.${index}.oryginalHeight`, 0);
            setValue(`floors.${index}.customWidth`, 0);
            setValue(`floors.${index}.customHeight`, 0);
            setValue(`floors.${index}.isVector`, true);
          } else {
            setValue(`floors.${index}.isVector`, false);
          }
        };

        const processRasterImage = (dataURL) => {
          const img = new Image();
          img.onload = () => {
            setValue(`floors.${index}.oryginalWidth`, img.width);
            setValue(`floors.${index}.oryginalHeight`, img.height);
            setValue(`floors.${index}.customWidth`, img.width);
            setValue(`floors.${index}.customHeight`, img.height);
            setValue(`floors.${index}.isVector`, false);
          };
          img.onerror = () => {
            setValue(`floors.${index}.isVector`, false);
          };
          img.src = dataURL;
        };

        reader.onload = (e) => {
          const fileType = file.type;

          if (!e.target) return;

          if (fileType === "image/svg+xml") {
            processSVG(e.target.result);
          } else if (fileType.startsWith("image/")) {
            processRasterImage(e.target.result);
          } else {
            setValue(`floors.${index}.isVector`, false);
          }
        };

        reader.onerror = () => {
          setValue(`floors.${index}.isVector`, false);
        };

        if (file.type === "image/svg+xml") {
          reader.readAsText(file);
        } else {
          reader.readAsDataURL(file);
        }
      } else {
        setValue(`floors.${index}.isVector`, false);
      }
    },
    [clearErrors, index, setValue]
  );

  return (
    <div className={styles.buildingFloorMainContainer}>
      <div className={styles.buildingFloorItemTitle}>
        <p>
          {t("floor")}
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              remove(index);
            }}
          />
        </p>
      </div>
      <div className={styles.buildingFloorItem}>
        <div className={styles.inputsRow}>
          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("name")}:</div>
            <div className={styles.inputFieldInRow}>
              <TextFieldReactHookForm
                id={`floors.${index}.name`}
                name={`floors.${index}.name`}
                control={control}
                label={t("floor_name")}
                required={true}
              />
            </div>
          </div>

          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("floor_level")}:</div>
            <div className={styles.inputFieldInRow}>
              <TextFieldReactHookForm
                id={`floors.${index}.number`}
                name={`floors.${index}.number`}
                type="number"
                control={control}
                label={t("floor_level")}
                required={true}
              />
            </div>
          </div>
        </div>

        <div className={styles.inputsRow}>
          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("is_default")}:</div>
            <div className={styles.inputFieldInRow}>
              <CheckboxWithLabelFieldReactHookForm
                id={`floors.${index}.isDefault`}
                name={`floors.${index}.isDefault`}
                control={control}
                value={getValues(`floors.${index}.isDefault`)}
                setValue={setValue}
                ifCircle={true}
                label={<span></span>}
                onChangeExtra={(event, value) => {
                  if (value) {
                    fields.forEach((field, i) => {
                      if (i !== index) {
                        setValue(`floors.${i}.isDefault`, false);
                      }
                    });
                  }
                }}
              />
            </div>
          </div>

          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("map")}:</div>
            <div className={styles.inputFieldInRow}>
              <UploadFileFieldReactHookForm
                id={`floors.${index}.map`}
                name={`floors.${index}.map`}
                register={register}
                control={control}
                setValue={setValue}
                getValues={getValues}
                label={t("map")}
                required={true}
                accept={smsSupportedImagesFormats.join(",")}
                watch={watch}
                onChangeExtra={(e) => {
                  if (e.target.files.length > 0) {
                    handleFileChange(e.target.files[0]);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.inputsRow}>
          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("width")}:</div>
            <div className={styles.inputFieldInRow}>
              <TextFieldReactHookForm
                id={`floors.${index}.customWidth`}
                name={`floors.${index}.customWidth`}
                type="number"
                control={control}
                label={t("width")}
                required={false}
              />
            </div>
          </div>

          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("height")}:</div>
            <div className={styles.inputFieldInRow}>
              <TextFieldReactHookForm
                id={`floors.${index}.customHeight`}
                name={`floors.${index}.customHeight`}
                type="number"
                control={control}
                label={t("height")}
                required={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingFloorItem;
