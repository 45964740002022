import { FireReportMode } from "../../enums/fireReportMode";
import FireReportContent from "../helpersComponents/FireReport/fireReport";

const EvacuationReport = () => {
  return (
    <div>
      <FireReportContent
        fireReportPath="evacuation-report/evacuation-report"
        layoutSettingName="layoutSettingNameFireReportFireReport"
        fireZonesPath="evacuation-report/evacuation-zones"
        pathPreferences="evacuation-report/preferences"
        targetCompaniesPath="evacuation-report/guests-visits-target-companies"
        fireReportMode={FireReportMode.EVACUATION_REPORT}
      />
    </div>
  );
};

export default EvacuationReport;
