import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch, withStyles } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { CheckboxStyled } from "../../helpersComponents/MaterialUi";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import PopupACWorker from "./PopupACWorker";
import PopupSetPinForACWorkers from "./PopupSetPinForACWorkers";
import WorkerSelectMenu from "./SelectMenus/SingleWorkerSelectMenu";
import WorkersSelectMenu from "./SelectMenus/WorkersSelectMenu";
import WorkersACRowDetail from "./WorkersACRowDetail";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import { ACSuperiorPermission } from "../../../enums/ACSuperiorPermission";
import { ProfileType } from "../../../enums/profileType";

const SwitchStyled = withStyles({
  switchBase: {
    color: "#1c84c6",
    "&$checked": {
      color: "#1c84c6",
    },
    "&$checked + $track": {
      backgroundColor: "#1c84c6",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#1c84c6",
    "$checked$checked + &": {
      backgroundColor: "#1c84c6",
    },
  },
})(Switch);

const WorkersACManagementContent = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  //Displaying ACWorkers
  const [isInactiveSwitchState, setIsInactiveSwitchState] = useState(false);

  const columns = [
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "department", title: t("department") },
    { name: "position", title: t("position") },
    { name: "isVipElement", title: t("VIP") },
    { name: "isContaminatedElement", title: t("contaminated") },
    { name: "additions", title: t("comments") },
    { name: "identifier", title: t("identifier") },
    { name: "phone", title: t("phone") },
    { name: "email", title: t("email") },
    { name: "login", title: t("login") },
    { name: "isPinLockedElement", title: t("pin_locked") },
    { name: "description", title: t("description") },
  ];

  const modifyResponseCallback = useCallback(
    (responseData) => {
      const modified: ACWorker[] = [];
      responseData.forEach((row: ACWorker) => {
        if (row.isActive === true && isInactiveSwitchState) return;
        else if (row.isActive === false && !isInactiveSwitchState) return;

        modified.push({
          ...row,
          isVipElement: <CheckboxStyled checked={row.isVIP} disabled />,
          isContaminatedElement: (
            <CheckboxStyled checked={row.isContaminated} disabled />
          ),
          department: row.department ? row.department : "-",
          isZJWElement: <CheckboxStyled checked={row.isZJW} disabled />,
          additions: row.additions ? row.additions : "-",
          identifier: row.identifier ? row.identifier : "-",
          cardNumber:
            row.cardNumber !== "00000000-0000-0000-0000-000000000000"
              ? row.cardNumber
              : "-",
          phone: row.phone ? row.phone : "-",
          email: row.email ? row.email : "-",
          login: row.login ? row.login : "-",
          isPinLockedElement: (
            <CheckboxStyled checked={row.isPinLocked} disabled />
          ),
          description: row.description ? row.description : "-",
        });
      });
      return modified;
    },
    [isInactiveSwitchState]
  );

  const [workersAC, fetchingStateWorkersAC, fetchAgainWorkersAC] =
    useFetchAndSetGET<ACWorker[]>({
      path: "superior-ac/ac-workers?fullInfo=true",
      modifyResponseCallback: modifyResponseCallback,
    });

  const IsActiveSwitch = (
    <div className="SwtichActive">
      {t("active")}
      <SwitchStyled
        checked={isInactiveSwitchState}
        onChange={() => setIsInactiveSwitchState(!isInactiveSwitchState)}
        name="checkedA"
      />
      {t("inactive")}
    </div>
  );

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [popupSetPinParams, setPopupSetPinParams] = useState<SetPinPopup>({
    isOpen: false,
    workersIds: [],
  });

  const customExportCellValue = useCallback(
    (row: any, columnName: string) => {
      if (columnName === "isVipElement") {
        return row.isVip ? t("yes") : t("no");
      }

      if (columnName === "isContaminatedElement") {
        return row.isContaminated ? t("yes") : t("no");
      }

      if (columnName === "isPinLockedElement") {
        return row.isPinLocked ? t("yes") : t("no");
      }

      return row[columnName];
    },
    [t]
  );

  const isPreview =
    authUser?.currentProfile?.type === ProfileType.SUPERIOR_AC &&
    authUser?.currentProfile?.permission === ACSuperiorPermission.PREVIEW;

  const actionsColumnUpdated = !isPreview
    ? {
        popup: PopupACWorker,
        successCallbackPopup: fetchAgainWorkersAC,
        addButton: {
          ifShow: true,
          showInToolbar: true,
        },
        editButton: {
          ifShow: true,
          name: <FontAwesomeIcon icon={faEdit} size="lg" />,
        },
        leftToolbarComponent: {
          ifShow: true,
          customComponent: () => {
            return (
              <WorkersSelectMenu
                acWorkersIds={selectedRows}
                setACWorkersIds={setSelectedRows}
                successCallback={fetchAgainWorkersAC}
                setPopupSetPinParams={setPopupSetPinParams}
              />
            );
          },
        },
      }
    : {
        popup: PopupACWorker,
        successCallbackPopup: fetchAgainWorkersAC,
        previewButton: {
          ifShow: true,
          name: t("preview"),
        },
      };

  return (
    <div className="flexAndCenter">
      <div>
        <TableComponentMemo
          columns={columns}
          rows={workersAC}
          layoutSettingName={"workersACManagement"}
          toolbarComponent={IsActiveSwitch}
          paddingConfirmRemoveActions={0}
          fetchingState={{
            isFetching: fetchingStateWorkersAC.isFetching || workersAC === null,
            isError: fetchingStateWorkersAC.isError,
            fetchAgain: fetchAgainWorkersAC,
          }}
          rowDetail={WorkersACRowDetail}
          rowMenu={{
            ifShow: !isPreview,
            menu: (row: ACWorker) => {
              return (
                <WorkerSelectMenu
                  acWorker={row}
                  successCallback={fetchAgainWorkersAC}
                  setPopupSetPinParams={setPopupSetPinParams}
                />
              );
            },
          }}
          actionsColumnUpdated={actionsColumnUpdated}
          refreshingButton={{
            ifShow: true,
            onlyRefreshNow: true,
            onClick: fetchAgainWorkersAC,
          }}
          selectData={(ids) => setSelectedRows(ids as number[])}
          selectedData={selectedRows}
          customExcelCellValue={customExportCellValue}
          customPdfCellValue={customExportCellValue}
          tableExportInfo={{
            createdBy: authUser.displayedName,
          }}
        />
      </div>

      <div>
        {popupSetPinParams.isOpen && (
          <PopupSetPinForACWorkers
            popupSetPinParams={popupSetPinParams}
            closePopup={() =>
              setPopupSetPinParams((prevState) => {
                return {
                  ...prevState,
                  isOpen: false,
                };
              })
            }
            successCallback={fetchAgainWorkersAC}
          />
        )}
      </div>
    </div>
  );
};

export default WorkersACManagementContent;
