import { useTranslation } from "react-i18next";
import { ReportToDownloadType } from "../../../../enums/ReportToDownloadType";
import { ReportToDownloadItem } from "./type";

const useReportsToDownloadTypes = () => {
  const { t } = useTranslation();
  return [
    {
      id: ReportToDownloadType.OVERTIME,
      name: t("overtime"),
    },
  ] as ReportToDownloadItem[];
};

export default useReportsToDownloadTypes;
