import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import DatePickerRange from "../../helpersComponents/DatePickerRange/datePickerRange";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import { useState } from "react";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import { CircularProgress } from "@material-ui/core";
import Button from "../../helpersComponents/Button/button";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";

const EventsHistorySMSUser = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Month")
  );
  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Month"));

  const columns = [
    { name: "occurenceDate", title: t("occurence_date"), type: "date_hours" },
    {
      name: "registrationDate",
      title: t("registration_date"),
      type: "date_hours",
    },
    { name: "source", title: t("source") },
    { name: "type", title: t("type") },
    { name: "isAlarm", title: t("is_alarm") },
    { name: "priority", title: t("priority") },
    { name: "description", title: t("description") },
    {
      name: "isApprovalRequired",
      title: t("is_approval_required"),

      customFormatter: (row: ISMSHistoryEvent) => {
        return row.isApprovalRequired ? t("yes") : t("no");
      },
    },
    {
      name: "isApproved",
      title: t("is_approved"),
      customFormatter: (row: ISMSHistoryEvent) => {
        return row.isApproved ? t("yes") : t("no");
      },
    },
    { name: "approvedBy", title: t("approved_by2") },
    { name: "approvedAt", title: t("approved_date2"), type: "date_hours" },
  ];

  const [historyEvents, fetchingStateHistoryEvents, fetchAgainHistoryEvents] =
    useFetchAndSetGET<ISMSHistoryEvent[]>({
      path: `sms-user/events-history?dateFrom=${convertDateFetch(
        getBeginningOfDate(startDate, "Month")
      )}&dateTo=${convertDateFetch(getEndOfDate(endDate, "Month"))}`,
      startFetchOnInitial: false,
    });

  const confirmFilters = () => {
    fetchAgainHistoryEvents();
  };

  const confirmButtonRight = (
    <>
      <div className="getEventsButton">
        {fetchingStateHistoryEvents.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <Button
            onClick={() => {
              confirmFilters();
            }}
          >
            {t("get_events")}
          </Button>
        )}
      </div>
    </>
  );

  return (
    <div>
      <TopBanner pathName={t("events_history")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            keyboardDateTimePicker={true}
            darkTheme={true}
            confirmButtonRight={confirmButtonRight}
            setDate2={setEndDate}
          ></DatePickerRange>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStateHistoryEvents.isFetching}
            isError={fetchingStateHistoryEvents.isError}
            setIfFetchAgain={fetchAgainHistoryEvents}
          >
            {!fetchingStateHistoryEvents.isFetching &&
              !fetchingStateHistoryEvents.isError && (
                <TableComponent
                  layoutSettingName="layoutSMSUserEventsHistory"
                  rows={historyEvents || []}
                  columns={columns}
                />
              )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default EventsHistorySMSUser;
