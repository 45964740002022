import VisualizationObjectActionItem from "./VisualizationObjectActionItem";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../../helpersComponents/Button/button";
import styles from "./VisualizationObjectActions.module.scss";

const VisualizationObjectActions = ({
  fields,
  append,
  clearErrors,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.actionsContainer}>
      <div>
        <ButtonStyled
          type="button"
          onClick={() => {
            append({
              name: "",
              topic: "",
              value: "",
              order: 0,
              type: { id: 0, name: "MQTT" },
              urlAddress: "",
              username: "",
              password: "",
              openURLInNewWindow: false,
            });
          }}
        >
          {t("add")}
        </ButtonStyled>
      </div>

      <div className={styles.actionsItemsContainer}>
        {fields.map((field, index) => (
          <VisualizationObjectActionItem
            id={field.id}
            key={field.id}
            fields={fields}
            index={index}
            clearErrors={clearErrors}
            {...props}
          />
        ))}
      </div>
    </div>
  );
};

export default VisualizationObjectActions;
