import { yupResolver } from "@hookform/resolvers/yup";
import DialogStyled from "../../PopupHelpers/dialogStyled";
import DialogTitleStyled from "../../PopupHelpers/dialogTitleStyled";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { DialogContentStyled } from "../../PopupHelpers/dialogContentStyled";
import DialogActionsStyled from "../../PopupHelpers/dialogActionsStyled";
import AddHomeOfficePopupContent from "./AddHomeOfficePopupContent";

interface IProps {
  isOpen: boolean;
  closePopup: any;
  days: Date[];
}

const AddHomeOfficePopup: React.FC<IProps> = ({ isOpen, closePopup, days }) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const schema = Yup.object({
    requestType: Yup.object().nullable().required(t("field_required")),
    homeOfficeAddress: Yup.object().nullable().required(t("field_required")),
    days: Yup.array()
      .of(Yup.date().required("field_required"))
      .min(1, t("at_least_one_day_required")),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      days: days,
    },
  });

  const removeDay = (index) => {
    const currentDays = getValues().days;
    if (currentDays) {
      const updatedDates = [
        ...currentDays.slice(0, index),
        ...currentDays.slice(index + 1),
      ];
      setValue("days", updatedDates);
    }
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {
          reset();
        },
        onEnter: () => {
          setErrorMessage("");
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitleStyled
        title={t("home_office")}
        subtitle={t("adding_home_office_requests")}
      />

      <DialogContentStyled>
        <AddHomeOfficePopupContent
          control={control}
          register={register}
          errors={errors}
          closePopup={closePopup}
          removeDay={removeDay}
          setValue={setValue}
          getValues={getValues}
          setErrorMessage={setErrorMessage}
          handleSubmit={handleSubmit}
          watch={watch}
        />
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitAddHomeOfficeRequestForm"
        onConfirmText={t("add")}
        onCancel={() => {
          closePopup();
        }}
        isLoading={false}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default AddHomeOfficePopup;
