import { useTranslation } from "react-i18next";
import styles from "./VisualizationObjectValues.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import { Grid } from "@mui/material";

const VisualizationObjectValuesItem: React.FC<any> = ({
  key,
  fields,
  index,
  control,
  register,
  setValue,
  remove,
  errors,
  getValues,
  watch,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.valuesMainContainer}>
      <div className={styles.valueItemTitle}>
        <p>
          {t("value")}
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              remove(index);
            }}
          />
        </p>
      </div>

      <div className={styles.valueItem}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <div className={styles.inputWithTitleInRow}>
              <div className={styles.inputTitleInRow}>{t("name")}:</div>
              <div className={styles.inputFieldInRow}>
                <TextFieldReactHookForm
                  id={`values.${index}.name`}
                  name={`values.${index}.name`}
                  control={control}
                  label={t("name")}
                  required={true}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <div className={styles.inputWithTitleInRow}>
              <div className={styles.inputTitleInRow}>{t("topic")}:</div>
              <div className={styles.inputFieldInRow}>
                <TextFieldReactHookForm
                  id={`values.${index}.topic`}
                  name={`values.${index}.topic`}
                  control={control}
                  label={t("topic")}
                  required={true}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={styles.inputWithTitleInRow}>
              <div className={styles.inputTitleInRow}>{t("condition")}:</div>
              <div className={styles.inputFieldInRow}>
                <TextFieldReactHookForm
                  id={`values.${index}.condition`}
                  name={`values.${index}.condition`}
                  control={control}
                  label={t("condition")}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={styles.inputWithTitleInRow}>
              <div className={styles.inputTitleInRow}>{t("property")}:</div>
              <div className={styles.inputFieldInRow}>
                <TextFieldReactHookForm
                  id={`values.${index}.property`}
                  name={`values.${index}.property`}
                  control={control}
                  label={t("property")}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default VisualizationObjectValuesItem;
