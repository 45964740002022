import "./holidaysPlan.scss";
import React, { useState, useEffect, useCallback } from "react";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
//import Popup from "./popup";
import AddEditHolidayPlanRequestPopup from "../../../helpersComponents/Requests/HolidayPlan/AddEditHolidayPlanRequestPopup";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import useFetchPatch from "../../../../hooks/fetchHooks/patch/useFetchPatch";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { useHistory } from "react-router-dom";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import moment from "moment";

let Cell: any = TableEditColumn.Cell;

const ActionColumn: React.FC = ({
  children,
  row,
  classes,
  ...restProps
}: any) => {
  if (row.status === 1 || row.status === 4) {
    return (
      <Cell row={restProps.row} className="tableCellEditCancelStyleGlobal">
        {children}
      </Cell>
    );
  }
  return (
    <Cell row={restProps.row} className="tableCellDisabledGlobal">
      {children}
    </Cell>
  );
};

function HolidaysPlanContent() {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear() - 2, 0, 1, 0, 0, 0)
  );
  // const [startDate, setStartDate] = useState(new Date(new Date().getFullYear() - 1, 11, 16));
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31, 0, 0)
  );
  const [fetchAgain, setFetchAgain] = useState(true);
  const [dataModified, setDataModified] = useState<any>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = authUser.currentProfile.subjectId;
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/absence-plans-requests?DateFrom=` +
      computeDateRangeFetch(startDate, "T00:00:00") +
      "&DateTo=" +
      computeDateRangeFetch(endDate),
    fetchAgain
  );

  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);
  const [newStatusBody, setNewStatusBody] = useState<any>(false);
  const [idUpdateStatus, setIdUpdateStatus] = useState();
  const history: any = useHistory();
  const responseStatusUpdate = useFetchPatch(
    `worker-time/${authUserId}/absence-plans-requests/` + idUpdateStatus,
    newStatusBody
  );

  const columns = [
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "dateFrom", title: t("from_date"), type: "date_hours" },
    { name: "dateTo", title: t("till_date"), type: "date_hours" },
    { name: "statusTranslated", title: t("request_status") },
    { name: "approvedBy", title: t("approved_by") },
  ];

  useEffect(() => {
    setIsError(false);
    setFetchAgain(true);
    setIsFetching(true); // circ
  }, [startDate, endDate]);

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setFetchAgain(false);
      return;
    }
    if (data === null) return;

    data.forEach((element, index) => {
      data[index].statusTranslated = t(statusDictionary[data[index].status]);
      data[index].approvedBy = data[index].approvedBy
        ? data[index].approvedBy
        : t("not_approved");
    });

    setDataModified(data);
    setIsFetching(false);
    setFetchAgain(false);
  }, [data, error, t]);

  const commitChanges = ({ added, changed, deleted }) => {
    if (deleted) {
      let row: any = dataModified.find((row) => row.id === deleted[0]);
      setNewStatusBody([
        {
          op: "replace",
          path: "/status",
          value: 7,
        },
      ]);
      setIdUpdateStatus(row.id);
    } else if (added) {
      setFetchAgain(true);
      setSnackbarState([true, "success", t("application_added")]);
    } else if (changed) {
      setFetchAgain(true);
      setSnackbarState([true, "success", t("application_edited")]);
    }
    setIsFetching(true); // circ
  };

  useEffect(() => {
    if (responseStatusUpdate.data?.status === 200) {
      setSnackbarState([true, "success", t("application_canceled")]);
      setFetchAgain(true);
      setIsFetching(true); // circ
    } else if (responseStatusUpdate.data?.status === 400) {
      let error = "";
      Object.keys(responseStatusUpdate.data.errors).forEach((errorObject) => {
        responseStatusUpdate.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setIsFetching(false); // circ
      setSnackbarState([true, "error", error]);
    } else if (responseStatusUpdate.error !== null) {
      setIsFetching(false); // circ
      setSnackbarState([true, "error", t("something_went_wrong")]);
    }
    setNewStatusBody(false);
  }, [responseStatusUpdate, t]);

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "createdAt") {
      return row.createdAt
        ? moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    if (columnName === "dateFrom") {
      return row.dateFrom
        ? moment(row.dateFrom).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    if (columnName === "dateTo") {
      return row.dateTo ? moment(row.dateTo).format("YYYY-MM-DD HH:mm:ss") : "";
    }

    return row[columnName];
  }, []);

  return (
    <>
      <TopBanner pathName={t("holidays_plan")} />

      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
          ></DatePickerRange>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={isFetching}
            isError={isError}
            setIfFetchAgain={setFetchAgain}
            setIsError={setIsError}
          >
            {!isFetching && !isError && (
              <TableComponent
                title={t("list_of_planned_holidays")}
                layoutSettingName="layoutWorkerTimeRequestsHolidaysPlan"
                rows={dataModified}
                columns={columns}
                popup={AddEditHolidayPlanRequestPopup}
                ifEdit={true}
                ifAdd={true}
                ifDelete={true}
                deleteCommandName={t("cancel")}
                commitChanges={commitChanges}
                actionColumn={ActionColumn}
                openPopup={history.location.state ? true : false}
                customExcelCellValue={customExportCellValue}
                customPdfCellValue={customExportCellValue}
                tableExportInfo={{
                  dateFrom: startDate,
                  dateTo: endDate,
                  createdBy: authUser.displayedName,
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default HolidaysPlanContent;
