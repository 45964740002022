import { useEffect } from "react";
import { useAppDispatch } from "../../../store/hooks";
import useFetchAndSetGET from "../../fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { setEvents } from "../../../reducers/sms/visualizationData";

const useSMSFetchEvents = () => {
  const dispatch = useAppDispatch();

  const [events, fetchingStateEvents, fetchAgainEvents] = useFetchAndSetGET<
    ISMSEvent[]
  >({
    path: `sms-user/events`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (
      !fetchingStateEvents.isFetching &&
      !fetchingStateEvents.isError &&
      fetchingStateEvents.response?.status === 200
    ) {
      dispatch(setEvents(events));
    }
  }, [
    dispatch,
    events,
    fetchingStateEvents.isError,
    fetchingStateEvents.isFetching,
    fetchingStateEvents.response?.status,
  ]);

  return { events, fetchingStateEvents, fetchAgainEvents };
};

export default useSMSFetchEvents;
