import FireReportContent from "../helpersComponents/FireReport/fireReport";

const FireReport = () => {
  return (
    <div>
      <FireReportContent
        fireReportPath="fire-report/fire-report"
        layoutSettingName="layoutSettingNameFireReportFireReport"
        fireZonesPath="fire-report/fire-zones"
        pathPreferences="fire-report/preferences"
        targetCompaniesPath="fire-report/guests-visits-target-companies"
      />
    </div>
  );
};

export default FireReport;
