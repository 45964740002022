import React from "react";
import NotificationsDailyLimitsContent from "./NotificationsDailyLimitsContent";

function NotificationsDailyLimits() {
  return (
    <div>
      <NotificationsDailyLimitsContent />
    </div>
  );
}

export default NotificationsDailyLimits;
