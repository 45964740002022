import React, {
  useState,
  useEffect,
  Dispatch,
  useRef,
  useCallback,
} from "react";
import styles from "./homePage.module.scss";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../hooks/fetchHooks/get/useFetchGet";
import {
  Chart,
  Legend,
  Title,
  PieSeries,
} from "@devexpress/dx-react-chart-material-ui";
import getDateRange from "../../../HelpersFunctions/dateAndTime/getDateRange";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import convertMinsToHrsMins from "../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import {
  // Animation,
  Palette,
} from "@devexpress/dx-react-chart";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import convertSecondsToHrsMinsSeconds from "../../../HelpersFunctions/dateAndTime/convertSecondsToHrsMinsSeconds";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import addHoursToDate from "../../../HelpersFunctions/dateAndTime/addHoursToDate";
import {
  computeWorkedTimeToday,
  filterEvents,
  reduceEntryReturnLastEvent,
} from "./computeWorkedTimeToday";
import { selectAuthUser } from "../../../reducers/session";
import {
  setLastEvent,
  setRemoteDutyLastEvent,
  setHomeOfficeLastEvent,
  setEightHoursLastEvent,
} from "../../../reducers/homePage";

interface props {
  setFetchAgain: Dispatch<boolean>;
  fetchAgain: boolean;
}

const ChartWorkingTime = ({ setFetchAgain, fetchAgain }: props) => {
  const { t } = useTranslation();

  const settings = useAppSelector(selectSettings);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [startDateSchedule, setStartDateSchedule] = useState<string | null>(
    null
  );
  const [endDate, setEndDate] = useState<string | null>(null);
  const [endDateSchedule, setEndDateSchedule] = useState<string | null>(null);
  const [chartData, setChartData] = useState<any>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [workingNormMonthTitle, setWorkingNormMonthTitle] =
    useState<string>("");
  const [workingNormTodayTitle, setWorkingNormTodayTitle] =
    useState<string>("");
  const [chartDataTodays, setChartDataTodays] = useState<any>([]);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const dispatch = useAppDispatch();
  const summariesResponse = useFetchGet(
    `worker-time/${authUserId}/summaries?DateFrom=` +
      startDate +
      "&DateTo=" +
      endDate +
      "&GroupBy=day" +
      "&TimeWorkerId=" +
      JSON.parse(localStorage.getItem("authUser") || "{}").currentProfile
        .subjectId,
    !fetchAgain || startDate === null || endDate === null ? false : true
  );

  const [startDateEvents, setStartDateEvents] = useState<string | null>(null);
  const [endDateEvents, setEndDateEvents] = useState<string | null>(null);
  const eventsResponse = useFetchGet(
    `worker-time/${authUserId}/events?dateFrom=` +
      startDateEvents +
      "&dateTo=" +
      endDateEvents,
    !fetchAgain || startDateEvents === null || endDateEvents === null
      ? false
      : true
  );

  const scheduleResponse = useFetchGet(
    `worker-time/${authUserId}/Schedules?DateFrom=` +
      startDateSchedule +
      "&DateTo=" +
      endDateSchedule,
    !fetchAgain || startDateSchedule === null || endDateSchedule === null
      ? false
      : true
  );

  useEffect(() => {
    /// compute fetching date ranges
    const dateRangeMonth = getDateRange(new Date(), "Month");
    setStartDate(convertDateFetch(dateRangeMonth.startDate, "T00:00:00"));
    setEndDate(convertDateFetch(dateRangeMonth.endDate, "T23:59:59"));

    setStartDateEvents(convertDateFetch(addHoursToDate(new Date(), -12)));
    setEndDateEvents(convertDateFetch(addHoursToDate(new Date(), 12)));

    const dateRangeToday = getDateRange(new Date(), "Day");

    setStartDateSchedule(
      convertDateFetch(dateRangeToday.startDate, "T00:00:00")
    );
    setEndDateSchedule(convertDateFetch(dateRangeToday.endDate, "T23:59:59"));
  }, []);

  const timeCounterRef = useRef<NodeJS.Timeout | null>(null);

  const runCountingIfPossibly = useCallback(
    (events, workingTimeStandardToday, workedTimeToday) => {
      let filteredEvents = filterEvents(events);

      // Sortujemy zdarzenia rosnąco
      let filteredSortedEvents = [...filteredEvents].sort((a, b) => {
        return new Date(a.time) > new Date(b.time) ? 1 : -1;
      });

      // Szukamy ostatniego zdarzenia wejściowego
      let entryEvent = reduceEntryReturnLastEvent(filteredSortedEvents);

      // Czyścimy timer jeśli jest odpalony
      if (timeCounterRef.current) {
        clearInterval(timeCounterRef.current);
        timeCounterRef.current = null;
      }

      if (
        entryEvent !== null &&
        workingTimeStandardToday - workedTimeToday > 0
      ) {
        timeCounterRef.current = setInterval(() => {
          setChartDataTodays((prev) => {
            let workedTimeTodayUpdated = prev[0].time + 1;
            if (workingTimeStandardToday - workedTimeTodayUpdated <= 0) {
              if (timeCounterRef.current) {
                clearInterval(timeCounterRef.current);
                timeCounterRef.current = null;
              }
            }

            /*if (
              new Date(entryEvent.time).getTime() <=
                new Date(startDateFrom).getTime() &&
              new Date().getTime() <= startDateFrom.getTime()
            ) {
              return [
                {
                  workingTime: `${"wypracowano"} ${convertSecondsToHrsMinsSeconds(
                    0
                  )}`,
                  time: 0,
                },
                {
                  workingTime: `${"pozostało"} ${convertSecondsToHrsMinsSeconds(
                    workingTimeStandardToday
                  )}`,
                  time: workingTimeStandardToday,
                },
              ];
            }*/

            return [
              {
                workingTime: `${"wypracowano"} ${convertSecondsToHrsMinsSeconds(
                  workedTimeTodayUpdated
                )}`,
                time: workedTimeTodayUpdated,
              },
              {
                workingTime: `${"pozostało"} ${convertSecondsToHrsMinsSeconds(
                  workingTimeStandardToday - workedTimeTodayUpdated
                )}`,
                time: workingTimeStandardToday - workedTimeTodayUpdated,
              },
            ];
          });
        }, 1000);
      }
    },
    []
  );

  useEffect(() => {
    if (
      summariesResponse.error !== null ||
      eventsResponse.error !== null ||
      scheduleResponse.error !== null
    ) {
      setIsError(true);
      setFetchAgain(false);
      return;
    }
    if (
      summariesResponse.data === null ||
      eventsResponse.data === null ||
      scheduleResponse.data === null
    ) {
      return;
    }

    /// setting last event
    let sortedEvents = [...eventsResponse.data].sort((a, b) => {
      return new Date(a.time) > new Date(b.time) ? -1 : 1;
    });

    if (sortedEvents.length !== 0) {
      for (let i = 0; i < sortedEvents.length; i++) {
        let tempEvent = sortedEvents[i];
        if (
          tempEvent.typeId === 1 ||
          tempEvent.typeId === 2 ||
          tempEvent.typeId === 8
        ) {
          dispatch(setLastEvent(tempEvent));
          break;
        }
      }

      for (let i = 0; i < sortedEvents.length; i++) {
        let tempEvent = sortedEvents[i];
        if (tempEvent.typeId === 8) {
          dispatch(setEightHoursLastEvent(tempEvent));
          break;
        }
      }

      for (let i = 0; i < sortedEvents.length; i++) {
        let tempEvent = sortedEvents[i];

        if (tempEvent.typeId === 11 || tempEvent.typeId === 18) {
          dispatch(setRemoteDutyLastEvent(tempEvent));
          break;
        }
      }

      for (let i = 0; i < sortedEvents.length; i++) {
        let tempEvent = sortedEvents[i];

        if (tempEvent.typeId === 1 || tempEvent.typeId === 8) {
          dispatch(setHomeOfficeLastEvent(tempEvent));
          break;
        }
      }
    }

    let workTimeStandardLocal = 0;
    let remainingWorkLocal = 0;

    summariesResponse.data.forEach((value, i) => {
      workTimeStandardLocal += value.workTimeStandard * 60;
      if (summariesResponse.data.length === i + 1)
        remainingWorkLocal = -value.normBalance * 60;
    });

    ////////////////////////////////////////// month chart data
    setChartData([
      {
        workingTime: `wypracowano ${convertMinsToHrsMins(
          (workTimeStandardLocal - remainingWorkLocal) / 60
        )}`,
        time: (workTimeStandardLocal - remainingWorkLocal) / 60,
      },
      {
        workingTime: `pozostało  ${convertMinsToHrsMins(
          remainingWorkLocal / 60
        )}`,
        time: remainingWorkLocal / 60,
      },
    ]);

    let titleMonthly = `${t("working_hours_for")} ${new Date()
      .toLocaleString(t("scheduler_language"), {
        month: "long",
      })
      .toLocaleLowerCase()}: ${convertMinsToHrsMins(
      workTimeStandardLocal / 60
    )}`;
    setWorkingNormMonthTitle(titleMonthly);

    ////////////////////////////////// today chart
    let workingTimeStandardToday = 0;
    if (scheduleResponse.data[0]?.workStandardInMinutes) {
      workingTimeStandardToday =
        scheduleResponse.data[0].workStandardInMinutes * 60;
    }

    let workedTimeToday = computeWorkedTimeToday([...sortedEvents]);

    if (
      workedTimeToday === -1 ||
      workingTimeStandardToday - workedTimeToday < 0
    ) {
      workedTimeToday = workingTimeStandardToday;
    }

    setChartDataTodays([
      {
        workingTime: `${"wypracowano"} ${convertSecondsToHrsMinsSeconds(
          workedTimeToday
        )}`,
        time: workedTimeToday,
      },
      {
        workingTime: `${"pozostało"} ${convertSecondsToHrsMinsSeconds(
          workingTimeStandardToday - workedTimeToday
        )}`,
        time: workingTimeStandardToday - workedTimeToday,
      },
    ]);

    let titleTodays = `${t(
      "working_hours_for_today"
    )}: ${convertSecondsToHrsMinsSeconds(workingTimeStandardToday)}`;

    setWorkingNormTodayTitle(titleTodays);
    runCountingIfPossibly(
      [...sortedEvents],
      workingTimeStandardToday,
      workedTimeToday
    );

    setIsFetching(false);
    setFetchAgain(false);

    return () => {
      if (timeCounterRef.current) clearInterval(timeCounterRef.current);
    };
  }, [
    summariesResponse,
    eventsResponse,
    setFetchAgain,
    dispatch,
    scheduleResponse,
    t,
    runCountingIfPossibly,
  ]);

  let LegendLabel: any = Legend.Label;
  const LegendComponent: any = ({ ...props }) => {
    let ifComplete: boolean = false;
    if (chartDataTodays[1].time <= 0) ifComplete = true;
    return (
      <LegendLabel
        {...props}
        className={ifComplete ? styles.titleTextComplete : ""}
      />
    );
  };

  return (
    <>
      <>
        <LoadingWrapper
          isLodadingProgress={isFetching}
          isError={isError}
          setIfFetchAgain={setFetchAgain}
          setIsError={setIsError}
        >
          {!isFetching && !isError && (
            <div className={styles.chartsHomePage}>
              {settings.WebStronaGlownaUkryjMiesiecznyWymiarPracy?.value !==
                "1" && (
                <div>
                  <Chart data={chartData} height={200} width={450}>
                    <Palette scheme={["#50D6AD", "red"]} />
                    <PieSeries
                      name="Series name"
                      valueField="time"
                      argumentField="workingTime"
                    />
                    <Legend />
                    <Title text={workingNormMonthTitle} />
                    {/* <Animation /> */}
                    {/* <Tooltip /> */}
                  </Chart>
                </div>
              )}
              {settings.WebStronaGlownaUkryjDziennyWymiarPracy?.value !==
                "1" && (
                <div>
                  <Chart data={chartDataTodays} height={200} width={450}>
                    <Palette scheme={["#50D6AD", "red"]} />
                    <PieSeries
                      name="Series name"
                      valueField="time"
                      argumentField="workingTime"
                    />
                    <Legend labelComponent={LegendComponent} />
                    <Title
                      text={workingNormTodayTitle}
                      // textComponent={TextComponent}
                    />
                    {/* <Animation /> */}
                    {/* <Tooltip /> */}
                  </Chart>
                </div>
              )}
            </div>
          )}
        </LoadingWrapper>
      </>
    </>
  );
};

export default ChartWorkingTime;
