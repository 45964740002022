import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useEffect, useMemo, useState } from "react";
import { FullscreenControl } from "react-leaflet-fullscreen";
import L from "leaflet";
import { useSMSVisualizationDataContext } from "../../../HelpersFunctions/SMSVisualizationDataProvider";

interface IBuildingMarker {
  latitude: number;
  longitude: number;
  name: string;
  description: string;
}

const BuildingsMapSMSUser = () => {
  const { t } = useTranslation();
  const position: any = [52.06861, 19.47194];

  const { buildingsData, buildingsLoading, buildingsError } =
    useSMSVisualizationDataContext();

  const buildings = useMemo(() => buildingsData || [], [buildingsData]);

  const [markers, setMarkers] = useState<IBuildingMarker[]>([]);

  const customIcon = new L.Icon({
    iconUrl: "/images/marker-icon.png",
    shadowUrl: "/images/marker-shadow.png",
    iconRetinaUrl: "",
    iconAnchor: [12, 20],
    popupAnchor: [1, -24],
  });

  useEffect(() => {
    if (buildingsLoading || buildingsError) {
      return;
    }

    let tempData: IBuildingMarker[] = [];

    buildings.forEach((building) => {
      if (
        building.latitude === null ||
        building.latitude === undefined ||
        building.longitude === null ||
        building.longitude === undefined
      )
        return;

      tempData.push({
        latitude: building.latitude,
        longitude: building.longitude,
        name: building.name ?? "",
        description: building.description ?? "",
      });
    });

    setMarkers(tempData);
  }, [buildings, buildingsLoading, buildingsError]);

  return (
    <div>
      <TopBanner pathName={t("buildings_map")} />
      <div style={{ width: "100%", height: "80vh" }}>
        <MapContainer center={position} zoom={6} scrollWheelZoom={true}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={[marker.latitude, marker.longitude]}
              icon={customIcon}
            >
              <Popup>
                <strong>{marker.name}</strong>
                <br />
                {marker.description}
              </Popup>
            </Marker>
          ))}
          <FullscreenControl />
        </MapContainer>
      </div>
    </div>
  );
};

export default BuildingsMapSMSUser;
