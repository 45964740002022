import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import fetchClient from "../../../api/fetchClient";
import { ProfileType } from "../../../enums/profileType";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";

const fetchSMSVisualizationObjects = async (
  profileType: ProfileType
): Promise<ISMSVisualizationObject[]> => {
  const endpoint =
    profileType === ProfileType.SMS_ADMINISTRATOR
      ? "sms-administrator/visualization-objects"
      : "sms-user/visualization-objects";

  return fetchClient<ISMSVisualizationObject[]>(endpoint, { useAuth: true });
};

const useFetchSMSVisualizationObjects = () => {
  const queryClient = useQueryClient();
  const authUser = useAppSelector(selectAuthUser);
  const profileType = authUser.currentProfile?.type;

  useEffect(() => {
    if (
      profileType === ProfileType.SMS_ADMINISTRATOR ||
      profileType === ProfileType.SMS_USER
    ) {
      queryClient.prefetchQuery(["SMSVisualizationObjects", profileType], () =>
        fetchSMSVisualizationObjects(profileType as ProfileType)
      );
    }
  }, [profileType, queryClient]);

  return useQuery<ISMSVisualizationObject[], Error>(
    ["SMSVisualizationObjects", profileType],
    () => fetchSMSVisualizationObjects(profileType as ProfileType),
    {
      enabled:
        profileType !== undefined &&
        (profileType === ProfileType.SMS_ADMINISTRATOR ||
          profileType === ProfileType.SMS_USER),
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export default useFetchSMSVisualizationObjects;
