import { openDB, DBSchema } from "idb";

interface SMSImagesLibraryDB extends DBSchema {
  maps: {
    key: number;
    value: ISMSMapsLibraryItemDB;
  };

  images: {
    key: number;
    value: ISMSImagesLibraryItemDB;
  };
}

const dbPromise = openDB<SMSImagesLibraryDB>("skalfi-sms-db", 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains("maps")) {
      db.createObjectStore("maps", { keyPath: "id" });
    }

    if (!db.objectStoreNames.contains("images")) {
      db.createObjectStore("images", { keyPath: "id" });
    }
  },
});

export const isIndexedDBAvailable = (): boolean => {
  try {
    return !!window.indexedDB;
  } catch (e) {
    return false;
  }
};

/*Save data operations*/
export const saveMapToIndexedDB = async (
  map: ISMSMapsLibraryItemDB
): Promise<void> => {
  const db = await dbPromise;
  const tx = db.transaction("maps", "readwrite");
  await tx.store.put(map);
  await tx.done;
};

export const saveImageToIndexedDB = async (
  image: ISMSImagesLibraryItemDB
): Promise<void> => {
  const db = await dbPromise;
  const tx = db.transaction("images", "readwrite");
  await tx.store.put(image);
  await tx.done;
};

/*Load data operations*/
export const loadMapFromIndexedDB = async (
  id: number
): Promise<ISMSMapsLibraryItemDB | undefined> => {
  const db = await dbPromise;
  return db.get("maps", id);
};

export const loadAllMapsFromIndexedDB = async (): Promise<
  ISMSMapsLibraryItemDB[]
> => {
  const db = await dbPromise;
  return db.getAll("maps");
};

export const loadImageFromIndexedDB = async (
  id: number
): Promise<ISMSImagesLibraryItemDB | undefined> => {
  const db = await dbPromise;
  return db.get("images", id);
};

export const loadAllImagesFromIndexedDB = async (): Promise<
  ISMSImagesLibraryItemDB[]
> => {
  const db = await dbPromise;
  return db.getAll("images");
};

/*Delete operations*/
export const deleteMapFromIndexedDB = async (id: number): Promise<void> => {
  const db = await dbPromise;
  const tx = db.transaction("maps", "readwrite");
  await tx.store.delete(id);
  await tx.done;
};

export const deleteImageFromIndexedDB = async (id: number): Promise<void> => {
  const db = await dbPromise;
  const tx = db.transaction("images", "readwrite");
  await tx.store.delete(id);
  await tx.done;
};
