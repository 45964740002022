import React from "react";
import HolidaysLimitContent from "./holidaysLimitContent";

function HolidaysLimit() {
  return (
    <div>
      <HolidaysLimitContent />
    </div>
  );
}

export default HolidaysLimit;
