import React, { ReactElement, useEffect, useState } from "react";
import { AutocompleteStyled } from "../../MaterialUi";
import { Controller } from "react-hook-form";
import ErrorLabel from "../ErrorLabel/errorLabel";

export default function SelectFieldReactHookForm({
  name,
  label,
  showTopLabel,
  control,
  setValue,
  clearErrors,
  watch,
  multiple,
  ...props
}: any): ReactElement {
  const [autocompleteValue, setAutocompleteValue] = useState<any>(
    multiple ? [] : null
  );
  const watchedValue = watch(name);

  useEffect(() => {
    if (watchedValue) {
      setAutocompleteValue(watchedValue);
    }
  }, [watchedValue]);

  return (
    <div>
      {showTopLabel && (
        <div>
          <b>{label}:</b>
        </div>
      )}

      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => {
          return (
            <>
              <AutocompleteStyled
                isError={error?.message}
                label={label}
                multiple={multiple}
                {...props}
                onChange={(_, value) => {
                  setAutocompleteValue(value || []);
                  setValue(name, value || []);

                  if (clearErrors) {
                    clearErrors(name);
                  }
                }}
                value={autocompleteValue ? autocompleteValue : null}
              />

              {error?.message ? (
                <ErrorLabel errorName={error?.message} />
              ) : null}
            </>
          );
        }}
      />
    </div>
  );
}
