import React, { useState, useRef } from "react";
import { Menu, MenuItem, Paper, Popper } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export interface MenuItemType {
  text?: string;
  icon?: IconDefinition;
  customComponent?: React.ReactNode;
  items?: MenuItemType[];
  onClick?: () => void;
}

interface MultiLevelContextMenuProps {
  menuItems: MenuItemType[];
  position?: { x: number; y: number };
  container?: HTMLElement | null;
  onClose?: () => void;
}

const MultiLevelContextMenu: React.FC<MultiLevelContextMenuProps> = ({
  menuItems,
  position,
  container,
  onClose,
}) => {
  const [openMenus, setOpenMenus] = useState<string[]>([]);
  const idCounter = useRef(0);

  const generateId = () => {
    idCounter.current += 1;
    return idCounter.current.toString();
  };

  const handleMenuOpen = (menuId: string) => {
    if (!openMenus.includes(menuId)) {
      setOpenMenus([...openMenus, menuId]);
    }
  };

  const handleMenuClose = (menuId: string) => {
    setOpenMenus(openMenus.filter((id) => id !== menuId));
  };

  const handleCloseMenu = () => {
    onClose?.();
  };

  const renderSubMenu = (
    items: MenuItemType[],
    parentId: string,
    anchorRef: React.RefObject<HTMLLIElement>
  ) => {
    return (
      <Popper
        open={openMenus.includes(parentId)}
        anchorEl={anchorRef.current}
        placement="right-start"
      >
        <Paper>
          <Menu
            anchorReference="anchorEl"
            anchorEl={anchorRef.current}
            open={openMenus.includes(parentId)}
            onClose={() => handleMenuClose(parentId)}
            container={container}
          >
            {items.map((item) => {
              const itemId = generateId();
              return (
                <MenuItem
                  key={itemId}
                  onClick={() => {
                    if (!item.customComponent) {
                      item.onClick?.();
                      handleMenuClose(parentId);
                      handleCloseMenu();
                    }
                  }}
                >
                  {item.icon && (
                    <FontAwesomeIcon
                      icon={item.icon}
                      style={{ marginRight: 8 }}
                    />
                  )}
                  {item.text}
                  {item.items && item.items.length > 0 && (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ marginLeft: "auto" }}
                    />
                  )}
                  {item.customComponent && (
                    <div
                      onMouseDown={(e) => e.stopPropagation()}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {item.customComponent}
                    </div>
                  )}
                </MenuItem>
              );
            })}
          </Menu>
        </Paper>
      </Popper>
    );
  };

  const renderMenuItems = (items: MenuItemType[]): React.ReactNode => {
    return items.map((item) => {
      const itemId = generateId();
      return (
        <div key={itemId}>
          {item.customComponent ? (
            <MenuItem
              onClick={(e) => e.stopPropagation()}
              style={{ display: "block", width: "100%", minHeight: "45px" }}
            >
              <div onMouseDown={(e) => e.stopPropagation()}>
                {item.customComponent}
              </div>
            </MenuItem>
          ) : (
            <MenuItem
              aria-controls={item.items ? `${itemId}-menu` : undefined}
              aria-haspopup={item.items ? "true" : undefined}
              onClick={() => {
                if (item.items && item.items.length > 0) {
                  handleMenuOpen(itemId);
                } else {
                  item.onClick?.();
                  handleCloseMenu();
                }
              }}
              ref={(node) => {
                if (node && item.items && item.items.length > 0) {
                  renderSubMenu(item.items, itemId, { current: node });
                }
              }}
            >
              {item.icon && (
                <FontAwesomeIcon icon={item.icon} style={{ marginRight: 8 }} />
              )}
              {item.text}
              {item.items && item.items.length > 0 && (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ marginLeft: "auto" }}
                />
              )}
            </MenuItem>
          )}
          {item.items &&
            item.items.length > 0 &&
            renderSubMenu(item.items, itemId, { current: null })}
        </div>
      );
    });
  };

  return (
    <div>
      {position && (
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={{ top: position.y, left: position.x }}
          keepMounted
          open={true}
          onClose={handleCloseMenu}
          container={container}
        >
          {renderMenuItems(menuItems)}
        </Menu>
      )}
    </div>
  );
};

export default MultiLevelContextMenu;
