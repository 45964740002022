import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import {
  faBook,
  faBuilding,
  faHome,
  faInfo,
  faMap,
  faObjectGroup,
} from "@fortawesome/free-solid-svg-icons";
import HomePageSMSAdministrator from "../../smsAdministrator/Home";
import BuildingsGroupsSMSAdministrator from "../../smsAdministrator/BuildingsGroups";
import BuildingsSMSAdministrator from "../../smsAdministrator/Buildings";
import MapsArrangmentSMSAdministrator from "../../smsAdministrator/MapsArrangement";
import ImagesLibrarySMSAdministrator from "../../smsAdministrator/Libraries/ImagesLibrary";
import VisualizationObjectsSMSAdministrator from "../../smsAdministrator/VisualizationObjects/VisualizationObjectsSMSAdministrator";
import VisualizationObjectsGroupsLibrarySMSAdministrator from "../../smsAdministrator/Libraries/VisualizationObjectsGroupsLibrary/VisualizationObjectsGroupsLibrarySMSAdministrator";
import VisualizationObjectsStatesGroupsLibrarySMSAdministrator from "../../smsAdministrator/Libraries/VisualizationObjectsStatesGroupsLibrary/VisualizationObjectsStatesGroupsLibrarySMSAdministrator";
import VisualizationObjectsGroupsCategoriesSMSAdministrator from "../../smsAdministrator/Libraries/VisualizationObjectsGroupsCategoriesLibrary/VisualizationObjectsGroupsCategoriesSMSAdministrator";
import { ProfileType } from "../../../enums/profileType";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArraySMSAdministrator: navLinksArrayTimeWorkerInterface = {
    profileId: ProfileType.SMS_ADMINISTRATOR,
    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/sms-administrator/profile",
        component: () => <Profile />,
      },
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: settings.WebUkryjUzytkownikKDStronaGlowna?.value === "1",
        singleLink: true,
        path: "/sms-administrator/home",
        component: (props) => <HomePageSMSAdministrator {...props} />,
      },
      {
        icon: faObjectGroup,
        name: t("buildings_groups"),
        ifHidden: settings.WebSMSAdministratorUkryjGrupyBudynkow?.value === "1",
        singleLink: true,
        path: "/sms-administrator/buildings-groups",
        component: (props) => <BuildingsGroupsSMSAdministrator {...props} />,
      },
      {
        icon: faBuilding,
        name: t("buildings"),
        ifHidden: settings.WebSMSAdministratorUkryjBudynki?.value === "1",
        singleLink: true,
        path: "/sms-administrator/buildings",
        component: (props) => <BuildingsSMSAdministrator {...props} />,
      },

      {
        icon: faBuilding,
        name: t("visualization_objects"),
        ifHidden: settings.WebSMSAdministratorUkryjBudynki?.value === "1",
        singleLink: true,
        path: "/sms-administrator/visualization-objects",
        component: (props) => (
          <VisualizationObjectsSMSAdministrator {...props} />
        ),
      },

      {
        icon: faMap,
        name: t("arrangement"),
        ifHidden: settings.WebSMSAdministratorUkryjAranzacjeMap?.value === "1",
        singleLink: true,
        path: "/sms-administrator/maps-arrangement",
        component: (props) => <MapsArrangmentSMSAdministrator {...props} />,
      },
      {
        icon: faBook,
        name: t("libraries"),
        ifHidden: settings.WebSMSAdministratorUkryjBiblioteki?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("images"),
            path: "/sms-administrator/images-library",
            ifHidden:
              settings.WebSMSAdministratorUkryjBibliotekeObrazow?.value === "1",
            component: () => <ImagesLibrarySMSAdministrator />,
          },
          {
            name: t("visualization_objects_groups_categories"),
            path: "/sms-administrator/visualization-objects-groups-categories",
            ifHidden:
              settings
                .WebSMSAdministratorUkryjBibliotekeKategoriiGrupObiektowWizualizacji
                ?.value === "1",
            component: () => (
              <VisualizationObjectsGroupsCategoriesSMSAdministrator />
            ),
          },
          {
            name: t("visualization_objects_groups"),
            path: "/sms-administrator/visualization-objects-groups",
            ifHidden:
              settings
                .WebSMSAdministratorUkryjBibliotekeGrupObiektowWizualizacji
                ?.value === "1",
            component: () => (
              <VisualizationObjectsGroupsLibrarySMSAdministrator />
            ),
          },
          {
            name: t("visualization_objects_states_groups"),
            path: "/sms-administrator/visualization-objects-states-groups",
            ifHidden:
              settings
                .WebSMSAdministratorUkryjBibliotekeGrupStanowObiektowWizualizacji
                ?.value === "1",
            component: () => (
              <VisualizationObjectsStatesGroupsLibrarySMSAdministrator />
            ),
          },
        ],
      },
    ],
  };

  return navLinksArraySMSAdministrator;
};

export default useNavLinksArray;
