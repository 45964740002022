export function secondsToHHmmWithout24HLimit(totalSeconds) {
  // Zaokrąglenie sekund do pełnej minuty, jeśli są większe lub równe 30
  if (totalSeconds % 60 >= 30) {
    totalSeconds += 60 - (totalSeconds % 60);
  }

  // Obliczenie liczby dni, godzin, minut i sekund
  let days = Math.floor(totalSeconds / (3600 * 24));
  totalSeconds %= 3600 * 24;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);

  // Formatowanie wyniku
  let formattedHours = String(hours + days * 24).padStart(2, "0");
  let formattedMinutes = String(minutes).padStart(2, "0");

  return formattedHours + ":" + formattedMinutes;
}
