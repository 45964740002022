import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import { faHistory, faHome, faInfo } from "@fortawesome/free-solid-svg-icons";
import HomePageSMSUser from "../../smsUser/Home";
import BuildingsMapSMSUser from "../../smsUser/BuildingsMap";
import BuildingSMSUser from "../../smsUser/BuildingView";
import BuildingFloorSMSUser from "../../smsUser/BuildingFloorView";
import BuildingsGroupSMSUser from "../../smsUser/BuildingsGroupView";
import EventsHistorySMSUser from "../../smsUser/EventsHistory/EventsHistory";
import { ProfileType } from "../../../enums/profileType";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArraySMSUser: navLinksArrayTimeWorkerInterface = {
    profileId: ProfileType.SMS_USER,

    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/sms-user/profile",
        component: () => <Profile />,
      },
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: settings.WebUkryjUzytkownikKDStronaGlowna?.value === "1",
        singleLink: true,
        path: "/sms-user/home",
        component: (props) => <HomePageSMSUser {...props} />,
      },
      {
        icon: faHistory,
        name: t("events_history"),
        ifHidden: settings.WebUkryjUzytkownikKDHistoriaZdarzen?.value === "1",
        singleLink: true,
        path: "/sms-user/events-history",
        component: () => <EventsHistorySMSUser />,
      },
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: false,
        singleLink: true,
        path: "/sms-user/buildings-map",
        component: (props) => <BuildingsMapSMSUser {...props} />,
      },
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: false,
        singleLink: true,
        path: "/sms-user/buildings-groups/:id",
        component: (props) => <BuildingsGroupSMSUser {...props} />,
      },
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: false,
        singleLink: true,
        path: "/sms-user/buildings/:id",
        component: (props) => <BuildingSMSUser {...props} />,
      },
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: false,
        singleLink: true,
        path: "/sms-user/building-floors/:id",
        component: (props) => <BuildingFloorSMSUser {...props} />,
      },
    ],
  };

  return navLinksArraySMSUser;
};
export default useNavLinksArray;
