import React, { useRef, useState } from "react";
import styles from "./selectingTable.module.scss";
import mouseState from "../../../../HelpersFunctions/mouseDownUpState";
import _ from "lodash";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import { useTranslation } from "react-i18next";

const setSelectedRange = (
  startCellRefCurrent: CellPosition,
  endCell: CellPosition,
  grid: GridTemplate[][],
  setGrid: React.Dispatch<GridTemplate[][]>,
  gridInitialTemplate: GridTemplate[][],
  ifNotResetSelected: boolean
) => {
  let startCellLocal = { ...startCellRefCurrent };
  let endCellLocal = { ...endCell };
  if (startCellLocal.yIndex > endCellLocal.yIndex) {
    startCellLocal.yIndex = endCell.yIndex;
    endCellLocal.yIndex = startCellRefCurrent.yIndex;
  }
  if (startCellLocal.xIndex > endCellLocal.xIndex) {
    startCellLocal.xIndex = endCell.xIndex;
    endCellLocal.xIndex = startCellRefCurrent.xIndex;
  }

  const selectedRange = {
    rowBegin: startCellLocal.yIndex,
    rowEnd: endCellLocal.yIndex,
    columnsBegin: startCellLocal.xIndex,
    columnsEnd: endCellLocal.xIndex,
  };

  if (selectedRange.rowBegin === 0) selectedRange.rowBegin = 1;
  if (selectedRange.columnsBegin === 0) selectedRange.columnsBegin = 1;

  let gridLocal: GridTemplate[][];
  if (ifNotResetSelected === true) {
    let clonedGrid: GridTemplate[][] = _.cloneDeep(grid);
    gridLocal = clonedGrid;
  } else {
    let clonedGrid: GridTemplate[][] = _.cloneDeep(gridInitialTemplate);
    gridLocal = clonedGrid;
  }

  gridLocal.forEach((row, yIndex) => {
    if (yIndex < selectedRange.rowBegin || yIndex > selectedRange.rowEnd)
      return;
    row.forEach((rowCell, xIndex) => {
      if (
        xIndex < selectedRange.columnsBegin ||
        xIndex > selectedRange.columnsEnd
      )
        return;
      gridLocal[yIndex][xIndex].isSelected = true;
    });
  });
  setGrid(gridLocal);
};

interface SelectingTableInterface {
  grid: GridTemplate[][];
  setGrid: React.Dispatch<GridTemplate[][]>;
  gridInitialTemplate: GridTemplate[][];
  setCellDescription: React.Dispatch<string>;
}

const SelectingTable = ({
  setGrid,
  grid,
  gridInitialTemplate,
  setCellDescription,
}: SelectingTableInterface) => {
  const { t } = useTranslation();
  const refRootDiv = useRef<HTMLDivElement>(null);
  const refPositionAbsolute = useRef<HTMLDivElement>(null);
  const [absolutePosition, setAbsolutePosition] = useState<AbsolutePosition>({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    startLeft: 0,
    startTop: 0,
  });
  let startCellRef = useRef<null | CellPosition>(null);

  return (
    <>
      <div className={styles.rootContainer} ref={refRootDiv}>
        <table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              {grid[0].map((headerCell, headerCellIndex) => {
                return (
                  <th
                    className={
                      headerCellIndex === 0
                        ? `${styles.stickyCol} ${styles.stickyHeaderCell}`
                        : ""
                    }
                    key={headerCellIndex}
                  >
                    {headerCell.cellValue}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody
            className={styles.body}
            onMouseMove={(e) => {
              if (refRootDiv.current === null) return;
              const rect = refRootDiv.current.getBoundingClientRect();
              const x = e.clientX - rect.left;
              const y = e.clientY - rect.top;
              const containerRightEdge = rect.right - rect.left;

              if (mouseState() === "down") {
                if (refPositionAbsolute.current === null) return;
                let absoluteDivPosition =
                  refPositionAbsolute.current.getBoundingClientRect();
                let scrollLeft = refRootDiv.current.scrollLeft;
                if (
                  x - absolutePosition.startLeft + scrollLeft < 0 &&
                  y - absolutePosition.startTop < 0
                ) {
                  setAbsolutePosition((prev: AbsolutePosition) => ({
                    ...prev,
                    left: x + scrollLeft,
                    top: y,
                    width: absolutePosition.startLeft - x - scrollLeft,
                    height: absolutePosition.startTop - y,
                  }));
                } else if (
                  x - absolutePosition.startLeft + scrollLeft < 0 &&
                  y - absolutePosition.startTop > 0
                ) {
                  setAbsolutePosition((prev: AbsolutePosition) => ({
                    ...prev,
                    left: x + scrollLeft,
                    top: prev.startTop,
                    width: absolutePosition.startLeft - x - scrollLeft,
                    height: e.clientY - absoluteDivPosition.top,
                  }));
                } else if (
                  x - absolutePosition.startLeft + scrollLeft > 0 &&
                  y - absolutePosition.startTop < 0
                ) {
                  setAbsolutePosition((prev: AbsolutePosition) => ({
                    ...prev,
                    left: absolutePosition.startLeft,
                    top: y,
                    width: e.clientX - absoluteDivPosition.left,
                    height: absolutePosition.startTop - y,
                  }));
                } else {
                  setAbsolutePosition((prev: AbsolutePosition) => ({
                    ...prev,
                    left: prev.startLeft,
                    top: prev.startTop,
                    width: e.clientX - absoluteDivPosition.left,
                    height: e.clientY - absoluteDivPosition.top,
                  }));
                }

                if (x > containerRightEdge * 0.94) {
                  refRootDiv.current.scrollBy({
                    left: 300,
                    behavior: "smooth",
                  });
                } else if (x > containerRightEdge * 0.9) {
                  refRootDiv.current.scrollBy({
                    left: 100,
                    behavior: "smooth",
                  });
                } else if (x < containerRightEdge * 0.1) {
                  refRootDiv.current.scrollBy({
                    left: -300,
                    behavior: "smooth",
                  });
                } else if (x < containerRightEdge * 0.06) {
                  refRootDiv.current.scrollBy({
                    left: -100,
                    behavior: "smooth",
                  });
                }
              }
            }}
          >
            {grid.map((row, yIndex) => {
              if (yIndex === 0) return null;
              return (
                <tr key={yIndex}>
                  {row.map((rowCell, xIndex) => {
                    let cellStyle = "";
                    let styleSelected = "";
                    let cellWeekdayNum = rowCell.day?.getDay();
                    
                    if (rowCell.cellValue !== "brak" && xIndex !== 0)
                      cellStyle = styles.filled;
                    if (xIndex === 0) cellStyle = styles.firstColumn;
                    if (rowCell?.isSelected) {
                      styleSelected = styles.cellSelected;
                    }
                    if (rowCell.cellBackground) { 
                      cellStyle = styles.cellPastDate;
                      
                      if (cellWeekdayNum === 0 ) cellStyle = styles.cellPastSunday;
                      else if (cellWeekdayNum === 6 ) cellStyle = styles.cellPastSaturday;
                    }
                    else {

                      if (cellWeekdayNum === 0 ) cellStyle = styles.cellSunday;
                      else if (cellWeekdayNum === 6 ) cellStyle = styles.cellSaturday;
                    }

                    return (
                      <td
                        className={`${xIndex === 0 && styles.stickyCol} ${cellStyle} ${styleSelected} `}
                        style={{
                          borderTop: rowCell?.schemaInfo?.dayFunction
                            ? `5px solid ${rowCell.schemaInfo.dayFunction.color}`
                            : undefined,
                        }}
                        onClick={() => {
                          if (xIndex === 0) return;
                          let cellDate = toLocaleStringCustom({
                            date: rowCell.day!,
                            t,
                          });

                          let cellSchemaName = rowCell.schemaInfo?.schemaName;
                          let cellDayFunctionSubjectName = rowCell.dayFunction
                            ? rowCell.dayFunction.subjectName
                            : "";
                          let cellEmployee = row[0].cellValue;

                          if (cellDayFunctionSubjectName) {
                            cellDayFunctionSubjectName += ", ";
                          }

                          let finalString = `${cellDate}[${cellSchemaName}]: ${cellDayFunctionSubjectName}${cellEmployee}`;
                          setCellDescription(finalString);
                        }}
                        onMouseDown={(e) => {
                          if (refRootDiv.current === null) return;
                          let scrollLeft = refRootDiv.current.scrollLeft;
                          const rect =
                            refRootDiv.current.getBoundingClientRect();
                          const x = e.clientX - rect.left;
                          const y = e.clientY - rect.top;
                          setAbsolutePosition((prev: AbsolutePosition) => ({
                            ...prev,
                            left: x,
                            top: y,
                            startLeft: x + scrollLeft,
                            startTop: y,
                          }));

                          startCellRef.current = { yIndex, xIndex };
                        }}
                        onMouseUp={(e) => {
                          setAbsolutePosition({
                            width: 0,
                            height: 0,
                            left: 0,
                            top: 0,
                            startLeft: 0,
                            startTop: 0,
                          });
                          if (startCellRef.current === null) return;
                          setSelectedRange(
                            startCellRef.current,
                            { yIndex, xIndex },
                            grid,
                            setGrid,
                            gridInitialTemplate,
                            e.ctrlKey
                          );
                        }}
                        key={xIndex}
                      >
                        {rowCell.cellValue}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div
          ref={refPositionAbsolute}
          className={styles.selectedArea}
          style={{
            width: absolutePosition.width,
            height: absolutePosition.height,
            left: absolutePosition.left,
            top: absolutePosition.top,
          }}
        ></div>
      </div>
    </>
  );
};

export default SelectingTable;
