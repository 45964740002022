import { DialogContent } from "@material-ui/core";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextFieldStyled } from "../MaterialUi";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
  Row,
  RowInput,
  RowTitle,
} from "../PopupHelpers";
import { isNullOrEmpty } from "../../../HelpersFunctions/stringUtils";
import { T_WorkersSelectionPreset } from "../../../hooks/useWorkersSelectionPresets/useWorkersSelectionPresets";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  profiles: T_WorkersSelectionPreset[];
  setProfiles: (
    newVal: React.SetStateAction<T_WorkersSelectionPreset[]>
  ) => void;
  newProfileValue: number[];
  onAdd?: () => void;
}

export default function NewSelectionProfilePopup({
  open,
  setOpen,
  profiles,
  setProfiles,
  newProfileValue,
  onAdd,
}: Props): ReactElement {
  const { t } = useTranslation();
  const closeDialog = () => setOpen(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [newProfileName, setNewProfileName] = useState<string>("");

  const saveNewProfile = () => {
    setProfiles((prev) => {
      const newProfile = {
        name: newProfileName,
        value: newProfileValue,
      };
      return [...prev, newProfile];
    });
  };

  return (
    <DialogStyled open={open} onClose={closeDialog}>
      <DialogTitleStyled title={t("saving_worker_profile")} />
      <DialogContent>
        <Row>
          <RowTitle>{t("profile_name")}:</RowTitle>
          <RowInput>
            <TextFieldStyled
              label={t("profile_name")}
              value={newProfileName}
              onChange={(e) => {
                setNewProfileName(e.target.value);
                setErrorMsg("");
              }}
              required={true}
            />
          </RowInput>
        </Row>
      </DialogContent>
      <DialogActionsStyled
        onCancel={closeDialog}
        errorString={t(errorMsg)}
        onConfirm={() => {
          if (isNullOrEmpty(newProfileName)) {
            setErrorMsg("profiles_name_cannot_be_empty");
            return;
          }
          if (profiles.find((el) => el.name === newProfileName)) {
            setErrorMsg("profile_with_this_name_already_exists");
            return;
          }
          saveNewProfile();

          if (onAdd) {
            onAdd();
          }
          setOpen(false);
        }}
      />
    </DialogStyled>
  );
}
