import { TFunction } from "react-i18next";

interface Props {
  date: Date | false;
  t: TFunction<"translation">;
  ifShowHours?: boolean;
  ifShowSeconds?: boolean;
}

const toLocaleStringCustom = ({
  date,
  ifShowHours,
  ifShowSeconds,
  t,
}: Props) => {
  if (!date) return "-";
  return new Date(date).toLocaleString(t("scheduler_language"), {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...((ifShowHours || ifShowSeconds) && { hour: "2-digit" }),
    ...((ifShowHours || ifShowSeconds) && { minute: "2-digit" }),
    ...(ifShowSeconds && { second: "2-digit" }),
  });
};

export default toLocaleStringCustom;
