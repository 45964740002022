import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../helpersComponents/PopupHelpers/dialogActionsStyled";
import DialogStyled from "../../helpersComponents/PopupHelpers/dialogStyled";
import { DialogContentStyled } from "../../helpersComponents/PopupHelpers/dialogContentStyled";
import DialogTitleStyled from "../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerFieldReactHookForm from "../ReactHookFormInputs/DatePickerField/DatePickerFieldReactHookForm";
import TwoColumnsRowsContainer from "../PopupHelpers/twoColumnsRowsContainer";
import Row from "../PopupHelpers/row";
import RowTitle from "../PopupHelpers/rowTitle";
import RowInput from "../PopupHelpers/rowInput";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";

interface IProps {
  isOpen: boolean;
  dateFrom?: Date | null;
  dateTo?: Date | null;
  closePopup: () => void;
  onDataChanged: (dateFrom: Date, dateTo: Date) => void;
}

interface IFormInput {
  dateFrom: Date;
  dateTo: Date;
}

const AddEditDateRangePopup: React.FC<IProps> = ({
  isOpen,
  dateFrom,
  dateTo,
  closePopup,
  onDataChanged,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const schema = yup.object({
    dateFrom: yup.date().nullable().required(t("field_required")),
    dateTo: yup.date().nullable().required(t("field_required")),
  });

  const { handleSubmit, reset, control } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      dateFrom: dateFrom ?? new Date(),
      dateTo: dateTo ?? new Date(),
    },
  });

  const onSubmit = (data) => {
    if (onDataChanged) {
      onDataChanged(
        getBeginningOfDate(data.dateFrom, "Day"),
        getEndOfDate(data.dateTo, "Day")
      );
    }
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {
          reset();
        },
        onEnter: () => {
          setErrorMessage("");
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitleStyled title={t("select_date_range")} subtitle={""} />

      <DialogContentStyled>
        <form onSubmit={handleSubmit(onSubmit)} id="submitForm" noValidate>
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("date_from")}:</RowTitle>
              <RowInput>
                <DatePickerFieldReactHookForm
                  name={"dateFrom"}
                  control={control}
                  view={["year", "month", "date"]}
                  format="dd-MM-yyyy"
                  required={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("date_to2")}:</RowTitle>
              <RowInput>
                <DatePickerFieldReactHookForm
                  name={"dateTo"}
                  control={control}
                  view={["year", "month", "date"]}
                  format="dd-MM-yyyy"
                  required={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>
        </form>
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="button"
        formConfirm="submitForm"
        onConfirmText={t("confirm")}
        onCancel={() => {
          closePopup();
        }}
        onConfirm={() => {
          handleSubmit(onSubmit)();
        }}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default AddEditDateRangePopup;
