import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import { faHome, faInfo } from "@fortawesome/free-solid-svg-icons";
import { ProfileType } from "../../../enums/profileType";
import FireReport from "../../fireReport/FireReport";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArrayFireReport: navLinksArrayTimeWorkerInterface = {
    profileId: ProfileType.FIRE_REPORT,
    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/fire-report/profile",
        component: () => <Profile />,
      },
      {
        icon: faHome,
        name: t("fire_report"),
        ifHidden: false,
        singleLink: true,
        path: "/fire-report/home",
        component: (props) => <FireReport {...props} />,
      },
    ],
  };

  return navLinksArrayFireReport;
};

export default useNavLinksArray;
