import React, { ButtonHTMLAttributes, CSSProperties } from "react";
import "./button.scss";
import { colorBackgroundClassNameDictionary } from "../../ProfilesRouting/profilesDictionaries";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  width?: number | string;
  height?: number | string;
  color?: string;
  variant?: "contained" | "outlined"
}

const ForwardInputRefFunction: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  Props
> = (props, ref) => {
  const authUser = useAppSelector(selectAuthUser);
  const myStyles: CSSProperties = {
    width: props.width,
    height: props.height,
  };
  
  const colorStyle: CSSProperties = 
  props.variant === "outlined" ? 
    {color: props.color, borderColor: props.color} : 
    {backgroundColor: props.color};

  const profileClassSuffix = props.variant === "outlined" ? "BorderColorWithHover" : "WithHover"
  const variantClassName = props.variant === "outlined" ? "styledButtonOutlined" : "styledButtonContained";
  const { children, className } = props;
  return (
    <>
      <button
        style={{...myStyles, ...colorStyle}}
        ref={ref}
        {...props}
        className={
          "styledButton " + variantClassName + " " +
          (!(
            Object.keys(authUser).length === 0 &&
            authUser.constructor === Object
          )
            ? colorBackgroundClassNameDictionary[authUser.currentProfile?.type] + profileClassSuffix
            : "acSuperiorWithHover") +
          " " +
          (className ? className : "")
        }
      >
        {children}
      </button>
    </>
  );
};

const ButtonStyled = React.forwardRef(ForwardInputRefFunction);

export default ButtonStyled;
