import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import ButtonStyled from "../../../helpersComponents/Button/button";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import { TimeUnit } from "../../../../Constants/timeUnit";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";
import AutocompleteStyled from "../../../helpersComponents/MaterialUi/Autocomplete/autocomplete";
import useReportsToDownloadTypes from "./useReportsToDownloadTypes";
import { OvertimesReportItem, ReportToDownloadItem } from "./type";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import { ReportToDownloadType } from "../../../../enums/ReportToDownloadType";
import OvertimeReport from "./Reports/OvertimeReport";

const ReportsToDownloadContent = () => {
  const { t } = useTranslation();
  const layoutSettingName = "layoutSuperiorTimeAnalysisHReportsTo";

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [, setAllWorkersSelected] = useState<boolean>(false);
  const [allWorkers, setAllWorkers] = useState<any[]>([]);
  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Year")
  );

  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Month"));
  const [, setSelectedWorkersString] = useState<string | undefined>();

  const reportsTypes = useReportsToDownloadTypes();
  const [selectedReportType, setSelectedReportType] =
    useState<ReportToDownloadItem | null>(reportsTypes[0]);

  const modifyResponseCallback = useCallback(
    (responseData) => {
      if (responseData && responseData.resJson) {
        const modified = responseData.resJson.map((row) => {
          return {
            ...row,
            fourteenConsecutiveDaysOff: row.fourteenConsecutiveDaysOff ? (
              <FontAwesomeIcon
                className={styles.faCheckCircle}
                icon={faCheckCircle}
              />
            ) : (
              <FontAwesomeIcon
                className={styles.faTimesCircle}
                icon={faTimesCircle}
              />
            ),
            remainedOverdueLeave:
              row.remainedOverdueLeaveUnit === TimeUnit.MINUTES
                ? `${convertMinsToHrsMins(row.remainedOverdueLeave)} ${t(
                    "hours_2"
                  )}`
                : `${row.remainedOverdueLeave} ${t("days")}`,
            id: row.timeWorkerId,
          };
        });

        return modified;
      }

      return [];
    },
    [t]
  );

  const [reportsToDownloadBody, setReportsToDownloadBody] = useState<
    false | string
  >(false);

  const [fetchingStateReportsToDownload, fetchAgainReportsToDownload] =
    useFetchOtherThanGET({
      path: "superior-time/reports-to-download",
      method: "POST",
      contentType: "application/json",
      body: reportsToDownloadBody,
      setBody: setReportsToDownloadBody,
      disableErrorSnackbar: true,
      disableSuccessSnackbar: true,
      successCallback: modifyResponseCallback,
    });

  useEffect(() => {
    if (reportsToDownloadBody) {
      fetchAgainReportsToDownload();
    }
  }, [reportsToDownloadBody, fetchAgainReportsToDownload]);

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  const confirmFilters = () => {
    if (!selectedReportType) return;
    if (allWorkers === undefined) return;

    let selectedWorkersLocal = [...selectedWorkers];

    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );
    setSelectedWorkersString(selectedWorkersStringLocal);
    updateLastSelectedWorkers();

    if (!selectedWorkersStringLocal) return;

    let requestBody = {
      type: selectedReportType.id,
      dateFrom: convertDateFetch(startDate),
      dateTo: convertDateFetch(endDate),
      timeWorkersIds: selectedWorkersStringLocal.split(","),
    };

    setReportsToDownloadBody(JSON.stringify(requestBody));
  };

  const confirmButtonRight = (
    <div className={styles.reportsTypesAndButtonsContainer}>
      <div className={styles.datepickerOptions}>
        <AutocompleteStyled
          width={200}
          darkTheme={true}
          required={true}
          label={t("report_type")}
          options={reportsTypes}
          value={selectedReportType}
          getOptionLabel={(option: any) => {
            return option.name.toUpperCase();
          }}
          getOptionSelected={(
            option: ReportToDownloadItem,
            value: ReportToDownloadItem
          ) => option.id === value.id}
          onChange={(_: ChangeEvent, newValue: ReportToDownloadItem) => {
            setSelectedReportType(newValue);
          }}
        />
      </div>
      <div className={styles.downloadReportButton}>
        {fetchingStateReportsToDownload.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <ButtonStyled
            onClick={() => {
              confirmFilters();
            }}
          >
            {t("download_report")}
          </ButtonStyled>
        )}
      </div>
      <span className="errorMessaggeSummarySuperior">
        {fetchingStateReportsToDownload.isError && t("something_went_wrong")}
      </span>
    </div>
  );

  return (
    <>
      <TopBanner pathName={t("reports_to_download")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName={layoutSettingName}
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            setAllWorkersSelected={setAllWorkersSelected}
          />
        </div>
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={confirmButtonRight}
          />
        </div>

        <div>
          {!fetchingStateReportsToDownload.isFetching &&
            fetchingStateReportsToDownload.response?.status === 200 &&
            selectedReportType?.id === ReportToDownloadType.OVERTIME && (
              <OvertimeReport
                data={
                  fetchingStateReportsToDownload.response
                    ?.resJson as OvertimesReportItem[]
                }
                dateFrom={startDate}
                dateTo={endDate}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default ReportsToDownloadContent;
