import { DialogContentStyled } from "../../../helpersComponents/PopupHelpers";
import FormTabs from "./Forms/FormTabs";

interface Props {
  rowId?: number;
  onSubmit: (values: any) => void;
  isPreview?: boolean;
}

const PopupContentACWorker: React.FC<Props> = ({
  rowId,
  onSubmit,
  isPreview,
}) => {
  return (
    <DialogContentStyled>
      <FormTabs onSubmit={onSubmit} rowId={rowId} isPreview={isPreview} />
    </DialogContentStyled>
  );
};

export default PopupContentACWorker;
