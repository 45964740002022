import React from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import SMSVisualization from "../../helpersComponents/SMS/SMSVisualization/SMSVisualization";
import { SMSVisualizationMode } from "../../../enums/SMS/SMSVisualizationMode";

const MapsArrangmentSMSAdministrator = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TopBanner pathName={t("arrangement")} />
      <div className="flexAndCenter">
        <SMSVisualization mode={SMSVisualizationMode.ARRANGEMENT} />
      </div>
    </div>
  );
};

export default MapsArrangmentSMSAdministrator;
