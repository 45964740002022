import {
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import Accordion from "../../../helpersComponents/MaterialUi/Accordion/wrappers/Accordion";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableAndActions from "../HolidaysRequests/tableAndActions";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import useFetch from "../../../../hooks/fetchHooks/useFetch/useFetch";
import { useTranslation } from "react-i18next";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import styles from "./according.module.scss";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { hoursRequestsTypes } from "../../../../Constants/hoursRequestsTypes";
import addMonthsToDate from "../../../../HelpersFunctions/dateAndTime/addMonthsToDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import RowDetail from "./rowDetail";
import {
  getJoinedRequestsName,
  isJoinedRequestType,
} from "../../../../HelpersFunctions/requests/hoursRequestsHelpers";
import moment from "moment";

const HoursRequestsAccordion = React.memo(function HoursRequestsAccordion({
  startDateFetch,
  ifOnlyIntroduced,
  endDateFetch,
  timeWorkersIdsString,
  settingsLayoutName,
}: {
  startDateFetch: Date;
  ifOnlyIntroduced?: boolean;
  endDateFetch?: Date;
  timeWorkersIdsString?: string;
  settingsLayoutName: string;
}): ReactElement {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { t } = useTranslation();
  const [dataModified, setDataModified] = useState<any>(null);

  let [setIsFetching, fetchingState, fetchAgain] = useFetch({
    method: "GET",
    path:
      "superior-time/hour-requests?source=timeSuperiorHomePageHoursRequests&dateFrom=" +
      convertDateFetch(startDateFetch, "T00:00:00") +
      "&dateTo=" +
      (endDateFetch
        ? convertDateFetch(endDateFetch, "T23:59:59")
        : convertDateFetch(
            addMonthsToDate(getEndOfDate(new Date(), "Day"), 12)
          )) +
      (timeWorkersIdsString ? `&timeWorkersIds=${timeWorkersIdsString}` : ""),
  });

  useEffect(() => {
    if (fetchingState.isError || fetchingState.response === undefined) return;
    if (!Array.isArray(fetchingState.response.resJson)) return;
    let data = fetchingState.response.resJson;
    let datamodifiedLocal: any = [];
    let lpHours = 1;

    data.forEach((element, index) => {
      data[index].createdAtString = new Date(element.createdAt).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );
      data[index].time = convertMinsToHrsMins(
        (new Date(element.dateTo).getTime() -
          new Date(element.dateFrom).getTime()) /
          (1000 * 60)
      );
      data[index].employee = element.firstName + " " + element.lastName;
      data[index].typeIdString = t(hoursRequestsTypes[data[index].typeId]);
      data[index].statusTranslated = t(statusDictionary[data[index].status]);
      data[index].lp = lpHours;

      data[index].isJoinedRequest = isJoinedRequestType(data[index].typeId);
      data[index].joinedRequest1Name = getJoinedRequestsName(
        data[index].typeId,
        t
      )[0];
      data[index].joinedRequest2Name = getJoinedRequestsName(
        data[index].typeId,
        t
      )[1];

      data[index].createdAtString = new Date(element.createdAt).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );

      data[index].dateFromString = new Date(element.dateFrom).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );
      data[index].dateToString = new Date(element.dateTo).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );

      if (element.date2From && element.date2To) {
        data[index].date2FromString = new Date(
          element.date2From
        ).toLocaleString(t("scheduler_language"), {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        data[index].date2ToString = new Date(element.date2To).toLocaleString(
          t("scheduler_language"),
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }
        );
      }

      data[index].approvedAtString = element?.approvedAt
        ? new Date(element.approvedAt).toLocaleString(t("scheduler_language"), {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })
        : "—";

      lpHours++;
      datamodifiedLocal.push(data[index]);
    });

    setDataModified(datamodifiedLocal);
    setIsFetching(false);
  }, [
    fetchingState.isError,
    fetchingState.response,
    ifOnlyIntroduced,
    setIsFetching,
    t,
  ]);

  const columns = [
    { name: "lp", title: "L.p." },
    {
      name: "dateFrom",
      title: t("date_from"),
      type: "date_hours",
      customFormatter: (row) => (
        <span style={{ color: "green" }}>
          {toLocaleStringCustom({
            date: row.dateFrom ? new Date(row.dateFrom) : false,
            t,
            ifShowHours: true,
          })}
        </span>
      ),
    },
    {
      name: "dateTo",
      title: t("date_to2"),
      type: "date_hours",
      customFormatter: (row) => (
        <span style={{ color: "red" }}>
          {toLocaleStringCustom({
            date: row.dateTo ? new Date(row.dateTo) : false,
            t,
            ifShowHours: true,
          })}
        </span>
      ),
    },
    { name: "time", title: t("time") },
    { name: "employee", title: t("employee") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "typeIdString", title: t("type_of_request") },
    { name: "statusTranslated", title: t("status") },
    { name: "approvedBy", title: t("approved_by") },
    { name: "approvedAt", title: t("approved"), type: "date_hours" },
    { name: "comment", title: t("comment") },
    { name: "finallyApprovedBy", title: t("finnal_approved_by") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
  ];

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "dateFrom") {
      return row.dateFrom
        ? moment(row.dateFrom).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    if (columnName === "dateTo") {
      return row.dateTo ? moment(row.dateTo).format("YYYY-MM-DD HH:mm:ss") : "";
    }

    if (columnName === "createdAt") {
      return row.createdAt
        ? moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    return row[columnName];
  }, []);

  return (
    <div>
      <LoadingWrapper
        isLodadingProgress={fetchingState.isFetching}
        isError={fetchingState.isError}
        setIfFetchAgain={fetchAgain}
      >
        {!fetchingState.isFetching &&
          !fetchingState.isError &&
          dataModified !== null &&
          dataModified?.length >= 0 && (
            <Accordion
              expanded={isExpanded && dataModified.length !== 0}
              onChange={() => setIsExpanded(!isExpanded)}
              disabled={dataModified.length === 0}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={styles.titleAccordion}>
                  {t("list_of_hours")}
                  <div
                    className={
                      styles.greenBorderColor +
                      " " +
                      styles.circleAccordionTitleNumber
                    }
                  >
                    {dataModified.length}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.width100}>
                  {isExpanded && (
                    <TableAndActions
                      rows={dataModified}
                      columns={columns}
                      parallerPatchPath={"superior-time/hour-requests"}
                      ifWhite={true}
                      fetchObject={{
                        fetchAgain,
                        isError: fetchingState.isError,
                        isFetching: fetchingState.isFetching,
                        response: fetchingState.response,
                        setIsFetching,
                      }}
                      settingsLayoutName={settingsLayoutName}
                      rowDetail={RowDetail}
                      customExcelCellValue={customExportCellValue}
                      customPdfCellValue={customExportCellValue}
                    />
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          )}
      </LoadingWrapper>
    </div>
  );
});

export default HoursRequestsAccordion;
