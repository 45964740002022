import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import SwitchStyled from "../../helpersComponents/MaterialUi/Switch/StyledSwitch";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import PopupCompany from "./PopupCompany";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import { ACSuperiorPermission } from "../../../enums/ACSuperiorPermission";

const CompaniesContent = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [isInactiveSwitchState, setIsInactiveSwitchState] = useState(false);

  const columns = [
    { name: "name", title: t("name") },
    { name: "abbrev", title: t("abbrev_name") },
    { name: "taxNumber", title: t("tax_number") },
  ];

  const modifyResponseCallback = useCallback(
    (responseData: ICompany[]) => {
      const modified: ICompany[] = [];
      responseData.forEach((row) => {
        if (row.isActive === true && isInactiveSwitchState) return;
        else if (row.isActive === false && !isInactiveSwitchState) return;

        modified.push(row);
      });

      return modified;
    },
    [isInactiveSwitchState]
  );

  const [companies, fetchingStateCompanies, fetchAgainCompanies] =
    useFetchAndSetGET<ICompany[]>({
      path: "superior-ac/ac-companies",
      modifyResponseCallback: modifyResponseCallback,
    });

  const IsActiveSwitch = (
    <div className="SwtichActive">
      {t("active")}
      <SwitchStyled
        checked={isInactiveSwitchState}
        onChange={() => setIsInactiveSwitchState(!isInactiveSwitchState)}
        name="checkedA"
      />
      {t("inactive")}
    </div>
  );

  const [companyToRemoveOrRestore, setCompanyToRemoveOrRestore] = useState<{
    id: false | number;
    method: "DELETE" | "PATCH";
    body?: [
      {
        op: "replace";
        path: "/isActive";
        value: 1;
      }
    ];
  }>({
    id: false,
    method: "DELETE",
  });

  const [
    companyRemoveOrRestoreFetchingState,
    companyRemoveOrRestoreFetchAgain,
  ] = useFetchOtherThanGET({
    path: `superior-ac/ac-companies/${companyToRemoveOrRestore?.id}`,
    method: companyToRemoveOrRestore.method,
    body:
      companyToRemoveOrRestore.method === "PATCH"
        ? JSON.stringify(companyToRemoveOrRestore.body)
        : null,
    setBody: setCompanyToRemoveOrRestore,
    contentType: "application/json",
    successCallback: fetchAgainCompanies,
  });

  useEffect(() => {
    if (companyToRemoveOrRestore.id) {
      companyRemoveOrRestoreFetchAgain();
    }
  }, [companyToRemoveOrRestore.id, companyRemoveOrRestoreFetchAgain]);

  const actionsColumnUpdated =
    authUser.currentProfile?.permission === ACSuperiorPermission.ADMINISTRATOR
      ? {
          popup: PopupCompany,
          successCallbackPopup: fetchAgainCompanies,
          addButton: {
            ifShow: true,
          },
          editButton: {
            ifShow: isInactiveSwitchState ? false : true,
            name: t("edit"),
          },
          removeButton: {
            ifShow: true,
            name: isInactiveSwitchState ? t("restore") : t("remove"),
            onClick: (row) => {
              if (row?.isActive) {
                setCompanyToRemoveOrRestore({
                  id: row.id,
                  method: "DELETE",
                  body: undefined,
                });
              } else {
                setCompanyToRemoveOrRestore({
                  id: row.id,
                  method: "PATCH",
                  body: [
                    {
                      op: "replace",
                      path: "/isActive",
                      value: 1,
                    },
                  ],
                });
              }
            },
          },
        }
      : {
          popup: PopupCompany,
          successCallbackPopup: fetchAgainCompanies,
          previewButton: {
            ifShow: true,
            name: t("preview"),
          },
        };

  return (
    <div>
      <div>
        <TopBanner pathName={t("companies")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            columns={columns}
            rows={companies}
            layoutSettingName={"acSuperiorCompanies"}
            toolbarComponent={IsActiveSwitch}
            fetchingState={{
              isFetching:
                fetchingStateCompanies.isFetching ||
                companies === null ||
                companyRemoveOrRestoreFetchingState.isFetching,
              isError:
                fetchingStateCompanies.isError ||
                companyRemoveOrRestoreFetchingState.isError,
              fetchAgain: fetchAgainCompanies,
            }}
            actionsColumnUpdated={actionsColumnUpdated}
            tableExportInfo={{
              createdBy: authUser.displayedName,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CompaniesContent;
