type FetchOptions = RequestInit & {
  useAuth?: boolean;
};

type FetchClient = <T>(endpoint: string, options?: FetchOptions) => Promise<T>;

const fetchClient: FetchClient = async (
  endpoint,
  { useAuth = false, ...options } = {}
) => {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    ...options.headers,
  };

  if (useAuth) {
    let authUser: authUserInfo = JSON.parse(
      localStorage.getItem("authUser") || "{}"
    );

    if (authUser.token) {
      headers["Authorization"] = `Bearer ${authUser.token}`;
    }
  }

  const language = localStorage.getItem("language");
  headers["Accept-Language"] = language ? language[0] : "pl";

  const response = await fetch(
    `${window.globalConfig.API_URL}/api/${endpoint}`,
    {
      ...options,
      headers,
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export default fetchClient;
