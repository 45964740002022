import { useTranslation } from "react-i18next";
import { AutocompleteFormik, TextFieldFormik } from "../../FormikInputs";
import CheckboxWithLabelFormik from "../../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../PopupHelpers";

interface Props {
  formFields: FormFields;
  formikValues: any;
  rowId?: number;
  userPermissionsList: ACUserPermission[];
  readersList: IReader[];
  companiesList: IACCompany[];
}

const UserDataForm = ({
  formFields,
  formikValues,
  rowId,
  userPermissionsList,
  readersList,
  companiesList,
}: Props) => {
  const { t } = useTranslation();

  const {
    login,
    permissions,
    leaveCurrentPassword,
    password,
    repeatPassword,
    fullName,
    phoneNumber,
    email,
    locked,
    isChangePasswordRequired,
    alternateEmail1,
    alternateEmail2,
    readers,
    companies,
  } = formFields;

  return (
    <>
      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(login.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(login.label)}
              name={login.name}
              type="text"
              required={true}
            />
          </RowInput>
        </Row>

        <Row>
          <RowTitle width={180}>{t(permissions.label)}:</RowTitle>
          <RowInput>
            <AutocompleteFormik
              options={userPermissionsList}
              getOptionLabel={(option: ACUserPermission) => {
                return option.name.toUpperCase();
              }}
              width="100%"
              getOptionSelected={(
                option: ACUserPermission,
                value: ACUserPermission
              ) => {
                return option.id === value.id;
              }}
              label={t(permissions.label)}
              name={permissions.name}
              required={true}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      {rowId && (
        <TwoColumnsRowsContainer>
          <Row>
            <RowTitle width={180}>{t(leaveCurrentPassword.label)}:</RowTitle>
            <RowInput>
              <CheckboxWithLabelFormik
                name={leaveCurrentPassword.name}
                defaultValue={true}
                label={<span>{t(leaveCurrentPassword.label)}</span>}
              />
            </RowInput>
          </Row>
        </TwoColumnsRowsContainer>
      )}

      {!formikValues[leaveCurrentPassword.name] && (
        <TwoColumnsRowsContainer>
          <Row>
            <RowTitle width={180}>{t(password.label)}:</RowTitle>
            <RowInput>
              <TextFieldFormik
                label={t(password.label)}
                name={password.name}
                type="password"
                hidden={true}
                autoComplete="new-password"
                required={true}
              />
            </RowInput>
          </Row>

          <Row>
            <RowTitle width={180}>{t(repeatPassword.label)}:</RowTitle>
            <RowInput>
              <TextFieldFormik
                label={t(repeatPassword.label)}
                name={repeatPassword.name}
                type="password"
                hidden={true}
                autoComplete="new-password"
                required={true}
              />
            </RowInput>
          </Row>
        </TwoColumnsRowsContainer>
      )}

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(fullName.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(fullName.label)}
              name={fullName.name}
              type="text"
            />
          </RowInput>
        </Row>

        <Row>
          <RowTitle width={180}>{t(phoneNumber.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(phoneNumber.label)}
              name={phoneNumber.name}
              type="number"
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(email.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(email.label)}
              name={email.name}
              type="text"
            />
          </RowInput>
        </Row>

        <Row>
          <RowTitle width={180}>{t(isChangePasswordRequired.label)}:</RowTitle>
          <RowInput>
            <CheckboxWithLabelFormik
              name={isChangePasswordRequired.name}
              label={<span>{t(isChangePasswordRequired.label)}</span>}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      {/*Alternatywne adresy email*/}
      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(alternateEmail1.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={`${t(alternateEmail1.label)} 1`}
              name={alternateEmail1.name}
              type="text"
            />
          </RowInput>
        </Row>

        <Row>
          <RowTitle width={180}>{t(alternateEmail2.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={`${t(alternateEmail1.label)} 2`}
              name={alternateEmail2.name}
              type="text"
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      {/*Readers and Companies*/}
      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(readers.label)}:</RowTitle>
          <RowInput>
            <AutocompleteFormik
              options={readersList}
              getOptionLabel={(reader: any) => {
                return `${reader?.serialNumber} - ${reader?.roomName} (${reader?.buildingName})`;
              }}
              width="100%"
              getOptionSelected={(option: IReader, value: IReader) => {
                return option.id === value.id;
              }}
              label={t(readers.label)}
              name={readers.name}
              required={true}
              multiple
              disableCloseOnSelect
              filterSelectedOptions
            />
          </RowInput>
        </Row>

        <Row>
          <RowTitle width={180}>{t(companies.label)}:</RowTitle>
          <RowInput>
            <AutocompleteFormik
              options={companiesList}
              getOptionLabel={(option: IACCompany) => {
                return option.name?.toUpperCase();
              }}
              width="100%"
              getOptionSelected={(option: IACCompany, value: IACCompany) => {
                return option.id === value.id;
              }}
              label={t(companies.label)}
              name={companies.name}
              required={true}
              multiple
              disableCloseOnSelect
              filterSelectedOptions
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      {rowId && (
        <Row>
          <RowTitle width={180}>{t(locked.label)}:</RowTitle>
          <RowInput>
            <CheckboxWithLabelFormik
              name={locked.name}
              label={<span>{t(locked.label)}</span>}
            />
          </RowInput>
        </Row>
      )}
    </>
  );
};

export default UserDataForm;
