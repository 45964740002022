import formFieldsModel from "./formFieldsModel";

const {
  formFields: {
    //WORKER DATA
    firstName,
    lastName,
    evidenceNumber,
    identifier,
    description,
    position,
    phone,
    email,
    department,
    additions,
    isTracked,
    acSuperiors,
    zones,
    isKP,
    firstDay,
    meetingPlace,
    regularPost,

    //COMPANIES
    companies,

    //GROUP PERMISSIONS
    groups,

    //PERMISSIONS
    permissions,
    isVIP,
    isZJW,
    isAccessDeniedOnHolidays,
    startPermissionDateCheck,
    startPermissionDate,
    permissionExpirationDateCheck,
    permissionExpirationDate,

    //CARD
    cardNumber,
    csn,
    pin,
    remoteNumber,
    additionalCards,

    //ELEVATORS AND CABINETS
    elevatorsAndCabinetsItem,
    elevatorsAndCabinets,

    //SPECIAL PERMISSIONS
    specialPermissions,
  },
} = formFieldsModel;

const emptyGuid = "00000000-0000-0000-0000-000000000000";

const formInitialValues = (
  acWorker: ACWorker | null = null,
  meetingPlacesList: MeetingPlace[],
  departmentsList: Department[],
  zonesList: Zone[],
  acSuperiorsList: ACUser[],
  companiesList: Company[],
  groupsList: Group[],
  elevatorsAndCabinetsList: ElevatorAndCabinet[]
) => {
  return {
    //WORKER DATA
    [firstName.name]: acWorker?.firstName ? acWorker?.firstName : "",
    [lastName.name]: acWorker?.lastName ? acWorker?.lastName : "",
    [evidenceNumber.name]: acWorker?.evidenceNumber
      ? acWorker.evidenceNumber
      : "",
    [identifier.name]: acWorker?.identifier ? acWorker.identifier : "",
    [description.name]: acWorker?.description ? acWorker.description : "",
    [position.name]: acWorker?.position ? acWorker?.position : "",
    [phone.name]: acWorker?.phone ? acWorker?.phone : "",
    [email.name]: acWorker?.email ? acWorker?.email : "",
    [department.name]: acWorker?.departmentId
      ? departmentsList.find(
          (department) => department.id === acWorker?.departmentId
        )
      : null,
    [additions.name]: acWorker?.additions ? acWorker.additions : "",
    [isTracked.name]: acWorker?.isTracked ? acWorker.isTracked : false,
    [acSuperiors.name]: acWorker?.acSuperiorsIds
      ? acSuperiorsList.filter((acSuperior) =>
          acWorker.acSuperiorsIds.includes(acSuperior.id)
        )
      : [],
    [zones.name]: acWorker?.zones
      ? zonesList.filter((zone) =>
          acWorker.zones.map((z) => z.id).includes(zone.id)
        )
      : [],
    [isKP.name]: acWorker?.notApplyKP ? acWorker.notApplyKP : false,
    [firstDay.name]: acWorker?.firstDay ? acWorker.firstDay : 1,
    [meetingPlace.name]: acWorker?.meetingPlaceId
      ? meetingPlacesList.find(
          (meetingPlace) => meetingPlace.id === acWorker.meetingPlaceId
        )
      : null,
    [regularPost.name]: acWorker?.regularPost ? acWorker.regularPost : 0,

    //COMPANIES
    [companies.name]: acWorker?.companies
      ? companiesList.filter((company) =>
          acWorker.companies.map((c) => c.id).includes(company.id)
        )
      : [],

    //GROUP PERMISSIONS
    [groups.name]: acWorker?.groups
      ? groupsList.filter((group) =>
          acWorker.groups.map((c) => c.id).includes(group.id)
        )
      : [],

    //PERMISSIONS
    [permissions.name]: acWorker?.permissions ? acWorker.permissions : [],
    [isVIP.name]: acWorker?.isVIP ? acWorker.isVIP : false,
    [isZJW.name]: acWorker?.isZJW ? acWorker.isZJW : false,
    [isAccessDeniedOnHolidays.name]: acWorker?.isAccessDeniedOnHolidays
      ? acWorker.isAccessDeniedOnHolidays
      : false,
    [startPermissionDateCheck.name]: acWorker?.startPermission ? true : false,
    [startPermissionDate.name]: acWorker?.startPermission
      ? new Date(acWorker.startPermission)
      : null,
    [permissionExpirationDateCheck.name]: acWorker?.permissionExpirationDate
      ? true
      : false,
    [permissionExpirationDate.name]: acWorker?.permissionExpirationDate
      ? new Date(acWorker.permissionExpirationDate)
      : null,

    //Card
    [cardNumber.name]:
      acWorker?.cardNumber && acWorker.cardNumber !== emptyGuid
        ? acWorker.cardNumber.toUpperCase()
        : "",
    [csn.name]: acWorker?.CSN ? acWorker.CSN : "",
    [pin.name]: acWorker?.pin ? acWorker.pin : "",
    [remoteNumber.name]: acWorker?.remoteNumber
      ? acWorker.remoteNumber.toUpperCase()
      : "",
    [additionalCards.name]: acWorker?.additionalCards
      ? acWorker.additionalCards
      : [],

    //Elevators and Cabinets
    [elevatorsAndCabinetsItem.name]: null,
    [elevatorsAndCabinets.name]: acWorker?.elevatorsAndCabinets
      ? acWorker.elevatorsAndCabinets
      : elevatorsAndCabinetsList,

    //Special Permissions
    [specialPermissions.name]: acWorker?.specialPermissions
      ? acWorker.specialPermissions
      : [],
  };
};

export default formInitialValues;
