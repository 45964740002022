import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import { useTranslation } from "react-i18next";
import TimeWithDescriptionSmall2 from "../../NumberWithDescription/NumberWithDescriptionSmall2";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";

const RequestOvertimesDetials = ({ row }) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <b>{t("overtime")}</b>
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <TimeWithDescriptionSmall2
          parametrs={[
            {
              time: convertMinsToHrsMins(row?.overtimes?.remainedInPeriod),
              description: t("remained_in_period"),
              colorNegative: true,
            },
            {
              time: convertMinsToHrsMins(row?.overtimes?.annualRemained),
              description: t("annual_remained"),
              colorNegative: true,
            },
            {
              time: convertMinsToHrsMins(row?.overtimes?.usedOvertimes),
              description: t("used_overtimes"),
              colorNegative: true,
            },
            {
              time: toLocaleStringCustom({
                date: new Date(row?.overtimes?.periodFrom),
                t,
              }),
              description: t("period_from"),
            },
            {
              time: toLocaleStringCustom({
                date: new Date(row?.overtimes?.periodTo),
                t,
              }),
              description: t("period_to"),
            },
            {
              time: convertMinsToHrsMins(row?.overtimes?.periodLimit),
              description: t("period_limit"),
            },
            {
              time: convertMinsToHrsMins(row?.overtimes?.annualLimit),
              description: t("annual_limit"),
            },
          ]}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default RequestOvertimesDetials;
