import "./timePicker.scss";
import dayjs from "dayjs";
import { padNumberTo2Digits } from "../../../../HelpersFunctions/padNumber";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

function TimePickerStyled(props) {
  const { onChange, name, view } = props;
  return (
    <TimePicker
      value={dayjs("2023-01-01T00:00")}
      views={view}
      onChange={(e: any) => {
        if (onChange) {
          let tempDate = new Date(e);
          let tempHours = tempDate.getHours();
          let tempMinutes = tempDate.getMinutes();

          if (!isNaN(tempHours) && !isNaN(tempMinutes)) {
            const tempValue = `${padNumberTo2Digits(
              tempHours
            )}:${padNumberTo2Digits(tempMinutes)}`;

            onChange(tempValue);
          }
        }
      }}
      format="HH:mm"
      className="timePickerInputStyles"
      name={name}
    />
  );
}

export default TimePickerStyled;
