const doFetch = async (
  path: string,
  method: "GET" | "POST" | "PUT" | "PUST" | "DELETE" | "PATCH",
  abortController: AbortController,
  body: any,
  contentType: string | undefined,
  ifBlob: boolean | undefined
): Promise<UseFetchResponse> => {
  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  let language = JSON.parse(
    localStorage.getItem("languageAndOptionId") || "{}"
  );

  const res = await fetch(window.globalConfig.API_URL + "/api/" + path, {
    method: method,
    credentials: "include",
    signal: abortController.signal,
    headers: {
      Authorization: "bearer " + authUser.token,
      "Accept-Language": language ? language[0] : "pl",
      Accept: "application/json",
      ...(contentType && { "Content-Type": contentType }),
    },
    ...(body && { body: body }),
  });

  let resJson: any = null;
  try {
    if (ifBlob) {
      let blob = await res.blob();
      resJson = window.URL.createObjectURL(blob);
    } else {
      const contentType = res.headers.get("content-type");

      if (contentType && contentType.indexOf("json") !== -1) {
        resJson = await res.json();
      }
    }
  } catch (error) {
    console.log(error);
  }

  return {
    isError: !res.ok,
    resJson: resJson,
    location: res.headers.get("Location"),
    status: res.status,
  };
};

export default doFetch;
