import { SMSEventPriority } from "../../enums/SMS/SMSEventPriority";
import { TFunction } from "react-i18next";

export function getEventPriorityName(
  priority: SMSEventPriority,
  t: TFunction<"translation">
): string {
  switch (priority) {
    case SMSEventPriority.LOW:
      return t("priority_low");

    case SMSEventPriority.NORMAL:
      return t("priority_normal");

    case SMSEventPriority.HIGH:
      return t("priority_high");

    case SMSEventPriority.VERY_HIGH:
      return t("priority_very_high");

    case SMSEventPriority.CRITICAL:
      return t("priority_critical");

    default:
      return t("priority_unknown");
  }
}
