import { CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../helpersComponents/Button/button";
import { TextFieldStyled } from "../helpersComponents/MaterialUi";
import styles from "./changePassword.module.scss";

interface Props {
  token?: string;
  onClose?: () => void;
}

const ChangePasswordForm: React.FC<Props> = ({ token, onClose }) => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  let isMounted = useRef(true);

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  let language = JSON.parse(
    localStorage.getItem("languageAndOptionId") || "{}"
  );

  const onSubmit = async () => {
    let ifFetch = true;

    if (oldPassword === "") {
      setOldPasswordError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }
    if (newPassword === "") {
      setNewPasswordError({
        isError: true,
        errorMessage: "required",
      });
      ifFetch = false;
    }
    if (confirmNewPassword === "") {
      setConfirmNewPasswordError({
        isError: true,
        errorMessage: "required",
      });
      ifFetch = false;
    }
    if (newPassword !== confirmNewPassword) {
      setErrorMessage(t("passwords_are_not_the_same"));
      ifFetch = false;
    } else {
      setErrorMessage("");
    }

    if (!ifFetch) return;

    setIsFetching(true);

    await fetch(window.globalConfig.API_URL + "/api/account/change-password", {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "bearer " + (authUser?.token ? authUser.token : token),
        "Content-Type": "application/json",
        "Accept-Language": language ? language[0] : "pl",
      },
      body: JSON.stringify({
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(
            `${t("password_changed")}. ${t("log_with_new_password")}`,
            { variant: "success" }
          );
          setIsFetching(false);

          if (onClose) onClose();

          return 200;
        } else if (response.status === 400) {
          return response.json();
        }

        throw response.status;
      })
      .then((data) => {
        if (data === 200) return;
        let error = "";
        Object.keys(data.errors).forEach((errorObject) => {
          data.errors[errorObject].forEach((errorString) => {
            error += t(errorString) + " ";
          });
        });
        setIsFetching(false);
        enqueueSnackbar(error, { variant: "error" });
      })
      .catch((error) => {
        setIsFetching(false);
        enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
      });
    if (isMounted.current === false) return;

    setIsFetching(false);
  };

  return (
    <div>
      <div className={styles.formChangePassword}>
        <div>
          <TextFieldStyled
            label={t("old_password")}
            defaultValue={oldPassword}
            type="password"
            required={true}
            isError={oldPasswordError.isError}
            onBlur={(e) => {
              if (e.target.value === "") {
                setOldPasswordError({
                  isError: true,
                  errorMessage: "required",
                });
              } else {
                setOldPasswordError({
                  isError: false,
                  errorMessage: "",
                });
              }
              setOldPassword(e.target.value);
            }}
          />
        </div>

        <div>
          <TextFieldStyled
            label={t("new_password")}
            type="password"
            defaultValue={newPassword}
            required={true}
            isError={newPasswordError.isError}
            onBlur={(e) => {
              if (e.target.value === "") {
                setNewPasswordError({
                  isError: true,
                  errorMessage: "required",
                });
              } else {
                setNewPasswordError({
                  isError: false,
                  errorMessage: "",
                });
              }
              setNewPassword(e.target.value);
            }}
          />
        </div>

        <div>
          <TextFieldStyled
            label={t("confirm_new_password")}
            type="password"
            defaultValue={confirmNewPassword}
            required={true}
            isError={confirmNewPasswordError.isError}
            onBlur={(e) => {
              if (e.target.value === "") {
                setConfirmNewPasswordError({
                  isError: true,
                  errorMessage: "required",
                });
              } else {
                setConfirmNewPasswordError({
                  isError: false,
                  errorMessage: "",
                });
              }
              setConfirmNewPassword(e.target.value);
            }}
          />
        </div>
        <div className={styles.confirmChange}>
          <Button disabled={isFetching} onClick={() => onSubmit()}>
            {isFetching ? <CircularProgress size={20} /> : t("change_password")}
          </Button>
        </div>

        <div className={styles.errorChangePassword}>{errorMessage}</div>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
