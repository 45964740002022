import React, { useState, useEffect } from "react";
import "./notificationGuests.scss";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { useTranslation } from "react-i18next";
// import useFetch from '../../../fetch/get/noParametrs'
import Popup from "./popup";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import useFetchDelete from "../../../../hooks/fetchHooks/delete/useFetchDelete";
import {
  CheckboxStyled,
  SnackbarStyled,
} from "../../../helpersComponents/MaterialUi/index";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useCachedFetch from "../../../../hooks/useCachedFetch/cachedFetch";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";

let Cell: any = TableEditColumn.Cell;

const ActionColumn: React.FC = ({
  children,
  row,
  classes,
  ...restProps
}: any) => {
  if (row.isActive) {
    return (
      <Cell row={restProps.row} className="tableCellEditCancelStyleGlobal">
        {children}
      </Cell>
    );
  }
  return (
    <Cell row={restProps.row} className="tableCellDisableSecondGlobal">
      {children}
    </Cell>
  );
};

const SwtichStyled = withStyles({
  switchBase: {
    color: "#25b498",
    "&$checked": {
      color: "#18A689",
    },
    "&$checked + $track": {
      backgroundColor: "#18A689",
    },
  },
  checked: {},
  track: {},
})(Switch);

function NotificationGuests({ ...props }) {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [fetchAgain, setFetchAgain] = useState(true);
  const [dataModified, setDataModified] = useState<any>(null);
  const [dataModifiedFiltered, setDataModifiedFiltered] = useState<any>(null);
  const [isFetching, setIsFetching] = useState(true);
  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);
  const [idUpdateStatus, setIdUpdateStatus] = useState();
  const [ifFetchDelete, setIfFetchDelete] = useState<boolean>(false);
  const authUserId = authUser.currentProfile.subjectId;
  const responseStatusDelete = useFetchDelete(
    `worker-time/${authUserId}/guest-notifications/` + idUpdateStatus,
    ifFetchDelete
  );

  const [isError, setIsError] = useState<boolean>(false);
  const guest = useCachedFetch("guestNotifications", fetchAgain, 36000, true);
  const [isActiveSwitchState, setIsActiveSwitchState] = React.useState(true);

  const columns = [
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "company", title: t("company") },
    { name: "isActiveComponent", title: t("if_active") },
    { name: "description", title: t("description") },
  ];

  useEffect(() => {
    if (guest === "error") {
      setIsError(true);
      setFetchAgain(false);
      return;
    }
    if (guest === null) return;
    let dataModifiedLocal: any = [];
    guest.forEach((element, index) => {
      let isActiveComponent = (
        <div>
          <FormControlLabel
            control={
              <CheckboxStyled
                checked={element.isActive}
                name="active"
                disabled
                // onChange={handleChangeCheckbox}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label={<span className="FormControlLabelStyle"></span>}
          />
        </div>
      );
      dataModifiedLocal.push({
        id: element.id,
        firstName: element.firstName,
        lastName: element.lastName,
        company: element.company,
        description: element.description,
        isActiveComponent: isActiveComponent,
        isActive: element.isActive,
      });
    });

    setDataModified(dataModifiedLocal);
    setFetchAgain(false);
  }, [guest, t]);

  const commitChanges = ({ added, changed, deleted }) => {
    if (deleted) {
      let row: any = dataModified.find((row) => row.id === deleted[0]);
      setIdUpdateStatus(row.id);
      setIfFetchDelete(true);
    } else if (added) {
      setFetchAgain(true);
      setSnackbarState([true, "success", t("guest_added")]);
    } else if (changed) {
      setFetchAgain(true);
      setSnackbarState([true, "success", t("guest_edited")]);
    }
    setIsFetching(true); // circ
  };

  useEffect(() => {
    if (responseStatusDelete.data === 200) {
      setSnackbarState([true, "success", t("guest_deactivated")]);
      setFetchAgain(true);
      setIsFetching(true); // circ
    } else if (responseStatusDelete.data?.status === 400) {
      let error = "";
      Object.keys(responseStatusDelete.data.errors).forEach((errorObject) => {
        responseStatusDelete.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setSnackbarState([true, "error", error]);
      setIsFetching(false); // circ
    } else if (responseStatusDelete.error !== null) {
      setIsFetching(false); // circ
      setSnackbarState([true, "error", t("something_went_wrong")]);
    }
    setIfFetchDelete(false);
  }, [responseStatusDelete, t]);

  useEffect(() => {
    if (dataModified === null) return;
    let dataModifiedLocal;
    if (isActiveSwitchState === true) {
      dataModifiedLocal = [...dataModified].filter(
        (value) => value.isActive === true
      );
    } else {
      dataModifiedLocal = [...dataModified].filter(
        (value) => value.isActive === false
      );
    }
    setDataModifiedFiltered(dataModifiedLocal);
    setIsFetching(false);
    setFetchAgain(false);
  }, [isActiveSwitchState, dataModified]);

  const IsActiveSwitch = (
    <div className="SwtichActive">
      {t("inactive")}
      <SwtichStyled
        checked={isActiveSwitchState}
        onChange={() => setIsActiveSwitchState(!isActiveSwitchState)}
        name="checkedA"
      />
      {t("active")}
    </div>
  );

  return (
    <>
      <TopBanner pathName={t("guests_list")} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={isFetching}
            isError={isError}
            setIfFetchAgain={setFetchAgain}
            setIsError={setIsError}
          >
            {!isFetching && !isError && (
              <TableComponent
                layoutSettingName="layoutWorkerTimeNotificationGuest"
                toolbarComponent={IsActiveSwitch}
                rows={dataModifiedFiltered}
                columns={columns}
                popup={Popup}
                ifEdit={true}
                ifAdd={true}
                ifDelete={true}
                deleteCommandName={t("deactivate")}
                commitChanges={commitChanges}
                actionColumn={ActionColumn}
                tableExportInfo={{
                  createdBy: authUser.displayedName,
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default NotificationGuests;
