import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../helpersComponents/PopupHelpers/dialogActionsStyled";
import DialogStyled from "../../helpersComponents/PopupHelpers/dialogStyled";
import { DialogContentStyled } from "../../helpersComponents/PopupHelpers/dialogContentStyled";
import DialogTitleStyled from "../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TwoColumnsRowsContainer from "../PopupHelpers/twoColumnsRowsContainer";
import Row from "../PopupHelpers/row";
import RowTitle from "../PopupHelpers/rowTitle";
import RowInput from "../PopupHelpers/rowInput";
import TimePickerFieldReactHookForm from "../ReactHookFormInputs/TimePickerField/TimePickerFieldReactHookForm";

interface IProps {
  isOpen: boolean;
  timeFrom?: string | null;
  timeTo?: string | null;
  closePopup: () => void;
  onDataChanged: (timeFrom: string, timeTo: string) => void;
}

interface IFormInput {
  timeFrom: string;
  timeTo: string;
}

const AddEditTimeRangePopup: React.FC<IProps> = ({
  isOpen,
  timeFrom,
  timeTo,
  closePopup,
  onDataChanged: onTimeSelected,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const schema = yup.object({
    timeFrom: yup.string().required(t("field_required")),
    timeTo: yup.string().required(t("field_required")),
  });

  const { handleSubmit, reset, control } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      timeFrom: timeFrom ?? "00:00",
      timeTo: timeTo ?? "00:00",
    },
  });

  const onSubmit = (data) => {
    if (onTimeSelected) {
      onTimeSelected(data.timeFrom, data.timeTo);
    }
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {
          reset();
        },
        onEnter: () => {
          setErrorMessage("");
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitleStyled title={t("select_time_range")} subtitle={""} />

      <DialogContentStyled>
        <form onSubmit={handleSubmit(onSubmit)} id="submitForm" noValidate>
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("time_from")}:</RowTitle>
              <RowInput>
                <TimePickerFieldReactHookForm
                  name={"timeFrom"}
                  control={control}
                  view={["hours", "minutes"]}
                  required={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("time_to")}:</RowTitle>
              <RowInput>
                <TimePickerFieldReactHookForm
                  name={"timeTo"}
                  control={control}
                  view={["hours", "minutes"]}
                  required={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>
        </form>
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="button"
        formConfirm="submitForm"
        onConfirmText={t("confirm")}
        onConfirm={() => {
          handleSubmit(onSubmit)();
        }}
        onCancel={() => {
          closePopup();
        }}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default AddEditTimeRangePopup;
