export default function getBasicAuthenticationURL(url, username, password) {
  if (username && password) {
    const urlObject = new URL(url);

    // Add the credentials to the URL
    urlObject.username = username;
    urlObject.password = password;

    return urlObject.toString();
  }

  return url;
}
