import VisualizationObjectLayersItem from "./VisualizationObjectLayersItem";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../../helpersComponents/Button/button";
import styles from "./VisualizationObjectLayers.module.scss";

const VisualizationObjectLayers = ({
  fields,
  append,
  clearErrors,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.layersContainer}>
      <div>
        <ButtonStyled
          type="button"
          onClick={() => {
            append({
              name: "",
              type: 0,
              order: 0,
              group: null,
              states: [],
              image_color: null,
              image_scale: 100,
              text_scale: 100,
            });
          }}
        >
          {t("add")}
        </ButtonStyled>
      </div>

      <div className={styles.layersItemsContainer}>
        {fields.map((field, index) => (
          <VisualizationObjectLayersItem
            id={field.id}
            key={field.id}
            field={field}
            fields={fields}
            index={index}
            clearErrors={clearErrors}
            {...props}
          />
        ))}
      </div>
    </div>
  );
};

export default VisualizationObjectLayers;
