import "./equipment.scss";
import React, { useState, useEffect, useCallback } from "react";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import moment from "moment";

const statusDictionary = {
  1: "adopted",
  2: "assigned",
  3: "released",
  4: "released_for_washing",
  5: "disposed",
};

function EquipmentContent() {
  const { t } = useTranslation();
  const [rows, setRows] = useState<object[]>([]);
  const columns = [
    { name: "articleCode", title: t("articleCode") },
    { name: "articleName", title: t("articleName") },
    { name: "articleSizeName", title: t("articleSizeName") },
    { name: "articleStatus", title: t("articleStatus") },
    {
      name: "articleModifiedBy",
      title: t("articleModifiedBy"),
    },
    {
      name: "articleModifiedAt",
      title: t("articleModifiedAt"),
      type: "date_hours",
    },
    {
      name: "articleDateOfFirstRelease",
      title: t("articleDateOfFirstRelease"),
      type: "date_hours",
    },
    {
      name: "articleEndOfLifeDate",
      title: t("articleEndOfLifeDate"),
      type: "date_hours",
    },
  ];

  const [isFetching, setIsFetching] = useState(true);

  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/equipment`,
    ifFetchAgain
  );

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (data === null) return;

    let dataModified: any = [];
    let id = 0;
    data.forEach((value) => {
      let modifiedElement = {
        id: id,
        articleCode: value.articleCode.toString(),
        articleDateOfFirstRelease: value.articleDateOfFirstRelease,
        articleEndOfLifeDate: value.articleEndOfLifeDate,
        articleModifiedAt: value.articleModifiedAt,
        articleModifiedBy: value.articleModifiedBy,
        articleName: value.articleName,
        articleSizeName: value.articleSizeName,
        articleStatus: t(statusDictionary[value.articleStatus]),
      };

      dataModified.push(modifiedElement);
      id++;
    });

    setRows(dataModified);
    setIsFetching(false);
  }, [data, error, t]);

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "articleModifiedAt") {
      return row.articleModifiedAt
        ? moment(row.articleModifiedAt).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    if (columnName === "articleDateOfFirstRelease") {
      return row.articleDateOfFirstRelease
        ? moment(row.articleDateOfFirstRelease).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    if (columnName === "articleEndOfLifeDate") {
      return row.articleEndOfLifeDate
        ? moment(row.articleEndOfLifeDate).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    return row[columnName];
  }, []);

  return (
    <>
      <TopBanner pathName={t("health_and_safety")} />
      <div className="flexAndCenter">
        <div>
          <div>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setIfFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching && !isError && (
                <TableComponent
                  layoutSettingName="layoutWorkerTimeSummaryEquipment"
                  rows={rows}
                  columns={columns}
                  ifEdit={false}
                  ifAdd={false}
                  ifDelete={false}
                  customExcelCellValue={customExportCellValue}
                  customPdfCellValue={customExportCellValue}
                />
              )}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    </>
  );
}

export default EquipmentContent;
