import React, { Dispatch, SetStateAction } from "react";
import styles from "./MapPreviewToolbar.module.scss";
import MultiSelect from "../../../../MultiSelect/MultiSelect";

interface IProps {
  objectsGroups: MultiSelectItem[];
  statesGroups: MultiSelectItem[];

  selectedObjectsGroups: number[];
  setSelectedObjectsGroups: Dispatch<SetStateAction<number[]>>;

  selectedStatesGroups: number[];
  setSelectedStatesGroups: Dispatch<SetStateAction<number[]>>;
}

const MapPreviewToolbar: React.FC<IProps> = ({
  objectsGroups,
  statesGroups,

  selectedObjectsGroups,
  setSelectedObjectsGroups,

  selectedStatesGroups,
  setSelectedStatesGroups,
}) => {
  return (
    <div className={styles.mainContainerToolbar}>
      <div className={styles.toolbarItem}>
        <MultiSelect
          label={""}
          label_id="smsUserObjectsGroups"
          items={objectsGroups || []}
          selectedItems={selectedObjectsGroups}
          setSelectedItems={setSelectedObjectsGroups}
          textColor={"gray"}
        />
      </div>
      <div className={styles.toolbarItem}>
        <MultiSelect
          label={""}
          label_id="smsUserStatesGroups"
          items={statesGroups || []}
          selectedItems={selectedStatesGroups}
          setSelectedItems={setSelectedStatesGroups}
          textColor={"gray"}
        />
      </div>
    </div>
  );
};

export default MapPreviewToolbar;
