import React from "react";
import { useTranslation } from "react-i18next";
import RequestApprovalsDetails from "../../../helpersComponents/Requests/RequestApprovalsDetails/RequestApprovalsDetails";
import RequestOvertimesDetials from "../../../helpersComponents/Requests/RequestOvertimesDetials/RequestOvertimesDetials";
import usePrintRequest from "../../../../hooks/usePrintRequest/usePrintRequest";
import ButtonStyled from "../../../helpersComponents/Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const RowDetail: React.FC = ({ row }: any) => {
  const { t } = useTranslation();
  const setFileIdAndName = usePrintRequest(
    `superior-time/hour-requests-pdf-generator`
  );

  return row?.isJoinedRequest ? (
    <>
      <div className="detailsHoliday">
        <div className="detailsHolidayRow">
          <ButtonStyled
            type="button"
            onClick={() => {
              setFileIdAndName([row.id.toString(), "Wniosek godzinowy.pdf"]);
            }}
          >
            {t("print")} <FontAwesomeIcon icon={faPrint} />
          </ButtonStyled>
        </div>
        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("type_of_request")}:</b>
            </div>
            <div>{row?.joinedRequest1Name ? row.joinedRequest1Name : "—"}</div>
          </div>
          <div>
            <div>
              <b>{t("request_status")}:</b>
            </div>
            <div>{row?.statusTranslated ? row.statusTranslated : "—"}</div>
          </div>
        </div>

        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("from_date")}:</b>
            </div>
            <div>{row?.dateFromString ? row.dateFromString : "—"}</div>
          </div>
          <div>
            <div>
              <b>{t("till_date")}:</b>
            </div>
            <div>{row?.dateToString ? row.dateToString : "—"}</div>
          </div>
        </div>

        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("created")}:</b>
            </div>
            <div>{row.createdBy !== null ? row.createdBy : "—"}</div>
          </div>
          <div>
            <div>
              <b>{t("data_created")}:</b>
            </div>
            <div>{row?.createdAtString ? row.createdAtString : "—"}</div>
          </div>
        </div>

        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("time")}:</b>
            </div>
            <div>{row?.time ? row.time : "—"}</div>
          </div>
        </div>

        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("approved")}:</b>
            </div>
            <div>{row?.approvedAtString ? row.approvedAtString : "—"}</div>
          </div>
          <div>
            <div>
              <b>{t("approved_by")}:</b>
            </div>
            <div>{row?.approvedBy ? row.approvedBy : "—"}</div>
          </div>
        </div>
        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("comment")}:</b>
            </div>
            <div>{row?.comment ? row.comment : "—"}</div>
          </div>
          <div>
            <div>
              <b>{t("finnal_approved_by")}:</b>
            </div>
            <div>{row?.finallyApprovedBy ? row.finallyApprovedBy : "—"}</div>
          </div>
        </div>

        <RequestOvertimesDetials row={row} />
        {row.configuredApprovalLevel > 0 && (
          <RequestApprovalsDetails row={row} />
        )}
      </div>

      <hr />

      <div className="detailsHoliday">
        <div className="detailsHolidayRow">
          <ButtonStyled
            type="button"
            onClick={() => {
              setFileIdAndName([row.id.toString(), "Wniosek godzinowy.pdf"]);
            }}
          >
            {t("print")} <FontAwesomeIcon icon={faPrint} />
          </ButtonStyled>
        </div>
        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("type_of_request")}:</b>
            </div>
            <div>{row?.joinedRequest2Name ? row.joinedRequest2Name : "—"}</div>
          </div>
          <div>
            <div>
              <b>{t("request_status")}:</b>
            </div>
            <div>{row?.statusTranslated ? row.statusTranslated : "—"}</div>
          </div>
        </div>

        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("from_date")}:</b>
            </div>
            <div>{row?.date2FromString ? row.date2FromString : "—"}</div>
          </div>
          <div>
            <div>
              <b>{t("till_date")}:</b>
            </div>
            <div>{row?.date2ToString ? row.date2ToString : "—"}</div>
          </div>
        </div>

        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("created")}:</b>
            </div>
            <div>{row.createdBy !== null ? row.createdBy : "—"}</div>
          </div>
          <div>
            <div>
              <b>{t("data_created")}:</b>
            </div>
            <div>{row?.createdAtString ? row.createdAtString : "—"}</div>
          </div>
        </div>

        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("time")}:</b>
            </div>
            <div>{row?.time2 ? row.time2 : "—"}</div>
          </div>
        </div>

        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("approved")}:</b>
            </div>
            <div>{row?.approvedAtString ? row.approvedAtString : "—"}</div>
          </div>
          <div>
            <div>
              <b>{t("approved_by")}:</b>
            </div>
            <div>{row?.approvedBy ? row.approvedBy : "—"}</div>
          </div>
        </div>
        <div className="detailsHolidayRow">
          <div>
            <div>
              <b>{t("comment")}:</b>
            </div>
            <div>{row?.comment ? row.comment : "—"}</div>
          </div>
          <div>
            <div>
              <b>{t("finnal_approved_by")}:</b>
            </div>
            <div>{row?.finallyApprovedBy ? row.finallyApprovedBy : "—"}</div>
          </div>
        </div>

        <RequestOvertimesDetials row={row} />
        {row.configuredApprovalLevel > 0 && (
          <RequestApprovalsDetails row={row} />
        )}
      </div>
    </>
  ) : (
    <div className="detailsHoliday">
      <div className="detailsHolidayRow">
        <ButtonStyled
          type="button"
          onClick={() => {
            setFileIdAndName([row.id.toString(), "Wniosek godzinowy.pdf"]);
          }}
        >
          {t("print")} <FontAwesomeIcon icon={faPrint} />
        </ButtonStyled>
      </div>
      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("type_of_request")}:</b>
          </div>
          <div>{row?.typeIdString ? row.typeIdString : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("request_status")}:</b>
          </div>
          <div>{row?.statusTranslated ? row.statusTranslated : "—"}</div>
        </div>
      </div>

      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("from_date")}:</b>
          </div>
          <div>{row?.dateFromString ? row.dateFromString : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("till_date")}:</b>
          </div>
          <div>{row?.dateToString ? row.dateToString : "—"}</div>
        </div>
      </div>

      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("created")}:</b>
          </div>
          <div>{row.createdBy !== null ? row.createdBy : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("data_created")}:</b>
          </div>
          <div>{row?.createdAtString ? row.createdAtString : "—"}</div>
        </div>
      </div>

      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("time")}:</b>
          </div>
          <div>{row?.time ? row.time : "—"}</div>
        </div>
      </div>

      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("approved")}:</b>
          </div>
          <div>{row?.approvedAtString ? row.approvedAtString : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("approved_by")}:</b>
          </div>
          <div>{row?.approvedBy ? row.approvedBy : "—"}</div>
        </div>
      </div>
      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("finnal_approved_by")}:</b>
          </div>
          <div>{row?.finallyApprovedBy ? row.finallyApprovedBy : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("comment")}:</b>
          </div>
          <div>{row?.comment ? row.comment : "—"}</div>
        </div>
      </div>

      <RequestOvertimesDetials row={row} />
      {row.configuredApprovalLevel > 0 && <RequestApprovalsDetails row={row} />}
    </div>
  );
};

export default RowDetail;
