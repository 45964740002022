import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../../../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import useFetchOtherThanGET from "../../../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import DialogStyled from "../../../../../../helpersComponents/PopupHelpers/dialogStyled";
import { DialogContentStyled } from "../../../../../../helpersComponents/PopupHelpers/dialogContentStyled";
import DialogTitleStyled from "../../../../../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldReactHookForm from "../../../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import TwoColumnsRowsContainer from "../../../../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import Row from "../../../../../../helpersComponents/PopupHelpers/row";
import RowTitle from "../../../../../../helpersComponents/PopupHelpers/rowTitle";
import RowInput from "../../../../../../helpersComponents/PopupHelpers/rowInput";
import erro400getTranslatedErrorString from "../../../../../../../HelpersFunctions/erro400getTranslatedErrorString";
import toLocaleStringCustom from "../../../../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import styles from "./ApproveEventPopup.module.scss";
import { useMQTTClient } from "../../../../../../../HelpersFunctions/MQTTClientProvider";

interface IFormInput {
  comment: string;
}

interface IProps {
  isOpen: boolean;
  row: ISMSEvent;
  closePopup: () => void;
  successCallback?: () => void;
  container?: React.RefObject<HTMLElement>;
}

const ApproveEventPopup: React.FC<IProps> = ({
  isOpen,
  row,
  closePopup,
  successCallback,
  container,
}) => {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { mqttPublish } = useMQTTClient();

  const successCallbackAndClose = useCallback(() => {
    mqttPublish({
      topic: "SMS/RefreshEvents",
      qos: 0,
      payload: JSON.stringify({ command: "refresh" }),
    });

    if (successCallback) successCallback();
    closePopup();
  }, [successCallback, closePopup, mqttPublish]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t]
  );

  const [fetchingStateApproveEvent, fetchAgainApproveEvent] =
    useFetchOtherThanGET({
      path: `sms-user/events/${row?.id}`,
      method: "PATCH",
      body: bodyRequest,
      setBody: setBodyRequest,
      contentType: "application/json",
      successCallback: successCallbackAndClose,
      errorCallback,
      disableErrorSnackbar: true,
    });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainApproveEvent();
    }
  }, [bodyRequest, fetchAgainApproveEvent]);

  const schema = yup.object({
    comment: yup.string().required(t("field_required")),
  });

  const { handleSubmit, reset, control } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: { comment: "" },
  });

  const onSubmit = (data) => {
    let dataToSend = [
      {
        path: "/isApproved",
        op: "replace",
        value: true,
      },
      {
        path: "/approvalComment",
        op: "replace",
        value: data.comment,
      },
    ];

    setBodyRequest(JSON.stringify(dataToSend));
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {
          reset();
        },
        onEnter: () => {
          setErrorMessage("");
        },
      }}
      container={container?.current || undefined}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitleStyled title={t("approving_event")} />

      <DialogContentStyled>
        {row && (
          <>
            <p>
              <span className={styles.eventInfoLabel}>{t("event")}:</span>{" "}
              {row.description}
            </p>
            <p>
              <span className={styles.eventInfoLabel}>
                {t("occurence_date")}:
              </span>{" "}
              {toLocaleStringCustom({
                date: row.occurenceDate,
                t,
                ifShowHours: true,
              })}
            </p>
          </>
        )}
        <form onSubmit={handleSubmit(onSubmit)} id="submitForm" noValidate>
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>
                <span className={styles.eventInfoLabel}>{t("comment")}:</span>
              </RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"comment"}
                  control={control}
                  label={t("comment")}
                  required={true}
                  multiline={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>
        </form>
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onConfirmText={t("confirm")}
        onCancel={() => {
          closePopup();
        }}
        isLoading={fetchingStateApproveEvent.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default ApproveEventPopup;
