export function getSubstring(str: string, maxLength: number) {
  return str.length > maxLength ? str.slice(0, maxLength) : str;
}

/**
 * Creates a shortcut string by taking the first character of each word in the input string.
 *
 * @param {string} str - The input string to create a shortcut from.
 * @param {number} [minWords=2] - The minimum number of words required to create a shortcut. Defaults to 2.
 * @return {string} The created shortcut string in uppercase.
 */
export function createShortcut(str: string, minWords: number = 2) {
  const strSplit = str.split(" ");

  if (strSplit.length < minWords) return str;

  let shortcut = "";
  for (let i = 0; i < strSplit.length; i++) {
    shortcut += strSplit[i][0];
  }
  return shortcut.toUpperCase();
}
