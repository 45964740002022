import { MqttClient } from "mqtt/*";
import mqtt from "mqtt";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectAuthUser } from "../reducers/session";
import { ProfileType } from "../enums/profileType";
import { setIsMQTTClientConnected } from "../reducers/sms/visualizationData";
import useSMSFetchEvents from "../hooks/sms/useSMSFetchEvents/useSMSFetchEvents";

const MQTTClientContext = createContext<any>({});

export function useMQTTClient() {
  return useContext(MQTTClientContext);
}

export const MQTTClientProvider = ({
  brokerUrl,
  mqttOptions,
  debounceTime,
  children,
}) => {
  const [mqttClient, setMQTTClient] = useState<MqttClient | null>(null);
  const [subscriptionsList, setSubscriptionsList] = useState<string[]>([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const messagesRef = useRef<Map<string, any>>(new Map<string, any>());
  const debouncingRef = useRef<any>(null);
  const [mqttClientConnected, setMqttClientConnected] = useState(false);
  const authUser = useAppSelector(selectAuthUser);
  const updateEventsInfoTopic = "SMS/RefreshEvents";
  const dispatch = useAppDispatch();
  const { fetchAgainEvents } = useSMSFetchEvents();

  const mqttSubscribe = useCallback(
    (subscription) => {
      if (mqttClient) {
        const { topic, qos } = subscription;
        mqttClient.subscribe(topic, { qos }, (error) => {
          setSubscriptionsList((prev) => {
            if (prev.includes(topic)) {
              return prev;
            }

            return [...prev, topic];
          });

          if (error) {
            return;
          }
        });
      }
    },
    [mqttClient]
  );

  const mqttUnSubscribe = (subscription) => {
    if (mqttClient) {
      const { topic } = subscription;
      mqttClient.unsubscribe(topic, (error) => {
        setSubscriptionsList((prev) => {
          return prev.filter((el) => el !== topic);
        });

        if (error) {
          console.log("Unsubscribe error", error);
          return;
        }
      });
    }
  };

  const mqttPublish = useCallback(
    (context) => {
      if (mqttClient) {
        const { topic, qos, payload } = context;
        mqttClient.publish(topic, payload, { qos }, (error) => {
          if (error) {
            console.log("Publish error: ", error);
          }
        });
      }
    },
    [mqttClient]
  );

  useEffect(() => {
    if (mqttClient) {
      const handleConnect = () => {
        setMqttClientConnected(true);
        dispatch(setIsMQTTClientConnected(true));
      };

      const handleError = (err) => {
        setMqttClientConnected(false);
        dispatch(setIsMQTTClientConnected(undefined));
      };

      const handleClose = () => {
        setMqttClientConnected(false);
        dispatch(setIsMQTTClientConnected(false));
      };

      const handleMessage = (topic, message) => {
        const payload = message.toString();
        messagesRef.current.set(topic, payload);

        if (debouncingRef.current) {
          clearTimeout(debouncingRef.current);
        }

        debouncingRef.current = setTimeout(() => {
          setUpdateTrigger((prev) => prev + 1);

          if (topic.toLowerCase() === updateEventsInfoTopic.toLowerCase()) {
            const msg = JSON.parse(message);

            if (msg.command === "refresh") {
              fetchAgainEvents();
            }
          }
        }, debounceTime);
      };

      mqttClient.on("connect", handleConnect);
      mqttClient.on("error", handleError);
      mqttClient.on("close", handleClose);
      mqttClient.on("message", handleMessage);

      return () => {
        mqttClient.off("connect", handleConnect);
        mqttClient.off("error", handleError);
        mqttClient.off("close", handleClose);
        mqttClient.off("message", handleMessage);
      };
    }
  }, [mqttClient, debounceTime, dispatch, fetchAgainEvents]);

  useEffect(() => {
    let newClient: MqttClient | null = null;

    if (brokerUrl) {
      try {
        if (authUser?.currentProfile?.type === ProfileType.SMS_USER) {
          dispatch(setIsMQTTClientConnected(undefined));
          newClient = mqtt.connect(brokerUrl, mqttOptions);
          setMQTTClient(newClient);
        }
      } catch (error) {
        console.error("Failed to connect to MQTT broker: ", error);
      }
    }

    return () => {
      if (newClient) {
        newClient.end();
        newClient = null;
      }
    };
  }, [brokerUrl, mqttOptions, authUser?.currentProfile?.type, dispatch]);

  useEffect(() => {
    if (mqttClient && mqttClientConnected) {
      mqttClient?.subscribe(updateEventsInfoTopic);
    }

    return () => {
      if (mqttClient && mqttClientConnected) {
        mqttClient?.unsubscribe(updateEventsInfoTopic);
      }
    };
  }, [mqttSubscribe, mqttClient, mqttClientConnected]);

  return (
    <MQTTClientContext.Provider
      value={{
        mqttClientConnected,
        mqttClient,
        mqttSubscribe,
        mqttUnSubscribe,
        mqttPublish,
        subscriptionsList,
        messagesRef,
        updateTrigger,
      }}
    >
      {children}
    </MQTTClientContext.Provider>
  );
};
