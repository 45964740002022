import React from "react";
import { useTranslation } from "react-i18next";
import { SMSNotificationBarDisplayMode } from "../../../../../enums/SMS/SMSNotificationBarDisplayMode";
import SMSNotificationBar from "../SMSNotificationBar/SMSNotificationBar";
import { ProfileType } from "../../../../../enums/profileType";
import { useAppSelector } from "../../../../../store/hooks";
import {
  selectEvents,
  selectIsMQTTClientConnected,
} from "../../../../../reducers/sms/visualizationData";
import { selectAuthUser } from "../../../../../reducers/session";
import moment from "moment";

const SMSNotification = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const isMQTTClientConnected = useAppSelector(selectIsMQTTClientConnected);
  const events = useAppSelector(selectEvents);

  const shouldShowNotificationBar =
    authUser?.currentProfile?.type === ProfileType.SMS_USER;

  const mqttConnectionError = isMQTTClientConnected === false;
  const foundEvent = events?.find((el) => el.isAlarm && !el.isApproved);

  return (
    <div>
      {shouldShowNotificationBar && (
        <>
          {mqttConnectionError ? (
            <SMSNotificationBar
              open={true}
              message={t("mqtt_connection_error")}
              severity={"error"}
              mode={SMSNotificationBarDisplayMode.STANDARD}
              row={foundEvent}
            />
          ) : (
            foundEvent && (
              <SMSNotificationBar
                open={true}
                message={t("alarm_events_occur_that_require_attention").replace(
                  "$1",
                  `${moment(foundEvent.occurenceDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )} ${foundEvent.description}`
                )}
                severity={"error"}
                mode={SMSNotificationBarDisplayMode.ALARM_EVENT}
                row={foundEvent}
              />
            )
          )}
        </>
      )}
    </div>
  );
};

export default SMSNotification;
