import { useTranslation } from "react-i18next";
import { AutocompleteFormik } from "../../FormikInputs";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../PopupHelpers";

interface Props {
  formFields: FormFields;
  departmentsList: Department[];
  groupsList: Group[];
  zonesList: Zone[];
}

const UserPermissionsForm = ({
  formFields,
  departmentsList,
  groupsList,
  zonesList,
}: Props) => {
  const { t } = useTranslation();
  const { departments, groups, zones } = formFields;

  return (
    <>
      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t("access_to_departments")}:</RowTitle>
          <RowInput>
            <AutocompleteFormik
              options={departmentsList}
              multiple
              filterSelectedOptions
              disableCloseOnSelect
              limitTags={5}
              getOptionLabel={(option: Department) => {
                return option.name;
              }}
              width="100%"
              getOptionSelected={(option: Department, value: Department) => {
                return option.id === value.id;
              }}
              label={t(departments.label)}
              name={departments.name}
            />
          </RowInput>
        </Row>

        <Row>
          <RowTitle width={180}>{t(groups.label)}:</RowTitle>
          <RowInput>
            <AutocompleteFormik
              options={groupsList}
              multiple
              filterSelectedOptions
              disableCloseOnSelect
              limitTags={5}
              getOptionLabel={(option: Group) => {
                return option.name;
              }}
              width="100%"
              getOptionSelected={(option: Group, value: Group) => {
                return option.id === value.id;
              }}
              label={t(groups.label)}
              name={groups.name}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t("access_to_zones")}:</RowTitle>
          <RowInput>
            <AutocompleteFormik
              options={zonesList}
              multiple
              filterSelectedOptions
              disableCloseOnSelect
              limitTags={5}
              getOptionLabel={(option: Zone) => {
                return option.name;
              }}
              width="100%"
              getOptionSelected={(option: Zone, value: Zone) => {
                return option.id === value.id;
              }}
              label={t(zones.label)}
              name={zones.name}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>
    </>
  );
};

export default UserPermissionsForm;
