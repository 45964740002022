import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../helpersComponents/PopupHelpers";
import PopupContentACWorker from "./PopupContentACWorker";
import moment from "moment";
import { ProfileType } from "../../../../enums/profileType";
import { ACSuperiorPermission } from "../../../../enums/ACSuperiorPermission";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";

interface Props {
  isOpen: boolean;
  rowId: number | undefined;
  closePopup: () => void;
  successCallback: () => void;
}

const PopupACWorker: React.FC<Props> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [errorMessage, setErrorMessage] = useState("");
  const [requestBody, setRequestBody] = useState<false | any>(false);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `superior-ac/ac-workers${rowId !== undefined ? `/${rowId}` : ""}`,
    method: rowId !== undefined ? "PUT" : "POST",
    body: requestBody,
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  const onSubmit = (values: AddACWorker) => {
    setErrorMessage("");
    const acWorker = values.acWorker;
    const photo = values.photo;

    let object = {
      firstName: acWorker.firstName,
      lastName: acWorker.lastName,
      evidenceNumber: acWorker.evidenceNumber,
      identifier: acWorker.identifier,
      description: acWorker.description,
      position: acWorker.position,
      phone: acWorker.phone,
      email: acWorker.email,
      departmentId: acWorker.department ? acWorker.department.id : null,
      isTracked: acWorker.isTracked,
      acSuperiorIds: acWorker.acSuperiors
        ? acWorker.acSuperiors.map((item) => item.id)
        : null,
      zonesIds: acWorker.zones ? acWorker.zones.map((item) => item.id) : null,
      firstDay: acWorker.firstDay,
      meetingPlaceId: acWorker.meetingPlace ? acWorker.meetingPlace.id : null,
      isKp: acWorker.isKp,
      regularPost: acWorker.regularPost,
      additions: acWorker.additions,
      companiesIds: acWorker.companies
        ? acWorker.companies.map((item) => item.id)
        : null,
      groupsIds: acWorker.groups
        ? acWorker.groups.map((group) => group.id)
        : null,
      permissions: acWorker.permissions ? acWorker.permissions : null,
      isVIP: acWorker.isVIP,
      isZJW: acWorker.isZJW,
      isAccessDeniedOnHolidays: acWorker.isAccessDeniedOnHolidays,
      startPermission: acWorker.startPermissionDateCheck
        ? acWorker.startPermissionDate
        : null,
      permissionExpirationDate: acWorker.permissionExpirationDateCheck
        ? acWorker.permissionExpirationDate
        : null,
      cardNumber: acWorker.cardNumber ? acWorker.cardNumber : null,
      csn: acWorker.csn ? acWorker.csn : "",
      remoteNumber: acWorker.remoteNumber ? acWorker.remoteNumber : null,
      pin: acWorker.pin ? acWorker.pin : 0,
      additionalsCards: acWorker.additionalCards
        ? acWorker.additionalCards
        : null,
      specialPermissions:
        acWorker.specialPermissions?.map((el) => {
          let entryFrom = new Date(el.entryFrom).toISOString();
          let entryFromTime = moment(entryFrom).format("HH:mm");

          let entryTo = new Date(el.entryTo).toISOString();
          let entryToTime = moment(entryTo).format("HH:mm");

          return { ...el, entryFrom, entryTo, entryFromTime, entryToTime };
        }) || null,
      elevatorsAndCabinets: acWorker.elevatorsAndCabinets
        ? acWorker.elevatorsAndCabinets
        : null,
    };

    let formData = new FormData();
    formData.append("RequestData", JSON.stringify(object));
    formData.append("Photo", photo as Blob);

    setRequestBody(formData);
  };

  const isPreview =
    authUser?.currentProfile?.type === ProfileType.SUPERIOR_AC &&
    authUser?.currentProfile?.permission === ACSuperiorPermission.PREVIEW;

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      disableEnforceFocus
      fullScreen
    >
      <DialogTitleStyled title={t("employee")} />
      <PopupContentACWorker rowId={rowId} onSubmit={onSubmit} isPreview={isPreview}/>
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        ifHideConfirm={isPreview}
        onCancelText={isPreview ? t("close") : t("cancel")}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupACWorker;
