import { useCallback, useEffect, useState } from "react";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import Row from "../../../../helpersComponents/PopupHelpers/row";
import RowInput from "../../../../helpersComponents/PopupHelpers/rowInput";
import RowTitle from "../../../../helpersComponents/PopupHelpers/rowTitle";
import TwoColumnsRowsContainer from "../../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import erro400getTranslatedErrorString from "../../../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import SelectFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/SelectField/selectFIeld";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "./AddEditVisualizationObjectsGroupsPopupContent.module.scss";
import AddEditVisualizationObjectsGroupsCategoriesForm from "../../VisualizationObjectsGroupsCategoriesLibrary/Popups/AddEditVisualizationObjectsGroupsCategoriesForm";

const AddEditVisualizationObjectsGroupsPopupContent: React.FC<any> = ({
  rowId,
  control,
  setValue,
  setErrorMessage,
  successCallback,
  handleSubmit,
  setIsFetching,
  watch,
}) => {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [addCategoryBodyRequest, setAddCategoryBodyRequest] =
    useState<any>(false);
  const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);
  const [addedCategoryId, setAddedCategoryId] = useState<number>(-1);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
  }, [successCallback]);

  const [
    visualizationObjectsGroup,
    fetchingStateVisualizationObjectsGroup,
    fetchAgainVisualizationObjectsGroup,
  ] = useFetchAndSetGET({
    path: `sms-administrator/visualization-objects-groups-library/${rowId}`,
    startFetchOnInitial: false,
  });

  const [
    visualizationObjectsGroupsCategories,
    fetchingStateVisualizationObjectsGroupsCategories,
    fetchAgainVisualizationObjectsGroupsCategories,
  ] = useFetchAndSetGET({
    path: `sms-administrator/visualization-objects-groups-categories-library`,
    startFetchOnInitial: true,
  });

  useEffect(() => {
    if (rowId) {
      fetchAgainVisualizationObjectsGroup();
    }
  }, [rowId, fetchAgainVisualizationObjectsGroup]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t, setErrorMessage]
  );

  const [
    fetchingStateAddEditVisualizationObjectsGroup,
    fetchAgainAddEditVisualizationObjectsGroup,
  ] = useFetchOtherThanGET({
    path: `sms-administrator/visualization-objects-groups-library${
      rowId ? "/" + rowId : ""
    }`,
    method: rowId ? "PUT" : "POST",
    contentType: "application/json",
    body: bodyRequest,
    setBody: setBodyRequest,
    successCallback: successCallbackAndClose,
    errorCallback,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditVisualizationObjectsGroup();
    }
  }, [bodyRequest, fetchAgainAddEditVisualizationObjectsGroup]);

  useEffect(() => {
    setIsFetching(fetchingStateAddEditVisualizationObjectsGroup.isFetching);
  }, [fetchingStateAddEditVisualizationObjectsGroup, setIsFetching]);

  const [, fetchAgainAddEditVisualizationObjectsGroupsCategory] =
    useFetchOtherThanGET({
      path: `sms-administrator/visualization-objects-groups-categories-library${
        rowId ? "/" + rowId : ""
      }`,
      method: rowId ? "PUT" : "POST",
      contentType: "application/json",
      body: addCategoryBodyRequest,
      setBody: setAddCategoryBodyRequest,
      successCallback: successCallbackAndClose,
      errorCallback,
      disableErrorSnackbar: true,
    });

  useEffect(() => {
    if (addCategoryBodyRequest) {
      fetchAgainAddEditVisualizationObjectsGroupsCategory();
    }
  }, [
    addCategoryBodyRequest,
    fetchAgainAddEditVisualizationObjectsGroupsCategory,
  ]);

  const onSubmit = (data: any) => {
    let tempOrder = parseInt(data.order);

    let jsonDataToSend: any = {
      name: data.name,
      order: isNaN(tempOrder) ? 0 : tempOrder,
      categoryId: data.category.id,
    };

    setBodyRequest(JSON.stringify(jsonDataToSend));
  };

  useEffect(() => {
    if (
      rowId &&
      !fetchingStateVisualizationObjectsGroup.isFetching &&
      fetchingStateVisualizationObjectsGroup.response?.status === 200 &&
      !fetchingStateVisualizationObjectsGroupsCategories.isFetching &&
      fetchingStateVisualizationObjectsGroupsCategories.response?.status === 200
    ) {
      setValue("name", visualizationObjectsGroup.name);
      setValue("order", visualizationObjectsGroup.order);

      let foundCategory = visualizationObjectsGroupsCategories.find(
        (el) => el.id === visualizationObjectsGroup.categoryId
      );
      if (foundCategory) {
        setValue("category", foundCategory);
      }
    }
  }, [
    fetchingStateVisualizationObjectsGroup.isFetching,
    fetchingStateVisualizationObjectsGroup.response?.status,
    fetchingStateVisualizationObjectsGroupsCategories.isFetching,
    fetchingStateVisualizationObjectsGroupsCategories.response?.status,
    rowId,
    setValue,
    visualizationObjectsGroup,
    visualizationObjectsGroupsCategories,
    addedCategoryId,
  ]);

  useEffect(() => {
    if (
      !fetchingStateVisualizationObjectsGroupsCategories.isFetching &&
      fetchingStateVisualizationObjectsGroupsCategories.response?.status === 200
    ) {
      let foundCategory = visualizationObjectsGroupsCategories.find(
        (el) => el.id === addedCategoryId
      );

      if (foundCategory) {
        setValue("category", foundCategory);
      }
    }
  }, [
    addedCategoryId,
    fetchingStateVisualizationObjectsGroupsCategories.isFetching,
    fetchingStateVisualizationObjectsGroupsCategories.response?.status,
    visualizationObjectsGroupsCategories,
    setValue,
  ]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateVisualizationObjectsGroup.isFetching ||
          fetchingStateVisualizationObjectsGroupsCategories.isFetching
        }
        isError={
          fetchingStateVisualizationObjectsGroup.isError ||
          fetchingStateVisualizationObjectsGroupsCategories.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateVisualizationObjectsGroup.isError) {
            fetchAgainVisualizationObjectsGroup();
          }

          if (fetchingStateVisualizationObjectsGroupsCategories.isError) {
            fetchAgainVisualizationObjectsGroupsCategories();
          }
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} id="submitForm" noValidate>
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("name")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"name"}
                  control={control}
                  label={t("name")}
                  required={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("order")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"order"}
                  control={control}
                  label={t("order")}
                  required={true}
                  type="number"
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("category")}:</RowTitle>
              <RowInput>
                <div className={styles[`categories-with-icon-contanier`]}>
                  <div className={styles[`categories-input`]}>
                    <SelectFieldReactHookForm
                      name={"category"}
                      control={control}
                      label={t("choose_category")}
                      required={true}
                      options={visualizationObjectsGroupsCategories || []}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      getOptionSelected={(option, value) => {
                        return option.id === value.id;
                      }}
                      setValue={setValue}
                      watch={watch}
                    />
                  </div>

                  <div
                    onClick={() => {
                      setShowAddCategoryForm((prev) => !prev);
                    }}
                    className={styles[`categories-icon`]}
                  >
                    {showAddCategoryForm ? (
                      <FontAwesomeIcon
                        className="faMinusNotifications fa-lg"
                        icon={faMinus}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="faPlusNotificationsAgent fa-lg"
                        icon={faPlus}
                      />
                    )}
                  </div>
                </div>
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>
        </form>

        {showAddCategoryForm && (
          <AddEditVisualizationObjectsGroupsCategoriesForm
            successCallback={(addedObjId) => {
              fetchAgainVisualizationObjectsGroupsCategories();
              setShowAddCategoryForm((prev) => !prev);
              setAddedCategoryId(addedObjId);
            }}
          />
        )}
      </LoadingWrapper>
    </>
  );
};

export default AddEditVisualizationObjectsGroupsPopupContent;
