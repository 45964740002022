import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import "./hoursRequest.scss";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import LoadingWrapper from "../../LoadingWrapper/loadingWrapper";
import TimeWithDescription from "../../NumberWithDescription/numberWithDescription";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { ProfileType } from "../../../../enums/profileType";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

interface TopRowsInterface {
  selectedDate: Date;
  timeWorkerId?: number;
}

const TopRows = ({ selectedDate, timeWorkerId }: TopRowsInterface) => {
  const { t } = useTranslation();

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const [
    hourRequestInformation,
    fetchingStateHourRequestInformation,
    fetchAgainHourRequestInformation,
  ] = useFetchAndSetGET({
    path:
      profileType === ProfileType.SUPERIOR_TIME
        ? `superior-time/hour-request-information?timeWorkerId=${timeWorkerId}&day=${computeDate(
            selectedDate,
            "T00:00:00"
          )}`
        : `worker-time/${authUserId}/hour-request-information?timeWorkerId=${authUserId}&day=${computeDate(
            selectedDate,
            "T00:00:00"
          )}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (selectedDate && timeWorkerId) {
      fetchAgainHourRequestInformation();
    }
  }, [selectedDate, timeWorkerId, fetchAgainHourRequestInformation]);

  return (
    <LoadingWrapper
      isLodadingProgress={fetchingStateHourRequestInformation.isFetching}
      isError={fetchingStateHourRequestInformation.isError}
      setIfFetchAgain={(fetchAgain) => {
        if (fetchAgain) {
          fetchAgainHourRequestInformation();
        }
      }}
    >
      {!fetchingStateHourRequestInformation.isFetching &&
        !fetchingStateHourRequestInformation.isError &&
        fetchingStateHourRequestInformation.response?.status === 200 &&
        hourRequestInformation && (
          <div>
            <TimeWithDescription
              size="small"
              parametrs={[
                { name: t("day_schema") },
                {
                  time:
                    new Date(
                      hourRequestInformation.daySchemaFrom
                    ).toLocaleString(t("scheduler_language"), {
                      hour: "2-digit",
                      minute: "2-digit",
                    }) +
                    " - " +
                    new Date(hourRequestInformation.daySchemaTo).toLocaleString(
                      t("scheduler_language"),
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    ),
                  description: t("plan"),
                },
                {
                  time:
                    new Date(hourRequestInformation.entry).toLocaleString(
                      t("scheduler_language"),
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    ) +
                    " - " +
                    new Date(hourRequestInformation.exit).toLocaleString(
                      t("scheduler_language"),
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    ),
                  description: t("execution"),
                },
              ]}
            />

            <TimeWithDescription
              size="small"
              parametrs={[
                { name: t("overtime_to_take") },
                {
                  time: convertMinsToHrsMins(
                    hourRequestInformation.overtimeToTakeToday
                  ),
                  description: t("today"),
                },
                {
                  time: convertMinsToHrsMins(
                    hourRequestInformation.overtimeToTakeRequestDay
                  ),
                  description: t("request_day"),
                },
                {
                  time: convertMinsToHrsMins(
                    hourRequestInformation.overtimeToTakeAllRequests
                  ),
                  description: t("all_requests"),
                },
              ]}
            />

            <TimeWithDescription
              size="small"
              parametrs={[
                { name: t("days_to_take") },
                {
                  time: hourRequestInformation.daysToTakeToday,
                  description: t("today"),
                },
                {
                  time: hourRequestInformation.daysToTakeRequestDay,
                  description: t("request_day"),
                },
                {
                  time: hourRequestInformation.daysToTakeAllRequests,
                  description: t("all_requests"),
                },
              ]}
            />
          </div>
        )}
    </LoadingWrapper>
  );
};

export default React.memo(TopRows);
