import { useTranslation } from "react-i18next";
import TableComponent from "../../../../helpersComponents/TableComponent/tableComponent";
import { OvertimesReportItem } from "../type";
import getTimeFromDate from "../../../../../HelpersFunctions/dateAndTime/getTimeFromDate";
import { useCallback } from "react";
import moment from "moment";
import convertMinsToHrsMins from "../../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import { statusDictionary } from "./../../../../../Constants/statusDictionary";
import { useAppSelector } from "../../../../../store/hooks";
import { selectAuthUser } from "../../../../../reducers/session";

interface IProps {
  data: OvertimesReportItem[] | null;
  dateFrom?: Date;
  dateTo?: Date;
}

const OvertimeReport: React.FC<IProps> = ({ data, dateFrom, dateTo }) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const columns = [
    { name: "evidenceNumber", title: t("control_number") },
    { name: "lastName", title: t("last_name") },
    { name: "firstName", title: t("first_name") },
    { name: "organizationalUnit", title: t("brigade") },
    { name: "requestCreatedAt", title: t("request_date"), type: "date" },
    { name: "overtimeInDay", title: t("overtime_in_day"), type: "date" },
    {
      name: "fromTime",
      title: t("from_time"),
      customFormatter: (row) => getTimeFromDate(new Date(row.requestDateFrom)),
    },
    {
      name: "toTime",
      title: t("till_hour"),
      customFormatter: (row) => getTimeFromDate(new Date(row.requestDateTo)),
    },
    {
      name: "time",
      title: t("time"),
      customFormatter: (row) => convertMinsToHrsMins(row.requestTime),
    },
    { name: "overtime1To1", title: "1:1" },
    {
      name: "overtime100",
      title: t("overtime_100"),
      customFormatter: (row) => convertMinsToHrsMins(row.overtime100),
    },
    {
      name: "overtime50",
      title: t("overtime_50"),
      customFormatter: (row) => convertMinsToHrsMins(row.overtime50),
    },
    { name: "scopeOfWorkToBeDone", title: t("scope_of_work_to_be_done") },
    { name: "overtimeCardIssuer", title: t("overtime_card_issuer") },
    { name: "requestIssuerComment", title: t("request_issuer_comment") },
    {
      name: "requestStatus",
      title: t("request_status"),
      customFormatter: (row) => <p>{t(statusDictionary[row.requestStatus])}</p>,
    },
    { name: "requestApprover", title: t("request_approver") },
    { name: "comments", title: t("comments") },
  ];

  const customExportCellValue = useCallback(
    (row: OvertimesReportItem, columnName: string) => {
      switch (columnName) {
        case "requestCreatedAt":
          return row.requestCreatedAt
            ? moment(row.requestCreatedAt).format("YYYY-MM-DD")
            : "";
        case "overtimeInDay":
          return row.requestDateFrom
            ? moment(row.requestDateFrom).format("YYYY-MM-DD")
            : "";

        case "time":
          return convertMinsToHrsMins(row.requestTime);

        case "fromTime":
          return getTimeFromDate(new Date(row.requestDateFrom));
        case "toTime":
          return getTimeFromDate(new Date(row.requestDateTo));

        case "overtime100":
          return convertMinsToHrsMins(row.overtime100);

        case "overtime50":
          return convertMinsToHrsMins(row.overtime50);

        case "requestStatus":
          return t(statusDictionary[row.requestStatus]);
      }

      return row[columnName];
    },
    [t]
  );

  return (
    <TableComponent
      rows={data ? data : []}
      columns={columns}
      layoutSettingName="layoutSuperiorTimeAnalysisReportsToDownloadOvertimeReport"
      customExcelCellValue={customExportCellValue}
      customPdfCellValue={customExportCellValue}
      tableExportInfo={{
        dateFrom: dateFrom,
        dateTo: dateTo,
        createdBy: authUser.displayedName,
      }}
    />
  );
};

export default OvertimeReport;
