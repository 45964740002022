import Grow from "@material-ui/core/Grow";
import MenuIcon from "@material-ui/icons/Menu";
import "devextreme/dist/css/dx.light.css";
import "leaflet/dist/leaflet.css";
import { SnackbarProvider } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import SnackbarCloseButton from "./components/helpersComponents/SnackbarCloseButton";
import LoginPage from "./components/LoginPage/index";
import Navigation from "./components/Navbar/index";
import useNavbarsArray from "./components/ProfilesRouting/navbarsArray";
import useNavLinksArrayNoProfile from "./components/ProfilesRouting/NoProfileRoutes/navLinksArray";
import "./components/ProfilesRouting/profilesColors.scss";
import {
  colorWithHoverClassNameDictionary,
  profileNarrowNavigationComponentsDictionary,
  profilesRoutesDictionary,
} from "./components/ProfilesRouting/profilesDictionaries";
import ConfirmationRostrumReader from "./components/security/ConfirmationRostrumReader";
import Evacuation from "./components/superiorAc/evacuation";
import "./css/app.scss";
import "./css/globalStyle.scss";
import "./css/icons.scss";
import "./css/confirmationStatuses.scss";
import { ProfileType } from "./enums/profileType";
import useFetchAndSetGET from "./hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import ConfirmDialog from "./hooks/useConfirm/confirmDialog";
import { LoginProtect } from "./PrivateRoutes";
import PrivateRoute from "./PrivateRoutes/privateRoute";
import {
  selectACSuperiorCurrentUser,
  setACSuperiorCurrentUser,
} from "./reducers/acSuperiorCurrentUser/acSuperiorCurrentUser";
import {
  selectIsOpenLeftNavbar,
  setIsOpenLeftNavbar,
} from "./reducers/isOpenLeftNavbar";
import { selectAuthUser } from "./reducers/session";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import AddOperationalSupportNotification from "./components/reception/Support/AddOperationalSupportNotification/AddOperationalSupportNotificationForm";
import ParallelsFetchErrorDialog from "./hooks/fetchHooks/useParallelsFetchErrorsSummary/parallelsFetchErrorDialog";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useCloudflareLoader from "./hooks/useCloudflareLoader/useCloudflareLoader";
import getBaseUrl from "./HelpersFunctions/urls/getBaseUrl";
import { MQTTClientProvider } from "./HelpersFunctions/MQTTClientProvider";
import SMSNotification from "./components/helpersComponents/SMS/SMSVisualization/SMSNotification/SMSNotification";
import { SMSVisualizationDataProvider } from "./HelpersFunctions/SMSVisualizationDataProvider";

let GrowAny: any = Grow;
// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function () {
  const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
  this.setHours(hoursDiff);
  return this.toISOString();
};

const root = document.getElementById("root")!;

let navbarsArray;

const originalFetch = window.fetch;

function App() {
  const history = useHistory();
  const { refreshIframe } = useCloudflareLoader(getBaseUrl());

  window.fetch = async function (...args) {
    let response = await originalFetch(...args);

    if (
      response.headers.has("cf-mitigated") &&
      response.headers.get("cf-mitigated") === "challenge"
    ) {
      let challengeSolved: boolean | undefined = false;
      try {
        challengeSolved = await refreshIframe(getBaseUrl());
      } catch (err) {}

      if (challengeSolved) {
        response = await originalFetch(...args);
      } else {
        window.location.reload();
        window.location.replace(window.location.href);
        history.go(0);
      }
    }

    return response;
  };

  const authUser = useAppSelector(selectAuthUser);
  const isOpenLeftNavbar = useAppSelector(selectIsOpenLeftNavbar);
  const dispatch = useAppDispatch();
  navbarsArray = useNavbarsArray();
  const navLinksArrayNoProfile = useNavLinksArrayNoProfile();

  let ifUserIsloggedIn = Object.keys(authUser).length === 0 ? false : true;

  const [routes, setRoutes] = useState(returnRoutes());
  const [nonProfileRoutes, setNonProfileRoutes] = useState(
    returnNonProfileRoutes(navLinksArrayNoProfile, authUser)
  );

  //pobieranie informacji o kd użytkowniku
  const profileName = ProfileType[authUser.currentProfile?.type]
    ?.toLowerCase()
    .replaceAll("_", "-");
  const [
    acSuperiorCurrentUser,
    fetchingStateACSuperiorCurrentUser,
    fetchAgainACSuperiorCurrentUser,
  ] = useFetchAndSetGET({
    path: `${profileName}/ac-users/current-user`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (
      authUser.currentProfile?.type === ProfileType.SUPERIOR_AC ||
      authUser.currentProfile?.type === ProfileType.PFSO ||
      authUser.currentProfile?.type === ProfileType.EMPLOYEE ||
      authUser.currentProfile?.type === ProfileType.OHS ||
      authUser.currentProfile?.type === ProfileType.EVENTS_PREVIEW
    ) {
      fetchAgainACSuperiorCurrentUser();
    }
  }, [authUser.currentProfile?.type, fetchAgainACSuperiorCurrentUser]);

  useEffect(() => {
    if (fetchingStateACSuperiorCurrentUser.response?.status === 200) {
      dispatch(setACSuperiorCurrentUser(acSuperiorCurrentUser));
    }
  }, [
    acSuperiorCurrentUser,
    dispatch,
    fetchingStateACSuperiorCurrentUser.response?.status,
  ]);

  const acPermission = useAppSelector(selectACSuperiorCurrentUser)?.permission;

  useEffect(() => {
    if (acPermission?.id !== null) {
      setRoutes(returnRoutes());
    }
    if (authUser) {
      setRoutes(returnRoutes());
    }
  }, [acPermission?.id, authUser]);

  const isFirstRun = useRef(true);
  ////////// update class nammes after log in / log out
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setNonProfileRoutes(
      returnNonProfileRoutes(navLinksArrayNoProfile, authUser)
    );
  }, [authUser, navLinksArrayNoProfile]);

  let pathRedirect: string = authUser?.currentProfile
    ? `/${profilesRoutesDictionary[authUser.currentProfile.type]}/home`
    : "/login";

  const firstRef = useRef<boolean>(true);
  const ConfirmationRostrumReaderComponent = (
    <ConfirmationRostrumReader firstRef={firstRef} />
  );

  const [smsSettings, , fetchAgainSMSSettings] =
    useFetchAndSetGET<ISMSSettings>({
      path: `${profileName}/sms-settings`,
      startFetchOnInitial: false,
    });

  useEffect(() => {
    if (
      authUser?.currentProfile?.type === ProfileType.SMS_ADMINISTRATOR ||
      authUser?.currentProfile?.type === ProfileType.SMS_USER
    ) {
      fetchAgainSMSSettings();
    }
  }, [authUser?.currentProfile?.type, fetchAgainSMSSettings]);

  const getBrokerUrl = useCallback(() => {
    let tempBrokerUrl = "";

    if (
      authUser?.currentProfile?.type === ProfileType.SMS_USER &&
      smsSettings
    ) {
      tempBrokerUrl = smsSettings.mqttBrokerTlsEnabled ? "wss://" : "ws://";

      tempBrokerUrl +=
        smsSettings.mqttBrokerHost + ":" + smsSettings.mqttBrokerPort;
    }

    return tempBrokerUrl;
  }, [authUser?.currentProfile?.type, smsSettings]);

  return (
    <>
      <MQTTClientProvider
        brokerUrl={getBrokerUrl()}
        mqttOptions={{
          keepalive: 60,
          clientId:
            `${smsSettings?.mqttClientId}_` + new Date().getTime().toString(),
          username: smsSettings?.mqttBrokerUserName,
          password: smsSettings?.mqttBrokerPassword,
          clean: true,
          reconnectPeriod: 5000,
          connectTimeout: 10000,
        }}
        debounceTime={
          smsSettings?.mqttDebounceTime !== null &&
          smsSettings?.mqttDebounceTime !== undefined
            ? smsSettings?.mqttDebounceTime
            : 1000
        }
      >
        <SMSVisualizationDataProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              TransitionComponent={GrowAny}
              action={(key) => <SnackbarCloseButton id={key} />}
            >
              <div className="App">
                <Router>
                  {authUser?.currentProfile && (
                    <div
                      className={
                        "navbarRootContainer " +
                        (isOpenLeftNavbar ? "navbarRootContainerMobile" : "")
                      }
                      onClick={(e) => {
                        if (!window.matchMedia("(max-width: 1000px)").matches)
                          return;
                        dispatch(setIsOpenLeftNavbar(!isOpenLeftNavbar));
                      }}
                    >
                      <div
                        className={"burger"}
                        onClick={(e) => {
                          if (!window.matchMedia("(max-width: 1000px)").matches)
                            return;
                          e.stopPropagation();
                        }}
                      >
                        <MenuIcon
                          className={
                            colorWithHoverClassNameDictionary[
                              authUser.currentProfile.type
                            ]
                          }
                          onClick={() => {
                            dispatch(setIsOpenLeftNavbar(!isOpenLeftNavbar));
                          }}
                        />
                      </div>
                      <div
                        onClick={(e) => {
                          if (!window.matchMedia("(max-width: 1000px)").matches)
                            return;
                          e.stopPropagation();
                        }}
                        className={
                          "navbarsContainer " +
                          (isOpenLeftNavbar
                            ? "openWideNavbar"
                            : "openNarrowNavbar")
                        }
                      >
                        <div
                          className={
                            "hideNarrowIfSmallScreen " +
                            (isOpenLeftNavbar
                              ? "narrowNavHidden"
                              : "openNavbarMargin")
                          }
                        >
                          {
                            profileNarrowNavigationComponentsDictionary[
                              authUser.currentProfile.type
                            ]
                          }
                        </div>
                        <div
                          className={
                            isOpenLeftNavbar ? "openNavbarMargin" : "navHidden"
                          }
                        >
                          <Navigation />
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    // className={
                    //   "content " +
                    //   (isOpenLeftNavbar && ifUserIsloggedIn
                    //     ? "widthWithOpenNaV "
                    //     : null) +
                    //   (window.location.hash.substring(2) === "login" ||
                    //   window.location.hash.substring(2) === ""
                    //     ? " loginContentClass"
                    //     : false)
                    // }
                    className={
                      "content " +
                      (ifUserIsloggedIn ? "" : "loginContentClass ") +
                      (isOpenLeftNavbar && ifUserIsloggedIn
                        ? "widthWithOpenNaV "
                        : null)
                    }
                  >
                    <Switch>
                      <Route
                        exact
                        strict
                        path="/evacuation"
                        component={Evacuation}
                      />
                      <PrivateRoute
                        exact
                        strict
                        path={"superior-ac/home"}
                        component={() => <div></div>}
                        profileId={0}
                      />
                      {routes.map((route) => route)}
                      {/* <div className="nonProfileComponents"> */}
                      {nonProfileRoutes.map((route) => route)}
                      {/* </div> */}
                      <LoginProtect
                        exact
                        strict
                        path="/login"
                        component={LoginPage}
                      />
                      <Redirect to={pathRedirect} />
                    </Switch>
                  </div>
                  {/* <Footer /> */}

                  {authUser.currentProfile?.type === ProfileType.SMS_USER && (
                    <SMSNotification />
                  )}
                </Router>
                <ConfirmDialog />
                <ParallelsFetchErrorDialog />
                {/*<CloudflareInteractiveChallenge />*/}
              </div>

              {ifUserIsloggedIn &&
                authUser.currentProfile?.type === ProfileType.SECURITY &&
                ReactDOM.createPortal(ConfirmationRostrumReaderComponent, root)}
            </SnackbarProvider>
          </LocalizationProvider>
        </SMSVisualizationDataProvider>
      </MQTTClientProvider>
    </>
  );
}

const returnNonProfileRoutes = (navLinksArrayNoProfile, authUser) => {
  let routes = navLinksArrayNoProfile.routes.map((routeData) => {
    if (routeData.ifHidden) return null;
    let componentWithStyle = () => (
      <div
        className={authUser?.token !== undefined ? "" : "nonProfileComponents"}
      >
        {routeData.component()}
      </div>
    );
    return (
      <Route
        key={routeData.path}
        exact
        strict
        path={routeData.path}
        component={componentWithStyle}
      />
    );
  });
  return routes;
};

const returnRoutes = () => {
  let routes: any = [];

  routes.push(
    <PrivateRoute
      key={"/reception/add-operational-support-notification"}
      exact
      strict
      path={"/reception/add-operational-support-notification"}
      component={AddOperationalSupportNotification}
      profileId={ProfileType.RECEPTION}
    />
  );

  navbarsArray.forEach((navbar, inedx) => {
    navbar.routesArrayWithComponents?.forEach((element) => {
      if (element.singleLink) {
        if (element.ifHidden) return null;

        return routes.push(
          <PrivateRoute
            key={element.path}
            exact
            strict
            path={element.path}
            component={element.component}
            profileId={navbar.profileId}
          />
        );
      }
      element.path?.forEach((subElement) => {
        if (subElement.ifHidden) return null;
        return routes.push(
          <PrivateRoute
            key={subElement.path}
            exact
            strict
            path={subElement.path}
            component={subElement.component}
            profileId={navbar.profileId}
          />
        );
      });
    });
    navbar.hidenInNavBar?.forEach((element) => {
      if (element.ifHidden) return null;
      return routes.push(
        <PrivateRoute
          key={element.path}
          exact
          strict
          path={element.path}
          component={element.component}
          profileId={navbar.profileId}
        />
      );
    });
  });

  return routes;
};

export default App;
