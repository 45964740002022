import React, { useState, useEffect, useRef } from "react";
import Button from "../../../helpersComponents/Button/button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  AutocompleteStyled,
  DatePickerStyled,
} from "../../../helpersComponents/MaterialUi/index";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import CircularProgress from "@material-ui/core/CircularProgress";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchPost from "../../../../hooks/fetchHooks/post/useFetchPost";
import useFetchPut from "../../../../hooks/fetchHooks/put/useFetchPut";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { selectTimeWorkersSuperiorArray } from "../../../../reducers/workersLists";
import { ProfileType } from "../../../../enums/profileType";
import { useHistory } from "react-router-dom";
import sortWorkersByName from "../../../../HelpersFunctions/sortWorkersByName";

const AddEditAnnualHolidaysRequestPopup: React.FC<any> = ({
  row,
  onApplyChanges,
  onCancelChanges,
  open,
  date,
}: any) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [ifClearReturnedValue, setIfClearReturnedValue] =
    useState<boolean>(false);

  const settings = useAppSelector(selectSettings);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const authUserType = useAppSelector(selectAuthUser).currentProfile.type;
  const timeWorkers = useAppSelector(selectTimeWorkersSuperiorArray);

  const [dateError, setDateError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [putingPostingCircularProgress, setPutingPostingCircularProgress] =
    useState<boolean>(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>();
  const [ifFetchholidayById, setIfFetchholidayById] = useState(false);
  const [postRequestBody, setPostRequestBody] = useState<any>(false);
  const [putRequestBody, setPutRequestBody] = useState<any>(false);
  const [selectedTimeWorker, setSelectedTimeWorker] = useState<any>();

  const [isLoading, setIsLoading] = useState<boolean>(
    row.hasOwnProperty("id") ? true : false
  );

  const [selectedTimeWorkerError, setSelectedTimeWorkerError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const history: any = useHistory();

  useEffect(() => {
    if (authUserType === ProfileType.SUPERIOR_TIME && row.timeWorkerId) {
      setSelectedTimeWorker(
        timeWorkers.find((el) => el.id === row.timeWorkerId)
      );
    }
  }, [row.timeWorkerId, authUserType, timeWorkers]);

  let postRequest = useFetchPost(
    authUserType === ProfileType.WORKER_TIME
      ? `worker-time/${authUserId}/absence-plans-requests`
      : "superior-time/absence-plans-requests",
    postRequestBody,
    ifClearReturnedValue
  );

  let putRequest = useFetchPut(
    authUserType === ProfileType.WORKER_TIME
      ? `worker-time/${authUserId}/absence-plans-requests/${row.id}`
      : `superior-time/absence-plans-requests/${row.id}`,
    putRequestBody,
    ifClearReturnedValue
  );

  const [isError, setIsError] = useState<boolean>(false);
  const holidayByIdResponse = useFetchGet(
    authUserType === ProfileType.WORKER_TIME
      ? `worker-time/${authUserId}/absence-plans-requests/${row.id}`
      : `superior-time/absence-plans-requests/${row.id}`,

    ifFetchholidayById && row.id ? true : false,
    ifClearReturnedValue
  );

  useEffect(() => {
    if (row.hasOwnProperty("id")) {
      setIfFetchholidayById(true);
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

    if (holidayByIdResponse.error !== null) {
      setIsError(true);
      setIfFetchholidayById(false);
      return;
    }

    if (holidayByIdResponse.data !== null && row.hasOwnProperty("id")) {
      setStartDate(new Date(holidayByIdResponse.data.dateFrom));
      setEndDate(new Date(holidayByIdResponse.data.dateTo));
      setIsLoading(false);
    }
  }, [row, holidayByIdResponse, t]);

  const applyChanges = useRef(onApplyChanges);
  useEffect(() => {
    if (postRequest.data?.status === 201) {
      applyChanges.current(true);
      setPutingPostingCircularProgress(false);
    } else if (putRequest.data?.status === 200) {
      applyChanges.current();
      setPutingPostingCircularProgress(false);
    } else if (postRequest.data?.status === 400) {
      let error = "";
      Object.keys(postRequest.data.errors).forEach((errorObject) => {
        postRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setResponseErrorMessage(error);
    } else if (putRequest.data?.status === 400) {
      let error = "";
      Object.keys(putRequest.data.errors).forEach((errorObject) => {
        putRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setResponseErrorMessage(error);
    } else if (putRequest.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    } else if (postRequest.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    }
  }, [postRequest, putRequest, t]);

  const confirm = () => {
    let ifFetch = true;
    let dateFrom = computeDate(startDate, "T00:00:00");
    let dateTo = computeDate(endDate, "T23:59:59");

    if (
      authUserType === ProfileType.SUPERIOR_TIME &&
      (selectedTimeWorker === undefined || selectedTimeWorker.id === undefined)
    ) {
      setSelectedTimeWorkerError({
        isError: true,
        errorMessage: t("select_employee"),
      });
      ifFetch = false;
    } else if (startDate === null || endDate === null) {
      setDateError({
        isError: true,
        errorMessage: t("invalid_date"),
      });
      ifFetch = false;
    } else if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      setDateError({
        isError: true,
        errorMessage: t("invalid_date"),
      });
      ifFetch = false;
    } else if (startDate.getTime() > endDate.getTime()) {
      setDateError({
        isError: true,
        errorMessage: t("wrong_date_range"),
      });
      ifFetch = false;
    } else if (!(settings.WebBrakOgraniczenWPlanowaniu?.value === "1")) {
      var diff =
        Math.floor(
          (new Date(dateTo).getTime() - new Date(dateFrom).getTime()) / 86400000
        ) + 1;

      if (diff < 14) {
        setDateError({
          isError: true,
          errorMessage: t("minimum_vacation_14_days"),
        });
        ifFetch = false;
      } else {
        setDateError({
          isError: false,
          errorMessage: "",
        });
      }
    }

    if (!ifFetch) return;

    setPutingPostingCircularProgress(false);
    setResponseErrorMessage("");

    if (row.hasOwnProperty("id")) {
      setPutRequestBody({
        dateFrom,
        dateTo,
        absenceTypeId: 1,
        timeWorkerId:
          authUserType === ProfileType.WORKER_TIME
            ? authUserId
            : selectedTimeWorker.id,
      });
    } else {
      setPostRequestBody({
        dateFrom,
        dateTo,
        absenceTypeId: 1,
        timeWorkerId:
          authUserType === ProfileType.WORKER_TIME
            ? authUserId
            : selectedTimeWorker.id,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelChanges}
      TransitionProps={{
        onExited: () => {
          setIfFetchholidayById(false);
          setStartDate(new Date());
          setEndDate(new Date());
          setIsLoading(true);
          setPostRequestBody(false);
          setResponseErrorMessage("");
          setPutingPostingCircularProgress(false);
          setIfClearReturnedValue(true);
          setDateError({
            isError: false,
            errorMessage: "",
          });
          setSelectedTimeWorker(undefined);
        },
        onEnter: () => {
          setIfClearReturnedValue(false);

          if (
            history.location.state?.startDate &&
            history.location.state?.endDate
          ) {
            setStartDate(history.location.state.startDate);
            setEndDate(history.location.state.endDate);
          } else if (date && !row.id) {
            setStartDate(date);

            let tempDate = new Date(date);
            setEndDate(
              new Date(
                tempDate.getFullYear(),
                tempDate.getMonth(),
                tempDate.getDate() + 14
              )
            );
          } else if (!date) {
            let tempDate = new Date();

            setStartDate(
              new Date(
                tempDate.getFullYear(),
                tempDate.getMonth(),
                tempDate.getDate()
              )
            );

            setEndDate(
              new Date(
                tempDate.getFullYear(),
                tempDate.getMonth(),
                tempDate.getDate() + 14
              )
            );
          }
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className="dialogTitleGlobal" onClick={() => {}}>
        <div className="titlePopup">{t("planned_holiday")}</div>
        {!row.id && (
          <div className="description">{t("adding_an_application")}</div>
        )}
        {row.id && (
          <div className="description">{t("editing_an_application")}</div>
        )}
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper
          isLodadingProgress={isLoading}
          isError={isError}
          setIfFetchAgain={(fetchAgain) => {
            setIfFetchholidayById(fetchAgain);
          }}
          setIsError={setIsError}
        >
          {!isLoading && !isError && (
            <div>
              {authUserType === ProfileType.SUPERIOR_TIME && (
                <div className="selectEmployee">
                  <div className="titleWidthHoliday">{t("employee")}:</div>
                  <div className="inputWidthHoliday">
                    <AutocompleteStyled
                      id="select_employee"
                      options={sortWorkersByName(timeWorkers)}
                      getOptionLabel={(option: any) => {
                        return `${option.lastName} ${option.firstName} ${option.evidenceNumber}`;
                      }}
                      value={selectedTimeWorker ? selectedTimeWorker : null}
                      onChange={(_, newValue) => {
                        setSelectedTimeWorker(newValue);
                      }}
                      required={true}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setSelectedTimeWorkerError({
                            isError: true,
                            errorMessage: t("select_employee"),
                          });
                        } else {
                          setSelectedTimeWorkerError({
                            isError: false,
                            errorMessage: t(""),
                          });
                        }
                      }}
                      isError={selectedTimeWorkerError.isError}
                      label={t("select_employee")}
                      width={"100%"}
                    />
                    {selectedTimeWorkerError.isError ? (
                      <div className="errorHolidayrequest">
                        {selectedTimeWorkerError.errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="selectDateHolidayPlanPopup">
                <div>
                  <div className="titleWidthHoliday">{t("date_from")}:</div>
                  <div className="inputWidthHolidayPlan">
                    <DatePickerStyled
                      view={["year", "month", "date"]}
                      format="dd-MM-yyyy"
                      isError={dateError.isError}
                      date={startDate}
                      setDate={setStartDate}
                      width={"100%"}
                    />
                  </div>
                </div>
                <div>
                  <div className="titleWidthHoliday">{t("date_to")}:</div>
                  <div className="inputWidthHolidayPlan">
                    <DatePickerStyled
                      view={["year", "month", "date"]}
                      format="dd-MM-yyyy"
                      date={endDate}
                      isError={dateError.isError}
                      width={"100%"}
                      setDate={setEndDate}
                    />
                  </div>
                </div>
              </div>
              {dateError.isError ? (
                <div className="errorHoliday">{dateError.errorMessage}</div>
              ) : null}
            </div>
          )}
        </LoadingWrapper>
      </DialogContent>

      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>
        {!putingPostingCircularProgress ? (
          <>
            <Button onClick={onCancelChanges} variant="outlined">{t("cancel")}</Button>
            <Button
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddEditAnnualHolidaysRequestPopup;
