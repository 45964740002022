import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import SelectMenu from "../../../helpersComponents/SelectMenu/selectMenu";

interface Props {
  setcurrentFilter: React.Dispatch<Filters>;
  currentFilter: Filters;
}

export default function FiltersSelectMenu({
  setcurrentFilter,
  currentFilter,
}: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <SelectMenu
      name={`${t("filter_2")}: ${t(currentFilter)}`}
      variant="outlined"
      items={[
        {
          optionName: t("none"),
          onClick: () => {
            setcurrentFilter("none");
          },
        },
        {
          optionName: t("show_gaps"),
          onClick: () => {
            setcurrentFilter("show_gaps");
          },
        },
        {
          optionName: t("show_lateness"),
          onClick: () => {
            setcurrentFilter("show_lateness");
          },
        },
        {
          optionName: t("show_business_i_o"),
          onClick: () => {
            setcurrentFilter("show_business_i_o");
          },
        },
        {
          optionName: t("show_private_outputs"),
          onClick: () => {
            setcurrentFilter("show_private_outputs");
          },
        },
        {
          optionName: t("show_potential_overtime"),
          onClick: () => {
            setcurrentFilter("show_potential_overtime");
          },
        },
        {
          optionName: t("unexcused_absence"),
          onClick: () => {
            setcurrentFilter("unexcused_absence");
          },
        },
        {
          optionName: t("show_overtime"),
          onClick: () => {
            setcurrentFilter("show_overtime");
          },
        },
      ]}
    />
  );
}
