import { TypeOfVisit } from "../../../../Constants/typeOfVisitInPorts";
import { ProfileType } from "../../../../enums/profileType";

const confirmFetching = ({
  values,
  authUserType,
  selectedFile,
  selectedFilesAttachments,
  setBodyRequest,
  EMPLOYEE_EMAIL_REQUIRED,
  notificationId,
  settings,
}: {
  values: InitStateInterface;
  authUserType: number | undefined;
  selectedFile: File[];
  selectedFilesAttachments: File[];
  setBodyRequest: (value: any) => void;
  EMPLOYEE_EMAIL_REQUIRED: boolean | undefined;
  notificationId: number | undefined;
  settings: any;
}) => {
  let permissionZoneId: number | null = null;

  if (authUserType !== undefined) {
    if (values?.typeOfVisit?.id === TypeOfVisit.REVISION) {
      let revisionCFSPermissionZoneId = parseInt(
        settings?.StrefaUprawnienMagazynCFS?.value
      );

      if (!isNaN(revisionCFSPermissionZoneId)) {
        permissionZoneId = revisionCFSPermissionZoneId;
      }
    } else {
      permissionZoneId = values.permissionZone?.id ?? null;
    }
  }

  let body: any = {
    dateFrom: values.dateFrom,
    dateTo: values.dateTo,
    companyName: values.company,
    targetDepartmentId: !EMPLOYEE_EMAIL_REQUIRED
      ? values.targetDepartment?.id
      : null,
    acSuperiorEmail:
      EMPLOYEE_EMAIL_REQUIRED &&
      authUserType !== ProfileType.EMPLOYEE &&
      authUserType !== ProfileType.OHS
        ? values.acSuperiorEmail
        : null,
    acSuperiorPhoneOrRadio:
      EMPLOYEE_EMAIL_REQUIRED &&
      (authUserType === ProfileType.EMPLOYEE ||
        authUserType === ProfileType.OHS)
        ? values.acSuperiorPhoneOrRadio
        : null,
    typeOfVisitId: values.typeOfVisit?.id,
    registrationNumber:
      values.typeOfVisit?.id && values.typeOfVisit.id !== TypeOfVisit.BUSINESS
        ? values.plateNumber.toUpperCase()
        : "",
    comments: values.information,
    isWorkPermitRequired:
      authUserType === undefined ||
      (values.typeOfVisit?.id &&
        values.typeOfVisit.id !== TypeOfVisit.COMPLETION_OF_THE_WORK)
        ? null
        : values.isWorkPermitRequiredTrue
        ? true
        : false,
    workPermitNumber:
      authUserType === undefined ||
      (values.typeOfVisit?.id &&
        values.typeOfVisit.id !== TypeOfVisit.COMPLETION_OF_THE_WORK)
        ? null
        : values.isWorkPermitRequiredTrue && values.workPermitNumber !== ""
        ? values.workPermitNumber
        : null,
    workArea:
      authUserType === undefined ||
      (values.typeOfVisit?.id &&
        values.typeOfVisit.id !== TypeOfVisit.COMPLETION_OF_THE_WORK)
        ? null
        : values.isWorkPermitRequiredTrue && values.workArea !== ""
        ? values.workArea
        : null,
    isWorkParticularlyDangerous:
      authUserType === undefined ||
      (values.typeOfVisit?.id &&
        values.typeOfVisit.id !== TypeOfVisit.COMPLETION_OF_THE_WORK)
        ? null
        : values.isWorkParticularlyDangerousTrue
        ? true
        : false,
    workParticularlyDangerousComments:
      authUserType === undefined ||
      (values.typeOfVisit?.id &&
        values.typeOfVisit.id !== TypeOfVisit.COMPLETION_OF_THE_WORK)
        ? null
        : values.isWorkParticularlyDangerousTrue &&
          values.workParticularlyDangerousComments !== ""
        ? values.workParticularlyDangerousComments
        : null,
    permissionZoneId: permissionZoneId,
    bh3: authUserType !== undefined ? values.bh3 : false,
    isToPfsoAndShiftDirector:
      authUserType === ProfileType.OPERATIONAL_SUPPORT ? true : false,
    captchaCode: values.captcha.toUpperCase(),
  };

  if (notificationId) {
    body["id"] = notificationId;
  }

  let guests = values.guestsArray.map((guest) => {
    return {
      id: guest.id ? guest.id : 0,
      firstName: guest.firstName,
      lastName: guest.lastName,
      dateOfBirth: guest.isSelectedDateOfBirth ? guest.dateOfBirth : null,
      email: guest.isSelectedEmail ? guest.email : null,
    };
  });

  body["guests"] = guests;

  let formData = new FormData();
  formData.append("requestData", JSON.stringify(body));
  selectedFile.forEach((file) => {
    formData.append("", file);
  });

  //attachments to notification
  if (
    selectedFilesAttachments !== null &&
    selectedFilesAttachments.length > 0
  ) {
    selectedFilesAttachments.forEach((file) => {
      formData.append("notification", file);
    });
  }

  setBodyRequest(formData);
};

export default confirmFetching;
