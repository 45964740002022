import {
  DataTypeProvider,
  EditingState,
  Filter,
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  RowDetailState,
  SearchState,
  SelectionState,
  SortingState,
  SummaryState,
} from "@devexpress/dx-react-grid";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  TableEditColumn,
  TableFilterRow,
  TableFixedColumns,
  TableGroupRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  TableSummaryRow,
} from "@devexpress/dx-react-grid-material-ui";

import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faEdit,
  faPlus,
  faRedoAlt,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControlLabel,
  Input,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createTheme, withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import saveAs from "file-saver";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ProfileType } from "../../../enums/profileType";
import sortByDate from "../../../HelpersFunctions/dateAndTime/sortByDate";
import toLocaleStringCustom from "../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import erro400getTranslatedErrorString from "../../../HelpersFunctions/erro400getTranslatedErrorString";
import { isObjectEmpty } from "../../../HelpersFunctions/isObjectEmpty";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import useUpdateSettings from "../../../hooks/useUpdateSettings/useUpdateSettings";
import { selectAuthUser } from "../../../reducers/session";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import {
  colorNameDictionary,
  profilesRoutesDictionary,
} from "../../ProfilesRouting/profilesDictionaries";
import { default as Button, default as ButtonStyled } from "../Button/button";
import { Button as MaterialUIButton } from "@material-ui/core";

import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import { CheckboxStyled, SnackbarStyled } from "../MaterialUi";
import {
  default as RefreshButton,
  default as RefreshButtonMenu,
} from "../RefreshButtonMenu";
import SelectMenu from "../SelectMenu/selectMenu";
import "./AbhayaLibre-Regular-normal.js";
import ConfirmRemoveActions from "./confirmRemoveActions";
import LabelHeaderTooltip from "./labelHeaderTooltip";
import "./tableComponent.scss";
import { ActionsColumn, ITableExportInfo } from "./type";
import moment from "moment";

const NewLayoutNamePopup = React.lazy(() => import("./newLayoutNamePopup"));
const PopupEditing = React.lazy(() => import("./popupEditing"));

//////////////////// export to excel function ///////////////
const onSave = (workbook, tableExportInfo: ITableExportInfo | undefined) => {
  const worksheet = workbook.worksheets[0];

  if (tableExportInfo) {
    let newRowArray: any = [];

    if (tableExportInfo.dateFrom) {
      newRowArray.push("Data od");
      newRowArray.push(
        moment(tableExportInfo.dateFrom).format("YYYY-MM-DD HH:mm:ss")
      );
    }

    if (tableExportInfo.dateTo) {
      newRowArray.push("Data do");
      newRowArray.push(
        moment(tableExportInfo.dateTo).format("YYYY-MM-DD HH:mm:ss")
      );
    }

    if (tableExportInfo.createdBy) {
      newRowArray.push("Utworzył");
      newRowArray.push(tableExportInfo.createdBy);
    }

    if (newRowArray.length > 0) {
      worksheet.insertRow(1, newRowArray);
    }
  }

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      "DataGrid.xlsx"
    );
  });
};

////////////// style for FormControlLabel (column visibility checkbox) /////////////
const FormControlLabelStyled = withStyles(() => ({
  root: {
    "& .MuiCheckbox-root": {
      padding: "0px 9px",
    },
  },
}))(FormControlLabel);

//////////// header background ///////////
let RowHeader: any = TableHeaderRow.Row;
const RowHeaderComponent: React.ComponentType<Table.RowProps> = ({
  children,
}) => {
  return <RowHeader className="headerStyle">{children}</RowHeader>;
};

//////////// Grid Root - border radious ///////////
const GridRootComponent: React.ComponentType<Grid.RootProps> = ({
  children,
}) => {
  return <Grid.Root className="gridRoot">{children}</Grid.Root>;
};

const getRowId = (row) => row.id;

const DateFormatter = ({ value, ...rest }) => {
  const { t } = useTranslation();

  if (rest?.column?.type === "date_hours")
    return toLocaleStringCustom({
      date: value ? new Date(value) : false,
      t,
      ifShowHours: true,
    });

  if (rest?.column?.type === "date_seconds")
    return toLocaleStringCustom({
      date: value ? new Date(value) : false,
      t,
      ifShowSeconds: true,
    });

  return toLocaleStringCustom({ date: value ? new Date(value) : false, t });
};

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

const CustomFormatter = ({ value, ...rest }) => {
  if (typeof rest?.column?.customFormatter === "function") {
    return rest?.column?.customFormatter(rest.row);
  }
  return "CustomFormatter require function ";
};
const CustomFormatterTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={CustomFormatter} {...props} />
);

const tableCellComponentFunction = ({ tableCellComponent, cellPadding }) => {
  let tableCellComponentFinal;

  if (tableCellComponent) {
    tableCellComponentFinal = withStyles(
      {
        cell: {
          whiteSpace: "normal",
          wordBreak: "break-word",
          padding: cellPadding,
        },
      },
      { name: "CellComponent" }
    )(tableCellComponent);
  } else {
    tableCellComponentFinal = withStyles(
      {
        cell: {
          whiteSpace: "normal",
          wordBreak: "break-word",
          padding: cellPadding,
          paddingLeft: "5px",
        },
      },
      { name: "CellComponent" }
    )(Table.Cell);
  }

  return tableCellComponentFinal;
};

const CustomTableFilterRowCell = (props) => {
  return (
    <TableFilterRow.Cell
      {...props}
      style={{
        borderRight: "1px solid #e0e0e0",
      }}
    />
  );
};

interface TableComponentInterface {
  pageSize?: number;
  openPopup?: boolean;
  ifAdd?: boolean;
  ifManualRefresh?: boolean;
  ifAddCustomAction?: () => void;
  ifDelete?: boolean;
  ifEdit?: boolean;
  editCommandName?: string;
  deleteCommandName?: string;
  hideActionsAndVisibilityColumns?: true;
  ifHideWholeToolbalr?: boolean;
  layoutSettingName: string;
  tableCellComponent?: any;
  columns: (
    | {
        name: string;
        title: string;
        customTooltip?: string;
        type: "date" | "date_hours";
        customFormatter: (row) => React.ReactNode;
        hide?: boolean;
      }
    | {
        name: string;
        title: string;
        customTooltip?: string;
        hide?: boolean;
      }
  )[];
  title?: JSX.Element | string;
  toolbarComponent?: JSX.Element;
  rows: Object[];
  filteringStateColumnExtensions?: {
    columnName: string;
    filteringEnabled: boolean;
  }[];
  selectedData?: (string | number)[];
  selectData?: (selection: (string | number)[]) => void;
  // selectData?: React.Dispatch<string[] | number[]>
  hideSelectDataCondition?: (row: any) => boolean;
  commitChanges?: any;
  rowComponent?: any;
  actionColumn?: React.FC;
  // cellComponent?: React.FC;
  rowDetail?: React.FC<any>;
  popup?: React.FC;
  popupAdditionalParams?: any;
  defaultSorting?: { columnName: string; direction: "asc" | "desc" }[];
  leftColumns?: string[];
  rightColumns?: string[];
  columnsWidth?: { columnName: string; width: string | number }[];
  backgroundColor?: string;
  leftFixedSelectionColumn?: boolean;
  leftFixedRowDetailsColumns?: boolean;
  filteringRowDefaultHidden?: boolean;
  fetchingState?: {
    isFetching: boolean;
    fetchAgain: () => void;
    isError: boolean;
  };
  popupExtraIndicator?: any;
  summaryRowProps?: {
    totalItems?: {
      columnName: string;
      type: string;
      showInGroupFooter?: boolean;
      alignByColumn?: boolean;
    }[];
    groupItems?: {
      columnName: string;
      type: string;
      showInGroupFooter?: boolean;
      alignByColumn?: boolean;
    }[];
    calculator?: (x, y, z) => string | number;
    customItem?: ({ props }: any) => any;
  };
  inlineSummaryItemComponent?: ({ props }: any) => any;
  tableEditColumnWidth?: number;
  grouping?: { columnName: string }[];
  groupCellContent?: any;
  expandedGroupsDefault?: string[];
  actionsColumnUpdated?: ActionsColumn;
  refreshingButton?: {
    ifShow: boolean;
    refreshingTime?: number | null | undefined;
    handleChangeRefreshingTime?: (value: number | null) => void;
    onClick?: () => void;
    onlyRefreshNow?: boolean;
  };
  customRow?: any;
  rowMenu?: {
    ifShow: boolean;
    menu: (row) => JSX.Element;
  };
  paddingConfirmRemoveActions?: number;
  cellPadding?: string;
  customExcelCellValue?: (row: any, columnName: string) => any;
  customPdfCellValue?: (row: any, columnName: string) => any;

  customExcelRowsFilter?: (rows: any) => any;
  customExcelColumnsFilter?: (rows: any) => any;
  customPdfRowsFilter?: (rows: any) => any;
  customPdfColumnsFilter?: (rows: any) => any;

  tableExportInfo?: ITableExportInfo;

  externalSearchValue?: string;
  setExternalSearchValue?: (value: string) => void;

  hiddenColumnsNames?: string[];
  disablePagination?: boolean;
}

const TableComponentMemo = React.memo(
  function TableComponent({
    pageSize = 10,
    filteringRowDefaultHidden = false,
    leftFixedRowDetailsColumns,
    openPopup = false,
    ifAdd,
    ifAddCustomAction,
    inlineSummaryItemComponent,
    ifManualRefresh,
    ifDelete,
    ifEdit,
    hideActionsAndVisibilityColumns,
    ifHideWholeToolbalr,
    editCommandName,
    deleteCommandName,
    layoutSettingName,
    tableCellComponent,
    columns,
    title,
    toolbarComponent,
    rows,
    filteringStateColumnExtensions,
    selectedData,
    selectData,
    hideSelectDataCondition,
    commitChanges,
    leftFixedSelectionColumn,
    rowComponent,
    actionColumn,
    rowDetail,
    popup,
    popupAdditionalParams,
    defaultSorting,
    leftColumns,
    rightColumns,
    columnsWidth,
    backgroundColor,
    fetchingState,
    popupExtraIndicator,
    summaryRowProps,
    tableEditColumnWidth,
    grouping,
    groupCellContent,
    expandedGroupsDefault,
    actionsColumnUpdated,
    refreshingButton,
    customRow,
    rowMenu,
    paddingConfirmRemoveActions,
    cellPadding,
    customExcelCellValue,
    customPdfCellValue,
    customExcelRowsFilter,
    customExcelColumnsFilter,
    customPdfRowsFilter,
    customPdfColumnsFilter,
    tableExportInfo,
    hiddenColumnsNames,
    disablePagination,
    externalSearchValue,
    setExternalSearchValue,
  }: TableComponentInterface) {
    let fixedLeftColumnsLocal: any = leftColumns;
    if (fixedLeftColumnsLocal) {
      fixedLeftColumnsLocal = [
        ...(leftFixedSelectionColumn ? [TableSelection.COLUMN_TYPE] : []),
        ...(leftFixedRowDetailsColumns ? [TableRowDetail.COLUMN_TYPE] : []),
        ...fixedLeftColumnsLocal,
      ];
    }

    if (!cellPadding) {
      cellPadding = "1px;";
    }

    //////////// formatting columns with type "date", and custom sorting for those columns
    const [integratedSortingColumnExtensions] = useState(() => {
      let result: { columnName: string; compare: any }[] = [];
      columns.forEach((column: any) => {
        if (column?.type === "date" || column?.type === "date_hours") {
          result.push({ columnName: column.name, compare: sortByDate });
        }
      });
      return result;
    });

    //////////////////Columns to format time
    const [dateColumns] = useState(() => {
      let result: string[] = [];
      columns.forEach((column: any) => {
        if (
          column?.type === "date" ||
          column?.type === "date_hours" ||
          column?.type === "date_seconds"
        ) {
          result.push(column.name);
        }
      });
      return result;
    });

    //////////////////Columns with custom formatting
    const [customFormatterColumns] = useState(() => {
      let result: string[] = [];
      columns.forEach((column: any) => {
        if (typeof column?.customFormatter === "function") {
          result.push(column.name);
        }
      });

      return result;
    });

    //////////////////////////// state of new version of
    const [popupUpdatedState, setPopupUpdatedState] = useState<{
      isOpen: boolean;
      rowData?: any;
    }>({
      isOpen: false,
      rowData: {},
    });

    const closePopup = useCallback(() => {
      setPopupUpdatedState({ isOpen: false });
    }, []);
    ////////////////////////////////////

    const { t } = useTranslation();
    const [expandedGroups, setExpandedGroups] = useState(expandedGroupsDefault);
    useEffect(() => {
      setExpandedGroups(expandedGroupsDefault);
    }, [expandedGroupsDefault]);

    const [pageSizes] = useState(() => {
      let pageSizesLocal = [5, 15, 30, 50];
      pageSizesLocal.push(pageSize);
      pageSizesLocal.sort(function (a, b) {
        return a - b;
      });
      return pageSizesLocal;
    });
    const [currentPage, setCurrentPage] = useState(0);
    const [openPopupLocal, setOpenPopupLocal] = useState(openPopup);
    const [columnOrder, setColumnOrder] = useState<string[]>([]);
    const [expandedRowIds, setExpandedRowIds] = useState<any>([]);
    const authUser = useAppSelector(selectAuthUser);
    const history = useHistory();
    const noDataCell = useMemo(() => {
      return { noData: fetchingState?.isFetching ? "" : t("no_data") };
    }, [fetchingState?.isFetching, t]);

    ////////////////// checkbox color ////////////////////

    const color = !(
      Object.keys(authUser).length === 0 && authUser.constructor === Object
    )
      ? colorNameDictionary[authUser.currentProfile.type]
      : "#1C84C6";

    const cellTheme = useMemo(() => {
      return createTheme({
        palette: {
          secondary: {
            main: color,
          },
        },
      });
    }, [color]);

    const cellComponentTableSelection = useCallback(
      (props) => (
        <ThemeProvider theme={cellTheme}>
          {hideSelectDataCondition === undefined ? (
            <TableSelection.Cell {...props} />
          ) : hideSelectDataCondition && !hideSelectDataCondition(props.row) ? (
            <TableSelection.Cell {...props} />
          ) : (
            <Table.Cell {...props} />
          )}
        </ThemeProvider>
      ),
      [cellTheme, hideSelectDataCondition]
    );

    const headerCellComponentTableSelection = useCallback(
      (props) => (
        <ThemeProvider theme={cellTheme}>
          <TableSelection.HeaderCell {...props} />
        </ThemeProvider>
      ),
      [cellTheme]
    );

    const Command: React.ComponentType<TableEditColumn.HeaderCellProps> = ({
      children,
      ...restProps
    }: any) => {
      return (
        <TableEditColumn.HeaderCell className="addButtonStyle" {...restProps}>
          <div className={"addRefreshContainer"}>
            {actionsColumnUpdated?.refreshButton?.ifShow && (
              <div>
                <RefreshButton
                  icon={actionsColumnUpdated?.refreshButton?.icon}
                  refreshingTime={
                    actionsColumnUpdated?.refreshButton?.refresh.refreshingTime
                  }
                  handleChangeRefreshingTime={
                    actionsColumnUpdated?.refreshButton?.refresh
                      ?.handleChangeRefreshingTime
                  }
                  hideInfo={actionsColumnUpdated?.refreshButton?.hideInfo}
                  color={actionsColumnUpdated?.refreshButton?.color}
                />
              </div>
            )}

            {(ifAdd || actionsColumnUpdated?.addButton?.ifShow) &&
              !actionsColumnUpdated?.addButton?.showInToolbar && (
                <div>
                  <Button
                    className="tableAddButton"
                    onClick={(event) => {
                      if (actionsColumnUpdated?.addButton?.ifShow) {
                        if (actionsColumnUpdated.addButton?.onClick) {
                          actionsColumnUpdated.addButton.onClick(event);
                        } else {
                          setPopupUpdatedState({
                            isOpen: true,
                          });
                        }
                      } else if (ifAddCustomAction) ifAddCustomAction();
                      else children.props.onExecute();
                    }}
                  >
                    <FontAwesomeIcon
                      className="faPlusTable"
                      icon={
                        actionsColumnUpdated?.addButton?.icon
                          ? actionsColumnUpdated.addButton.icon
                          : faPlus
                      }
                    />
                    &nbsp; {actionsColumnUpdated?.addButton?.name ?? t("add")}
                  </Button>
                </div>
              )}

            {(ifManualRefresh ||
              actionsColumnUpdated?.manualRefreshButton?.ifShow) &&
              !actionsColumnUpdated?.manualRefreshButton?.showInToolbar && (
                <div>
                  <Button
                    className="tableAddButton"
                    onClick={(event) => {
                      if (actionsColumnUpdated?.manualRefreshButton?.ifShow) {
                        if (actionsColumnUpdated.manualRefreshButton?.onClick) {
                          actionsColumnUpdated.manualRefreshButton.onClick(
                            event
                          );
                        } else {
                          setPopupUpdatedState({
                            isOpen: true,
                          });
                        }
                      } else if (ifAddCustomAction) ifAddCustomAction();
                      else children.props.onExecute();
                    }}
                  >
                    <FontAwesomeIcon
                      className="faPlusTable"
                      icon={
                        actionsColumnUpdated?.manualRefreshButton?.icon
                          ? actionsColumnUpdated.manualRefreshButton.icon
                          : faRedoAlt
                      }
                    />
                    &nbsp;{" "}
                    {actionsColumnUpdated?.manualRefreshButton?.name ??
                      t("refresh")}
                  </Button>
                </div>
              )}
          </div>
        </TableEditColumn.HeaderCell>
      );
    };
    ////////////////////////////

    const filterRowMessages = {
      filterPlaceholder: t("filter_table") + "...",
      contains: t("contains"),
      notContains: t("notContains"),
      startsWith: t("startsWith"),
      endsWith: t("endsWith"),
      equal: t("equal"),
      notEqual: t("notEqual"),
    };
    const pagingPanelMessages: any = {
      rowsPerPage: t("rows_per_page_table"),
      info: "{from}-{to} " + t("of") + " {count}",
    };

    const editColumnMessages = {
      // addCommand: addCommandName ? addCommandName : <>{t('add')}&nbsp; <FontAwesomeIcon className="faPlusTable" icon={faPlus} /></>,
      editCommand: editCommandName ? editCommandName : t("edit"),
      deleteCommand: deleteCommandName ? deleteCommandName : t("delete"),
    };

    /////////// break words in cells, first check if there is Table.Cell in props ///////
    const TableCellComponentFinal = useMemo(
      () =>
        tableCellComponentFunction({
          tableCellComponent,
          cellPadding,
        }),
      [tableCellComponent, cellPadding]
    );

    ////////////////////  columns order ////////////////////////

    useEffect(() => {
      let columnOrder: string[] = [];
      columns.forEach((column) => {
        columnOrder.push(column.name);
      });
      setColumnOrder(columnOrder);
    }, [columns]);

    //////////  TABLE LAYOUT (user preferences) /////////

    interface LayoutSorting {
      columnName: string;
      direction: "asc" | "desc";
    }

    interface LayoutsettingSingle {
      name: string;
      columnOrder: string[];
      hiddenColumnNames: string[];
      rowsPerPage: number;
      sorting: LayoutSorting[];
      filters: Array<Filter>;
      searchValue: string;
      ifShowFilteringRow?: boolean;
    }

    interface Layoutsetting {
      defaultLayout: string | null;
      layoutsList: {
        [key in string]: LayoutsettingSingle;
      };
    }
    const [ifShowFilteringRow, setifShowFilteringRow] = useState<boolean>(
      !filteringRowDefaultHidden
    );
    const [ifSettingAppliedOnInitial, setIfSettingAppliedOnInitial] =
      useState<boolean>(false);
    const deleteString: any = t("delete");
    let initPageSize = 15;
    if (pageSize) initPageSize = pageSize;
    const [pageSizeLocal, setPageSizeLocal] = useState<number>(initPageSize);
    const [sorting, setSorting] = useState<any>(
      defaultSorting ? defaultSorting : []
    );
    const [searchValue, setSearchValue] = useState<string>("");
    const [filters, setFilters] = useState<Array<Filter>>([]);
    const [hiddenColumnNames, setHiddenColumnNames] = useState<string[]>([
      "organizationalUnits",
    ]);
    const [checkbox, setCheckbox] = useState({});
    const [postRequestBody, setPostRequestBody] = useState<any>(false);
    const [postRowsPerPageDataRequestBody, setPostRowsPerPageDataRequestBody] =
      useState<any>(false);

    const settings = useAppSelector(selectSettings);
    const [snackbarState, setSnackbarState] = useState<
      [boolean, string, string]
    >([false, "error", ""]);
    const [ifUpdateSetting, setIfUpdateSetting] = useState<boolean>(false);

    const ifSettingsUpdated = useUpdateSettings(ifUpdateSetting);

    const [newLayoutName, setNewLayoutName] = useState<string>("");
    const [newLayoutNameIsOpenPopup, setNewLayoutNameIsOpenPopup] =
      useState<boolean>(false);
    const [currentLayoutName, setCurrentLayoutName] = useState<string | null>(
      () => {
        let layoutSettingValue: Layoutsetting | undefined = undefined;
        let layoutSettingValueRaw = settings[layoutSettingName]?.value;
        if (layoutSettingValueRaw) {
          layoutSettingValue = JSON.parse(layoutSettingValueRaw);
        }
        if (layoutSettingValue?.defaultLayout)
          return layoutSettingValue?.defaultLayout;
        return null;
      }
    );
    const [setasDefault, setsetasDefault] = useState<boolean>(false);
    const { confirm } = useConfirm();
    const confirmRef = useRef(confirm);

    const restoreLayoutCallBack = useCallback(() => {
      setHiddenColumnNames([]);
      setNewLayoutName("");
      setSearchValue("");
      setifShowFilteringRow(!filteringRowDefaultHidden);
      let columnOrder: string[] = [];
      columns.forEach((column) => {
        columnOrder.push(column.name);
      });
      setSorting(defaultSorting ? defaultSorting : []);
      setFilters([]);
      setPageSizeLocal(initPageSize);
      setColumnOrder(columnOrder);
      setCurrentLayoutName(null);
      let checkboxLocal = {};
      columns.forEach((column) => {
        checkboxLocal[column.name] = true;
      });
      setCheckbox(checkboxLocal);
    }, [columns, initPageSize, defaultSorting, filteringRowDefaultHidden]);

    interface SelectLayoutItemsInterface {
      optionName: React.ReactNode;
      onClick: () => void;
    }
    const [selectLayoutItems, setselectLayoutItems] = useState<
      SelectLayoutItemsInterface[]
    >([
      {
        optionName: <div className={"tableLayoutsList"}>Początkowy</div>,
        onClick: () => {
          restoreLayoutCallBack();
        },
      },
    ]);
    useEffect(() => {
      let checkboxLocal = {};
      columns.forEach((column) => {
        checkboxLocal[column.name] = true;
      });
      setCheckbox(checkboxLocal);
    }, [columns]);

    useEffect(() => {
      const hiddenColumnsName = columns
        .filter((c) => c.hide)
        .map((c) => c.name);
      setHiddenColumnNames(hiddenColumnsName);
    }, [columns]);

    const removeLayoutCallBack = useCallback(
      (layoutName: string) => {
        let currentLayoutSettingValue: Layoutsetting | undefined = settings[
          layoutSettingName
        ]?.value
          ? JSON.parse(settings[layoutSettingName].value)
          : undefined;

        if (currentLayoutSettingValue === undefined) return;

        let newLayoutsList: {
          [key in string]: LayoutsettingSingle;
        } = {};

        Object.keys(currentLayoutSettingValue.layoutsList).forEach(
          (layoutNameLocal) => {
            if (
              layoutNameLocal !== layoutName &&
              currentLayoutSettingValue?.layoutsList
            ) {
              newLayoutsList[layoutNameLocal] =
                currentLayoutSettingValue.layoutsList[layoutNameLocal];
            }
          }
        );

        let postPutBodyValue: Layoutsetting;
        postPutBodyValue = {
          defaultLayout:
            currentLayoutSettingValue?.defaultLayout === layoutName
              ? null
              : currentLayoutSettingValue.defaultLayout,
          layoutsList: newLayoutsList,
        };

        if (currentLayoutName === layoutName) {
          restoreLayoutCallBack();
        }

        setPostRequestBody(
          JSON.stringify({
            name: layoutSettingName,
            value: JSON.stringify(postPutBodyValue),
          })
        );
      },
      [layoutSettingName, settings, currentLayoutName, restoreLayoutCallBack]
    );

    useEffect(() => {
      if (ifSettingAppliedOnInitial) {
        if (settings[layoutSettingName + "_RowsPerPage"]?.value) {
          let tempRowsPerPage = parseInt(
            settings[layoutSettingName + "_RowsPerPage"]?.value
          );

          if (pageSizes.includes(tempRowsPerPage)) {
            setPageSizeLocal(tempRowsPerPage);
          }
        }
      }
    }, [ifSettingAppliedOnInitial, layoutSettingName, pageSizes, settings]);

    useEffect(() => {
      if (ifUpdateSetting) return;

      let checkboxLocal = {};
      if (!settings[layoutSettingName]) {
        setIfSettingAppliedOnInitial(true);
        return;
      }

      let layoutSettingValue: Layoutsetting | undefined = undefined;
      let layoutSettingValueRaw = settings[layoutSettingName]?.value;
      if (layoutSettingValueRaw) {
        layoutSettingValue = JSON.parse(layoutSettingValueRaw);
      }
      if (layoutSettingValue?.defaultLayout === undefined) {
        setIfSettingAppliedOnInitial(true);
        return;
      }
      let selectLayoutItemsLocal: SelectLayoutItemsInterface[] = [];
      Object.keys(layoutSettingValue.layoutsList).forEach((itemName) => {
        selectLayoutItemsLocal.push({
          optionName: (
            <div className="tableLayoutsList">
              <div
                className={
                  itemName === layoutSettingValue?.defaultLayout
                    ? "defaultLayout"
                    : ""
                }
              >
                {itemName}
              </div>
              <div>
                <Tooltip title={deleteString}>
                  <div
                    onClick={async (e) => {
                      e.stopPropagation();
                      let buttonId = await confirmRef.current({
                        text: `${t(
                          "are_you_sure_you_want_to_delete_layout"
                        )} "${itemName}"?`,
                        buttons: [
                          { buttonName: t("delete"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });
                      if (buttonId === 0) {
                        removeLayoutCallBack(itemName);
                      }
                    }}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                    className={"faTimesCircleTable"}
                  >
                    <div>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                  </div>
                </Tooltip>
              </div>
            </div>
          ),
          onClick: () => {
            setCurrentLayoutName(itemName);
            setNewLayoutName(itemName);
          },
        });
      });
      setselectLayoutItems((prev) => {
        return [prev[0], ...selectLayoutItemsLocal];
      });
      if (isObjectEmpty(layoutSettingValue?.layoutsList)) {
        setIfSettingAppliedOnInitial(true);
        return;
      }
      if (currentLayoutName === null || currentLayoutName === "") {
        setIfSettingAppliedOnInitial(true);
        return;
      }

      let layoutToApply: LayoutsettingSingle =
        layoutSettingValue.layoutsList[currentLayoutName];

      if (!layoutToApply?.name) {
        return;
      }

      setNewLayoutName(layoutToApply.name);
      setCurrentLayoutName(layoutToApply.name);
      setifShowFilteringRow(
        layoutToApply?.ifShowFilteringRow
          ? layoutToApply?.ifShowFilteringRow
          : filteringRowDefaultHidden
      );

      setColumnOrder(layoutToApply.columnOrder);
      setFilters(layoutToApply.filters);

      setPageSizeLocal(layoutToApply.rowsPerPage);
      setSorting(layoutToApply.sorting);
      setSearchValue(
        layoutToApply?.searchValue ? layoutToApply.searchValue : ""
      );
      columns.forEach((column) => {
        const ifVisible = layoutToApply.hiddenColumnNames.findIndex(
          (hidenColumnName) => hidenColumnName === column.name
        );

        setHiddenColumnNames(layoutToApply.hiddenColumnNames);
        if (ifVisible === -1) checkboxLocal[column.name] = true;
        else checkboxLocal[column.name] = false;
      });
      setCheckbox(checkboxLocal);
      setIfSettingAppliedOnInitial(true);
    }, [
      columns,
      layoutSettingName,
      currentLayoutName,
      ifUpdateSetting,
      settings,
      removeLayoutCallBack,
      deleteString,
      filteringRowDefaultHidden,
      pageSizes,
      t,
      hiddenColumnsNames,
    ]);

    const [preferencesSaved, setPreferencesSaved] = useState(false);

    const saveLayout = (pageSize: number | undefined = undefined) => {
      let currentLayoutSettingValue: Layoutsetting | undefined = settings[
        layoutSettingName
      ]?.value
        ? JSON.parse(settings[layoutSettingName].value)
        : undefined;

      const newLayoutProps: LayoutsettingSingle = {
        name: newLayoutName,
        columnOrder: columnOrder,
        hiddenColumnNames: hiddenColumnNames,
        rowsPerPage: pageSize ? pageSize : pageSizeLocal,
        sorting: sorting,
        filters: filters,
        searchValue: searchValue,
        ifShowFilteringRow: ifShowFilteringRow,
      };

      let defaultLayout: string | null = null;
      if (setasDefault) {
        defaultLayout = newLayoutName;
      } else if (newLayoutName === currentLayoutSettingValue?.defaultLayout) {
        defaultLayout = null;
      } else {
        defaultLayout = currentLayoutSettingValue?.defaultLayout
          ? currentLayoutSettingValue?.defaultLayout
          : null;
      }

      let postPutBodyValue: Layoutsetting;
      if (currentLayoutSettingValue?.layoutsList) {
        postPutBodyValue = currentLayoutSettingValue;
        postPutBodyValue.layoutsList[newLayoutName] = newLayoutProps;
        postPutBodyValue.defaultLayout = defaultLayout;
      } else {
        postPutBodyValue = {
          defaultLayout: defaultLayout,
          layoutsList: {
            [newLayoutName]: newLayoutProps,
          },
        };
      }

      if (pageSize && currentLayoutName === null) {
        setPostRowsPerPageDataRequestBody(
          JSON.stringify({
            name: layoutSettingName + "_RowsPerPage",
            value: pageSize,
          })
        );
      } else if (pageSize && currentLayoutName !== null) {
        setPostRowsPerPageDataRequestBody(
          JSON.stringify({
            name: layoutSettingName,
            value: JSON.stringify(postPutBodyValue),
          })
        );
      } else {
        setPreferencesSaved(false);
        setPostRequestBody(
          JSON.stringify({
            name: layoutSettingName,
            value: JSON.stringify(postPutBodyValue),
          })
        );
      }
    };

    let ifWorkerTimeId = "";
    if (authUser.currentProfile?.type === ProfileType.WORKER_TIME) {
      ifWorkerTimeId = `/${authUser.currentProfile.subjectId}`;
    }

    const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
      path: `${
        profilesRoutesDictionary[authUser.currentProfile?.type]
      }${ifWorkerTimeId}/preferences`,
      method: "POST",
      body: postRequestBody,
      setBody: setPostRequestBody,
      contentType: "application/json",
      disableErrorSnackbar: true,
      disableSuccessSnackbar: true,
    });

    const successCallback = useCallback(() => {
      setIfUpdateSetting(true);
    }, []);

    const [, fetchAgainPostRowsPerPageData] = useFetchOtherThanGET({
      path: `${
        profilesRoutesDictionary[authUser.currentProfile?.type]
      }${ifWorkerTimeId}/preferences`,
      method: "POST",
      body: postRowsPerPageDataRequestBody,
      setBody: setPostRowsPerPageDataRequestBody,
      contentType: "application/json",
      disableErrorSnackbar: true,
      disableSuccessSnackbar: true,
      successCallback: successCallback,
    });

    useEffect(() => {
      if (postRequestBody) {
        fetchAgainPostData();
      }
    }, [postRequestBody, fetchAgainPostData]);

    useEffect(() => {
      if (postRowsPerPageDataRequestBody) {
        fetchAgainPostRowsPerPageData();
      }
    }, [postRowsPerPageDataRequestBody, fetchAgainPostRowsPerPageData]);

    useEffect(() => {
      if (
        !fetchingStatePostData.isFetching &&
        (fetchingStatePostData.response?.status === 200 ||
          fetchingStatePostData.response?.status === 201) &&
        !preferencesSaved
      ) {
        setSnackbarState([true, "success", t("layout_saved")]);
        setIfUpdateSetting(true);
        setPreferencesSaved(true);
      } else if (fetchingStatePostData.response?.status === 400) {
        setSnackbarState([
          true,
          "error",
          erro400getTranslatedErrorString(
            fetchingStatePostData.response.resJson.errors,
            t
          ),
        ]);
      } else if (fetchingStatePostData.response?.isError) {
        setSnackbarState([true, "error", t("something_went_wrong")]);
      }
    }, [
      fetchingStatePostData.response,
      fetchingStatePostData.isFetching,
      t,
      preferencesSaved,
    ]);

    const handleChangeCheckbox = (checkboxName) => {
      let hiddenColumnNamesLocal = [...hiddenColumnNames];
      let checkboxLocal = { ...checkbox };
      if (checkboxLocal[checkboxName]) {
        hiddenColumnNamesLocal.push(checkboxName);
      } else {
        let index = hiddenColumnNamesLocal.indexOf(checkboxName);
        if (index > -1) {
          hiddenColumnNamesLocal.splice(index, 1);
        }
      }
      checkboxLocal[checkboxName] = !checkboxLocal[checkboxName];
      setHiddenColumnNames(hiddenColumnNamesLocal);
      setCheckbox(checkboxLocal);
    };

    const columnVisibilityMenuOptions: {
      optionName: React.ReactElement;
      onClick: () => void;
    }[] = [];
    columns.forEach((element) => {
      if (!element.hide) {
        columnVisibilityMenuOptions.push({
          optionName: (
            <FormControlLabelStyled
              style={{
                pointerEvents: "none",
              }}
              control={
                <CheckboxStyled
                  checked={checkbox[element.name]}
                  name={element.name}
                />
              }
              label={<span className="chekbox">{element.title}</span>}
            />
          ),
          onClick: () => {
            handleChangeCheckbox(element.name);
          },
        });
      }
    });

    //////////////// export to excel /////////////////////////////
    const exporterRef: any = useRef(null);
    const startExcelExport = useCallback(() => {
      exporterRef.current.exportGrid();
    }, [exporterRef]);
    /////////

    ///////// pdf export

    const startPdfExport = (tableExportInfo: ITableExportInfo | undefined) => {
      const unit = "px";
      const size = "A2"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      let title = "";

      if (tableExportInfo) {
        if (tableExportInfo.dateFrom) {
          title += `Data od: ${moment(tableExportInfo.dateFrom).format(
            "YYYY-MM-DD HH:mm:ss"
          )}    `;
        }

        if (tableExportInfo.dateTo) {
          title += `Data do: ${moment(tableExportInfo.dateTo).format(
            "YYYY-MM-DD HH:mm:ss"
          )}    `;
        }

        if (tableExportInfo.createdBy) {
          title += `Utworzył: ${tableExportInfo.createdBy}    `;
        }
      }

      let filteredColumns = customPdfColumnsFilter
        ? customPdfColumnsFilter(columns)
        : columns;

      let headers: string[][] = [
        filteredColumns.map((column) => column.title ?? ""),
      ];

      let data: any[] = [];
      let tempRows = customPdfRowsFilter ? customPdfRowsFilter(rows) : rows;

      if (customPdfCellValue) {
        data = tempRows.map((row: any) => {
          return filteredColumns.map((column) => {
            return customPdfCellValue(row, column.name);
          });
        });
      } else {
        data = tempRows.map((row: any) => {
          return filteredColumns.map((column) => {
            return row[column.name];
          });
        });
      }

      let content = {
        startY: 50,
        head: headers,
        body: data,
      };
      doc.setFont("AbhayaLibre-Regular");
      doc.text(title, marginLeft, 40);
      autoTable(doc, {
        ...content,
        styles: { font: "AbhayaLibre-Regular", fontSize: 10 },
      });
      doc.save("report.pdf");

      /*const unit = "px";
      const size = "A2"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "";

      let filteredColumns = customPdfColumnsFilter
        ? customPdfColumnsFilter(columnOrder)
        : columnOrder;

      let headers: string[][] = [
        filteredColumns.map((columnName) => {
          let columnNameTranslated = columns.find(
            (column) => column.name === columnName
          )?.title;
          return columnNameTranslated ? columnNameTranslated : "";
        }),
      ];

      let data: any[] = [];
      let tempRows = customPdfRowsFilter ? customPdfRowsFilter(rows) : rows;

      if (customPdfCellValue) {
        data = tempRows.map((row: any) => {
          return filteredColumns.map((columnName) => {
            return customPdfCellValue(row, columnName);
          });
        });
      } else {
        data = tempRows.map((row: any) => {
          return filteredColumns.map((columnName, index) => {
            return row[columnName];
          });
        });
      }

      let content = {
        startY: 50,
        head: headers,
        body: data,
      };
      doc.setFont("AbhayaLibre-Regular");
      doc.text(title, marginLeft, 40);
      autoTable(doc, {
        ...content,
        styles: { font: "AbhayaLibre-Regular", fontSize: 10 },
      });
      doc.save("report.pdf");*/
    };

    useEffect(() => {
      if (ifSettingsUpdated) {
        setIfUpdateSetting(false);
      }
    }, [ifSettingsUpdated]);

    const canExpandAllRows = useCallback(() => {
      if (
        expandedRowIds?.length === 0 ||
        expandedRowIds?.length !== rows?.length
      ) {
        return true;
      }

      return false;
    }, [expandedRowIds?.length, rows?.length]);

    useEffect(() => {
      if (hiddenColumnsNames && !currentLayoutName) {
        setHiddenColumnNames(hiddenColumnsNames);

        for (let i = 0; i < hiddenColumnsNames.length; i++) {
          setCheckbox((prev) => {
            let newObj = { ...prev };
            newObj[hiddenColumnsNames[i]] = 0;
            return newObj;
          });
        }
      }
    }, [hiddenColumnsNames, currentLayoutName]);

    return (
      <div className="tableContainer">
        {!ifHideWholeToolbalr && (
          <div
            className="tableToolbar"
            style={
              backgroundColor ? { backgroundColor: backgroundColor } : undefined
            }
          >
            <div className="titleStyledBlockTable">
              <div className="tableTitle">{title}</div>
              <div className="tableToolbarFull">
                <div className="tableToolbarLeft">
                  {(ifAdd || actionsColumnUpdated?.addButton?.ifShow) &&
                    actionsColumnUpdated?.addButton?.showInToolbar && (
                      <div>
                        <Button
                          className="tableAddButton"
                          onClick={(event) => {
                            if (actionsColumnUpdated?.addButton?.ifShow) {
                              if (actionsColumnUpdated.addButton?.onClick) {
                                actionsColumnUpdated.addButton.onClick(event);
                              } else {
                                setPopupUpdatedState({
                                  isOpen: true,
                                });
                              }
                            } else if (ifAddCustomAction) ifAddCustomAction();
                          }}
                        >
                          <FontAwesomeIcon
                            className="faPlusTable"
                            icon={
                              actionsColumnUpdated?.addButton?.icon
                                ? actionsColumnUpdated.addButton.icon
                                : faPlus
                            }
                          />
                          &nbsp;{" "}
                          {actionsColumnUpdated?.addButton?.name ?? t("add")}
                        </Button>
                      </div>
                    )}
                  {actionsColumnUpdated?.leftToolbarComponent?.ifShow &&
                  actionsColumnUpdated?.leftToolbarComponent.customComponent ? (
                    <div className="leftToolbarCustomComponent">
                      {actionsColumnUpdated?.leftToolbarComponent?.customComponent()}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {hideActionsAndVisibilityColumns === undefined && (
                  <div className="tableToolbarRight">
                    {toolbarComponent && <>{toolbarComponent}</>}
                    {refreshingButton?.ifShow && (
                      <div className="container">
                        {!refreshingButton.onlyRefreshNow ? (
                          <RefreshButtonMenu
                            refreshingTime={refreshingButton?.refreshingTime}
                            handleChangeRefreshingTime={
                              refreshingButton?.handleChangeRefreshingTime
                            }
                          />
                        ) : (
                          <div>
                            <ButtonStyled
                              variant="outlined"
                              onClick={() => {
                                if (refreshingButton.onClick)
                                  refreshingButton.onClick();
                              }}
                            >
                              <FontAwesomeIcon icon={faRedoAlt} />
                              {t("refresh")}
                            </ButtonStyled>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="container">
                      <SelectMenu
                        name={t("columns_visibility")}
                        variant="outlined"
                        ifNotCloseAfterSelectDisabled={true}
                        items={columnVisibilityMenuOptions}
                      />
                    </div>
                    <Suspense fallback={<CircularProgress size={20} />}>
                      <NewLayoutNamePopup
                        open={newLayoutNameIsOpenPopup}
                        setOpen={setNewLayoutNameIsOpenPopup}
                        saveLayout={saveLayout}
                        newLayoutName={newLayoutName}
                        setNewLayoutName={setNewLayoutName}
                        setsetasDefault={setsetasDefault}
                        setasDefault={setasDefault}
                      />
                    </Suspense>
                    <div className="saveLayoutButton">
                      {!fetchingStatePostData.isFetching ? (
                        <SelectMenu
                          name={t("actions")}
                          variant="outlined"
                          items={[
                            {
                              optionName: t("save_the_layout"),
                              onClick: () => {
                                setNewLayoutNameIsOpenPopup(true);
                              },
                            },
                            {
                              optionName: ifShowFilteringRow
                                ? t("hide_filtering_row")
                                : t("show_filtering_row"),
                              onClick: () => {
                                setifShowFilteringRow(!ifShowFilteringRow);
                              },
                            },
                            {
                              optionName: t("export_pdf"),
                              onClick: () => {
                                startPdfExport(tableExportInfo);
                              },
                            },
                            {
                              optionName: t("export_excel"),
                              onClick: () => {
                                startExcelExport();
                              },
                            },

                            {
                              optionName: canExpandAllRows()
                                ? t("expand_rows")
                                : t("collapse_rows"),
                              onClick: () => {
                                if (canExpandAllRows()) {
                                  let tempRows: any = rows;
                                  setExpandedRowIds(
                                    tempRows.map((el) => el.id)
                                  );
                                } else {
                                  setExpandedRowIds([]);
                                }
                              },
                            },
                          ]}
                        />
                      ) : (
                        <div className="confirmWaiting">
                          <CircularProgress size={22} />
                        </div>
                      )}
                    </div>
                    <div className="saveLayoutButton">
                      {!fetchingStatePostData.isFetching ? (
                        <SelectMenu
                          name={
                            currentLayoutName === null
                              ? t("initial_layout")
                              : currentLayoutName
                          }
                          variant="outlined"
                          items={selectLayoutItems}
                        />
                      ) : (
                        <div className="confirmWaiting">
                          <CircularProgress size={22} />
                        </div>
                      )}
                    </div>
                    {externalSearchValue === undefined && (
                      <div className="saveLayoutButton">
                        <Input
                          placeholder={t("search")}
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          startAdornment={
                            <InputAdornment position="start">
                              <FontAwesomeIcon
                                className={"tableSearchIcon"}
                                icon={faSearch}
                              />
                            </InputAdornment>
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {actionsColumnUpdated?.popup &&
          actionsColumnUpdated.popup({
            successCallback: actionsColumnUpdated?.successCallbackPopup,
            successCallbackAdd:
              actionsColumnUpdated?.addButton?.successCallbackAdd,
            successCallbackEdit:
              actionsColumnUpdated?.editButton?.successCallbackEdit,
            closePopup: closePopup,
            isOpen: popupUpdatedState.isOpen,
            rowId: popupUpdatedState?.rowData?.id,
            row: popupUpdatedState?.rowData,
            extraInfo: actionsColumnUpdated?.extraInfo,
          })}
        <div>
          {ifSettingAppliedOnInitial && (
            <Grid
              rows={fetchingState?.isFetching ? [] : rows}
              columns={columns}
              getRowId={getRowId}
              rootComponent={GridRootComponent}
            >
              <DateTypeProvider for={dateColumns} />
              <CustomFormatterTypeProvider for={customFormatterColumns} />
              {externalSearchValue ? (
                <SearchState
                  value={externalSearchValue}
                  onValueChange={setExternalSearchValue}
                />
              ) : (
                <SearchState
                  value={searchValue}
                  onValueChange={setSearchValue}
                />
              )}
              <SearchState value={searchValue} onValueChange={setSearchValue} />
              <SortingState sorting={sorting} onSortingChange={setSorting} />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              {ifShowFilteringRow && (
                <FilteringState
                  filters={filters}
                  onFiltersChange={setFilters}
                  {...(filteringStateColumnExtensions
                    ? { columnExtensions: filteringStateColumnExtensions }
                    : undefined)}
                />
              )}
              {grouping && (
                <GroupingState
                  grouping={grouping}
                  {...(expandedGroups && { expandedGroups: expandedGroups })}
                  {...(expandedGroups && {
                    onExpandedGroupsChange: setExpandedGroups,
                  })}
                />
              )}
              {grouping && <IntegratedGrouping />}
              <IntegratedFiltering />
              <SelectionState
                selection={selectedData}
                onSelectionChange={selectData}
              />
              <IntegratedSelection />

              {disablePagination !== true && (
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={(page) => {
                    setCurrentPage(page);
                    setExpandedRowIds([]);
                  }}
                  pageSize={pageSizeLocal}
                  onPageSizeChange={(value) => {
                    setPageSizeLocal(value);
                    saveLayout(value);
                  }}
                />
              )}

              {disablePagination !== true && <IntegratedPaging />}

              {disablePagination !== true && (
                <PagingPanel
                  pageSizes={pageSizes}
                  messages={pagingPanelMessages}
                />
              )}

              <EditingState onCommitChanges={commitChanges} />
              <DragDropProvider />

              {customRow !== undefined ? (
                <Table
                  columnExtensions={columnsWidth ? columnsWidth : undefined}
                  cellComponent={TableCellComponentFinal}
                  {...(rowComponent
                    ? { rowComponent: rowComponent(expandedRowIds) }
                    : undefined)}
                  messages={noDataCell}
                  rowComponent={customRow}
                />
              ) : (
                <Table
                  columnExtensions={columnsWidth ? columnsWidth : undefined}
                  cellComponent={TableCellComponentFinal}
                  {...(rowComponent
                    ? { rowComponent: rowComponent(expandedRowIds) }
                    : undefined)}
                  messages={noDataCell}
                />
              )}

              <TableColumnVisibility
                hiddenColumnNames={
                  hiddenColumnNames ? hiddenColumnNames : hiddenColumnsNames
                }
                onHiddenColumnNamesChange={setHiddenColumnNames}
                messages={{
                  noColumns: "",
                }}
              />

              <TableColumnReordering
                order={columnOrder}
                onOrderChange={setColumnOrder}
              />
              <TableHeaderRow
                showSortingControls
                sortLabelComponent={LabelHeaderTooltip}
                rowComponent={RowHeaderComponent}
                // contentComponent={RowHeaderContentComponent}
                messages={{
                  sortingHint: undefined,
                }}
              />
              {/* action column old version */}
              {(ifAdd || ifEdit || ifDelete) &&
                actionsColumnUpdated === undefined && (
                  <TableEditColumn
                    showAddCommand={ifAdd}
                    showEditCommand={ifEdit}
                    showDeleteCommand={ifDelete}
                    width={tableEditColumnWidth ? tableEditColumnWidth : "auto"}
                    messages={editColumnMessages}
                    headerCellComponent={Command}
                    {...(actionColumn
                      ? { cellComponent: actionColumn }
                      : undefined)}
                    commandComponent={(props): any => {
                      return (
                        <>
                          {props.id === "edit" && (
                            <MaterialUIButton
                              style={{
                                maxWidth: "30px",
                                maxHeight: "30px",
                                minWidth: "30px",
                              }}
                              onClick={() => {
                                props.onExecute();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                title={t("edit")}
                                className="editIcon"
                              />
                            </MaterialUIButton>
                          )}

                          {props.id === "delete" && (
                            <MaterialUIButton
                              style={{
                                maxWidth: "30px",
                                maxHeight: "30px",
                                minWidth: "30px",
                                minHeight: "30px",
                              }}
                              onClick={() => {
                                props.onExecute();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                size="lg"
                                title={t("delete")}
                                className="deleteIcon"
                              />
                            </MaterialUIButton>
                          )}
                        </>
                      );
                    }}
                  />
                )}
              {/* action column new version */}
              {actionsColumnUpdated !== undefined && (
                <TableEditColumn
                  width={"auto"}
                  messages={editColumnMessages}
                  headerCellComponent={Command}
                  cellComponent={(props): any => {
                    return (
                      <ConfirmRemoveActions
                        actionsData={actionsColumnUpdated}
                        setPopupUpdatedState={setPopupUpdatedState}
                        rowMenu={rowMenu}
                        paddingValue={paddingConfirmRemoveActions}
                        {...props}
                      />
                    );
                  }}
                />
              )}
              <RowDetailState
                expandedRowIds={expandedRowIds}
                onExpandedRowIdsChange={setExpandedRowIds}
              />

              {rowDetail && <TableRowDetail contentComponent={rowDetail} />}

              {selectData && (
                <TableSelection
                  showSelectAll={hideSelectDataCondition === undefined}
                  cellComponent={cellComponentTableSelection}
                  headerCellComponent={headerCellComponentTableSelection}
                />
              )}
              {popup && (
                <Suspense fallback={<CircularProgress size={20} />}>
                  <PopupEditing
                    popupComponent={popup}
                    setOpenPopup={setOpenPopupLocal}
                    openPopup={openPopupLocal}
                    history={history}
                    popupExtraIndicator={popupExtraIndicator}
                    popupAdditionalParams={popupAdditionalParams}
                  />
                </Suspense>
              )}
              {/* //////// summary rows */}
              {/* {summaryRowProps?.customFormater && (
                <DataTypeProvider
                  for={summaryRowProps.customFormater.columnsName}
                  formatterComponent={summaryRowProps.customFormater.formater}
                />
              )} */}
              {summaryRowProps && (
                <SummaryState
                  totalItems={
                    summaryRowProps?.totalItems
                      ? summaryRowProps.totalItems
                      : undefined
                  }
                  groupItems={
                    summaryRowProps?.groupItems
                      ? summaryRowProps.groupItems
                      : undefined
                  }
                />
              )}
              {summaryRowProps && (
                <IntegratedSummary
                  calculator={
                    summaryRowProps?.calculator
                      ? summaryRowProps.calculator
                      : undefined
                  }
                />
              )}
              {summaryRowProps && (
                <TableSummaryRow
                  itemComponent={
                    summaryRowProps?.customItem
                      ? summaryRowProps.customItem
                      : undefined
                  }
                />
              )}
              {grouping && (
                <TableGroupRow
                  {...(groupCellContent && {
                    contentComponent: groupCellContent,
                  })}
                  {...(inlineSummaryItemComponent && {
                    inlineSummaryItemComponent: inlineSummaryItemComponent,
                  })}
                  showColumnsWhenGrouped={true}
                />
              )}
              {ifShowFilteringRow && (
                <TableFilterRow
                  showFilterSelector
                  messages={filterRowMessages}
                  cellComponent={CustomTableFilterRowCell}
                />
              )}

              <TableFixedColumns
                leftColumns={
                  fixedLeftColumnsLocal ? fixedLeftColumnsLocal : undefined
                }
                rightColumns={rightColumns ? rightColumns : undefined}
              />
            </Grid>
          )}
        </div>
        {(fetchingState?.isFetching || fetchingState?.isError) && (
          <div className="loadingBarTable">
            <LoadingWrapper
              isLodadingProgress={fetchingState.isFetching}
              isError={fetchingState.isError}
              setIfFetchAgain={fetchingState.fetchAgain}
            >
              <></>
            </LoadingWrapper>
          </div>
        )}
        <GridExporter
          ref={exporterRef}
          rows={customExcelRowsFilter ? customExcelRowsFilter(rows) : rows}
          columns={
            customExcelColumnsFilter
              ? customExcelColumnsFilter(columns)
              : columns
          }
          onSave={(workbook) => onSave(workbook, tableExportInfo)}
          getCellValue={customExcelCellValue}
        />
        <SnackbarStyled
          setSnackbarState={setSnackbarState}
          snackbarState={snackbarState}
        />
      </div>
    );
  },
  (prevProps, nextProps): any => {
    if (!(prevProps.selectedData === nextProps.selectedData)) return false;
    if (!(prevProps.toolbarComponent === nextProps.toolbarComponent))
      return false;
    if (!(prevProps.columns === nextProps.columns)) return false;
    if (!(prevProps.rows === nextProps.rows)) return false;
    if (!(prevProps.tableCellComponent === nextProps.tableCellComponent))
      return false;
    if (!(prevProps.fetchingState === nextProps.fetchingState)) return false;
    if (!(prevProps.expandedGroupsDefault === nextProps.expandedGroupsDefault))
      return false;
    if (!(prevProps.actionsColumnUpdated === nextProps.actionsColumnUpdated))
      return false;

    return true;
  }
);

export default TableComponentMemo;
