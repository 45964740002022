import { useCallback, useEffect, useState } from "react";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import Row from "../../../../helpersComponents/PopupHelpers/row";
import RowInput from "../../../../helpersComponents/PopupHelpers/rowInput";
import RowTitle from "../../../../helpersComponents/PopupHelpers/rowTitle";
import TwoColumnsRowsContainer from "../../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import erro400getTranslatedErrorString from "../../../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import UploadFileFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/UploadFileField/uploadFileField";
import { smsSupportedImagesFormats } from "../../../../../Constants/smsSupportedImagesFormats";

const AddEditImagePopupContent: React.FC<any> = ({
  rowId,
  control,
  register,
  setValue,
  getValues,
  setErrorMessage,
  successCallback,
  handleSubmit,
  setIsFetching,
  watch,
  clearErrors,
}) => {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<any>(false);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
  }, [successCallback]);

  const [image, fetchingStateImage, fetchAgainImage] = useFetchAndSetGET({
    path: `sms-administrator/images-library/${rowId}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (rowId) {
      fetchAgainImage();
    }
  }, [rowId, fetchAgainImage]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t, setErrorMessage]
  );

  const [fetchingStateAddEditImage, fetchAgainAddEditImage] =
    useFetchOtherThanGET({
      path: `sms-administrator/images-library${rowId ? "/" + rowId : ""}`,
      method: rowId ? "PUT" : "POST",
      body: bodyRequest,
      setBody: setBodyRequest,
      successCallback: successCallbackAndClose,
      errorCallback,
      disableErrorSnackbar: true,
    });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditImage();
    }
  }, [bodyRequest, fetchAgainAddEditImage]);

  useEffect(() => {
    setIsFetching(fetchingStateAddEditImage.isFetching);
  }, [fetchingStateAddEditImage, setIsFetching]);

  const onSubmit = (data: any) => {
    let jsonDataToSend: any = {
      name: data.name,
      group: data.group,
      imageWidth: parseInt(data.imageWidth) || 0,
      imageHeight: parseInt(data.imageHeight) || 0,
      isVectorImage: data.isVectorImage,
    };

    let formData = new FormData();
    formData.append("requestImageData", JSON.stringify(jsonDataToSend));

    if (data.image) {
      formData.append("requestImage", data.image[0]);
    }

    setBodyRequest(formData);
  };

  useEffect(() => {
    if (
      rowId &&
      !fetchingStateImage.isFetching &&
      fetchingStateImage.response?.status === 200
    ) {
      setValue("name", image.name);
      setValue("group", image.group);

      if (image.fileName && image.fileSize > 0) {
        setValue("image", [
          {
            name: image.fileName,
            size: image.fileSize,
          },
        ]);

        setValue("imageWidth", image.imageWidth);
        setValue("imageHeight", image.imageHeight);
        setValue("isVectorImage", image.isVectorImage);
      }
    }
  }, [
    fetchingStateImage.isFetching,
    fetchingStateImage.response?.status,
    rowId,
    setValue,
    image?.name,
    image?.group,
    image?.fileName,
    image?.fileSize,
    image?.imageWidth,
    image?.imageHeight,
    image?.isVectorImage,
  ]);

  let watchedFile = watch(`image`);

  useEffect(() => {
    if (!watchedFile || (watchedFile && watchedFile.length === 0)) {
      setValue("imageWidth", 0);
      setValue("imageHeight", 0);
      setValue("isVectorImage", false);
    }
  }, [watchedFile, setValue]);

  useEffect(() => {
    const handleFileChange = async (file) => {
      clearErrors("image");

      if (file) {
        setValue("imageWidth", 0);
        setValue("imageHeight", 0);

        const reader = new FileReader();

        const processSVG = (text) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, "image/svg+xml");
          const svgElement = doc.getElementsByTagName("svg")[0];

          if (svgElement) {
            setValue("isVectorImage", true);
            setValue("imageWidth", 0);
            setValue("imageHeight", 0);
          } else {
            setValue("isVectorImage", false);
          }
        };

        const processRasterImage = (dataURL) => {
          const img = new Image();
          img.onload = () => {
            setValue("isVectorImage", false);
            setValue("imageWidth", img.width);
            setValue("imageHeight", img.height);
          };
          img.onerror = () => {
            setValue("isVectorImage", false);
          };
          img.src = dataURL;
        };

        reader.onload = (e) => {
          const fileType = file.type;
          if (!e.target) return;

          if (fileType === "image/svg+xml") {
            processSVG(e.target.result);
          } else if (fileType.startsWith("image/")) {
            processRasterImage(e.target.result);
          } else {
            setValue("isVectorImage", false);
          }
        };

        reader.onerror = () => {
          setValue("isVectorImage", false);
        };

        if (file.type === "image/svg+xml") {
          reader.readAsText(file);
        } else {
          reader.readAsDataURL(file);
        }
      } else {
        setValue("isVectorImage", false);
      }
    };

    if (watchedFile && watchedFile[0] && watchedFile[0].lastModified) {
      handleFileChange(watchedFile[0]);
    }
  }, [watchedFile, setValue, clearErrors]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStateImage.isFetching}
        isError={fetchingStateImage.isError}
        setIfFetchAgain={() => {
          if (fetchingStateImage.isError) {
            fetchAgainImage();
          }
        }}
      >
        <form
          key={"add_image_to_library_form"}
          onSubmit={handleSubmit(onSubmit)}
          id="submitForm"
          noValidate
        >
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("name")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"name"}
                  control={control}
                  label={t("image_name")}
                  required={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("group")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"group"}
                  control={control}
                  label={t("image_group")}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("file")}:</RowTitle>
              <RowInput>
                <UploadFileFieldReactHookForm
                  id="image"
                  register={register}
                  name={"image"}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  required={true}
                  accept={smsSupportedImagesFormats.join(",")}
                  watch={watch}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          {watch("isVectorImage") && (
            <>
              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle width={"100px"}>{t("width")}:</RowTitle>
                  <RowInput>
                    <TextFieldReactHookForm
                      name={"imageWidth"}
                      control={control}
                      label={t("image_width")}
                      type={"number"}
                      required={true}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle width={"100px"}>{t("height")}:</RowTitle>
                  <RowInput>
                    <TextFieldReactHookForm
                      name={"imageHeight"}
                      control={control}
                      label={t("image_height")}
                      type={"number"}
                      required={true}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>
            </>
          )}
        </form>
      </LoadingWrapper>
    </>
  );
};

export default AddEditImagePopupContent;
