import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import Button from "../../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import styles from "./operationsLogs.module.scss";
import { AutocompleteStyled } from "../../../helpersComponents/MaterialUi";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { ProfileType } from "../../../../enums/profileType";
import moment from "moment";

const OperationsLogsContent = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const currentProfile = useAppSelector(selectAuthUser).currentProfile;

  const columns = [
    { name: "timeSuperior", title: t("created") },
    { name: "time", title: t("data_created"), type: "date_hours" },
    { name: "typeName", title: t("type") },
    { name: "description", title: t("description") },
    { name: "subjectId", title: t("subject") },
  ];

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Month")
  );
  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Month"));
  const [selectedLogType, setSelectedLogType] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const [operationsLogsTypes] = useFetchAndSetGET({
    path: `superior-time/operations-logs-types`,
    startFetchOnInitial: true,
  });

  const [users] = useFetchAndSetGET<ITimeSuperiorBasicInfo[]>({
    path: `superior-time/time-superiors`,
    startFetchOnInitial: true,
  });

  const [
    operationsLogs,
    fetchingStateOperationsLogs,
    fetchAgainOperationsLogs,
  ] = useFetchAndSetGET({
    path:
      `superior-time/operations-logs?dateFrom=` +
      computeDateRangeFetch(startDate) +
      "&dateTo=" +
      computeDateRangeFetch(endDate) +
      (selectedLogType?.id ? `&typeId=${selectedLogType.id.toString()}` : "") +
      (selectedUser?.id ? `&timeSuperiorId=${selectedUser.id.toString()}` : ""),
    startFetchOnInitial: true,
  });

  const confirmFilters = useCallback(() => {
    fetchAgainOperationsLogs();
  }, [fetchAgainOperationsLogs]);

  const confirmButtonRight = useCallback(() => {
    return (
      <div className={styles.filterMainContainer}>
        <div className={styles.autocomplete}>
          <div>{t("type")}</div>
          <div className={styles.autocompleteInput}>
            <AutocompleteStyled
              value={selectedLogType || null}
              required={false}
              label={t("select_type")}
              options={operationsLogsTypes}
              getOptionLabel={(el) => el.name}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(_, newValue) => {
                setSelectedLogType(newValue);
              }}
              darkTheme={true}
              width={200}
            />
          </div>
        </div>

        {currentProfile.type === ProfileType.SUPERIOR_TIME &&
          (currentProfile.permissionId === 1 ||
            currentProfile.permissionId === 2) && (
            <div className={styles.autocomplete}>
              <div>{t("user")}</div>
              <div className={styles.autocompleteInput}>
                <AutocompleteStyled
                  value={selectedUser || null}
                  required={false}
                  label={t("select_user")}
                  options={users}
                  getOptionLabel={(el) => (el.fullName ? el.fullName : el.name)}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(_, newValue) => {
                    setSelectedUser(newValue);
                  }}
                  darkTheme={true}
                  width={200}
                />
              </div>
            </div>
          )}

        <div className={styles.buttonContainer}>
          <div className={styles.getOperationsLogsButton}>
            {fetchingStateOperationsLogs.isFetching ? (
              <CircularProgress size={25} />
            ) : (
              <Button
                onClick={() => {
                  confirmFilters();
                }}
              >
                {t("get_logs")}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }, [
    confirmFilters,
    fetchingStateOperationsLogs.isFetching,
    operationsLogsTypes,
    selectedLogType,
    selectedUser,
    t,
    users,
    currentProfile.permissionId,
    currentProfile.type,
  ]);

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "time") {
      return row.time ? moment(row.time).format("YYYY-MM-DD HH:mm:ss") : "";
    }

    return row[columnName];
  }, []);

  return (
    <div>
      <TopBanner pathName={t("operations_logs")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            keyboardDateTimePicker={true}
            darkTheme={true}
            confirmButtonRight={confirmButtonRight()}
            setDate2={setEndDate}
          ></DatePickerRange>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStateOperationsLogs.isFetching}
            isError={fetchingStateOperationsLogs.isError}
            setIfFetchAgain={() => {
              fetchAgainOperationsLogs();
            }}
          >
            {!fetchingStateOperationsLogs.isFetching && (
              <TableComponent
                layoutSettingName="layoutTimeSuperiorOperationsLogs"
                rows={operationsLogs || []}
                columns={columns}
                customExcelCellValue={customExportCellValue}
                customPdfCellValue={customExportCellValue}
                tableExportInfo={{
                  dateFrom: startDate,
                  dateTo: endDate,
                  createdBy: authUser.displayedName,
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default OperationsLogsContent;
