import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useRefreshData from "../../../../hooks/useRefreshData/useRefreshData";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import styles from "../styles.module.scss";
import moment from "moment";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";

const LocationEmployees = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const columns = [
    { name: "acWorker", title: t("employee") },
    { name: "acWorkerRegistrationNumber", title: t("evidence_number") },
    { name: "location", title: t("location") },
    { name: "enterTime", title: t("time"), type: "date_hours" },
  ];

  const locationOfEmployeesResponseCallback = useCallback(
    (responseData: ACWorkerSector[]) => {
      const modified = responseData?.map(
        (row: ACWorkerSector, index: number) => {
          return {
            ...row,
            id: index,
          };
        }
      );
      return modified;
    },
    []
  );

  const [
    locationOfEmployees,
    fetchingStateLocationOfEmployees,
    fetchAgainLocationOfEmployees,
  ] = useFetchAndSetGET<ACWorkerSector[]>({
    path: `superior-ac/ac-worker-sectors`,
    modifyResponseCallback: locationOfEmployeesResponseCallback,
  });

  const { refreshingTime, handleChangeRefreshingTime } = useRefreshData(
    fetchAgainLocationOfEmployees
  );

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "enterTime") {
      return row.enterTime
        ? moment(row.enterTime).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    return row[columnName];
  }, []);

  return (
    <>
      <div className={styles.contentTitleContainerBlockPaper}>
        <div className={styles.headerContainer}>
          {t("recent_locations_employees")}
        </div>
      </div>
      <div className={styles.contentValueContainerBlockPaper}>
        <TableComponentMemo
          columns={columns}
          rows={locationOfEmployees}
          layoutSettingName="layoutACSuperiorLocationOfEmployees"
          fetchingState={{
            fetchAgain: fetchAgainLocationOfEmployees,
            isError: fetchingStateLocationOfEmployees.isError,
            isFetching: fetchingStateLocationOfEmployees.isFetching,
          }}
          backgroundColor="white"
          columnsWidth={[
            {
              columnName: "date",
              width: 200,
            },
          ]}
          refreshingButton={{
            ifShow: true,
            refreshingTime: refreshingTime?.value,
            handleChangeRefreshingTime,
          }}
          filteringRowDefaultHidden={true}
          customExcelCellValue={customExportCellValue}
          customPdfCellValue={customExportCellValue}
          tableExportInfo={{
            createdBy: authUser.displayedName,
          }}
        />
      </div>
    </>
  );
};

export default LocationEmployees;
