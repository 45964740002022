import VisualizationObjectValuesItem from "./VisualizationObjectValuesItem";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../../helpersComponents/Button/button";
import styles from "./VisualizationObjectValues.module.scss";
import { v4 as uuidv4 } from "uuid";

const VisualizationObjectValues = ({ fields, append, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.valuesContainer}>
      <div>
        <ButtonStyled
          type="button"
          onClick={() => {
            append({
              virtualId: uuidv4(),
              name: "",
              topic: "",
              condition: "",
              property: "",
            });
          }}
        >
          {t("add")}
        </ButtonStyled>
      </div>

      <div className={styles.valuesItemsContainer}>
        {fields.map((field, index) => (
          <VisualizationObjectValuesItem
            id={field.id}
            key={field.id}
            fields={fields}
            index={index}
            {...props}
          />
        ))}
      </div>
    </div>
  );
};

export default VisualizationObjectValues;
