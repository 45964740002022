import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./RequestApprovalsDetails.module.scss";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import { useTranslation } from "react-i18next";
import RequestStatusName from "../../../../HelpersFunctions/requestStatusName";

const RequestApprovalsDetails = ({ row }) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <b>{t("approvals")}</b>
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {row?.approvals?.length === 0 && <span>{t("none").toUpperCase()}</span>}

        <div className={styles["grid-container"]}>
          {row?.approvals.map((el) => (
            <>
              <div className={styles["grid-item"]}>
                <b>{t("approval_level")}:</b>
              </div>

              <div className={styles["grid-item"]}>
                {el.approvalLevel > 0
                  ? el.approvalLevel
                  : RequestStatusName(el.approvalLevel * -1)}
              </div>

              <div className={styles["grid-item"]}>
                <b>{t("approved_by")}:</b>
              </div>
              <div className={styles["grid-item"]}>{el.approverName}</div>

              <div className={styles["grid-item"]}>
                <b>{t("approved_date")}:</b>
              </div>
              <div className={styles["grid-item"]}>
                {toLocaleStringCustom({
                  date: el.approvalDate,
                  t,
                  ifShowHours: true,
                })}
              </div>
            </>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default RequestApprovalsDetails;
