import {
    OutlinedInput,
    InputAdornment,
    withStyles,
  } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ChangeEventHandler, FocusEventHandler } from "react";
import { useTranslation } from "react-i18next";

const StyledOutlinedInput = withStyles({
  root: {
    backgroundColor: "#f3f3f4",
    borderRadius: "7px",
    height: "35px",
    marginLeft: "15px",

    '& $notchedOutline': {
      borderColor: "#ddd",
      
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: "#aaa",
    },
    '&$focused $notchedOutline': {
      borderColor: "#bbb",
      
    }
  },
  input: {
    margin: "0 !important",
  },
  /* Styles applied to the root element if the component is focused. */
  focused: {},
  /* Styles applied to the root element if `disabled={true}`. */
  disabled: {},
  /* Styles applied to the root element if `error={true}`. */
  error: {},
  /* Styles applied to the `NotchedOutline` element. */
  notchedOutline: {},
})(OutlinedInput);

type SearchBarProps = {
    value?: unknown;
    onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

function WorkerSearchBar(props: SearchBarProps ) {
  const { t } = useTranslation();
  return (
    <StyledOutlinedInput 
      {...props}
      placeholder={t("search")}
      margin="dense"
      startAdornment={
        <InputAdornment position="start">
          <FontAwesomeIcon
            className={"tableSearchIcon"}
            icon={faSearch}
          />
        </InputAdornment>
      }
    />
  )
}

export default WorkerSearchBar