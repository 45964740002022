import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./NotificationRowDetail.module.scss";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";

type RowType = {
  guestFirstName?: string;
  guestLastName?: string;
  arrivalDateString?: string;
  announces?: string;
  announcesEmail?: string;
  parkingName?: string;
  isProvisioningString?: string;
  arrivalString?: string;
  attention?: string;
  description?: string;
  comments?: string;
  createdAtString?: string;
  approvedAtString?: string;
  carRegistrationNumber?: string;
  approverName?: string;
  meetingApprovalString?: string;
  guardianPhoneNumber?: string;
  purposeOfVisit?: string;
  importedExportedItems?: string;
  visitationObject?: string;
  dateToString?: string;
  typeOfVisit?: string;
};

type NotificationRowDetailProps = {
  row: RowType;
};

const NotificationRowDetail: React.FC<NotificationRowDetailProps> = ({
  row,
}) => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const getData = useCallback(() => {
    const dataToDisplay = [
      {
        label: t("guest_name"),
        value: row?.guestFirstName ? row.guestFirstName : "—",
      },
      {
        label: t("guest_surname"),
        value: row?.guestLastName ? row.guestLastName : "—",
      },
      {
        label: t("date_of_arrival"),
        value: row?.arrivalDateString ? row.arrivalDateString : "—",
      },
      {
        label: t("type_of_visit"),
        value: row.typeOfVisit ? row.typeOfVisit : "—",
      },
      { label: t("for_employee"), value: row?.announces ? row.announces : "—" },
      {
        label: t("email"),
        value: row.announcesEmail ? row.announcesEmail : "—",
      },
      {
        label: t("parking_lot"),
        value: row?.parkingName ? row.parkingName : "—",
      },
      {
        label: t("prepare_meal"),
        value: row?.isProvisioningString ? row.isProvisioningString : "—",
      },
      {
        label: t("arrival"),
        value: row?.arrivalString ? row.arrivalString : "—",
      },
      { label: t("comments"), value: row?.attention ? row.attention : "—" },
      {
        label: t("description"),
        value: row?.description ? row.description : "—",
      },
      { label: t("comment"), value: row?.comments ? row.comments : "—" },
      {
        label: t("was_added"),
        value: row?.createdAtString ? row.createdAtString : "—",
      },
      {
        label: t("approved"),
        value: row?.approvedAtString ? row.approvedAtString : "—",
      },
      {
        label: t("licence_place"),
        value: row?.carRegistrationNumber ? row.carRegistrationNumber : "—",
      },
      {
        label: t("approved_by"),
        value: row?.approverName ? row.approverName : "—",
      },
      {
        label: t("confirm_contact"),
        value: row?.meetingApprovalString ? row.meetingApprovalString : "—",
      },
      {
        label: t("guardian_phone"),
        value: row?.guardianPhoneNumber ? row.guardianPhoneNumber : "—",
      },
      {
        label: t("purpose_of_visit"),
        value: row?.purposeOfVisit ? row.purposeOfVisit : "—",
      },
      {
        label: t("imported_or_exported_items"),
        value: row?.importedExportedItems ? row.importedExportedItems : "—",
      },
      {
        label: t("visitation_object"),
        value: row?.visitationObject ? row.visitationObject : "—",
      },
    ];

    if (settings.WebAnonseUkryjDateDoAwizacji?.value !== "1") {
      dataToDisplay.splice(3, 0, {
        label: t("date_date_to"),
        value: row?.dateToString ? row.dateToString : "—",
      });
    }

    return dataToDisplay;
  }, [settings.WebAnonseUkryjDateDoAwizacji?.value, t, row]);

  const dataToDisplay = getData();

  return (
    <div className={styles["grid-container"]}>
      {dataToDisplay.map((el, index) => (
        <div key={index} className={styles["grid-item"]}>
          <div>
            <b>{el.label}:</b>
          </div>
          <div>{el.value}</div>
        </div>
      ))}
    </div>
  );
};

export default NotificationRowDetail;
