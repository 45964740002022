import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import SMSVisualizationObjectLayerType from "../../enums/SMS/SMSVisualizationObjectLayerType";
import { i18n } from "./../../lang/i18n";
import SMSVisualizationObjectActionType from "../../enums/SMS/SMSVisualizationObjectActionType";

interface stateInterface {
  visualizationObjectsLayersTypes: ISMSVisualizationObjectLayerType[];
  visualizationObjectsBuiltInStates: ISelectListItem[];
  visualizasionObjectsActionsTypes: ISMSVisualizationObjectActionType[];
  textFonts: ITextFont[];
  events: ISMSEvent[];
  isMQTTClientConnected?: boolean;
  currentMapId?: number | null;
  mapTargetLocation: [number, number, number] | null;
}

const initialState: stateInterface = {
  isMQTTClientConnected: undefined,
  visualizationObjectsLayersTypes: [
    {
      id: SMSVisualizationObjectLayerType.IMAGE_LAYER,
      name: i18n.t("image_layer"),
    },
    {
      id: SMSVisualizationObjectLayerType.GRAPH_LAYER,
      name: i18n.t("graph_layer"),
    },
    {
      id: SMSVisualizationObjectLayerType.TEXT_LAYER,
      name: i18n.t("text_layer"),
    },
  ],
  visualizationObjectsBuiltInStates: [
    { id: -1, name: i18n.t("builtin_state_object_unavailable") },
  ],
  visualizasionObjectsActionsTypes: [
    { id: SMSVisualizationObjectActionType.MQTT_ACTION, name: "MQTT" },
    { id: SMSVisualizationObjectActionType.LINK_ACTION, name: "LINK" },
  ],
  textFonts: [
    { id: 0, name: "Arial" },
    { id: 1, name: "Helvetica" },
    { id: 2, name: "Tahoma" },
    { id: 3, name: "Verdana" },
    { id: 4, name: "Georgia" },
    { id: 5, name: "Times New Roman" },
    { id: 6, name: "Courier New" },
    { id: 7, name: "Lucida Console" },
  ],

  events: [],
  currentMapId: null,
  mapTargetLocation: null,
};

export const visualizationDataReducer = createSlice({
  name: "visualizationData",
  initialState,
  reducers: {
    setEvents: (state, action: PayloadAction<any>) => {
      state.events = action.payload;
    },
    setIsMQTTClientConnected: (state, action: PayloadAction<any>) => {
      state.isMQTTClientConnected = action.payload;
    },
    setCurrentMapId: (state, action: PayloadAction<any>) => {
      state.currentMapId = action.payload;
    },
    setMapTargetLocation: (state, action: PayloadAction<any>) => {
      state.mapTargetLocation = action.payload;
    },
  },
});

export const selectVisualizationObjectsLayersTypes = (state: RootState) =>
  state.visualizationData.visualizationObjectsLayersTypes;

export const selectVisualizationObjectsBuiltInStates = (state: RootState) =>
  state.visualizationData.visualizationObjectsBuiltInStates;

export const selectVisualizationObjectsActionsTypes = (state: RootState) =>
  state.visualizationData.visualizasionObjectsActionsTypes;

export const selectTextFonts = (state: RootState) =>
  state.visualizationData.textFonts;

export const selectEvents = (state: RootState) =>
  state.visualizationData.events;

export const selectIsMQTTClientConnected = (state: RootState) =>
  state.visualizationData.isMQTTClientConnected;

export const selectCurrentMapId = (state: RootState) =>
  state.visualizationData.currentMapId;

export const selectMapTargetLocation = (state: RootState) =>
  state.visualizationData.mapTargetLocation;

export const {
  setEvents,
  setIsMQTTClientConnected,
  setCurrentMapId,
  setMapTargetLocation,
} = visualizationDataReducer.actions;
export default visualizationDataReducer.reducer;
