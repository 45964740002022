import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AddEditVisualizationObjectsGroupsCategoriesPopupContent from "./AddEditVisualizationObjectsGroupsCategoriesPopupContent";
import ButtonStyled from "../../../../helpersComponents/Button/button";
import styles from "./AddEditVisualizationObjectsGroupsCategoriesForm.module.scss";

const AddEditVisualizationObjectsGroupsCategoriesForm: React.FC<any> = ({
  successCallback,
}) => {
  const { t } = useTranslation();
  const [, setErrorMessage] = useState("");
  const [, setIsFetching] = useState(false);

  const schema = Yup.object({
    name: Yup.string().required(t("field_required")),
    order: Yup.string().required(t("field_required")),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: { name: "", order: "0" },
  });

  return (
    <div className={styles.addCategoryForm}>
      <AddEditVisualizationObjectsGroupsCategoriesPopupContent
        rowId={undefined}
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        successCallback={successCallback}
        setErrorMessage={setErrorMessage}
        handleSubmit={handleSubmit}
        setIsFetching={setIsFetching}
        watch={watch}
        disableSuccessSnackbar={true}
      />
      <ButtonStyled form="add-edit-visualization-object-category-form">
        {t("add_category")}
      </ButtonStyled>
    </div>
  );
};

export default AddEditVisualizationObjectsGroupsCategoriesForm;
