import { TFunction } from "react-i18next";
import * as Yup from "yup";
import { TypeOfVisit } from "../../../../Constants/typeOfVisitInPorts";
import { ProfileType } from "../../../../enums/profileType";
import yupIsValidDate from "../../../../HelpersFunctions/yupSchemes/isValidDate";

interface Params {
  t: TFunction<"translation">;
  authUserType: number | undefined;
  ifRequireCaptcha: boolean;
  EMPLOYEE_EMAIL_REQUIRED: boolean | undefined;
  values: any;
}

const getValidationSchema = ({
  t,
  authUserType,
  ifRequireCaptcha,
  EMPLOYEE_EMAIL_REQUIRED,
  values,
}: Params) => {
  return Yup.object({
    dateFrom: yupIsValidDate({ t }),
    dateTo: Yup.date().when("dateFrom", (dateFrom, schema) => {
      let dateFromCopy: any = new Date(dateFrom);
      if (
        dateFrom === "Invalid Date" ||
        dateFromCopy === "Invalid Date" ||
        dateFrom === null
      ) {
        return schema.typeError(t("invalid_date"));
      }
      dateFromCopy.setHours(0, 0, 0, 0);
      return (
        dateFrom &&
        schema
          .min(dateFromCopy, t("date_to_must_be_later_than_date_from"))
          .typeError(t("invalid_date"))
      );
    }),
    plateNumber: Yup.string(),
    isWorkPermitRequiredFalse:
      authUserType !== undefined
        ? Yup.bool().when(["isWorkPermitRequiredTrue", "typeOfVisit"], {
            is: (
              isWorkPermitRequiredTrue: boolean,
              typeOfVisit: TypeOfVisitInterface
            ) =>
              isWorkPermitRequiredTrue === false &&
              typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK,
            then: Yup.bool().isTrue(""),
            otherwise: Yup.bool(),
          })
        : Yup.bool(),
    isWorkParticularlyDangerousFalse:
      authUserType !== undefined
        ? Yup.bool().when(["isWorkParticularlyDangerousTrue", "typeOfVisit"], {
            is: (
              isWorkParticularlyDangerousTrue: boolean,
              typeOfVisit: TypeOfVisitInterface
            ) =>
              isWorkParticularlyDangerousTrue === false &&
              typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK,
            then: Yup.bool().isTrue(""),
            otherwise: Yup.bool(),
          })
        : Yup.bool(),
    company: Yup.string()
      .required(t("field_required"))
      .max(100, `${t("max_length_is")}100`),
    targetDepartment: Yup.object()
      .nullable()
      .test("isValidTargetDepartment", t("field_required"), function (value) {
        return EMPLOYEE_EMAIL_REQUIRED === undefined || !EMPLOYEE_EMAIL_REQUIRED
          ? Yup.object().nullable().required().isValid(value)
          : Yup.object().nullable().isValid(value);
      }),
    acSuperiorEmail: Yup.string()
      .test("isRequiredACSuperiorEmail", t("field_required"), function (value) {
        return EMPLOYEE_EMAIL_REQUIRED !== undefined &&
          EMPLOYEE_EMAIL_REQUIRED &&
          authUserType !== ProfileType.EMPLOYEE &&
          authUserType !== ProfileType.OHS &&
          authUserType !== ProfileType.OPERATIONAL_SUPPORT
          ? Yup.string().required().isValid(value)
          : Yup.string().isValid(value);
      })
      .test("isValidACSuperiorEmail", t("invalid_email"), function (value) {
        return EMPLOYEE_EMAIL_REQUIRED !== undefined &&
          EMPLOYEE_EMAIL_REQUIRED &&
          authUserType !== ProfileType.EMPLOYEE &&
          authUserType !== ProfileType.OHS &&
          authUserType !== ProfileType.OPERATIONAL_SUPPORT
          ? Yup.string().email().isValid(value)
          : Yup.string().isValid(value);
      }),
    acSuperiorPhoneOrRadio: Yup.string().test(
      "isRequiredACSuperiorPhoneOrRadio",
      t("field_required"),
      function (value) {
        return authUserType !== ProfileType.OPERATIONAL_SUPPORT &&
          EMPLOYEE_EMAIL_REQUIRED !== undefined &&
          EMPLOYEE_EMAIL_REQUIRED &&
          (authUserType === ProfileType.EMPLOYEE ||
            authUserType === ProfileType.OHS)
          ? Yup.string().required().isValid(value)
          : Yup.string().isValid(value);
      }
    ),
    typeOfVisit: Yup.object().nullable(false).typeError(t("field_required")),
    workArea: Yup.string().when(
      ["isWorkPermitRequiredTrue", "isWorkParticularlyDangerousTrue"],
      {
        is: (isWorkPermitRequiredTrue, isWorkParticularlyDangerousTrue) =>
          isWorkPermitRequiredTrue && isWorkParticularlyDangerousTrue,
        then: Yup.string().required(t("field_required")),
        otherwise: Yup.string(),
      }
    ),
    ...(ifRequireCaptcha
      ? {
          captcha: Yup.string().required(t("field_required")),
        }
      : {}),
    permissionZone: Yup.object()
      .nullable()
      .test("test", t("field_required"), function (value) {
        if (
          values?.typeOfVisit?.id !== TypeOfVisit.REVISION &&
          authUserType !== undefined &&
          authUserType !== ProfileType.OPERATIONAL_SUPPORT &&
          !value
        )
          return false;

        return true;
      }),
    guestsArray: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required(t("field_required")),
        lastName: Yup.string().required(t("field_required")),
        dateOfBirth: Yup.date()
          .nullable()
          .test(
            "isValidBirthOfDate",
            t("date_must_be_earlier_than_today"),
            function (value) {
              if (
                this.parent.isSelectedEmail === true &&
                this.parent.dateOfBirth === null &&
                this.parent?.email !== undefined
              ) {
                return true;
              }

              let yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);

              if (this.parent.isSelectedDateOfBirth === false) {
                return true;
              }
              return Yup.object()
                .nullable()
                .shape({
                  date: Yup.date()
                    .typeError(t("invalid_date"))
                    .max(yesterday, t("date_must_be_earlier_than_today")),
                })
                .isValid({ date: value });
            }
          ),

        email: Yup.string().test(
          "isValidMail",
          t("invalid_email"),
          function (value) {
            if (
              this.parent.isSelectedDateOfBirth === true &&
              this.parent?.email === undefined &&
              this.parent.dateOfBirth !== null &&
              this.parent.dateOfBirth !== "Invalid Date"
            ) {
              return true;
            }
            if (this.parent.isSelectedEmail === false) {
              return true;
            }

            return Yup.object()
              .shape({
                email: Yup.string()
                  .email(t("invalid_email"))
                  .required(t("field_required")),
              })
              .isValid({ email: value });
          }
        ),
      })
    ),

    information: Yup.string().max(255, `${t("max_length_is")}255`),
  });
};

export default getValidationSchema;
