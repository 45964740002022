import { faPortrait } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useRefreshData from "../../../../hooks/useRefreshData/useRefreshData";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import styles from "../styles.module.scss";
import { colorNameDictionary } from "../../../ProfilesRouting/profilesDictionaries";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";
import moment from "moment";

const LastEventsEmployees = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const color = colorNameDictionary[authUser.currentProfile?.type];

  const columns = [
    { name: "employee", title: t("employee") },
    { name: "roomName", title: t("room") },
    { name: "time", title: t("time"), type: "date_hours" },
    { name: "typeName", title: t("event_type") },
  ];

  const eventsOfEmployeesResponseCallback = useCallback(
    (responseData: Event[]) => {
      const modified = responseData?.map((row: Event) => {
        return {
          ...row,
          employee: `${row.firstName} ${row.lastName}`,
        };
      });
      return modified;
    },
    []
  );

  const [
    eventsOfEmployees,
    fetchingStateEventsOfEmployees,
    fetchAgainEventsOfEmployees,
  ] = useFetchAndSetGET<Event[]>({
    path: `superior-ac/events/newest`,
    modifyResponseCallback: eventsOfEmployeesResponseCallback,
  });

  const { refreshingTime, handleChangeRefreshingTime } = useRefreshData(
    fetchAgainEventsOfEmployees
  );

  const history = useHistory();

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "time") {
      return row.time ? moment(row.time).format("YYYY-MM-DD HH:mm:ss") : "";
    }

    return row[columnName];
  }, []);

  return (
    <div className={styles.contentContainerBlockPaper}>
      <div className={styles.contentTitleContainerBlockPaper}>
        <div className={styles.headerContainer}>
          {t("recent_events_employees")}
        </div>
        <div
          className={styles.buttonGoToEvents}
          onClick={() => {
            history.push({
              pathname: "/superior-ac/lists/events",
            });
          }}
        >
          <FontAwesomeIcon
            icon={faPortrait}
            size="2x"
            className={styles.eventsIcon}
            style={{ color }}
          />
          <span style={{ color }}>{t("go_to_events")}</span>
        </div>
      </div>
      <div className={styles.contentValueContainerBlockPaper}>
        <TableComponentMemo
          columns={columns}
          rows={eventsOfEmployees}
          layoutSettingName="layoutACSuperiorEventsOfEmployees"
          fetchingState={{
            fetchAgain: fetchAgainEventsOfEmployees,
            isError: fetchingStateEventsOfEmployees.isError,
            isFetching: fetchingStateEventsOfEmployees.isFetching,
          }}
          backgroundColor="white"
          columnsWidth={[
            {
              columnName: "date",
              width: 200,
            },
          ]}
          pageSize={5}
          filteringRowDefaultHidden={true}
          refreshingButton={{
            ifShow: true,
            refreshingTime: refreshingTime?.value,
            handleChangeRefreshingTime,
          }}
          customExcelCellValue={customExportCellValue}
          customPdfCellValue={customExportCellValue}
          tableExportInfo={{
            createdBy: authUser.displayedName,
          }}
        />
      </div>
    </div>
  );
};

export default LastEventsEmployees;
