import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import useSMSMapsLibrary from "../hooks/sms/useSMSMapsLibrary";
import useSMSImagesLibrary from "../hooks/sms/useSMSImagesLibrary";
import useFetchSMSBuildings from "../hooks/reactQueryHooks/sms/useFetchSMSBuildings";
import useFetchSMSBuildingsGroups from "../hooks/reactQueryHooks/sms/useFetchSMSBuildingsGroups";
import useFetchSMSVisualizationObjectsGroups from "../hooks/reactQueryHooks/sms/useFetchVisualizationObjectsGroups";
import useFetchSMSVisualizationObjects from "../hooks/reactQueryHooks/sms/useFetchSMSVisualizationObjects";
import useFetchSMSVisualizationObjectsLayersInstances from "../hooks/reactQueryHooks/sms/useFetchSMSVisualizationObjectsLayersInstances";
import useFetchSMSVisualizationObjectsStatesGroups from "../hooks/reactQueryHooks/sms/useFetchVisualizationObjectsStatesGroups";

interface ISMSVisualizationDataContextType {
  visualizationDataLoading: boolean;
  visualizationDataError: boolean;
  visualizationDataRefetch: () => void;

  mapsData: ISMSCompleteMap[] | undefined;
  mapsLoading: boolean;
  mapsError: boolean;
  mapsRefetch: () => void;

  imagesData: ISMSCompleteImage[] | undefined;
  imagesLoading: boolean;
  imagesError: boolean;
  imagesRefetch: () => void;

  buildingsGroupsData: ISMSBuildingsGroup[] | undefined;
  buildingsGroupsLoading: boolean;
  buildingsGroupsError: boolean;
  buildingsGroupsRefetch: () => void;

  buildingsData: ISMSBuilding[] | undefined;
  buildingsLoading: boolean;
  buildingsError: boolean;
  buildingsRefetch: () => void;

  visualizationObjectsGroupsData:
    | ISMSVisualizationObjectsGroupsLibraryItem[]
    | undefined;
  visualizationObjectsGroupsLoading: boolean;
  visualizationObjectsGroupsError: boolean;
  visualizationObjectsGroupsRefetch: () => void;

  visualizationObjectsData: ISMSVisualizationObject[] | undefined;
  visualizationObjectsLoading: boolean;
  visualizationObjectsError: boolean;
  visualizationObjectsRefetch: () => void;

  visualizationObjectsLayersInstancesData:
    | ISMSVisualizationObjectLayerInstance[]
    | undefined;
  visualizationObjectsLayersInstancesLoading: boolean;
  visualizationObjectsLayersInstancesError: boolean;
  visualizationObjectsLayersInstancesRefetch: () => void;

  visualizationObjectsStatesGroupsData:
    | ISMSVisualizationObjectsStatesGroupsLibraryItem[]
    | undefined;
  visualizationObjectsStatesGroupsLoading: boolean;
  visualizationObjectsStatesGroupsError: boolean;
  visualizationObjectsStatesGroupsRefetch: () => void;
}

const SMSVisualizationDataContext = createContext<
  ISMSVisualizationDataContextType | undefined
>(undefined);

export const SMSVisualizationDataProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    data: mapsData,
    isLoading: mapsLoading,
    isError: mapsError,
    refetch: mapsRefetch,
  } = useSMSMapsLibrary();

  const {
    data: imagesData,
    isLoading: imagesLoading,
    isError: imagesError,
    refetch: imagesRefetch,
  } = useSMSImagesLibrary();

  const {
    data: buildingsGroupsData,
    isLoading: buildingsGroupsLoading,
    isError: buildingsGroupsError,
    refetch: buildingsGroupsRefetch,
  } = useFetchSMSBuildingsGroups();

  const {
    data: buildingsData,
    isLoading: buildingsLoading,
    isError: buildingsError,
    refetch: buildingsRefetch,
  } = useFetchSMSBuildings();

  const {
    data: visualizationObjectsGroupsData,
    isLoading: visualizationObjectsGroupsLoading,
    isError: visualizationObjectsGroupsError,
    refetch: visualizationObjectsGroupsRefetch,
  } = useFetchSMSVisualizationObjectsGroups();

  const {
    data: visualizationObjectsData,
    isLoading: visualizationObjectsLoading,
    isError: visualizationObjectsError,
    refetch: visualizationObjectsRefetch,
  } = useFetchSMSVisualizationObjects();

  const {
    data: visualizationObjectsLayersInstancesData,
    isLoading: visualizationObjectsLayersInstancesLoading,
    isError: visualizationObjectsLayersInstancesError,
    refetch: visualizationObjectsLayersInstancesRefetch,
  } = useFetchSMSVisualizationObjectsLayersInstances();

  const {
    data: visualizationObjectsStatesGroupsData,
    isLoading: visualizationObjectsStatesGroupsLoading,
    isError: visualizationObjectsStatesGroupsError,
    refetch: visualizationObjectsStatesGroupsRefetch,
  } = useFetchSMSVisualizationObjectsStatesGroups();

  const visualizationDataLoading =
    mapsLoading ||
    imagesLoading ||
    buildingsGroupsLoading ||
    buildingsLoading ||
    visualizationObjectsGroupsLoading ||
    visualizationObjectsLoading ||
    visualizationObjectsLayersInstancesLoading ||
    visualizationObjectsStatesGroupsLoading;

  const visualizationDataError =
    !!mapsError ||
    !!imagesError ||
    !!buildingsGroupsError ||
    !!buildingsError ||
    !!visualizationObjectsGroupsError ||
    !!visualizationObjectsError ||
    !!visualizationObjectsLayersInstancesError ||
    !!visualizationObjectsStatesGroupsError;

  const visualizationDataRefetch = useCallback(() => {
    buildingsGroupsRefetch();
    buildingsRefetch();
    visualizationObjectsGroupsRefetch();
    visualizationObjectsRefetch();
    visualizationObjectsLayersInstancesRefetch();
    visualizationObjectsStatesGroupsRefetch();
  }, [
    buildingsGroupsRefetch,
    buildingsRefetch,
    visualizationObjectsGroupsRefetch,
    visualizationObjectsRefetch,
    visualizationObjectsLayersInstancesRefetch,
    visualizationObjectsStatesGroupsRefetch,
  ]);

  return (
    <SMSVisualizationDataContext.Provider
      value={{
        visualizationDataLoading,
        visualizationDataError,
        visualizationDataRefetch,

        mapsData,
        mapsLoading,
        mapsError,
        mapsRefetch,

        imagesData,
        imagesLoading,
        imagesError,
        imagesRefetch,

        buildingsGroupsData,
        buildingsGroupsLoading,
        buildingsGroupsError,
        buildingsGroupsRefetch,

        buildingsData,
        buildingsLoading,
        buildingsError,
        buildingsRefetch,

        visualizationObjectsGroupsData,
        visualizationObjectsGroupsLoading,
        visualizationObjectsGroupsError,
        visualizationObjectsGroupsRefetch,

        visualizationObjectsData,
        visualizationObjectsLoading,
        visualizationObjectsError,
        visualizationObjectsRefetch,

        visualizationObjectsLayersInstancesData,
        visualizationObjectsLayersInstancesLoading,
        visualizationObjectsLayersInstancesError,
        visualizationObjectsLayersInstancesRefetch,

        visualizationObjectsStatesGroupsData,
        visualizationObjectsStatesGroupsLoading,
        visualizationObjectsStatesGroupsError,
        visualizationObjectsStatesGroupsRefetch,
      }}
    >
      {children}
    </SMSVisualizationDataContext.Provider>
  );
};

export const useSMSVisualizationDataContext =
  (): ISMSVisualizationDataContextType => {
    const context = useContext(SMSVisualizationDataContext);
    if (!context) {
      throw new Error(
        "useSMSVisualizationDataContext must be used within a SMSVisualizationDataContext"
      );
    }
    return context;
  };
