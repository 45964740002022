import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React, { CSSProperties, useRef, useState } from "react";
import Button from "../Button/button";
import "./selectMenu.scss";

const StyledMenuItem = withStyles(() => ({
  root: {
    // padding: 10
  },
  gutters: {
    // padding: "0px 0px"
  },
}))(MenuItem);

interface SelectMenuInterface {
  name: React.ReactNode;
  items: {
    optionName: React.ReactNode;
    onClick: () => void;
    hidden?: boolean;
    key?: React.Key | null;
  }[];
  ifNotCloseAfterSelectDisabled?: boolean;
  disabled?: boolean;
  variant?: "contained" | "outlined";
  style?: CSSProperties;
}

function SelectMenu({
  name,
  items,
  ifNotCloseAfterSelectDisabled,
  disabled,
  variant,
  style,
}: SelectMenuInterface) {
  const anchorRef: any = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="dropdown">
      <Button
        ref={anchorRef}
        disabled={disabled}
        onClick={() => handleToggle()}
        variant={variant}
        className="selectButton"
        style={style}
      >
        <div className="selectButtonContent">
          <div className="name">{name}</div>
          <div className="selectMenuCenterArrow">
            <ArrowDropDownIcon />
          </div>
        </div>
      </Button>
      <Popper
        className="setInexZ"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                <MenuList autoFocusItem={open}>
                  {items.map((item, index) => {
                    return !item.hidden ? (
                      <div key={item.key ? item.key : index}>
                        <StyledMenuItem
                          onClick={(e) => {
                            if (!ifNotCloseAfterSelectDisabled) handleClose(e);
                            item.onClick();
                          }}
                        >
                          <span className="fontColorNav">
                            {item.optionName}
                          </span>
                        </StyledMenuItem>
                      </div>
                    ) : (
                      <div key={item.key ? item.key : index}></div>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default SelectMenu;
