import { useEffect, useState } from "react";
import useFetchFile from "../fetchHooks/get/useFetchFile";

const usePrintRequest = (generatePdfApiPath: string) => {
  const [fileIdAndName, setFileIdAndName] = useState<[false | string, string]>([
    false,
    "",
  ]);

  const { fetchFileData } = useFetchFile(generatePdfApiPath, fileIdAndName);

  useEffect(() => {
    if (fetchFileData === null) return;
    setFileIdAndName([false, ""]);
  }, [fetchFileData]);

  return setFileIdAndName;
};

export default usePrintRequest;
