import { CRS, LatLng, LatLngBounds, LeafletMouseEvent } from "leaflet";
import { XYCoord, useDrop } from "react-dnd";
import {
  ImageOverlay,
  MapContainer,
  Marker,
  useMapEvents,
} from "react-leaflet";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import L from "leaflet";
import { SMSVisualizationMode } from "../../../../../enums/SMS/SMSVisualizationMode";
import { useTranslation } from "react-i18next";
import { useMQTTClient } from "../../../../../HelpersFunctions/MQTTClientProvider";

import "react-leaflet-fullscreen/styles.css";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsAlt,
  faCheckCircle,
  faCircle,
  faExpand,
  faHome,
  faLock,
  faSearchMinus,
  faSearchPlus,
  faTrash,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./SMSMapViewer.module.scss";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import MultiLevelContextMenu, {
  MenuItemType,
} from "../../../MultiLevelContextMenu/MultiLevelContextMenu";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { selectAuthUser } from "../../../../../reducers/session";
import { ProfileType } from "../../../../../enums/profileType";
import screenfull from "screenfull";
import _ from "lodash";
import SMSVisualizationEvents from "../SMSVisualizationEvents/SMSVisualizationEvents";
import { useHistory } from "react-router-dom";
import SMSVisualizationObjectActionType from "../../../../../enums/SMS/SMSVisualizationObjectActionType";
import getBasicAuthenticationURL from "../../../../../HelpersFunctions/urls/getBasicAuthenticationURL";
import {
  selectMapTargetLocation,
  setCurrentMapId,
} from "../../../../../reducers/sms/visualizationData";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    padding: "0px",
    margin: "0px",
    height: "30px",
  },
  input: {
    color: "red",
    marginLeft: "10px",
    padding: "8px",
    fontSize: "12px",
    width: "50px",
    height: "100%",
    boxSizing: "border-box",
  },
});

interface IProps {
  visualizationMode: SMSVisualizationMode;
  mapId: number;
  mapUrl: string;
  mapWidth: number;
  mapHeight: number;
  mapObjects: ISMSMapObject[];
  setMapObjects: React.Dispatch<React.SetStateAction<ISMSMapObject[]>>;
  onMapObjectDrop?: (item: ISMSMapObjectDropItem) => void;
  onMapObjectDragEnd?: (item: ISMSMapObjectDragEndItem) => void;
  onMapObjectDelete?: (id: number, deleteObject: boolean) => void;
  onMapObjectToggleDraggable?: (id: number) => void;
  setIsEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  buildings: ISMSBuilding[];
  visualizationObjects: ISMSVisualizationObject[];
  visualizationObjectsLayersInstances: ISMSVisualizationObjectLayerInstance[];
}

const NumberInputWithAcceptButton = ({ initialValue = 0, onAccept }) => {
  const [value, setValue] = useState(initialValue);
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.valueAsNumber);
  };

  const handleAccept = () => {
    if (onAccept) {
      onAccept(value);
    }
  };

  return (
    <TextField
      type="number"
      value={value}
      onChange={handleChange}
      InputProps={{
        style: {
          marginLeft: "10px",
          marginRight: "10px",
          fontSize: "16px",
          width: "150px",
          boxSizing: "border-box",
        },
        classes: { root: classes.root, input: classes.input },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleAccept}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="green"
                style={{ marginRight: "-12px" }}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const ZoomLevelDisplay = ({ zoom }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        right: "45px",
        backgroundColor: "white",
        padding: "5px",
        border: "1px solid black",
        opacity: 0.7,
        zIndex: 999999,
      }}
    >
      Zoom: {zoom}
    </div>
  );
};

const SMSMapViewer: React.FC<IProps> = ({
  visualizationMode,
  mapId,
  mapUrl,
  mapWidth,
  mapHeight,
  mapObjects,
  setMapObjects,
  onMapObjectDrop,
  onMapObjectDragEnd,
  onMapObjectDelete,
  onMapObjectToggleDraggable,
  setIsEditMode,
  buildings,
  visualizationObjects,
  visualizationObjectsLayersInstances,
}) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const profileType = authUser?.currentProfile?.type;
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { mqttPublish } = useMQTTClient();
  const history = useHistory();
  const [mapLoaded, setMapLoaded] = useState(false);
  const dispatch = useAppDispatch();
  const mapTargetLocation = useSelector(selectMapTargetLocation);

  const mapBounds = useMemo(() => {
    return new LatLngBounds([0, 0], [mapHeight, mapWidth]);
  }, [mapHeight, mapWidth]);

  const mapRef = useRef<any>();
  const [mapZoom, setMapZoom] = useState(0);
  const [mapMinZoom, setMapMinZoom] = useState<any>(undefined);
  const [mapMaxZoom, setMapMaxZoom] = useState<any>(undefined);

  const dropRef = useRef<any>(null);

  const [mapCreated, setMapCreated] = useState(false);
  const [processedItems, setProcessedItems] = useState<any>([]);
  const [zoomInfo, setZoomInfo] = useState<any>({
    minZoom: undefined,
    maxZoom: undefined,
    defaultZoom: undefined,
  });

  const [contextMenuData, setContextMenuData] = useState<MenuItemType[]>([]);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleCloseMenu = () => {
    setContextMenuPosition(null);
  };

  const [updateMapRequetsBody, setUpdateMapRequestBody] = useState<any>("");

  const [, fetchAgainUpdateMap] = useFetchOtherThanGET({
    path: `sms-administrator/maps-library/${mapId}`,
    method: "PATCH",
    body: updateMapRequetsBody,
    setBody: setUpdateMapRequestBody,
    contentType: "application/json",
  });

  useEffect(() => {
    if (updateMapRequetsBody) {
      fetchAgainUpdateMap();
    }
  }, [updateMapRequetsBody, fetchAgainUpdateMap]);

  const mapModifyResponseCallback = useCallback(
    (response) => {
      if (visualizationMode === SMSVisualizationMode.NORMAL) {
        _.isNil(response.minZoom)
          ? setMapMinZoom(-20)
          : setMapMinZoom(response.minZoom);
        _.isNil(response.maxZoom)
          ? setMapMaxZoom(20)
          : setMapMaxZoom(response.maxZoom);
        _.isNil(response.defaultZoom)
          ? setMapZoom(0)
          : setMapZoom(response.defaultZoom);
      } else {
        setMapMinZoom(-20);
        setMapMaxZoom(20);
        setMapZoom(0);
      }

      setZoomInfo({
        minZoom: response.minZoom,
        maxZoom: response.maxZoom,
        defaultZoom: response.defaultZoom,
      });

      return response;
    },
    [visualizationMode]
  );

  const [, , fetchAgainMap] = useFetchAndSetGET({
    path:
      profileType === ProfileType.SMS_ADMINISTRATOR
        ? `sms-administrator/maps-library/${mapId}`
        : `sms-user/maps-library/${mapId}`,
    startFetchOnInitial: false,
    modifyResponseCallback: mapModifyResponseCallback,
  });

  useEffect(() => {
    if (mapId) {
      fetchAgainMap();
    }
  }, [mapId, fetchAgainMap]);

  const [, drop] = useDrop({
    accept: onMapObjectDrop
      ? ["VISUALIZATION_OBJECT_ITEM", "VISUALIZATION_OBJECT_LAYER_ITEM"]
      : [],
    drop: (item: ISMSMapObjectDropItem, monitor) => {
      const currentMap = mapRef.current;
      if (!currentMap) return;

      const { x: clientX, y: clientY } = monitor.getClientOffset() as XYCoord;
      const boundingRect = dropRef.current.getBoundingClientRect();
      const offsetX = clientX - boundingRect.left;
      const offsetY = clientY - boundingRect.top;

      const containerPoint = L.point(offsetX, offsetY);
      const latLng: LatLng = currentMap.containerPointToLatLng(containerPoint);

      item.positionX = latLng.lat;
      item.positionY = latLng.lng;
      item.zoom = mapZoom;

      if (onMapObjectDrop) {
        onMapObjectDrop(item);
      }
    },
  });

  drop(dropRef);

  const handleMapContextMenu = useCallback(
    (e: LeafletMouseEvent) => {
      let tempContextMenuData: MenuItemType[] = [];

      switch (visualizationMode) {
        case SMSVisualizationMode.NORMAL:
          tempContextMenuData.push({
            icon: faArrowsAlt,
            text: t("fit_map_to_viewport"),
            onClick: () => {
              if (mapRef?.current) {
                mapRef.current.fitBounds(mapBounds);
                handleCloseMenu();
              }
            },
          });
          break;

        case SMSVisualizationMode.ARRANGEMENT:
          tempContextMenuData.push({
            icon: faSearchMinus,
            text: t("save_zoom_as_min"),
            onClick: () => {
              let body: PathProperty[] = [];

              body.push({
                path: "/minZoom",
                op: "replace",
                value: mapZoom,
              });

              setUpdateMapRequestBody(JSON.stringify(body));
              setZoomInfo({ ...zoomInfo, minZoom: mapZoom });
              handleCloseMenu();
            },
          });

          tempContextMenuData.push({
            icon: faSearchPlus,
            text: t("save_zoom_as_max"),
            onClick: () => {
              let body: PathProperty[] = [];

              body.push({
                path: "/maxZoom",
                op: "replace",
                value: mapZoom,
              });

              setUpdateMapRequestBody(JSON.stringify(body));
              setZoomInfo({ ...zoomInfo, maxZoom: mapZoom });
              handleCloseMenu();
            },
          });

          tempContextMenuData.push({
            icon: faHome,
            text: t("save_zoom_as_default"),
            onClick: () => {
              let body: PathProperty[] = [];

              body.push({
                path: "/defaultZoom",
                op: "replace",
                value: mapZoom,
              });

              setUpdateMapRequestBody(JSON.stringify(body));
              setZoomInfo({ ...zoomInfo, defaultZoom: mapZoom });
              handleCloseMenu();
            },
          });

          tempContextMenuData.push({
            icon: faArrowsAlt,
            text: t("fit_map_to_viewport"),
            onClick: () => {
              if (mapRef?.current) {
                mapRef.current.fitBounds(mapBounds);
                handleCloseMenu();
              }
            },
          });
          break;
      }

      setContextMenuData(tempContextMenuData);
      setContextMenuPosition({
        mouseX: e.originalEvent.clientX - 2,
        mouseY: e.originalEvent.clientY - 4,
      });
    },
    [t, visualizationMode, mapZoom, zoomInfo, mapBounds]
  );

  const handleVisualizationObjectContextMenu = useCallback(
    (mapObject: ISMSMapObject, e: LeafletMouseEvent) => {
      let tempContextMenuData: MenuItemType[] = [];

      switch (visualizationMode) {
        case SMSVisualizationMode.NORMAL:
          if (mapObject.actions && mapObject.actions.length > 0) {
            tempContextMenuData = mapObject.actions
              .filter((el) => el.visible)
              .map((action) => {
                return {
                  icon: faCircle,
                  text: action.name,
                  onClick: () => {
                    switch (action.type) {
                      case SMSVisualizationObjectActionType.MQTT_ACTION:
                        mqttPublish({
                          topic: action.topic,
                          qos: 0,
                          payload: action.value,
                        });
                        break;

                      case SMSVisualizationObjectActionType.LINK_ACTION:
                        let url = getBasicAuthenticationURL(
                          action.urlAddress,
                          action.username,
                          action.password
                        );
                        window.open(
                          url,
                          "_blank",
                          action.openURLInNewWindow ? "new_window" : ""
                        );

                        break;
                    }

                    handleCloseMenu();
                  },
                };
              });
          }
          break;

        case SMSVisualizationMode.ARRANGEMENT:
          tempContextMenuData.push({
            icon: faTrash,
            text: t("delete_layer"),
            onClick: () => {
              if (onMapObjectDelete) {
                onMapObjectDelete(mapObject.id, false);
              }
              handleCloseMenu();
            },
          });

          tempContextMenuData.push({
            icon: faTrash,
            text: t("delete_object"),
            onClick: () => {
              if (onMapObjectDelete) {
                onMapObjectDelete(mapObject.objectId, true);
              }
              handleCloseMenu();
            },
          });

          tempContextMenuData.push({
            icon: mapObject.draggable ? faLock : faUnlock,
            text: mapObject.draggable
              ? t("lock_visualization_object_layer_instance")
              : t("unlock_visualization_object_layer_instance"),
            onClick: () => {
              if (onMapObjectToggleDraggable) {
                onMapObjectToggleDraggable(mapObject.id);
              }
              handleCloseMenu();
            },
          });

          tempContextMenuData.push({
            customComponent: (
              <div className={styles.scaleMenuItemContainer}>
                <div>{t("scale")}:</div>
                <div>
                  <NumberInputWithAcceptButton
                    initialValue={(mapObject as ISMSMapObjectScale).scale}
                    onAccept={(value) => {
                      let tempMapObject = mapObject as ISMSMapObjectScale;
                      tempMapObject.scale = value;

                      setMapObjects((prev) =>
                        prev.map((item) =>
                          item.id === mapObject.id
                            ? (tempMapObject as ISMSMapObject)
                            : item
                        )
                      );

                      if (setIsEditMode) {
                        setIsEditMode(true);
                      }
                      handleCloseMenu();
                    }}
                  />
                </div>
              </div>
            ),
          });
          break;
      }

      setContextMenuData(tempContextMenuData);
      setContextMenuPosition({
        mouseX: e.originalEvent.clientX - 2,
        mouseY: e.originalEvent.clientY - 4,
      });
    },
    [
      onMapObjectDelete,
      onMapObjectToggleDraggable,
      setIsEditMode,
      mqttPublish,
      t,
      visualizationMode,
      setMapObjects,
    ]
  );

  const modifySvgColor = useCallback(
    async (imageUrl: string, color: string, opacity: number) => {
      try {
        const response = await fetch(imageUrl);
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("image/svg+xml")) {
          let svgText = await response.text();
          svgText = svgText.replace(/fill=".*?"/g, `fill="${color}"`);

          svgText = svgText.replace(/opacity=".*?"/g, `opacity="${opacity}"`);

          if (!svgText.includes("opacity")) {
            svgText = svgText.replace("<svg", `<svg opacity="${opacity}"`);
          }

          const svgBlob = new Blob([svgText], {
            type: "image/svg+xml;charset=utf-8",
          });
          const coloredSvgUrl = URL.createObjectURL(svgBlob);
          return coloredSvgUrl;
        }

        return imageUrl;
      } catch (error) {
        console.error("Error fetching and modifying SVG:", error);
        throw error;
      }
    },
    []
  );

  const createIcon = useCallback(
    async (mapObject: ISMSMapImageObject): Promise<L.DivIcon> => {
      let { imageUrl, color, opacity } = mapObject;

      if (imageUrl) {
        imageUrl = await modifySvgColor(imageUrl, color, opacity ?? 1);
      }

      let imageWidth = mapObject.width!;
      let imageHeight = mapObject.height!;
      let imageScale = mapObject.scale! / 100;
      let imageBaseScale = mapObject.baseScale! / 100;
      let imageZoom = mapObject.zoom!;

      let zoomRatio = Math.pow(2, mapZoom - imageZoom);
      let imageScaleRatio = imageScale * imageBaseScale;
      let ratio = imageScaleRatio * zoomRatio;

      imageWidth *= ratio;
      imageHeight *= ratio;

      return L.divIcon({
        html: `<img src=${imageUrl} alt="Custom Icon" style="width: ${imageWidth}px; height: ${imageHeight}px; transform: rotate(${mapObject.rotationAngle}deg); opacity: ${opacity}" />`,
        className: "",
        iconSize: [imageWidth, imageHeight],
        iconAnchor: [imageWidth / 2, imageHeight / 2],
      });
    },
    [modifySvgColor, mapZoom]
  );

  const getDivIconDimensions = useCallback((divIcon) => {
    const marker: any = L.marker([0, 0], { icon: divIcon }).addTo(
      mapRef.current!
    );

    const iconElement = marker._icon!.querySelector(".leaflet-div-icon");
    const rect = iconElement.getBoundingClientRect();
    const width = rect.width;
    const height = rect.height;

    marker.remove();
    return { width, height };
  }, []);

  const createText = useCallback(
    (mapObject: ISMSMapTextObject): L.DivIcon => {
      let {
        text,
        backgroundColor,
        color,
        size = "12",
        opacity,
        italics,
        bold,
        underline,
        fontId,
      } = mapObject;

      let fontSize = "";

      let divStyles = `color: ${color}; white-space: nowrap; display: inline-block;`;
      if (backgroundColor) divStyles += `background-color: ${backgroundColor};`;
      if (italics) divStyles += "font-style: italic;";
      if (bold) divStyles += "font-weight: bold;";
      if (underline) divStyles += "text-decoration: underline;";
      if (size) {
        divStyles += `font-size: ${size}px;`;
        fontSize = `font-size: ${size}px;`;
      }

      if (opacity) divStyles += `opacity: ${opacity};`;

      if (fontId) {
        switch (fontId) {
          case 0:
            divStyles += "font-family: Arial;";
            break;

          case 1:
            divStyles += "font-family: Helvetica;";
            break;

          case 2:
            divStyles += "font-family: Tahoma;";
            break;

          case 3:
            divStyles += "font-family: Verdana;";
            break;

          case 4:
            divStyles += "font-family: Georgia;";
            break;

          case 5:
            divStyles += "font-family: Times New Roman;";
            break;

          case 6:
            divStyles += "font-family: Courier New;";
            break;

          case 7:
            divStyles += "font-family: Lucida Console;";
            break;
        }
      }

      let textScale = mapObject.scale! / 100.0;
      let textBaseScale = mapObject.baseScale! / 100.0;
      let textZoom = mapObject.zoom!;

      let zoomRatio = Math.pow(2, mapZoom - textZoom);
      let textScaleRatio = textScale * textBaseScale;

      let ratio = textScaleRatio * zoomRatio;

      let tempElement = L.divIcon({
        html: `<div class="leaflet-div-icon" style="${divStyles}">${text}</div>`,
        className: "",
      });

      const { width, height } = getDivIconDimensions(tempElement);
      let textWidth = width * ratio;
      let textHeight = height * ratio;
      let newFontSize = parseInt(size) * ratio;

      if (fontSize) {
        divStyles = divStyles.replace(fontSize, `font-size: ${newFontSize}px;`);
      }

      tempElement.options.html = `<div class="leaflet-div-icon" style="${divStyles}; width: ${textWidth}px; height: ${textHeight}px;">${text}</div>`;
      return tempElement;
    },
    [mapZoom, getDivIconDimensions]
  );

  useEffect(() => {
    dispatch(setCurrentMapId(mapId));
  }, [mapId, dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        const results = await Promise.all(
          mapObjects
            ?.filter((mapObject) =>
              visualizationMode === SMSVisualizationMode.NORMAL
                ? mapObject.visible
                : true
            )
            ?.map(async (mapObject) => {
              if (signal.aborted) return null;

              if (mapObject.type === 0 || mapObject.type === 2) {
                let tempObj = mapObject as ISMSMapObjectPosition &
                  ISMSMapObjectScale;

                let icon: L.DivIcon | undefined = undefined;

                try {
                  icon =
                    mapObject.type === 0
                      ? await createIcon(mapObject as ISMSMapImageObject)
                      : createText(mapObject as ISMSMapTextObject);
                } catch (error) {
                  console.error(
                    `Error creating icon for map object: ${mapObject.id}: ${error}`
                  );
                }

                if (!icon) return null;

                //if (signal.aborted) return null;

                return (
                  <Marker
                    key={mapObject.id}
                    position={
                      new LatLng(
                        tempObj?.position?.x ?? 0,
                        tempObj?.position?.y ?? 0
                      )
                    }
                    icon={icon}
                    draggable={
                      visualizationMode === SMSVisualizationMode.ARRANGEMENT &&
                      mapObject.draggable
                    }
                    eventHandlers={{
                      dragend: (e) => {
                        if (onMapObjectDragEnd)
                          onMapObjectDragEnd({
                            id: mapObject.id,
                            positionX: e.target.getLatLng().lat,
                            positionY: e.target.getLatLng().lng,
                          } as ISMSMapObjectDragEndItem);
                      },
                      contextmenu: (e) => {
                        handleVisualizationObjectContextMenu(mapObject, e);
                      },
                    }}
                    zIndexOffset={mapObject.order * -1}
                  />
                );
              }

              return null;
            })
        );

        if (!signal.aborted) {
          setProcessedItems(results);
        }
      } catch (error: any) {
        console.error(`_________call fetchData error: ${error}_______`);
      }
    };

    if (mapCreated && !signal.aborted) {
      fetchData();
    }

    return () => {
      controller.abort();
    };
  }, [
    mapObjects,
    createIcon,
    createText,
    onMapObjectDragEnd,
    handleVisualizationObjectContextMenu,
    visualizationMode,
    mapCreated,
  ]);

  const MapEventHandler = () => {
    useMapEvents({
      load: handleMapLoad,
      contextmenu(e) {
        handleMapContextMenu(e);
      },
    });

    return null;
  };

  const divRef = useRef<any>(null);

  const handleFullscreenChange = () => {
    if (screenfull.isEnabled) {
      setIsFullscreen(screenfull.isFullscreen);
    }
  };

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", handleFullscreenChange);
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", handleFullscreenChange);
      }
    };
  }, []);

  const FullscreenButtonDisplay = useCallback(() => {
    return (
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          backgroundColor: "white",
          padding: "5px",
          border: "1px solid black",
          opacity: 0.7,
          zIndex: 999999,
          width: "18px",
          height: "18px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          visibility: !isFullscreen ? "visible" : "hidden",
        }}
        onClick={() => {
          if (screenfull.isEnabled) {
            screenfull.toggle(divRef.current);
          }
        }}
      >
        <FontAwesomeIcon icon={faExpand} />
      </div>
    );
  }, [isFullscreen]);

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  useEffect(() => {
    if (
      history.location.state &&
      /*mapRef.current != null &&
      mapCreated &&
      processedItems?.length > 0 &&*/
      mapLoaded
    ) {
      const locationState = history.location.state as any;

      if (locationState.action === "GO_TO_VISUALIZATION_OBJECT") {
        const foundInstance = visualizationObjectsLayersInstances.find(
          (el) => el.id === locationState.id
        );

        if (foundInstance) {
          const instanceParameters = JSON.parse(foundInstance.parameters);

          if (mapRef.current != null) {
            mapRef.current.flyTo(
              [instanceParameters.position_x, instanceParameters.position_y],
              instanceParameters.zoom,
              { duration: 1.5 }
            );
          }
        }
      }
    }
  }, [
    history.location.state,
    mapRef,
    visualizationObjectsLayersInstances,
    mapCreated,
    processedItems,
    mapLoaded,
  ]);

  useEffect(() => {
    if (mapTargetLocation && mapRef.current != null) {
      mapRef.current.flyTo(
        [mapTargetLocation[0], mapTargetLocation[1]],
        mapTargetLocation[2],
        { duration: 1.5 }
      );
    }
  }, [mapTargetLocation]);

  return (
    <div ref={dropRef}>
      {mapUrl && !_.isNil(mapMinZoom) && !_.isNil(mapMaxZoom) && (
        <div ref={divRef}>
          <div className={styles.mainContainer}>
            <MapContainer
              //key={mapId}
              center={[0, 0]}
              zoom={0}
              minZoom={mapMinZoom}
              maxZoom={mapMaxZoom}
              className={styles.map}
              crs={CRS.Simple}
              whenCreated={(map) => {
                mapRef.current = map;
                setMapCreated(true);

                if (map != null) {
                  setMapZoom(map.getZoom());

                  const onZoomEnd = () => {
                    setMapZoom(map.getZoom());
                  };
                  map.on("zoomend", onZoomEnd);

                  return () => {
                    map.off("zoomend", onZoomEnd);
                  };
                }
              }}
            >
              <ImageOverlay
                url={mapUrl}
                bounds={mapBounds}
                eventHandlers={{ load: handleMapLoad }}
              />
              {processedItems}
              <MapEventHandler />
              <ZoomLevelDisplay zoom={mapZoom} />
              <FullscreenButtonDisplay />
              {contextMenuPosition !== null &&
                contextMenuData &&
                contextMenuData.length > 0 && (
                  <MultiLevelContextMenu
                    menuItems={contextMenuData}
                    position={{
                      x: contextMenuPosition.mouseX,
                      y: contextMenuPosition.mouseY,
                    }}
                    onClose={handleCloseMenu}
                    container={divRef.current}
                  />
                )}
            </MapContainer>

            {visualizationMode === SMSVisualizationMode.NORMAL && (
              <div className={styles.events}>
                <SMSVisualizationEvents />
              </div>
            )}
          </div>

          {visualizationMode === SMSVisualizationMode.ARRANGEMENT && (
            <div>
              <p>
                <span className={styles.statusBarItemLabel}>Zoom:</span>
                <span className={styles.statusBarItemValue}>{mapZoom} </span>[
                <span className={styles.statusBarItemLabel}>
                  {t("minimum")}:{" "}
                </span>
                <span className={styles.statusBarItemValue}>
                  {zoomInfo?.minZoom ?? t("none")},{" "}
                </span>
                <span className={styles.statusBarItemLabel}>
                  {t("maximum")}:{" "}
                </span>
                <span className={styles.statusBarItemValue}>
                  {zoomInfo?.maxZoom ?? t("none")},{" "}
                </span>
                <span className={styles.statusBarItemLabel}>
                  {t("default")}:{" "}
                </span>
                <span className={styles.statusBarItemValue}>
                  {zoomInfo?.defaultZoom ?? t("none")}
                </span>
                ]
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SMSMapViewer;
