const formFieldsModel = {
  formFields: {
    //#region WORKER DATA
    firstName: {
      name: "firstName",
      label: "first_name",
    },
    lastName: {
      name: "lastName",
      label: "last_name",
    },
    evidenceNumber: {
      name: "evidenceNumber",
      label: "evidence_number",
    },
    identifier: {
      name: "identifier",
      label: "identifier",
    },
    description: {
      name: "description",
      label: "description",
    },
    position: {
      name: "position",
      label: "position",
    },
    phone: {
      name: "phone",
      label: "phone",
    },
    email: {
      name: "email",
      label: "email",
    },
    department: {
      name: "department",
      label: "department",
    },
    additions: {
      name: "additions",
      label: "comments",
    },
    isTracked: {
      name: "isTracked",
      label: "track_by_email",
    },
    acSuperiors: {
      name: "acSuperiors",
      label: "track_by_email",
    },
    zones: {
      name: "zones",
      label: "may_be_in_the_zone",
    },
    isKP: {
      name: "isKp",
      label: "no_obligation_for_KP_employee",
    },
    firstDay: {
      name: "firstDay",
      label: "first_day_of_the_week",
    },
    meetingPlace: {
      name: "meetingPlace",
      label: "meeting_place",
    },
    regularPost: {
      name: "regularPost",
      label: "regular_post",
    },
    //#endregion

    //#region COMPANIES
    companies: {
      name: "companies",
      label: "companies",
    },
    //#endregion

    //#region GROUP PERMISSIONS
    groups: {
      name: "groups",
      label: "groups",
    },
    //#endregion

    //#region PERMISSIONS
    permissions: {
      name: "permissions",
      label: "permissions",
    },
    isVIP: {
      name: "isVIP",
      label: "VIP",
    },
    isZJW: {
      name: "isZJW",
      label: "ZJW",
    },
    isAccessDeniedOnHolidays: {
      name: "isAccessDeniedOnHolidays",
      label: "access_denied_on_holidays",
    },
    startPermissionDateCheck: {
      name: "startPermissionDateCheck",
      label: "start_permission_date",
    },
    startPermissionDate: {
      name: "startPermissionDate",
      label: "start_permission_date",
    },
    permissionExpirationDateCheck: {
      name: "permissionExpirationDateCheck",
      label: "permission_expiration_date",
    },
    permissionExpirationDate: {
      name: "permissionExpirationDate",
      label: "permission_expiration_date",
    },
    //#endregion

    //#region Card
    cardNumber: {
      name: "cardNumber",
      label: "card_tag",
    },
    csn: {
      name: "csn",
      label: "CSN",
    },
    remoteNumber: {
      name: "remoteNumber",
      label: "pilot",
    },
    pin: {
      name: "pin",
      label: "pin",
    },
    additionalCards: {
      name: "additionalCards",
      label: "additional_cards",
    },
    //#endregion

    //#region Elevators and Cabinets
    elevatorsAndCabinetsItem: {
      name: "elevatorsAndCabinetsItem",
      label: "elevators_and_cabinets_item",
    },
    elevatorsAndCabinets: {
      name: "elevatorsAndCabinets",
      label: "elevators_and_cabinets",
    },

    //#region Special permissions
    specialPermissions: {
      name: "specialPermissions",
      label: "special_permissions",
    },
  },
};

export default formFieldsModel;
