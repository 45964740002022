import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { ProfileType } from "../../../enums/profileType";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArraySMSService: navLinksArrayTimeWorkerInterface = {
    profileId: ProfileType.SMS_SERVICE,
    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/sms-service/profile",
        component: () => <Profile />,
      },
    ],
  };

  return navLinksArraySMSService;
};

export default useNavLinksArray;
