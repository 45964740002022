import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import ErrorLabel from "../ErrorLabel/errorLabel";
import { FormControlLabel } from "@material-ui/core";
import { CheckboxStyled } from "../../MaterialUi";

export default function CheckboxWithLabelFieldReactHookForm({
  name,
  label,
  showTopLabel,
  control,
  setValue,
  onChangeExtra,
  ...props
}: any): ReactElement {
  return (
    <div>
      {showTopLabel && (
        <div>
          <b>{label}:</b>
        </div>
      )}

      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => {
          return (
            <>
              <FormControlLabel
                control={
                  <CheckboxStyled
                    inputProps={{ "aria-label": "primary checkbox" }}
                    label={label}
                    type="checkbox"
                    checked={value}
                    {...props}
                    onChange={(event, value) => {
                      if (onChangeExtra) {
                        onChangeExtra(event, value);
                      }

                      if (value !== null && value !== undefined) {
                        setValue(name, value);
                      }
                    }}
                  />
                }
                label={<span>{label}</span>}
              />

              {error?.message ? (
                <ErrorLabel errorName={error?.message} />
              ) : null}
            </>
          );
        }}
      />
    </div>
  );
}
