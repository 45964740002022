import { useTranslation } from "react-i18next";
import styles from "./VisualizationObjectActions.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import SelectFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/SelectField/selectFIeld";
import { useEffect, useState } from "react";
import {
  selectVisualizationObjectsActionsTypes,
  selectVisualizationObjectsBuiltInStates,
} from "../../../../../reducers/sms/visualizationData";
import { useAppSelector } from "../../../../../store/hooks";
import CheckboxWithLabelFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/CheckboxWithLabelField/checkboxWithLabelField";

const VisualizationObjectActionItem: React.FC<any> = ({
  key,
  fields,
  index,
  control,
  register,
  setValue,
  remove,
  errors,
  getValues,
  clearErrors,
  watch,
}) => {
  const { t } = useTranslation();
  const statesValues = watch("states");
  const [statesList, setStatesList] = useState<any>([]);
  const visualizationObjectsBuiltInStates = useAppSelector(
    selectVisualizationObjectsBuiltInStates
  );
  const actionsTypes = useAppSelector(selectVisualizationObjectsActionsTypes);

  useEffect(() => {
    let tempList: any = [];
    [...visualizationObjectsBuiltInStates].forEach((el) => tempList.push(el));

    if (statesValues) {
      for (let i = 0; i < statesValues.length; i++) {
        tempList.push({
          id: statesValues[i].virtualId,
          name: statesValues[i].name,
        });
      }
    }

    setStatesList(tempList);
  }, [visualizationObjectsBuiltInStates, statesValues, t]);

  const actionsValues = watch("actions");

  return (
    <div className={styles.actionsMainContainer}>
      <div className={styles.actionItemTitle}>
        <p>
          {t("action")}
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              remove(index);
            }}
          />
        </p>
      </div>

      <div className={styles.actionItem}>
        <div className={styles.inputsRow}>
          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("name")}:</div>
            <div className={styles.inputFieldInRow}>
              <TextFieldReactHookForm
                id={`actions.${index}.name`}
                name={`actions.${index}.name`}
                control={control}
                label={t("name")}
                required={true}
              />
            </div>
          </div>

          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("type")}:</div>
            <div className={styles.inputFieldInRow}>
              <SelectFieldReactHookForm
                id={`actions.${index}.type`}
                name={`actions.${index}.type`}
                control={control}
                label={t("choose_type")}
                required={true}
                options={actionsTypes || []}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                getOptionSelected={(option, value) => {
                  return (
                    option.id === value.id || option.id === value.virtualId
                  );
                }}
                setValue={setValue}
                clearErrors={clearErrors}
                watch={watch}
              />
            </div>
          </div>
        </div>

        <div className={styles.inputsRow}>
          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>
              {t("visible_in_states")}:
            </div>
            <div className={styles.inputFieldInRow}>
              <SelectFieldReactHookForm
                id={`actions.${index}.states`}
                name={`actions.${index}.states`}
                control={control}
                label={t("choose_state")}
                required={true}
                options={statesList || []}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                getOptionSelected={(option, value) => {
                  return (
                    option.id === value.id || option.id === value.virtualId
                  );
                }}
                setValue={setValue}
                clearErrors={clearErrors}
                watch={watch}
                multiple
                disableCloseOnSelect
              />
            </div>
          </div>

          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("order")}:</div>
            <div className={styles.inputFieldInRow}>
              <TextFieldReactHookForm
                id={`actions.${index}.order`}
                name={`actions.${index}.order`}
                type="number"
                control={control}
                label={t("order")}
                required={true}
              />
            </div>
          </div>
        </div>

        {actionsValues[index].type?.id === 0 && (
          <>
            <div className={styles.inputsRow}>
              <div className={styles.inputWithTitleInRow}>
                <div className={styles.inputTitleInRow}>{t("topic")}:</div>
                <div className={styles.inputFieldInRow2}>
                  <TextFieldReactHookForm
                    id={`actions.${index}.topic`}
                    name={`actions.${index}.topic`}
                    control={control}
                    label={t("topic")}
                    required={true}
                  />
                </div>
              </div>
            </div>

            <div className={styles.inputsRow}>
              <div className={styles.inputWithTitleInRow}>
                <div className={styles.inputTitleInRow}>{t("value")}:</div>
                <div className={styles.inputFieldInRow2}>
                  <TextFieldReactHookForm
                    id={`actions.${index}.value`}
                    name={`actions.${index}.value`}
                    control={control}
                    label={t("value")}
                    required={true}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {actionsValues[index].type?.id === 1 && (
          <>
            <div className={styles.inputsRow}>
              <div className={styles.inputWithTitleInRow}>
                <div className={styles.inputTitleInRow}>
                  {t("url_address")}:
                </div>
                <div className={styles.inputFieldInRow2}>
                  <TextFieldReactHookForm
                    id={`actions.${index}.urlAddress`}
                    name={`actions.${index}.urlAddress`}
                    control={control}
                    label={t("url_address")}
                    required={true}
                  />
                </div>
              </div>
            </div>

            <div className={styles.inputsRow}>
              <div className={styles.inputWithTitleInRow}>
                <div className={styles.inputTitleInRow}>{t("username")}:</div>
                <div className={styles.inputFieldInRow}>
                  <TextFieldReactHookForm
                    id={`actions.${index}.username`}
                    name={`actions.${index}.username`}
                    control={control}
                    label={t("username")}
                  />
                </div>
              </div>

              <div className={styles.inputWithTitleInRow}>
                <div className={styles.inputTitleInRow}>{t("password")}:</div>
                <div className={styles.inputFieldInRow}>
                  <TextFieldReactHookForm
                    id={`actions.${index}.password`}
                    name={`actions.${index}.password`}
                    control={control}
                    label={t("password")}
                  />
                </div>
              </div>
            </div>

            <div>
              <CheckboxWithLabelFieldReactHookForm
                id={"openURLInNewWindow"}
                name={`actions.${index}.openURLInNewWindow`}
                control={control}
                value={getValues("openURLInNewWindow")}
                setValue={setValue}
                ifCircle={true}
                label={t("open_url_in_new_window")}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VisualizationObjectActionItem;
