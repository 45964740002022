import VisualizationObjectStatesItem from "./VisualizationObjectStatesItem";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../../helpersComponents/Button/button";
import styles from "./VisualizationObjectStates.module.scss";
import { v4 as uuidv4 } from "uuid";

const VisualizationObjectStates = ({
  fields,
  append,
  visualizationObjectsStatesGroups,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.statesContainer}>
      <div>
        <ButtonStyled
          type="button"
          onClick={() => {
            append({
              virtualId: uuidv4(),
              name: "",
              condition: "",
              group: null,
            });
          }}
        >
          {t("add")}
        </ButtonStyled>
      </div>

      <div className={styles.statesItemsContainer}>
        {fields.map((field, index) => (
          <VisualizationObjectStatesItem
            id={field.id}
            key={field.id}
            fields={fields}
            index={index}
            visualizationObjectsStatesGroups={visualizationObjectsStatesGroups}
            {...props}
          />
        ))}
      </div>
    </div>
  );
};

export default VisualizationObjectStates;
