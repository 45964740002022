import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./advanceNarrowNavbar.module.scss";
import { useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { colorNameDictionary } from "../ProfilesRouting/profilesDictionaries";
import { selectAuthUser } from "../../reducers/session";
import { useAppSelector } from "../../store/hooks";

interface IProps {
  menuData: AdvancedNavbarMenuItem[];
}

function findParentLevel(root, targetLevel) {
  // Rekurencyjne wyszukiwanie poprzedniego poziomu w drzewie
  for (const item of root) {
    if (item.submenu === targetLevel) {
      return root;
    } else if (item.submenu && item.submenu.length > 0) {
      const parent = findParentLevel(item.submenu, targetLevel);
      if (parent) {
        return item.submenu;
      }
    }
  }
  return null;
}

const AdvancedNarrowNavbarContent: React.FC<IProps> = ({ menuData }) => {
  const location = useLocation();

  const authUser = useAppSelector(selectAuthUser);
  const profileColor = colorNameDictionary[authUser.currentProfile?.type]; 

  const [currentLevel, setCurrentLevel] =
    useState<AdvancedNavbarMenuItem[]>(menuData);

  const handleItemClick = (item: AdvancedNavbarMenuItem) => {
    if (item.submenu && item.submenu.length > 0) {
      // Jeżeli element ma submenu, ustaw nowy poziom danych
      setCurrentLevel(item.submenu);
    }
  };

  const handleGoBack = useCallback(() => {
    // Powrót o jeden poziom w górę
    // Sprawdzamy, czy nie jesteśmy już na najwyższym poziomie
    if (currentLevel !== menuData) {
      // Znajdź poprzedni poziom w drzewie
      const parentLevel = findParentLevel(menuData, currentLevel);

      // Ustaw nowy poziom danych
      if (parentLevel == null) {
        setCurrentLevel(menuData);
      } else {
        setCurrentLevel(parentLevel);
      }
    }
  }, [currentLevel, menuData]);

  return (
    <div className={styles.narrowNavbar}>
      {/* Przycisk cofnij */}
      {currentLevel !== menuData && (
        <div className={styles.narrowNavbarIcon} onClick={handleGoBack}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
      )}

      {/* Lista ikon dla aktualnego poziomu */}
      {currentLevel?.map((item, index) => (
        <Link
          key={`narrow_navbar_${index}`}
          className={`${styles.singleLink}`}
          to={`${item.url + "?menuItemName=" + item.title}`}
          title={item.title}
          onClick={() => {
            handleItemClick(item);
          }}
        >
          <div
            style={(location.pathname === item.url) ? {color: profileColor} : {}}
            className={`${styles.narrowNavbarIcon} ${
              location.pathname === item.url && styles.singleLinkSelected
            }`}
          >
            {item.icon}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default AdvancedNarrowNavbarContent;
