import { DialogContent, FormControlLabel } from "@material-ui/core";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxStyled, TextFieldStyled } from "../../MaterialUi";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../PopupHelpers";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  saveLayout: () => void;
  newLayoutName: string;
  setNewLayoutName: React.Dispatch<string>;
  newLayoutIsDefault: boolean;
  setNewLayoutIsDefault: React.Dispatch<boolean>;
}

export default function NewLayoutNamePopup({
  isOpen,
  setIsOpen,
  saveLayout,
  newLayoutName,
  setNewLayoutName,
  newLayoutIsDefault,
  setNewLayoutIsDefault,
}: Props): ReactElement {
  const { t } = useTranslation();
  const closDialog = () => setIsOpen(false);
  const [isEmptyNameError, setIsEmptyNameError] = useState<boolean>(false);

  return (
    <DialogStyled open={isOpen} onClose={closDialog}>
      <DialogTitleStyled title={t("saving_layout")} />
      <DialogContent>
        <Row>
          <RowTitle>{t("layout_name")}:</RowTitle>
          <RowInput>
            <TextFieldStyled
              label={t("layout_name")}
              value={newLayoutName}
              onChange={(e) => {
                setNewLayoutName(e.target.value);
                setIsEmptyNameError(false);
              }}
              required={true}
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle>{t("set_as_default")}:</RowTitle>
          <RowInput>
            <FormControlLabel
              control={
                <CheckboxStyled
                  checked={newLayoutIsDefault}
                  name="ifSetaAsDefault"
                  onChange={() => {
                    setNewLayoutIsDefault(!newLayoutIsDefault);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label={""}
            />
          </RowInput>
        </Row>
      </DialogContent>
      <DialogActionsStyled
        onCancel={closDialog}
        errorString={isEmptyNameError ? t("type_layout_name") : ""}
        onConfirm={() => {
          if (newLayoutName === "") {
            setIsEmptyNameError(true);
            return;
          }
          saveLayout();
          setIsOpen(false);
        }}
      />
    </DialogStyled>
  );
}
