import AutocompleteStyled from "../../../../MaterialUi/Autocomplete/autocomplete";
import styles from "./MapArrangementToolbar.module.scss";
import { useTranslation } from "react-i18next";
import { selectSettings } from "../../../../../../reducers/settings";
import { useAppSelector } from "../../../../../../store/hooks";
import ButtonStyled from "../../../../Button/button";

interface IProps {
  buildingsGroups: ISMSBuildingsGroup[];
  buildings: ISMSBuilding[];

  selectedBuildingGroup: ISMSBuildingsGroup | null;
  setSelectedBuildingGroup: React.Dispatch<
    React.SetStateAction<ISMSBuildingsGroup | null>
  >;

  selectedBuilding: ISMSBuilding | null;
  setSelectedBuilding: React.Dispatch<
    React.SetStateAction<ISMSBuilding | null>
  >;

  selectedFloor: ISMSBuildingFloor | null;
  setSelectedFloor: React.Dispatch<
    React.SetStateAction<ISMSBuildingFloor | null>
  >;
  isEditMode: boolean;
  onSaveChangesClick: () => void;
  onCancelChangesClick: () => void;
}

const MapArrangementToolbar: React.FC<IProps> = ({
  buildingsGroups,
  buildings,
  selectedBuildingGroup,
  setSelectedBuildingGroup,
  selectedBuilding,
  setSelectedBuilding,
  selectedFloor,
  setSelectedFloor,
  isEditMode,
  onSaveChangesClick,
  onCancelChangesClick,
}) => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const btnSaveChangesClick = () => {
    if (onSaveChangesClick) onSaveChangesClick();
  };

  const btnCancelChangesClick = () => {
    if (onCancelChangesClick) onCancelChangesClick();
  };

  return (
    <div className={styles.mainContainerToolbar}>
      {settings.WebSMSWylaczObslugeGrupyBudynkow?.value !== "1" && (
        <div className={styles.toolbarItem}>
          <AutocompleteStyled
            value={selectedBuildingGroup || null}
            required={true}
            label={t("buildings_group")}
            options={buildingsGroups || []}
            getOptionLabel={(el) => el.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(_, buildingGroup: ISMSBuildingsGroup) => {
              setSelectedBuildingGroup(buildingGroup);
              setSelectedBuilding(null);
              setSelectedFloor(null);
            }}
            disabled={isEditMode}
          />
        </div>
      )}
      <div className={styles.toolbarItem}>
        <AutocompleteStyled
          value={selectedBuilding || null}
          required={true}
          label={t("building")}
          options={
            buildings.filter(
              (b) => b.buildingsGroupId === selectedBuildingGroup?.id
            ) || []
          }
          getOptionLabel={(el) => el.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(_, building: ISMSBuilding) => {
            setSelectedBuilding(building);
            setSelectedFloor(null);
          }}
          disabled={isEditMode}
        />
      </div>
      <div className={styles.toolbarItem}>
        <AutocompleteStyled
          value={selectedFloor || null}
          required={true}
          label={t("floor")}
          options={selectedBuilding?.floors || []}
          getOptionLabel={(el) => el.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(_, floor: ISMSBuildingFloor) => {
            setSelectedFloor(floor);
          }}
          disabled={isEditMode}
        />
      </div>
      {isEditMode && (
        <div className={styles.toolbarButtonsItem}>
          <div>
            <ButtonStyled
              style={{ backgroundColor: "green", fontSize: "18px" }}
              onClick={btnSaveChangesClick}
            >
              {t("save_changes")}
            </ButtonStyled>
          </div>
          <div>
            <ButtonStyled
              style={{
                backgroundColor: "red",
                fontSize: "18px",
                marginLeft: "10px",
              }}
              onClick={btnCancelChangesClick}
            >
              {t("cancel")}
            </ButtonStyled>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapArrangementToolbar;
