import "./businessExits.scss";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import Button from "../../../helpersComponents/Button/button";
import useParallelFetchJson from "../../../../hooks/fetchHooks/parallelJSON/useParallelFetchJson";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import { useAppSelector } from "../../../../store/hooks";
import { selectTimeWorkersSuperiorObject } from "../../../../reducers/workersLists";
import moment from "moment";
import { selectAuthUser } from "../../../../reducers/session";

function BusinessExitsContent() {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Month")
  );
  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Month"));
  const [fetchAgain, setFetchAgain] = useState(true);
  const [dataModified, setDataModified] = useState<any>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [isError, setIsError] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);

  const timeWorkersSuperiorObject = useAppSelector(
    selectTimeWorkersSuperiorObject
  );

  const { data, error } = useFetchGet(
    "superior-time/business-exits?DateFrom=" +
      computeDateRangeFetch(startDate, "T00:00:00") +
      "&DateTo=" +
      computeDateRangeFetch(endDate),
    fetchAgain
  );

  const [bodyPatch, setBodyPatch] = useState<any>(false);
  const result = useParallelFetchJson(
    "PATCH",
    "superior-time/business-exits",
    bodyPatch,
    selectedRows
  );

  useEffect(() => {
    if (result?.isFetching) return;
    setBodyPatch(false);

    if (result.isAllGood) {
      setSnackbarState([true, "success", t("actions_completed_successfully")]);
      setFetchAgain(true);
      setIsFetching(true); // circ
    } else if (result.isAllFail) {
      setSnackbarState([true, "error", t("all_actions_failed")]);
      setFetchAgain(true);
      setIsFetching(true); // circ
    } else {
      setSnackbarState([true, "warning", t("not_all_actions_were_successful")]);
      setFetchAgain(true);
      setIsFetching(true); // circ
    }
  }, [result, t]);

  const columns = [
    { name: "date", title: t("event_time"), type: "date_hours" },
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "organizationalUnits", title: t("organizational_units") },
    { name: "purposeOfExit", title: t("purpose_of_the_exit") },
    { name: "statusTranslated", title: t("request_status") },
    {
      name: "statusModifiedAt",
      title: t("date_of_changing_status"),
      type: "date_hours",
    },
    { name: "approvedBy", title: t("approved_by") },
  ];

  useEffect(() => {
    setFetchAgain(true);
    setIsError(false);
    setIsFetching(true); // circ
  }, [startDate, endDate]);

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setFetchAgain(false);
      return;
    }
    if (data === null) return;

    data.forEach((element, index) => {
      data[index].organizationalUnits =
        timeWorkersSuperiorObject[element?.timeWorkerId]?.organizationalUnits;
      data[index].evidenceNumber =
        timeWorkersSuperiorObject[element?.timeWorkerId]?.evidenceNumber;
      data[index].date = element.date;
      data[index].statusModifiedAt = element.statusModifiedAt;
      data[index].statusTranslated = t(statusDictionary[element.status]);
    });

    setDataModified(data);
    setIsFetching(false);
    setFetchAgain(false);
  }, [data, error, timeWorkersSuperiorObject, t]);

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "date") {
      return row.date ? moment(row.date).format("YYYY-MM-DD HH:mm:ss") : "";
    }

    if (columnName === "statusModifiedAt") {
      return row.statusModifiedAt
        ? moment(row.statusModifiedAt).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    return row[columnName];
  }, []);

  return (
    <>
      <TopBanner pathName={t("business_exits")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
          ></DatePickerRange>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={isFetching}
            isError={isError}
            setIfFetchAgain={setFetchAgain}
            setIsError={setIsError}
          >
            {!isFetching && !isError && (
              <TableComponent
                layoutSettingName="layoutSuperiorTimeRequestsBusinessExits"
                rows={dataModified}
                selectData={setSelectedRows}
                selectedData={selectedRows}
                columns={columns}
                customExcelCellValue={customExportCellValue}
                customPdfCellValue={customExportCellValue}
                tableExportInfo={{
                  dateFrom: startDate,
                  dateTo: endDate,
                  createdBy: authUser.displayedName,
                }}
              />
            )}
          </LoadingWrapper>
        </div>
        <div>
          <div className="acionButtonsContainerGlobal">
            <div>
              <Button
                className="actionButtonGlobal"
                width={250}
                variant="outlined"
                onClick={() => {
                  setIsFetching(true);
                  setBodyPatch([
                    {
                      op: "replace",
                      path: "/status",
                      value: 7,
                    },
                  ]);
                }}
              >
                {t("cancel")}
              </Button>
            </div>
            <div>
              <Button
                width={250}
                onClick={() => {
                  setIsFetching(true);
                  setBodyPatch([
                    {
                      op: "replace",
                      path: "/status",
                      value: 2,
                    },
                  ]);
                }}
                className="actionButtonGlobal"
              >
                {t("approve")}
              </Button>
            </div>
            <div>
              <Button
                width={250}
                onClick={() => {
                  setIsFetching(true);
                  setBodyPatch([
                    {
                      op: "replace",
                      path: "/status",
                      value: 5,
                    },
                  ]);
                }}
                className="actionButtonGlobal"
              >
                {t("deny")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default BusinessExitsContent;
