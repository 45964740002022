import { useEffect, useState } from "react";
import ButtonStyled from "../Button/button";
import SelectMenu from "../SelectMenu/selectMenu";
import useWorkersSelectionPresets from "../../../hooks/useWorkersSelectionPresets/useWorkersSelectionPresets";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave as saveIcon,
  faTrash as removeIcon,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@material-ui/core";

import styles from "./workerList.module.scss";
import NewSelectionProfilePopup from "./newSelectionProfilePopup";
import useConfirm from "../../../hooks/useConfirm/useConfirm";

type WorkerPresetSelectionProps = {
  selectedWorkers: number[];
  setSelectedWorkers: (selection: (string | number)[]) => void;
};

const WorkerPresetSelection = (props: WorkerPresetSelectionProps) => {
  const { t } = useTranslation();
  const [currentPresetIndex, setCurrentPresetIndex] = useState<number | null>(
    null
  );
  const selectionPresets = useWorkersSelectionPresets();

  const [isPopupDialogOpen, setIsPopupDialogOpen] = useState<boolean>(false);
  const { confirm } = useConfirm();

  useEffect(() => {
    if (currentPresetIndex === null || !selectionPresets.presets.length) return;
    if (
      selectionPresets.presets[currentPresetIndex].value !==
      props.selectedWorkers
    ) {
      setCurrentPresetIndex(null);
    }
  }, [props.selectedWorkers, currentPresetIndex, selectionPresets.presets]);

  return (
    <>
      <div className={styles.headerPesetSelectionContainer}>
        {selectionPresets.presets.length > 0 && (
          <SelectMenu
            style={{ marginRight: "5px" }}
            name={
              currentPresetIndex !== null &&
              currentPresetIndex < selectionPresets.presets.length &&
              selectionPresets.presets[currentPresetIndex].name
                ? selectionPresets.presets[currentPresetIndex].name
                : t("select_profile")
            }
            variant="outlined"
            items={[
              ...selectionPresets.presets.map((el, index) => ({
                optionName: el.name,
                onClick: () => {
                  props.setSelectedWorkers(el.value);
                  setCurrentPresetIndex(index);
                },
                key: `WorkerPresetSelection_${el.name}`,
              })),
              {
                optionName: (
                  <span style={{ color: "#e11d48" }}>{t("cancel")}</span>
                ),
                onClick: () => {
                  props.setSelectedWorkers([]);
                  setCurrentPresetIndex(null);
                },
                hidden: currentPresetIndex === null,
              },
            ]}
          />
        )}
        {currentPresetIndex === null ? (
          props.selectedWorkers.length > 0 && (
            <Tooltip title={t("save_profile")}>
              <ButtonStyled
                style={{ padding: "0 5px", margin: "2px 0" }}
                onClick={() => setIsPopupDialogOpen(true)}
              >
                {selectionPresets.presets.length === 0 && (
                  <span
                    style={{
                      marginRight: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    {t("save_profile")}
                  </span>
                )}
                <FontAwesomeIcon icon={saveIcon} size="lg" />
              </ButtonStyled>
            </Tooltip>
          )
        ) : (
          <Tooltip title={t("remove_profile")}>
            <ButtonStyled
              style={{
                padding: "0 5px",
                width: "26px",
                margin: "2px 0",
                backgroundColor: "#e11d48",
              }}
              onClick={async () => {
                const confirmResponse = await confirm({
                  text: t("are_you_sure_you_want_to_delete_this_profile"),
                  buttons: [
                    { buttonName: t("delete"), buttonId: 0 },
                    { buttonName: t("cancel"), buttonId: 1 },
                  ],
                });
                if (confirmResponse === 1) return;
                selectionPresets.update((prev) => {
                  const updated = [...prev];
                  updated.splice(currentPresetIndex, 1);
                  return updated;
                });
                setCurrentPresetIndex(null);
              }}
            >
              <FontAwesomeIcon icon={removeIcon} />
            </ButtonStyled>
          </Tooltip>
        )}
      </div>
      <NewSelectionProfilePopup
        open={isPopupDialogOpen}
        setOpen={setIsPopupDialogOpen}
        newProfileValue={props.selectedWorkers}
        profiles={selectionPresets.presets}
        setProfiles={selectionPresets.update}
        onAdd={() => setCurrentPresetIndex(selectionPresets.presets.length)}
      />
    </>
  );
};

export default WorkerPresetSelection;
