import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../hooks/fetchHooks/get/useFetchGet";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import SelectMenu from "../../helpersComponents/SelectMenu/selectMenu";
import { CircularProgress } from "@material-ui/core";
import styles from "./readersMonitoring.module.scss";
import moment from "moment";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";

interface rowInterface {
  id: number;
  reader: string;
  destinationRoom: string;
  passageOpen: string;
  door: string;
  tamper: string;
  noPower: string;
  noCommunication: string;
  updateTime: string;
  safetyButton: string;
  breakInBurglarAlarm: string;
  doorOpenTooLongAlarm: string;
}

const frequencyDictionary = {
  10: 10,
  20: 20,
  30: 30,
};

export default function ReadersMonitoringContent(): ReactElement {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const [rows, setRows] = useState<rowInterface[]>([]);
  const columns = [
    { name: "reader", title: t("reader") },
    { name: "destinationRoom", title: t("destination_room") },
    { name: "passageOpen", title: t("passage_open") },
    { name: "door", title: t("door") },
    { name: "tamper", title: t("tamper") },
    { name: "noPower", title: t("no_power") },
    { name: "noCommunication", title: t("no_communication") },
    { name: "updateTime", title: t("update_time"), type: "date_hours" },

    { name: "safetyButton", title: t("safety_button") },
    { name: "breakInBurglarAlarm", title: t("break_in_burglar_alarm") },
    { name: "doorOpenTooLongAlarm", title: t("door_open_too_long_alarm") },
  ];
  const [isFetching, setIsFetching] = useState(true);
  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const readersMonitoringResponse = useFetchGet(
    `superior-ac/readers-monitoring`,
    ifFetchAgain
  );
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  let readersMonitoring = JSON.parse(
    localStorage.getItem("readersMonitoring") || "{}"
  );

  let initialFreQuency = frequencyDictionary[10];
  let initialFreQuencyLocal = frequencyDictionary[readersMonitoring];
  if (initialFreQuencyLocal) {
    initialFreQuency = initialFreQuencyLocal;
  }

  const intervalSecondsRef = useRef<number>(initialFreQuency);
  const [currentFrequency, setCurrentFrequency] = useState(initialFreQuency);
  const [isFetchingAfterTimeout, setIsFetchingAfterTimeout] = useState(false);

  useEffect(() => {
    if (readersMonitoringResponse.error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      setIsFetching(true);
      return;
    }
    if (readersMonitoringResponse.data === null) return;

    let dataModified: rowInterface[] = [];
    readersMonitoringResponse.data.forEach((reader) => {
      dataModified.push({
        id: reader.id,
        reader: reader.serialNumber,
        destinationRoom: reader.targetRoom,
        passageOpen: reader?.output === 0 ? t("no") : t("yes"),
        door: reader.doorSensor ? t("open") : t("closed"),
        tamper: reader.tamperReader ? t("yes") : t("no"),
        noPower: reader.powerState ? "-" : t("yes"),
        noCommunication: reader.noCommunication ? t("yes") : "-",
        updateTime: reader.outputChange,
        safetyButton: reader.safetyButton,
        breakInBurglarAlarm: reader.breakInBurglarAlarm,
        doorOpenTooLongAlarm: reader.doorOpenTooLongAlarm,
      });
    });
    setRows(dataModified);
    setIsFetching(false);
    setIfFetchAgain(false);
    setIsFetchingAfterTimeout(false);
    const timeoutId = setTimeout(() => {
      setIfFetchAgain(true);
      setIsFetchingAfterTimeout(true);
    }, intervalSecondsRef.current * 1000);
    timeoutIdRef.current = timeoutId;
  }, [readersMonitoringResponse, t]);

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearInterval(timeoutIdRef.current);
      }
    };
  }, []);

  const Refreshfrequency = (
    <div className={styles.frequencyContent}>
      <div>{isFetchingAfterTimeout && <CircularProgress size={20} />}</div>
      <div>
        <SelectMenu
          name={
            <>{`${t("refresh_frequency")} - ${currentFrequency} ${t(
              "seconds"
            )}`}</>
          }
          variant="outlined"
          items={[
            {
              optionName: (
                <>
                  {frequencyDictionary[10]} {t("seconds")}
                </>
              ),
              onClick: () => {
                if (timeoutIdRef.current) {
                  clearInterval(timeoutIdRef.current);
                  timeoutIdRef.current = undefined;
                }
                localStorage.setItem("readersMonitoring", JSON.stringify(10));
                intervalSecondsRef.current = frequencyDictionary[10];
                setIsFetchingAfterTimeout(true);
                setCurrentFrequency(frequencyDictionary[10]);
                setIfFetchAgain(true);
              },
            },
            {
              optionName: (
                <>
                  {frequencyDictionary[20]} {t("seconds")}
                </>
              ),
              onClick: () => {
                if (timeoutIdRef.current) {
                  clearInterval(timeoutIdRef.current);
                  timeoutIdRef.current = undefined;
                }
                localStorage.setItem("readersMonitoring", JSON.stringify(20));
                intervalSecondsRef.current = frequencyDictionary[20];
                setIsFetchingAfterTimeout(true);
                setCurrentFrequency(frequencyDictionary[20]);
                setIfFetchAgain(true);
              },
            },
            {
              optionName: (
                <>
                  {frequencyDictionary[30]} {t("seconds")}
                </>
              ),
              onClick: () => {
                if (timeoutIdRef.current) {
                  clearInterval(timeoutIdRef.current);
                  timeoutIdRef.current = undefined;
                }
                localStorage.setItem("readersMonitoring", JSON.stringify(30));
                intervalSecondsRef.current = frequencyDictionary[30];
                setIsFetchingAfterTimeout(true);
                setCurrentFrequency(frequencyDictionary[30]);
                setIfFetchAgain(true);
              },
            },
          ]}
        />
      </div>
    </div>
  );

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "updateTime") {
      return row.updateTime
        ? moment(row.updateTime).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    return row[columnName];
  }, []);

  return (
    <>
      <TopBanner pathName={t("readers_monitoring")} />
      <div className="flexAndCenter">
        <div>
          <div>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setIfFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching && !isError && (
                <TableComponentMemo
                  layoutSettingName="layoutSuperiorAcReadersMonitoring"
                  toolbarComponent={Refreshfrequency}
                  rows={rows}
                  columns={columns}
                  ifEdit={false}
                  ifAdd={false}
                  ifDelete={false}
                  customExcelCellValue={customExportCellValue}
                  customPdfCellValue={customExportCellValue}
                  tableExportInfo={{
                    createdBy: authUser.displayedName,
                  }}
                />
              )}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    </>
  );
}
