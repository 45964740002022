import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import DialogStyled from "../../../../helpersComponents/PopupHelpers/dialogStyled";
import { DialogContentStyled } from "../../../../helpersComponents/PopupHelpers/dialogContentStyled";
import DialogTitleStyled from "../../../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AddEditVisualizationObjectsGroupsCategoriesPopupContent from "./AddEditVisualizationObjectsGroupsCategoriesPopupContent";

const AddEditVisualizationObjectsGroupsCategoriesPopup: React.FC<any> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const schema = Yup.object({
    name: Yup.string().required(t("field_required")),
    order: Yup.string().required(t("field_required")),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: { name: "", order: "0" },
  });

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {
          reset();
        },
        onEnter: () => {
          setErrorMessage("");
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitleStyled
        title={t("visualization_objects_groups_categories")}
        subtitle={
          rowId
            ? t("editing_visualization_objects_groups_category")
            : t("adding_visualization_objects_groups_category")
        }
      />

      <DialogContentStyled>
        <AddEditVisualizationObjectsGroupsCategoriesPopupContent
          rowId={rowId}
          control={control}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          successCallback={successCallback}
          setErrorMessage={setErrorMessage}
          handleSubmit={handleSubmit}
          setIsFetching={setIsFetching}
          watch={watch}
        />
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="add-edit-visualization-object-category-form"
        onConfirmText={rowId ? t("confirm") : t("add")}
        onCancel={() => {
          closePopup();
        }}
        isLoading={isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default AddEditVisualizationObjectsGroupsCategoriesPopup;
