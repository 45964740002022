import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import { faHome, faInfo } from "@fortawesome/free-solid-svg-icons";
import { ProfileType } from "../../../enums/profileType";
import EvacuationReport from "../../evacuationReport/EvacuationReport";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArrayEvacuationReport: navLinksArrayTimeWorkerInterface = {
    profileId: ProfileType.EVACUATION_REPORT,
    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/evacuation-report/profile",
        component: () => <Profile />,
      },
      {
        icon: faHome,
        name: t("evacuation_report"),
        ifHidden: false,
        singleLink: true,
        path: "/evacuation-report/home",
        component: (props) => <EvacuationReport {...props} />,
      },
    ],
  };

  return navLinksArrayEvacuationReport;
};

export default useNavLinksArray;
