import { useTranslation } from "react-i18next";
import styles from "./VisualizationObjectLayers.module.scss";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import ColorFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/ColorField/ColorFieldReactHookForm";

const VisualizationObjectGraphLayersItem: React.FC<any> = ({
  index,
  control,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles["grid-container"]}>
      {/*Kolor wypełnienia*/}
      <div className={styles["grid-item"]}>{t("background_color")}:</div>
      <div className={styles["grid-item"]}>
        <ColorFieldReactHookForm
          id={`layers.${index}.graph_background_color`}
          name={`layers.${index}.graph_background_color`}
          control={control}
          label={t("background_color")}
        />
      </div>

      {/*Kolor obramowania*/}
      <div className={styles["grid-item"]}>{t("border_color")}:</div>
      <div className={styles["grid-item"]}>
        <ColorFieldReactHookForm
          id={`layers.${index}.graph_border_color`}
          name={`layers.${index}.graph_border_color`}
          control={control}
          label={t("border_color")}
        />
      </div>

      {/*Szerokość obramowania*/}
      <div className={styles["grid-item"]}>{t("border_width")}:</div>
      <div className={styles["grid-item"]}>
        <TextFieldReactHookForm
          id={`layers.${index}.graph_border_width`}
          name={`layers.${index}.graph_border_width`}
          control={control}
          label={t("border_width")}
          type={"number"}
        />
      </div>

      {/*Wypełnienie*/}
      <div className={styles["grid-item"]}>{t("fill_opacity")}:</div>
      <div className={styles["grid-item"]}>
        <TextFieldReactHookForm
          id={`layers.${index}.graph_background_color_opacity`}
          name={`layers.${index}.graph_background_color_opacity`}
          control={control}
          label={t("fill_opacity")}
          type={"number"}
        />
      </div>
    </div>
  );
};

export default VisualizationObjectGraphLayersItem;
