import React, { ReactElement, useRef, ChangeEvent, useMemo } from "react";
import { Controller } from "react-hook-form";
import { debounce } from "lodash";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function ColorFieldReactHookForm({
  name,
  label,
  showTopLabel,
  control,
  onChangeCustom,
  debounceDelay = 100,
  ...props
}: any): ReactElement {
  const colorInputRef = useRef<HTMLInputElement>(null);

  const debouncedOnChange = useMemo(
    () =>
      debounce((value: string, onChange: (value: string) => void) => {
        onChange(value);
      }, debounceDelay),
    [debounceDelay]
  );

  const handleColorChange = (
    color: string,
    onChange: (value: string) => void
  ) => {
    debouncedOnChange(color, onChange);
    if (onChangeCustom)
      onChangeCustom({ target: { value: color } } as ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement
      >);
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    const color = e.target.value;
    debouncedOnChange(color, onChange);
  };

  const handleColorPickerClick = () => {
    if (colorInputRef.current) {
      colorInputRef.current.click();
    }
  };

  const handleClear = (onChange: (value: string) => void) => {
    onChange("");
  };

  return (
    <div>
      {showTopLabel && (
        <div>
          <b>{label}:</b>
        </div>
      )}

      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "24px",
                height: "24px",
                backgroundColor: value || "transparent",
                border: value ? "1px solid #ccc" : "1px solid gray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={handleColorPickerClick}
            >
              <input
                type="color"
                ref={colorInputRef}
                style={{ visibility: "hidden" }}
                value={value || "#000000"}
                onChange={(e) => handleColorChange(e.target.value, onChange)}
              />
            </Box>
            <TextField
              {...props}
              value={value || ""}
              error={!!error}
              helperText={error ? error.message : null}
              onChange={(e) => handleInputChange(e, onChange)}
              onBlur={onBlur}
              sx={{ maxWidth: "100px" }}
            />

            <IconButton onClick={() => handleClear(onChange)} size="small">
              <FontAwesomeIcon icon={faTimes} fontSize="small" />
            </IconButton>
          </Box>
        )}
      />
    </div>
  );
}
