export function setTimeToDate(date: Date, timeString: string): Date {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const dateWithHours = new Date(date);
  dateWithHours.setHours(hours);
  dateWithHours.setMinutes(minutes);
  if (seconds !== undefined) {
    dateWithHours.setSeconds(seconds);
  } else {
    dateWithHours.setSeconds(0);
  }
  return dateWithHours;
}
