import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../profile.module.scss";
import { selectAuthUser } from "../../../reducers/session";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectTimeWorkerProfile,
  setTimeWorkerProfile,
} from "../../../reducers/profiles";
import { useTranslation } from "react-i18next";
import profileIcon from "../../Navbar/Images/empty-profile-picture.png";
import SelectLanguage from "../../SelectLanguage";
import { selectSettings } from "../../../reducers/settings";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faEdit } from "@fortawesome/free-solid-svg-icons";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import AccordionStyled from "../../helpersComponents/MaterialUi/Accordion/AccordionStyled";
import TimeWorkersHolidaysLimits from "../../helpersComponents/TimeWorkersHolidaysLimits/timeWorkersHolidaysLimits";
import ChangePinPopup from "./../Popups/ChangePinPopup";
import QRCode from "qrcode.react";
import StyledBlock from "../../helpersComponents/StyledBlock/styledBlock";
import AddEditHomeOfficeAddressPopup from "./Popups/PopupAddEditHomeOfficeAddress";

function TimeWorkerProfileContent() {
  const settings = useAppSelector(selectSettings);
  const authUser = useAppSelector(selectAuthUser);
  const profile = useAppSelector(selectTimeWorkerProfile);
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(true);
  const [fetchAgain, setFetchAgain] = useState(true);
  const [mobileToken, setMobileToken] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const [ififShowPin, setIfifShowPin] = useState<boolean>(false);
  const authUserId = authUser.currentProfile.subjectId;
  const [isChangePinPopupOpen, setIsChangePinPopupOpen] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const columns = [
    { name: "street", title: t("street") },
    { name: "city", title: t("city") },
  ];

  const fetchMobileToken = async () => {
    await fetch(window.globalConfig.API_URL + "/api/account/token-for-mobile", {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: "bearer " + authUser.token,
      },
    })
      .then((response) => {
        if (response.status === 200) return response.text();
        throw response.status;
      })
      .then((data) => {
        setIsFetching(false);
        setFetchAgain(false);
        setMobileToken(data);
      })
      .catch((error) => {
        setIsError(true);
        setFetchAgain(false);
      });
    setIsFetching(false);
  };
  const fetchMobileTokenRef = useRef(fetchMobileToken);

  useEffect(() => {
    if (fetchAgain === false) return;
    async function anyNameFunction() {
      await fetchMobileTokenRef.current();
    }
    anyNameFunction();
  }, [fetchAgain]);

  const [
    homeOfficeAddresses,
    fetchingStatesHomeOfficeAddresses,
    fetchAgainHomeOfficeAddresse,
  ] = useFetchAndSetGET<HomeOfficeAddress[]>({
    path: `worker-time/${authUserId}/home-office-addresses`,
    startFetchOnInitial: true,
  });

  const changePin = () => {
    setIsChangePinPopupOpen(true);
  };

  const commitChanges = useCallback(() => {
    fetchAgainHomeOfficeAddresse();
  }, [fetchAgainHomeOfficeAddresse]);

  return (
    <>
      <div>
        <div className={styles.profilHeader}>
          <div className={styles.profilHeaderLeft}>
            <div className={styles.profilIcon}>
              {profile?.photo ? (
                <img
                  src={"data:image/png;base64, " + profile.photo}
                  alt="Profil"
                />
              ) : (
                <img src={profileIcon} alt="Profil" />
              )}
            </div>
            <div className={styles.profilTopInfo}>
              <div>
                <strong>{profile.firstName + " " + profile.lastName}</strong>
              </div>
              <div>{profile.position}</div>
              <div>
                {profile.company} -{">"} {profile.organizationalUnits}
              </div>
            </div>
          </div>
          <div className={styles.qrCodeDiv}>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching &&
                !isError &&
                mobileToken &&
                settings.WebPracownikRCPUkryjKodQRMenuOMnie?.value !== "1" && (
                  <QRCode
                    size={192}
                    value={`{"token": "${mobileToken}", "apiUrl": "${window.globalConfig.API_URL}"}`}
                  />
                )}
            </LoadingWrapper>
          </div>
          <div className={styles.selectLanguageProfile}>
            <SelectLanguage />
          </div>
        </div>
      </div>
      <div>
        <StyledBlock>
          <div className={styles.profileInfoRowsContrainer}>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                <div>
                  <strong>{t("evidence_number")}:</strong>
                </div>
                <div>{profile.evidenceNumber}</div>
              </div>
              <div
                className={
                  styles.profileInfoSecondColumn +
                  " " +
                  styles.profileInfoColumn
                }
              >
                <div>
                  <strong>PIN:</strong>
                </div>

                <div className={styles.pinContainer}>
                  <div>
                    {profile?.pin ? (
                      <>
                        {ififShowPin ? (
                          profile?.pin
                        ) : (
                          <span className={styles.fontPassword}>****</span>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className={styles.faPlusTable}
                      icon={ififShowPin ? faEye : faEyeSlash}
                      onClick={() => setIfifShowPin(!ififShowPin)}
                    />
                  </div>
                  <div className={styles.editButton}>
                    <FontAwesomeIcon
                      className={styles.faPlusTable}
                      icon={faEdit}
                      onClick={() => changePin()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>Login:</strong>
                </div>
                <div>{profile.login}</div>
              </div>
              <div
                className={
                  styles.profileInfoSecondColumn +
                  " " +
                  styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>ID:</strong>
                </div>
                <div>{profile.id}</div>
              </div>
            </div>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>{t("hire_date")}:</strong>
                </div>
                <div>
                  {profile.hireDate &&
                    new Date(profile.hireDate).toLocaleString(
                      t("scheduler_language"),
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                </div>
              </div>
              <div
                className={
                  styles.profileInfoSecondColumn +
                  " " +
                  styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>{t("upcoming_end_date")}:</strong>
                </div>
                <div>
                  {profile.fireDate &&
                    new Date(profile.fireDate).toLocaleString(
                      t("scheduler_language"),
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                </div>
              </div>
            </div>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>{t("validity_of_medical_checkups")}:</strong>
                </div>
                <div>
                  {profile.examinationExpirationDate &&
                    new Date(profile.examinationExpirationDate).toLocaleString(
                      t("scheduler_language"),
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                </div>
              </div>
              <div
                className={
                  styles.profileInfoSecondColumn +
                  " " +
                  styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>{t("birth_date")}:</strong>
                </div>
                <div>
                  {profile.dateOfBirth &&
                    new Date(profile.dateOfBirth).toLocaleString(
                      t("scheduler_language"),
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                </div>
              </div>
            </div>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>
                    {settings?.Data1 ? settings.Data1.value : <>{t("date")}1</>}
                    :
                  </strong>
                </div>
                <div>
                  {profile.userDate1 &&
                    new Date(profile.userDate1).toLocaleString(
                      t("scheduler_language"),
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                </div>
              </div>
              <div
                className={
                  styles.profileInfoSecondColumn +
                  " " +
                  styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>
                    {settings?.Data2 ? settings.Data2.value : <>{t("date")}2</>}
                    :
                  </strong>
                </div>
                <div>
                  {profile.userDate2 &&
                    new Date(profile.userDate2).toLocaleString(
                      t("scheduler_language"),
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                </div>
              </div>
            </div>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>
                    {settings?.Data3 ? settings.Data3.value : <>{t("date")}3</>}
                    :
                  </strong>
                </div>
                <div>
                  {profile.userDate3 &&
                    new Date(profile.userDate3).toLocaleString(
                      t("scheduler_language"),
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                </div>
              </div>
              <div
                className={
                  styles.profileInfoSecondColumn +
                  " " +
                  styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>
                    {settings?.Data4 ? settings.Data4.value : <>{t("date")}4</>}
                    :
                  </strong>
                </div>
                <div>
                  {profile.userDate4 &&
                    new Date(profile.userDate4).toLocaleString(
                      t("scheduler_language"),
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                </div>
              </div>
            </div>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>
                    {settings?.Data5 ? settings.Data5.value : <>{t("date")}5</>}
                    :
                  </strong>
                </div>
                <div>
                  {profile.userDate5 &&
                    new Date(profile.userDate5).toLocaleString(
                      t("scheduler_language"),
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                </div>
              </div>
              <div
                className={
                  styles.profileInfoSecondColumn +
                  " " +
                  styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>
                    {settings?.Data6 ? settings.Data6.value : <>{t("date")}6</>}
                    :
                  </strong>
                </div>
                <div>
                  {profile.userDate6 &&
                    new Date(profile.userDate6).toLocaleString(
                      t("scheduler_language"),
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                </div>
              </div>
            </div>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>
                    {settings?.Pole1 ? (
                      settings.Pole1.value
                    ) : (
                      <>{t("field")}1</>
                    )}
                    :
                  </strong>
                </div>
                <div>{profile.field1}</div>
              </div>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>
                    {settings?.Pole2 ? (
                      settings.Pole2.value
                    ) : (
                      <>{t("field")}2</>
                    )}
                    :
                  </strong>
                </div>
                <div>{profile.field2}</div>
              </div>
            </div>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>
                    {settings?.Pole3 ? (
                      settings.Pole3.value
                    ) : (
                      <>{t("field")}3</>
                    )}
                    :
                  </strong>
                </div>
                <div>{profile.field3}</div>
              </div>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>
                    {settings?.Pole4 ? (
                      settings.Pole4.value
                    ) : (
                      <>{t("field")}4</>
                    )}
                    :
                  </strong>
                </div>
                <div>{profile.field4}</div>
              </div>
            </div>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>
                    {settings?.Pole5 ? (
                      settings.Pole5.value
                    ) : (
                      <>{t("field")}5</>
                    )}
                    :
                  </strong>
                </div>
                <div>{profile.field5}</div>
              </div>
            </div>

            <div className={styles.accordionContainer}>
              <AccordionStyled
                title={t("homeOfficeAddresses")}
                defaultExpanded={true}
              >
                {!fetchingStatesHomeOfficeAddresses.isFetching && (
                  <TableComponent
                    fetchingState={{
                      fetchAgain: fetchAgainHomeOfficeAddresse,
                      isError: fetchingStatesHomeOfficeAddresses.isError,
                      isFetching: fetchingStatesHomeOfficeAddresses.isFetching,
                    }}
                    layoutSettingName="layoutHomeOfficeAddressesWorkers"
                    rows={homeOfficeAddresses || []}
                    columns={columns || []}
                    backgroundColor="#fff"
                    pageSize={8}
                    hideActionsAndVisibilityColumns={true}
                    popup={AddEditHomeOfficeAddressPopup}
                    ifAdd={
                      settings.WebDodawanieAdresuPracyZdalnejPrzezPracownika
                        ?.value === "1"
                    }
                    ifEdit={
                      settings.WebEdycjaAdresuPracyZdalnejPrzezPracownika
                        ?.value === "1"
                    }
                    commitChanges={commitChanges}
                  />
                )}
              </AccordionStyled>
            </div>

            <div className={styles.accordionContainer}>
              <AccordionStyled
                title={t("holidays_limit")}
                defaultExpanded={true}
              >
                <TimeWorkersHolidaysLimits />
              </AccordionStyled>
            </div>
          </div>
        </StyledBlock>
      </div>

      {isChangePinPopupOpen && (
        <ChangePinPopup
          changePinApiPath={`worker-time/${authUserId}/time-workers/${authUserId}`}
          isOpen={isChangePinPopupOpen}
          closePopup={() => {
            setIsChangePinPopupOpen(false);
          }}
          successCallback={(newPin) => {
            dispatch(setTimeWorkerProfile({ ...profile, pin: newPin }));
            setIsChangePinPopupOpen(false);
          }}
        />
      )}
    </>
  );
}

export default TimeWorkerProfileContent;
