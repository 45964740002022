import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import UsersManagementContent from "../../helpersComponents/UserManagement/UsersManagementContent";

const SwitchStyled = withStyles({
  switchBase: {
    color: "#1c84c6",
    "&$checked": {
      color: "#1c84c6",
    },
    "&$checked + $track": {
      backgroundColor: "#1c84c6",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#1c84c6",
    "$checked$checked + &": {
      backgroundColor: "#1c84c6",
    },
  },
})(Switch);

const UsersManagementSuperiorAC = () => {
  return (
    <div>
      <UsersManagementContent
        pathUsers="superior-ac/ac-users"
        pathUserPermissions="superior-ac/ac-user-permissions"
        pathReaders="superior-ac/readers"
        pathCompanies="superior-ac/ac-companies"
        pathDepartments="superior-ac/departments"
        pathZones="superior-ac/zones"
        pathGroups="superior-ac/groups?isActive=true"
        pathPolicies="superior-ac/policies?isActive=true"
        layoutSettingName="layoutSuperiorACUsersManagementRecords"
        SwitchStyled={SwitchStyled}
      />
    </div>
  );
};

export default UsersManagementSuperiorAC;
