import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableComponent from "../../../TableComponent/tableComponent";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import computeDateRangeFetch from "../../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import TimeWithDescriptionSmall2 from "../../../NumberWithDescription/NumberWithDescriptionSmall2";
import convertMinsToHrsMins from "../../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import React from "react";
import LoadingWrapper from "../../../LoadingWrapper/loadingWrapper";
import toLocaleStringCustom from "../../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";

interface IProps {
  day: Date;
  timeWorkersIds: number[];
}

interface IWorkerRecord {
  firstName: string;
  lastName: string;
  evidenceNumber: string;
  daySchemaFrom: Date;
  daySchemaTo: Date;
  entry: Date;
  exit: Date;
  overtimeToTakeToday: number;
  overtimeToTakeRequestDay: number;
  overtimeToTakeAllRequests: number;
  daysToTakeToday: number;
  daysToTakeRequestDay: number;
  daysToTakeAllRequests: number;
  periodLimit: number;
  annualLimit: number;
  periodRemained: number;
  annualRemained: number;
  periodFrom: Date;
  periodTo: Date;
}

const WorkersInfoTable: React.FC<IProps> = ({ day, timeWorkersIds }) => {
  const { t } = useTranslation();

  const columns = [
    { name: "employee", title: t("employee") },
    {
      name: "overtimeToTake",
      title: t("overtime_to_take"),
      customFormatter: (row) => (
        <div>
          <TimeWithDescriptionSmall2
            parametrs={[
              { name: t("overtime_to_take") },
              {
                time: convertMinsToHrsMins(row.overtimeToTakeToday),
                description: t("today"),
              },
              {
                time: convertMinsToHrsMins(row.overtimeToTakeRequestDay),
                description: t("request_day"),
              },
              {
                time: convertMinsToHrsMins(row.overtimeToTakeAllRequests),
                description: t("all_requests"),
              },
            ]}
          />
        </div>
      ),
    },
    {
      name: "daysToTake",
      title: t("days_to_take"),
      customFormatter: (row) => (
        <div>
          <TimeWithDescriptionSmall2
            parametrs={[
              { name: t("days_to_take") },
              {
                time: row.daysToTakeToday,
                description: t("today"),
              },
              {
                time: row.daysToTakeRequestDay,
                description: t("request_day"),
              },
              {
                time: row.daysToTakeAllRequests,
                description: t("all_requests"),
              },
            ]}
          />
        </div>
      ),
    },
    {
      name: "overtimeSummary",
      title: t("summary"),
      customFormatter: (row) => (
        <div>
          <TimeWithDescriptionSmall2
            parametrs={[
              {
                time: convertMinsToHrsMins(row.remainedInPeriod),
                description: t("remained_in_period"),
                colorNegative: true,
              },
              {
                time: convertMinsToHrsMins(row.annualRemained),
                description: t("annual_remained"),
                colorNegative: true,
              },
              {
                time: convertMinsToHrsMins(row.usedOvertimes),
                description: t("used_overtimes"),
                colorNegative: true,
              },
              {
                time: toLocaleStringCustom({
                  date: new Date(row.periodFrom),
                  t,
                }),
                description: t("period_from"),
              },
              {
                time: toLocaleStringCustom({
                  date: new Date(row.periodTo),
                  t,
                }),
                description: t("period_to"),
              },
              {
                time: convertMinsToHrsMins(row.periodLimit),
                description: t("period_limit"),
              },
              {
                time: convertMinsToHrsMins(row.annualLimit),
                description: t("annual_limit"),
              },
            ]}
          />
        </div>
      ),
    },
    {
      name: "daySchema",
      title: t("day_schema"),
      customFormatter: (row) => (
        <div>
          <TimeWithDescriptionSmall2
            parametrs={[
              {
                time:
                  new Date(row.daySchemaFrom).toLocaleString(
                    t("scheduler_language"),
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  ) +
                  " - " +
                  new Date(row.daySchemaTo).toLocaleString(
                    t("scheduler_language"),
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  ),
                description: t("plan"),
              },
              {
                time:
                  new Date(row.entry).toLocaleString(t("scheduler_language"), {
                    hour: "2-digit",
                    minute: "2-digit",
                  }) +
                  " - " +
                  new Date(row.exit).toLocaleString(t("scheduler_language"), {
                    hour: "2-digit",
                    minute: "2-digit",
                  }),
                description: t("execution"),
              },
            ]}
          />
        </div>
      ),
    },
  ];

  const [rows, setRows] = useState<IWorkerRecord[]>([]);

  const successCallback = useCallback((response) => {
    if (response.resJson) {
      let result: any = [];
      for (let i = 0; i < response.resJson.length; i++) {
        let tempItem = response.resJson[i];

        let tempObj = {
          id: tempItem.timeWorkerId,
          employee: `${tempItem.firstName} ${tempItem.lastName}`,
          evidenceNumber: tempItem.evidenceNumber,
          daySchemaFrom: tempItem.daySchemaFrom,
          daySchemaTo: tempItem.daySchemaTo,
          entry: tempItem.entry,
          exit: tempItem.exit,
          overtimeToTakeToday: tempItem.overtimeToTakeToday,
          overtimeToTakeRequestDay: tempItem.overtimeToTakeRequestDay,
          overtimeToTakeAllRequests: tempItem.overtimeToTakeAllRequests,
          daysToTakeToday: tempItem.daysToTakeToday,
          daysToTakeRequestDay: tempItem.daysToTakeRequestDay,
          daysToTakeAllRequests: tempItem.daysToTakeAllRequests,

          periodLimit: tempItem.periodLimit,
          annualLimit: tempItem.annualLimit,
          remainedInPeriod: tempItem.remainedInPeriod,
          annualRemained: tempItem.annualRemained,
          periodFrom: tempItem.periodFrom,
          periodTo: tempItem.periodTo,
          usedOvertimes: tempItem.usedOvertimes,
        };
        result.push(tempObj);
      }

      setRows(result);
    }
  }, []);

  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `superior-time/hours-requests-information`,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (timeWorkersIds && timeWorkersIds.length > 0) {
      let dataToSend = {
        timeWorkersIds: timeWorkersIds,
        day: computeDateRangeFetch(day),
      };

      setBodyRequest(JSON.stringify(dataToSend));
    }
  }, [day, timeWorkersIds]);

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  return (
    <LoadingWrapper
      isLodadingProgress={fetchingStatePostData.isFetching}
      isError={fetchingStatePostData.isError}
      setIfFetchAgain={(fetchAgain) => {
        if (fetchAgain) {
          fetchAgainPostData();
        }
      }}
    >
      <TableComponent
        rows={rows || []}
        columns={columns}
        layoutSettingName="layoutSuperiorTimeHoursRequestsInfo"
        ifHideWholeToolbalr={true}
        filteringRowDefaultHidden={true}
        disablePagination={true}
        columnsWidth={[
          {
            columnName: "employee",
            width: 220,
          },
          {
            columnName: "daySchema",
            width: 220,
          },
          {
            columnName: "overtimeToTake",
            width: 300,
          },
          {
            columnName: "daysToTake",
            width: 300,
          },
          {
            columnName: "overtimeSummary",
            width: 800,
          },
        ]}
      />
    </LoadingWrapper>
  );
};

export default WorkersInfoTable;
