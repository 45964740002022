import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  saveImageToIndexedDB,
  loadAllImagesFromIndexedDB,
  deleteImageFromIndexedDB,
  isIndexedDBAvailable,
} from "../../HelpersFunctions/indexedDB/idb";
import { useAppSelector } from "../../store/hooks";
import { selectAuthUser, selectLanguage } from "../../reducers/session";
import { ProfileType } from "../../enums/profileType";
import { useEffect, useState } from "react";

const fetchImagesLibraryFromAPI = async (
  url: string,
  lang: string,
  token: string
): Promise<ISMSImagesLibraryItem[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: "bearer " + token,
      "Accept-Language": lang,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const fetchImageContentFromAPI = async (
  url: string,
  lang: string,
  token: string
): Promise<ArrayBuffer> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: "bearer " + token,
      "Accept-Language": lang,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.arrayBuffer();
};

const syncWithIndexedDB = async (
  imagesFromAPI: ISMSImagesLibraryItem[],
  fetchImageContentFromAPI: (id: number) => Promise<ArrayBuffer>
): Promise<ISMSCompleteImage[]> => {
  const imagesFromDB = await loadAllImagesFromIndexedDB();
  const imagesFromDBMap = new Map(imagesFromDB.map((img) => [img.id, img]));

  const updatedImages: ISMSCompleteImage[] = [];

  for (const image of imagesFromAPI) {
    const dbImage = imagesFromDBMap.get(image.id);
    if (!dbImage || dbImage.fileContentHash !== image.fileContentHash) {
      const fileContent = await fetchImageContentFromAPI(image.id);
      const newImageDB: ISMSImagesLibraryItemDB = {
        id: image.id,
        fileContent,
        fileContentHash: image.fileContentHash,
      };
      await saveImageToIndexedDB(newImageDB);
      updatedImages.push({ ...image, fileContent });
      imagesFromDBMap.delete(image.id);
    } else {
      updatedImages.push({ ...image, fileContent: dbImage.fileContent });
    }
  }

  for (const [id] of imagesFromDBMap) {
    await deleteImageFromIndexedDB(id);
  }

  return updatedImages;
};

const useSMSImagesLibrary = () => {
  const queryClient = useQueryClient();
  const authUser = useAppSelector(selectAuthUser);
  const language = useAppSelector(selectLanguage);
  const [initialData, setInitialData] = useState<
    ISMSCompleteImage[] | undefined
  >(undefined);

  const profileName =
    authUser.currentProfile?.type === ProfileType.SMS_ADMINISTRATOR
      ? "sms-administrator"
      : "sms-user";

  const downloadImagesLibraryUrl =
    window.globalConfig.API_URL + "/api/" + profileName + "/images-library";
  const downloadImageBaseUrl =
    window.globalConfig.API_URL + "/api/" + profileName + "/download-image";

  useEffect(() => {
    if (
      authUser.currentProfile?.type !== ProfileType.SMS_ADMINISTRATOR &&
      authUser.currentProfile?.type !== ProfileType.SMS_USER
    )
      return;

    const loadInitialData = async () => {
      if (isIndexedDBAvailable()) {
        const dbData = await loadAllImagesFromIndexedDB();
        const apiData = await fetchImagesLibraryFromAPI(
          downloadImagesLibraryUrl,
          language,
          authUser.token
        );
        const completeData: ISMSImagesLibraryItem[] = apiData.map((image) => {
          const dbImage = dbData.find((dbImg) => dbImg.id === image.id);
          return {
            ...image,
            fileContent: dbImage?.fileContent,
          } as ISMSImagesLibraryItem;
        });
        setInitialData(completeData);
      } else {
        setInitialData([]);
      }
    };

    loadInitialData();
  }, [
    authUser.token,
    downloadImagesLibraryUrl,
    language,
    authUser.currentProfile?.type,
  ]);

  return useQuery<ISMSCompleteImage[], Error>(
    ["smsImagesLibrary"],
    () => {
      return fetchImagesLibraryFromAPI(
        downloadImagesLibraryUrl,
        language,
        authUser.token
      );
    },
    {
      initialData,
      onSuccess: async (data: any) => {
        if (isIndexedDBAvailable()) {
          const completeData = await syncWithIndexedDB(
            data,
            (id: number): Promise<ArrayBuffer> => {
              const url = downloadImageBaseUrl + `/${id}`;
              return fetchImageContentFromAPI(url, language, authUser.token);
            }
          );
          queryClient.setQueryData(["smsImagesLibrary"], completeData);
        }
      },
      enabled:
        authUser.currentProfile?.type === ProfileType.SMS_ADMINISTRATOR ||
        authUser.currentProfile?.type === ProfileType.SMS_USER,
    }
  );
};

export default useSMSImagesLibrary;
