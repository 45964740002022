import React, { useState, useEffect } from "react";
import "./addressBook.scss";
import { useTranslation } from "react-i18next";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import useFetchGet from "../../../hooks/fetchHooks/get/useFetchGet";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";

const AddressBookContent = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [rows, setRows] = useState<object[]>([]);
  const columns = [
    { name: "worker", title: t("worker") },
    { name: "email", title: t("email") },
    { name: "phone", title: t("phone") },
  ];
  const authUserId = authUser.currentProfile.subjectId;

  const [isFetching, setIsFetching] = useState(true);

  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/time-workers?source=TimeWorkerAddressBook`,
    ifFetchAgain
  );

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (data === null) return;

    let dataModified: any = [];
    data.forEach((value) => {
      dataModified.push({
        id: value.id,
        worker: `${value.firstName} ${value.lastName}`,
        email: <a href={`mailto:${value.email}`}>{value.email}</a>,
        phone: value.phone,
      });
    });

    setRows(dataModified);
    setIsFetching(false);
  }, [data, error, t]);

  return (
    <>
      <TopBanner pathName={t("address_book")} />
      <div className="flexAndCenter">
        <div>
          <div>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setIfFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching && !isError && (
                <TableComponent
                  layoutSettingName="layoutWorkerTimeAddressBook"
                  rows={rows}
                  columns={columns}
                  // columnOrder={this.state.columnOrder}
                  ifEdit={false}
                  ifAdd={false}
                  ifDelete={false}
                  defaultSorting={[{ columnName: "worker", direction: "asc" }]}
                  tableExportInfo={{
                    createdBy: authUser.displayedName,
                  }}
                />
              )}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressBookContent;
