import {
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckboxStyled,
  DatePickerStyled,
  TextFieldStyled,
} from "../../helpersComponents/MaterialUi";
import Button from "../../helpersComponents/Button/button";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./addProject.module.scss";
import image_1 from "./defaultProjectsImages/image_1.svg";
import image_2 from "./defaultProjectsImages/image_2.svg";
import image_3 from "./defaultProjectsImages/image_3.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useFetchPut from "../../../hooks/fetchHooks/put/useFetchPut";
import useFetchPost from "../../../hooks/fetchHooks/post/useFetchPost";
import computeDate from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import { useSnackbar } from "notistack";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";

const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

interface Props {
  setIsDialogOpen: React.Dispatch<boolean>;
  setIfFetchAgain: React.Dispatch<boolean>;
  fetchedProject: any;
}

export default function AddEditProjectDialogContent({
  setIsDialogOpen,
  setIfFetchAgain,
  fetchedProject,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [postRequestBody, setPostRequestBody] = useState<any>(false);
  const [putRequestBody, setPutRequestBody] = useState<any>(false);
  const [putingPostingCircularProgress, setPutingPostingCircularProgress] =
    useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  let postRequest = useFetchPost(
    `worker-time/${authUserId}/projects/`,
    postRequestBody
  );
  let putRequest = useFetchPut(
    `worker-time/${authUserId}/projects/${fetchedProject.id}`,
    putRequestBody
  );
  const [imagesArray, setImagesArray] = useState([image_1, image_2, image_3]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    if (fetchedProject?.icon) {
      setImagesArray((prev) => [fetchedProject.icon, ...prev]);
    }
  }, [fetchedProject]);

  const [responseErrorMessage, setResponseErrorMessage] = useState<string>("");

  const [name, setName] = useState<String>(
    fetchedProject ? fetchedProject.name : ""
  );
  const [nameError, setNameError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const [number, setNumber] = useState<String>(
    fetchedProject ? fetchedProject.number : ""
  );
  const [numberError, setNumberError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const [startDate, setStartDate] = useState<Date>(
    fetchedProject ? new Date(fetchedProject.dateFrom) : new Date()
  );
  const [startDateError, setStartDateError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const [endDate, setEndDate] = useState<Date>(
    fetchedProject?.dateTo ? new Date(fetchedProject.dateTo) : new Date()
  );
  const [endDateError, setEndDateError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [checkbox, setCheckbox] = React.useState({
    startDate: fetchedProject ? true : false,
    endDate: fetchedProject?.dateTo ? true : false,
  });

  const handleChangeCheckbox = (event) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (postRequest.data?.status === 201) {
      setIfFetchAgain(true);
      setIsDialogOpen(false);
      enqueueSnackbar(t("project_added"), { variant: "success" });
      setPutingPostingCircularProgress(false);
    } else if (putRequest.data?.status === 200) {
      setIfFetchAgain(true);
      setIsDialogOpen(false);
      enqueueSnackbar(t("project_edited"), { variant: "success" });
      setPutingPostingCircularProgress(false);
    } else if (putRequest.data?.status === 400) {
      let error = "";
      Object.keys(putRequest.data.errors).forEach((errorObject) => {
        putRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(error);
    } else if (postRequest.data?.status === 400) {
      let error = "";
      Object.keys(postRequest.data.errors).forEach((errorObject) => {
        postRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(error);
    } else if (postRequest.error !== null || putRequest.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    }
  }, [
    postRequest,
    putRequest,
    enqueueSnackbar,
    setIsDialogOpen,
    setIfFetchAgain,
    t,
  ]);

  const confirm = async () => {
    let ifFetch = true;

    if (name === "") {
      ifFetch = false;
      setNameError({
        isError: true,
        errorMessage: "",
      });
    } else {
      setNameError({
        isError: false,
        errorMessage: "",
      });
    }

    if (number === "" || number === null) {
      ifFetch = false;
      setNumberError({
        isError: true,
        errorMessage: "",
      });
    } else {
      setNumberError({
        isError: false,
        errorMessage: "",
      });
    }

    let bodyRequest = {};

    if (checkbox.startDate === true) {
      if (startDate === null || isNaN(startDate.getTime())) {
        setStartDateError({
          isError: true,
          errorMessage: t("invalid_date"),
        });
        ifFetch = false;
      } else {
        bodyRequest["dateFrom"] = computeDate(startDate, "T00:00:00");
        setStartDateError({
          isError: false,
          errorMessage: "",
        });
      }
    }

    if (checkbox.endDate === true) {
      if (endDate === null || isNaN(endDate.getTime())) {
        setEndDateError({
          isError: true,
          errorMessage: t("invalid_date"),
        });
        ifFetch = false;
      } else {
        bodyRequest["dateTo"] = computeDate(endDate, "T00:00:00");
        setEndDateError({
          isError: false,
          errorMessage: "",
        });
      }
    }

    if (ifFetch === false) return;
    bodyRequest["name"] = name;
    bodyRequest["number"] = number;
    bodyRequest["icon"] = await toDataURL(imagesArray[selectedImageIndex]);
    setPutingPostingCircularProgress(true);
    if (fetchedProject) {
      setPutRequestBody(bodyRequest);
    } else setPostRequestBody(bodyRequest);
  };
  const inputFileRef: any = useRef<HTMLInputElement>(null);

  return (
    <>
      <DialogTitle className="dialogTitleGlobal">
        <div className="titlePopup">
          {fetchedProject ? t("editing_project") : t("adding_project")}
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={styles.rows}>
          <div className={styles.formRow}>
            <div className={styles.rowtitle}>{t("name")}:</div>
            <div className={styles.rowInput}>
              <TextFieldStyled
                label={t("name")}
                defaultValue={name}
                required={true}
                isError={nameError.isError}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setNameError({
                      isError: true,
                      errorMessage: "required",
                    });
                  } else {
                    setNameError({
                      isError: false,
                      errorMessage: "",
                    });
                  }
                  setName(e.target.value);
                }}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.rowtitle}>{t("number")}:</div>
            <div className={styles.rowInput}>
              <TextFieldStyled
                label={t("number")}
                defaultValue={number}
                required={true}
                isError={numberError.isError}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setNumberError({
                      isError: true,
                      errorMessage: "required",
                    });
                  } else {
                    setNumberError({
                      isError: false,
                      errorMessage: "",
                    });
                  }
                  setNumber(e.target.value);
                }}
              />
            </div>
          </div>

          <div className={styles.selectProjectImageContainer}>
            <div>{t("select_project_image")}</div>
            <div className={styles.imagesContainer}>
              <div
                className={styles.addOwnImageCard}
                onClick={() => inputFileRef.current.click()}
              >
                <input
                  type="file"
                  ref={inputFileRef}
                  className={styles.inputFile}
                  // accept={"image/*"}
                  accept="image/jpg, image/jpeg, image/png, image/svg+xml"
                  onChange={(e: any) => {
                    if (e.target.files && e.target.files[0]) {
                      setSelectedImageIndex(imagesArray.length);
                      setImagesArray((prev) => [
                        ...prev,
                        URL.createObjectURL(e.target.files[0]),
                      ]);
                    }
                  }}
                />
                <div>
                  <div>
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                  <div className={styles.addOwnImageTitle}>
                    {t("add_image")}
                  </div>
                </div>
              </div>
              {imagesArray.map((image, imageIndex) => {
                return (
                  <div
                    key={imageIndex}
                    className={
                      styles.singleExampleImage +
                      " " +
                      (selectedImageIndex === imageIndex
                        ? styles.selectedImage
                        : "")
                    }
                    onClick={() => setSelectedImageIndex(imageIndex)}
                  >
                    <img src={image} alt="skalmex" />
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.rowtitle}>
              <FormControlLabel
                control={
                  <CheckboxStyled
                    checked={checkbox.startDate}
                    name="startDate"
                    onChange={handleChangeCheckbox}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    disabled={fetchedProject ? true : false}
                  />
                }
                label={<span className="chekbox">{t("date_from")}:</span>}
              />
            </div>
            <div className={styles.rowInput}>
              {checkbox.startDate && (
                <DatePickerStyled
                  view={["year", "month", "date"]}
                  format="dd-MM-yyyy"
                  date={startDate}
                  isError={startDateError.isError}
                  width="100%"
                  setDate={(date) => {
                    setStartDate(date);
                  }}
                />
              )}
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.rowtitle}>
              <FormControlLabel
                control={
                  <CheckboxStyled
                    checked={checkbox.endDate}
                    name="endDate"
                    onChange={handleChangeCheckbox}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={<span className="chekbox">{t("date_to2")}:</span>}
              />
            </div>
            <div className={styles.rowInput}>
              {checkbox.endDate && (
                <DatePickerStyled
                  view={["year", "month", "date"]}
                  format="dd-MM-yyyy"
                  date={endDate}
                  isError={endDateError.isError}
                  width="100%"
                  setDate={(date) => {
                    setEndDate(date);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>
        {!putingPostingCircularProgress ? (
          <>
            <Button onClick={() => setIsDialogOpen(false)} variant="outlined">
              {t("cancel")}
            </Button>
            <Button
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </>
  );
}
