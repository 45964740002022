import { useCallback, useEffect, useState } from "react";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { Tab, Tabs, Tooltip } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import erro400getTranslatedErrorString from "../../../../HelpersFunctions/erro400getTranslatedErrorString";
import { colorNameDictionary } from "./../../../ProfilesRouting/profilesDictionaries";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";
import TabPanel from "../../../helpersComponents/TabPanel/TabPanel";
import VisualizationObjectActions from "./Actions/VisualizationObjectActions";
import VisualizationObjectValues from "./Values/VisualizationObjectValues";
import VisualizationObjectLayers from "./Layers/VisualizationObjectLayers";
import VisualizationObjectStates from "./States/VisualizationObjectStates";
import VisualizationObjectBasicData from "./BasicData/VisualizationObjectBasicData";
import SMSVisualizationObjectLayerType from "../../../../enums/SMS/SMSVisualizationObjectLayerType";
import { v4 as uuidv4 } from "uuid";
import {
  selectTextFonts,
  selectVisualizationObjectsActionsTypes,
  selectVisualizationObjectsBuiltInStates,
  selectVisualizationObjectsLayersTypes,
} from "../../../../reducers/sms/visualizationData";
import styles from "./AddEditVisualizationObjectPopupContent.module.scss";
import { getValuesNamesFromCondition } from "../../../../HelpersFunctions/sms/visualizationObjectsHelpers";
import { useSMSVisualizationDataContext } from "../../../../HelpersFunctions/SMSVisualizationDataProvider";

const AddEditVisualizationObjectPopupContent: React.FC<any> = ({
  rowId,
  successCallback,
  setErrorMessage,
  handleSubmit,
  control,
  register,
  watch,
  setValue,
  getValues,
  appendValue,
  removeValue,
  appendState,
  removeState,
  appendLayer,
  removeLayer,
  insertLayer,
  appendAction,
  removeAction,
  errors,
  values,
  states,
  layers,
  actions,
  clearErrors,
}) => {
  const { t } = useTranslation();
  const authUserType = useAppSelector(selectAuthUser).currentProfile?.type;
  const [valueTab, setValueTab] = useState<number>(0);
  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const visualizationObjectsBuiltInStates = useAppSelector(
    selectVisualizationObjectsBuiltInStates
  );

  const [statesConditionsErrors, setStatesConditionsErrors] =
    useState<boolean>(false);
  const [layersErrors, setLayersErrors] = useState<boolean>(false);

  const textFonts = useAppSelector(selectTextFonts);

  const { imagesData, imagesLoading, imagesError } =
    useSMSVisualizationDataContext();

  const [
    visualizationObjectsGroups,
    fetchingStateVisualizationObjectsGroups,
    fetchAgainVisualizationObjectsGroups,
  ] = useFetchAndSetGET({
    path: `sms-administrator/visualization-objects-groups-library`,
    startFetchOnInitial: true,
  });

  const [
    visualizationObjectsStatesGroups,
    fetchingStateVisualizationObjectsStatesGroups,
    fetchAgainVisualizationObjectsStatesGroups,
  ] = useFetchAndSetGET({
    path: `sms-administrator/visualization-objects-states-groups-library`,
    startFetchOnInitial: true,
  });

  const [
    visualizationObject,
    fetchingStateVisualizationObject,
    fetchAgainVisualizationObject,
  ] = useFetchAndSetGET({
    path: `sms-administrator/visualization-objects/${rowId}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (rowId) {
      fetchAgainVisualizationObject();
    }
  }, [rowId, fetchAgainVisualizationObject]);

  useEffect(() => {
    if (
      !fetchingStateVisualizationObjectsGroups?.isFetching &&
      fetchingStateVisualizationObjectsGroups?.response?.status === 200 &&
      !fetchingStateVisualizationObjectsStatesGroups?.isFetching &&
      fetchingStateVisualizationObjectsStatesGroups?.response?.status === 200 &&
      !fetchingStateVisualizationObject?.isFetching &&
      fetchingStateVisualizationObject?.response?.status === 200
    ) {
      setDataLoaded(true);
    }
  }, [
    fetchingStateVisualizationObjectsGroups?.isFetching,
    fetchingStateVisualizationObjectsGroups?.response?.status,
    fetchingStateVisualizationObjectsStatesGroups?.isFetching,
    fetchingStateVisualizationObjectsStatesGroups?.response?.status,
    fetchingStateVisualizationObject?.isFetching,
    fetchingStateVisualizationObject?.response?.status,
  ]);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
  }, [successCallback]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t, setErrorMessage]
  );

  const [, fetchAgainAddEditVisualizationObject] = useFetchOtherThanGET({
    path: `sms-administrator/visualization-objects${rowId ? "/" + rowId : ""}`,
    method: rowId ? "PUT" : "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    successCallback: successCallbackAndClose,
    errorCallback,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditVisualizationObject();
    }
  }, [bodyRequest, fetchAgainAddEditVisualizationObject]);

  const isBasicDataValidationErrors = useCallback(() => {
    if (errors.name && errors.name?.ref?.name === "name") return true;
    return false;
  }, [errors.name]);

  const isValuesDataValidationErrors = useCallback(() => {
    if (errors.values) return true;
    return false;
  }, [errors.values]);

  const isStatesDataValidationErrors = useCallback(() => {
    if (errors.states || statesConditionsErrors) return true;
    return false;
  }, [errors.states, statesConditionsErrors]);

  const isLayersDataValidationErrors = useCallback(() => {
    if (errors.layers || layersErrors) return true;
    return false;
  }, [errors.layers, layersErrors]);

  const isActionsDataValidationErrors = useCallback(() => {
    if (errors.actions) return true;
    return false;
  }, [errors.actions]);

  const handleChangeValueTab = useCallback(
    (event: React.ChangeEvent<any>, newValue: number) => {
      setValueTab(newValue);
    },
    []
  );

  const onSubmit = (data: any) => {
    setErrorMessage("");
    setStatesConditionsErrors(false);
    setLayersErrors(false);

    let jsonDataToSend: any = {
      name: data.name,
      imageId: data.icon?.id,
      groupsIds: data.groups ? data.groups.map((el) => el.id) : [],
      description: data.description,
      values: data.values,
      states: data.states,
      layers: [],
      actions: [],
    };

    if (data.states) {
      for (let i = 0; i < data.states.length; i++) {
        if (data.states[i].group) {
          data.states[i]["groupId"] = data.states[i].group.id;
        }
      }
    }

    if (data.layers) {
      for (let i = 0; i < data.layers.length; i++) {
        let tempLayer: any = {
          id: data.layers[i].id,
          baseAnchoredLayerId: data.layers[i].baseAnchoredLayerId,
          name: data.layers[i].name,
          type: data.layers[i].type.id,
          value: data.layers[i].value,
          order: data.layers[i].order,
          group:
            data.layers[i].group === undefined ? null : data.layers[i].group,

          parameters: "",
          imageId: null,
          statesIds: [] as any,
        };

        if (data.layers[i].states) {
          for (let j = 0; j < data.layers[i].states.length; j++) {
            tempLayer.statesIds.push(data.layers[i].states[j].id.toString());
          }
        }

        switch (data.layers[i].type.id) {
          case SMSVisualizationObjectLayerType.IMAGE_LAYER:
            {
              let tempParametersObject = {
                image_width: data.layers[i].image_width,
                image_height: data.layers[i].image_height,
                image_rotation_angle: data.layers[i].image_rotation_angle,
                image_color: data.layers[i].image_color,
                image_opacity: data.layers[i].image_opacity,
                image_scale: data.layers[i].image_scale,
              };

              tempLayer["parameters"] = JSON.stringify(tempParametersObject);
              tempLayer["imageId"] = data.layers[i].image.id;
            }
            break;

          case SMSVisualizationObjectLayerType.GRAPH_LAYER:
            {
              let tempParametersObject = {
                graph_background_color:
                  data.layers[i].graph_background_color || "#FFFFFF",
                graph_border_color:
                  data.layers[i].graph_border_color || "#000000",
                graph_border_width: data.layers[i].graph_border_width || 0,
                graph_background_color_opacity:
                  data.layers[i].graph_background_color_opacity || 0.7,
              };

              tempLayer["parameters"] = JSON.stringify(tempParametersObject);
            }
            break;

          case SMSVisualizationObjectLayerType.TEXT_LAYER:
            {
              let tempParametersObject = {
                text: data.layers[i].text,
                text_font: data.layers[i].text_font?.id,
                text_color: data.layers[i].text_color || "",
                text_background_color:
                  data.layers[i].text_background_color || "",
                text_size: data.layers[i].text_size,
                text_options_italics:
                  data.layers[i].text_options_italics || false,
                text_options_bold: data.layers[i].text_options_bold || false,
                text_options_underline:
                  data.layers[i].text_options_underline || false,
                text_background_color_opacity:
                  data.layers[i].text_background_color_opacity,
                text_scale: data.layers[i].text_scale,
              };

              tempLayer["parameters"] = JSON.stringify(tempParametersObject);
            }
            break;
        }

        jsonDataToSend.layers.push(tempLayer);
      }
    }

    if (data.actions) {
      for (let i = 0; i < data.actions.length; i++) {
        let tempAction: any = {
          id: data.actions[i].id,
          name: data.actions[i].name,
          topic: data.actions[i].topic,
          value: data.actions[i].value,
          order: data.actions[i].order,
          type: data.actions[i].type.id,
          urlAddress: data.actions[i].urlAddress,
          username: data.actions[i].username,
          password: data.actions[i].password,
          openURLInNewWindow: data.actions[i].openURLInNewWindow,
          statesIds: [] as any,
        };

        if (data.actions[i].states) {
          for (let j = 0; j < data.actions[i].states.length; j++) {
            tempAction.statesIds.push(data.actions[i].states[j].id.toString());
          }
        }

        jsonDataToSend.actions.push(tempAction);
      }
    }

    let anyLayerWithObjectUnavailableState = false;

    for (let i = 0; i < data.layers.length; i++) {
      let foundState = data.layers[i].states.find(
        (el) => el.id.toString() === "-1"
      );

      if (foundState) {
        anyLayerWithObjectUnavailableState = true;
        break;
      }
    }

    if (!anyLayerWithObjectUnavailableState) {
      setLayersErrors(true);
      setErrorMessage(
        t("at_least_one_layer_required_with_object_unavailable_state_assigned")
      );
      return;
    }

    const allValuesNames: string[] = [];

    for (let i = 0; i < data.states.length; i++) {
      const foundValuesNames = getValuesNamesFromCondition(
        data.states[i].condition
      );

      if (foundValuesNames && foundValuesNames.length > 0) {
        for (let j = 0; j < foundValuesNames.length; j++) {
          if (!allValuesNames.includes(foundValuesNames[j])) {
            allValuesNames.push(foundValuesNames[j]);
          }
        }
      }
    }

    for (let i = 0; i < allValuesNames.length; i++) {
      let exists = false;

      for (let j = 0; j < data.values.length; j++) {
        if (data.values[j].name === allValuesNames[i]) {
          exists = true;
          break;
        }
      }

      if (!exists) {
        setStatesConditionsErrors(true);
        setErrorMessage(
          t("non_existent_values_were_used_in_the_state_conditions")
        );
        return;
      }
    }

    setBodyRequest(JSON.stringify(jsonDataToSend));
  };

  const layerTypes = useAppSelector(selectVisualizationObjectsLayersTypes);
  const actionsTypes = useAppSelector(selectVisualizationObjectsActionsTypes);

  useEffect(() => {
    if (rowId && dataLoaded && !imagesLoading) {
      let imagesDataMap = new Map<number, ISMSCompleteImage>();

      if (imagesData && imagesData.length > 0) {
        imagesDataMap = imagesData.reduce((acc, obj) => {
          acc.set(obj.id, obj);
          return acc;
        }, new Map());
      }

      setValue("name", visualizationObject.name);
      setValue("description", visualizationObject.description);

      if (visualizationObject.imageId) {
        let tempImage = imagesDataMap.get(visualizationObject.imageId);
        if (tempImage) {
          setValue("icon", tempImage);
        }
      }

      if (
        visualizationObject.groupsIds &&
        visualizationObject.groupsIds.length > 0
      ) {
        let selectedGroups = visualizationObjectsGroups.filter((el) =>
          visualizationObject.groupsIds.includes(el.id)
        );

        setValue("groups", selectedGroups);
      }

      let mappedValuesVirtualIds = {};
      let mappedStatesVirtualIds = {};

      if (visualizationObject.values) {
        for (let i = 0; i < visualizationObject.values.length; i++) {
          let tempVirtualId = uuidv4();
          appendValue({
            id: visualizationObject.values[i].id,
            virtualId: tempVirtualId,
            objectId: visualizationObject.values[i].objectId,
            name: visualizationObject.values[i].name,
            topic: visualizationObject.values[i].topic,
            condition: visualizationObject.values[i].condition,
            property: visualizationObject.values[i].property,
          });

          mappedValuesVirtualIds[tempVirtualId] =
            visualizationObject.values[i].id;
        }
      }

      let objectStates: any = [];
      [...visualizationObjectsBuiltInStates].forEach((el) =>
        objectStates.push(el)
      );

      if (visualizationObject.states) {
        for (let i = 0; i < visualizationObject.states.length; i++) {
          let tempVirtualId = uuidv4();
          let objToAdd = {
            id: visualizationObject.states[i].id,
            virtualId: tempVirtualId,
            objectId: visualizationObject.states[i].objectId,
            name: visualizationObject.states[i].name,
            condition: visualizationObject.states[i].condition,
            group: null,
          };

          if (visualizationObject.states[i].groupId) {
            objToAdd.group = visualizationObjectsStatesGroups.find(
              (el) => el.id === visualizationObject.states[i].groupId
            );
          }

          objectStates.push(objToAdd);
          appendState(objToAdd);

          mappedStatesVirtualIds[tempVirtualId] =
            visualizationObject.states[i].id;
        }
      }

      if (visualizationObject.actions) {
        for (let i = 0; i < visualizationObject.actions.length; i++) {
          let actionStates: any[] = [];

          if (visualizationObject.actions[i].statesIds.length > 0) {
            for (
              let j = 0;
              j < visualizationObject.actions[i].statesIds.length;
              j++
            ) {
              const foundObj = objectStates.find(
                (el) => el.id === visualizationObject.actions[i].statesIds[j]
              );

              if (foundObj) {
                actionStates.push(foundObj);
              }
            }
          }

          var objToAdd = {
            id: visualizationObject.actions[i].id,
            objectId: visualizationObject.actions[i].objectId,
            name: visualizationObject.actions[i].name,
            topic: visualizationObject.actions[i].topic,
            value: visualizationObject.actions[i].value,
            order: visualizationObject.actions[i].order,
            type: actionsTypes.find(
              (el) => el.id === visualizationObject.actions[i].type
            ),
            urlAddress: visualizationObject.actions[i].urlAddress,
            username: visualizationObject.actions[i].username,
            password: visualizationObject.actions[i].password,
            openURLInNewWindow:
              visualizationObject.actions[i].openURLInNewWindow,
            states: actionStates,
          };

          appendAction(objToAdd);
        }
      }

      if (visualizationObject.layers) {
        for (let i = 0; i < visualizationObject.layers.length; i++) {
          let layerStates: any[] = [];

          if (visualizationObject.layers[i].statesIds.length > 0) {
            for (
              let j = 0;
              j < visualizationObject.layers[i].statesIds.length;
              j++
            ) {
              const foundObj = objectStates.find(
                (el) => el.id === visualizationObject.layers[i].statesIds[j]
              );

              if (foundObj) {
                layerStates.push(foundObj);
              }
            }
          }

          let objToAdd: any = {
            id: visualizationObject.layers[i].id,
            layerId: visualizationObject.layers[i].id,
            objectId: visualizationObject.layers[i].objectId,
            name: visualizationObject.layers[i].name,
            order: visualizationObject.layers[i].order,
            group: visualizationObject.layers[i].group,
            type: layerTypes.find(
              (el) => el.id === visualizationObject.layers[i].type
            ),
            image: visualizationObject.layers[i].imageId
              ? imagesDataMap.get(visualizationObject.layers[i].imageId)
              : null,
            states: layerStates,

            /*Image layers params*/
            image_width: "",
            image_height: "",
            image_rotation_angle: "",
            image_color: "",
            image_opacity: "",
            image_scale: "100",

            /*Graph layers params*/
            graph_background_color: "#000000",
            graph_border_color: "#000000",
            graph_border_width: 0,
            graph_background_color_opacity: 0.7,

            /*Text layers params*/
            text: "",
            text_font: null,
            text_color: "",
            text_background_color: "",
            text_size: "",
            text_options_italics: false,
            text_options_bold: false,
            text_options_underline: false,
            text_background_color_opacity: "",
            text_scale: "100",
          };

          switch (objToAdd.type?.id) {
            case SMSVisualizationObjectLayerType.IMAGE_LAYER:
              if (visualizationObject.layers[i].parameters) {
                let parameters = JSON.parse(
                  visualizationObject.layers[i].parameters
                );

                if (parameters.image_width) {
                  objToAdd.image_width = parameters.image_width;
                }

                if (parameters.image_height) {
                  objToAdd.image_height = parameters.image_height;
                }

                if (parameters.image_rotation_angle) {
                  objToAdd.image_rotation_angle =
                    parameters.image_rotation_angle;
                }

                if (parameters.image_color) {
                  objToAdd.image_color = parameters.image_color;
                }

                if (parameters.image_opacity) {
                  objToAdd.image_opacity = parameters.image_opacity;
                }

                if (parameters.image_scale) {
                  objToAdd.image_scale = parameters.image_scale;
                }
              }
              break;

            case SMSVisualizationObjectLayerType.GRAPH_LAYER:
              if (visualizationObject.layers[i].parameters) {
                let parameters = JSON.parse(
                  visualizationObject.layers[i].parameters
                );

                objToAdd.graph_background_color =
                  parameters.graph_background_color || "#FFFFFF";
                objToAdd.graph_border_color =
                  parameters.graph_border_color || "#000000";
                objToAdd.graph_border_width =
                  parameters.graph_border_width || 0;
                objToAdd.graph_background_color_opacity =
                  parameters.graph_background_color_opacity || 0.7;
              }
              break;

            case SMSVisualizationObjectLayerType.TEXT_LAYER:
              if (visualizationObject.layers[i].parameters) {
                let parameters = JSON.parse(
                  visualizationObject.layers[i].parameters
                );

                objToAdd.text = parameters.text;
                objToAdd.text_font =
                  parameters.text_font !== null
                    ? textFonts.find((el) => el.id === parameters.text_font)
                    : null;

                objToAdd.text_color = parameters.text_color;
                objToAdd.text_background_color =
                  parameters.text_background_color;

                objToAdd.text_size = parameters.text_size;
                objToAdd.text_options_italics =
                  !!parameters.text_options_italics;
                objToAdd.text_options_bold = !!parameters.text_options_bold;
                objToAdd.text_options_underline =
                  !!parameters.text_options_underline;
                objToAdd.text_background_color_opacity =
                  parameters.text_background_color_opacity;
                objToAdd.text_scale = parameters.text_scale;
              }
              break;
          }

          appendLayer(objToAdd);
        }
      }
    }
  }, [
    rowId,
    dataLoaded,
    setValue,
    visualizationObject?.name,
    visualizationObject?.groupsIds,
    visualizationObject?.imageId,
    visualizationObject?.values,
    visualizationObject?.states,
    visualizationObject?.actions,
    visualizationObject?.layers,
    visualizationObject?.description,
    appendValue,
    appendState,
    appendAction,
    appendLayer,
    layerTypes,
    textFonts,
    imagesData,
    imagesLoading,
    visualizationObjectsBuiltInStates,
    visualizationObjectsStatesGroups,
    visualizationObjectsGroups,
    actionsTypes,
  ]);

  return (
    <LoadingWrapper
      isLodadingProgress={
        fetchingStateVisualizationObjectsGroups.isFetching ||
        fetchingStateVisualizationObjectsStatesGroups.isFetching ||
        fetchingStateVisualizationObject.isFetching ||
        imagesLoading
      }
      isError={
        fetchingStateVisualizationObjectsGroups.isError ||
        fetchingStateVisualizationObjectsStatesGroups.isError ||
        fetchingStateVisualizationObject.isError ||
        imagesError
      }
      setIfFetchAgain={() => {
        if (fetchingStateVisualizationObjectsGroups.isError) {
          fetchAgainVisualizationObjectsGroups();
        }

        if (fetchingStateVisualizationObjectsStatesGroups.isError) {
          fetchAgainVisualizationObjectsStatesGroups();
        }

        if (fetchingStateVisualizationObject.isError) {
          fetchAgainVisualizationObject();
        }
      }}
    >
      <div className={styles.mainContainer}>
        <form
          key={"add_visualization_object_form"}
          onSubmit={handleSubmit(onSubmit)}
          id="submitForm"
          noValidate
        >
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
            value={valueTab}
            onChange={handleChangeValueTab}
            TabIndicatorProps={{
              style: {
                backgroundColor: `${colorNameDictionary[authUserType]}`,
              },
            }}
            variant="fullWidth"
          >
            <Tab
              label={t("basic_data")}
              value={0}
              icon={
                isBasicDataValidationErrors() ? (
                  <Tooltip title={t("invalid_data")!} placement="top">
                    <Error color="error" />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            />
            <Tab
              label={t("values")}
              value={1}
              icon={
                isValuesDataValidationErrors() ? (
                  <Tooltip title={t("invalid_data")!} placement="top">
                    <Error color="error" />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            />
            <Tab
              label={t("states")}
              value={2}
              icon={
                isStatesDataValidationErrors() ? (
                  <Tooltip title={t("invalid_data")!} placement="top">
                    <Error color="error" />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            />

            <Tab
              label={t("layers")}
              value={3}
              icon={
                isLayersDataValidationErrors() ? (
                  <Tooltip title={t("invalid_data")!} placement="top">
                    <Error color="error" />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            />

            <Tab
              label={t("actions")}
              value={4}
              icon={
                isActionsDataValidationErrors() ? (
                  <Tooltip title={t("invalid_data")!} placement="top">
                    <Error color="error" />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            />
          </Tabs>

          {/*Obiekt wizualizacji - Dane podstawowe*/}
          <TabPanel value={valueTab} index={0}>
            <>
              <VisualizationObjectBasicData
                control={control}
                register={register}
                fields={values}
                append={appendValue}
                remove={removeValue}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                watch={watch}
                visualizationObjectsGroups={visualizationObjectsGroups}
              />
            </>
          </TabPanel>

          {/*Obiekt wizualizacji - Wartości*/}
          <TabPanel value={valueTab} index={1}>
            <VisualizationObjectValues
              control={control}
              register={register}
              fields={values}
              append={appendValue}
              remove={removeValue}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              watch={watch}
            />
          </TabPanel>

          {/*Obiekt wizualizacji - Stany*/}
          <TabPanel value={valueTab} index={2}>
            <VisualizationObjectStates
              control={control}
              register={register}
              fields={states}
              append={appendState}
              remove={removeState}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              watch={watch}
              visualizationObjectsStatesGroups={
                visualizationObjectsStatesGroups
              }
            />
          </TabPanel>

          {/*Obiekt wizualizacji - Warstwy*/}
          <TabPanel value={valueTab} index={3}>
            <VisualizationObjectLayers
              control={control}
              register={register}
              fields={layers}
              append={appendLayer}
              remove={removeLayer}
              insert={insertLayer}
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              errors={errors}
              watch={watch}
              imagesData={imagesData || []}
            />
          </TabPanel>

          {/*Obiekt wizualizacji - Akcje*/}
          <TabPanel value={valueTab} index={4}>
            <VisualizationObjectActions
              control={control}
              register={register}
              fields={actions}
              append={appendAction}
              remove={removeAction}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              clearErrors={clearErrors}
              watch={watch}
            />
          </TabPanel>
        </form>
      </div>
    </LoadingWrapper>
  );
};

export default AddEditVisualizationObjectPopupContent;
