import PivotGridDataSource, {
  Field,
} from "devextreme/ui/pivot_grid/data_source";
import PivotGrid, {
  Export,
  FieldChooser,
  FieldPanel,
} from "devextreme-react/pivot-grid";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./PivotTable.module.scss";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { profilesRoutesDictionary } from "../../ProfilesRouting/profilesDictionaries";
import { ProfileType } from "../../../enums/profileType";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import SelectMenu from "../SelectMenu/selectMenu";
import CircularProgress from "@material-ui/core/CircularProgress";
import NewLayoutNamePopup from "./Popups/PivotTableNewLayoutNamePopup";
import { selectSettings } from "../../../reducers/settings";
import {
  LayoutSettings,
  LayoutSettingsItem,
  SelectLayoutItemsInterface,
} from "./type";
import { SnackbarStyled } from "../MaterialUi";
import erro400getTranslatedErrorString from "../../../HelpersFunctions/erro400getTranslatedErrorString";
import useUpdateSettings from "../../../hooks/useUpdateSettings/useUpdateSettings";
import { Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import { isObjectEmpty } from "../../../HelpersFunctions/isObjectEmpty";

interface IProps {
  layoutSettingName: string;
  fields: Field[];
  data: any;
  customActionsColumn?: any;
}

const PivotTable: React.FC<IProps> = ({
  layoutSettingName,
  fields,
  data,
  customActionsColumn,
}) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [dataSource, setDataSource] = useState<any>(null);
  const settings = useAppSelector(selectSettings);
  const [ifSettingAppliedOnInitial, setIfSettingAppliedOnInitial] =
    useState<boolean>(false);
  const { confirm } = useConfirm();
  const confirmRef = useRef(confirm);

  const [snackbarState, setSnackbarState] = useState<[boolean, string, string]>(
    [false, "error", ""]
  );

  const [ifUpdateSetting, setIfUpdateSetting] = useState<boolean>(false);
  const ifSettingsUpdated = useUpdateSettings(ifUpdateSetting);
  const [preferencesSaved, setPreferencesSaved] = useState(false);

  const [currentLayoutName, setCurrentLayoutName] = useState<string | null>(
    () => {
      let layoutSettingValue: LayoutSettings | undefined = undefined;
      let layoutSettingValueRaw = settings[layoutSettingName]?.value;
      if (layoutSettingValueRaw) {
        layoutSettingValue = JSON.parse(layoutSettingValueRaw);
      }

      if (layoutSettingValue?.defaultLayout)
        return layoutSettingValue?.defaultLayout;
      return null;
    }
  );

  const [selectLayoutItems, setSelectLayoutItems] = useState<
    SelectLayoutItemsInterface[]
  >([
    {
      optionName: (
        <div className={"tableLayoutsList"}>{t("initial_layout")}</div>
      ),
      onClick: () => {
        restoreLayoutCallBack();
      },
    },
  ]);

  const [newLayoutName, setNewLayoutName] = useState<string>("");
  const [newLayoutNameIsOpenPopup, setNewLayoutNameIsOpenPopup] =
    useState<boolean>(false);
  const [newLayoutIsDefault, setNewLayoutIsDefault] = useState<boolean>(false);

  const saveLayout = () => {
    let currentLayoutSettingValue: LayoutSettings | undefined = settings[
      layoutSettingName
    ]?.value
      ? JSON.parse(settings[layoutSettingName].value)
      : undefined;

    const newLayoutProps: LayoutSettingsItem = {
      name: newLayoutName,
      data: !dataSource ? [] : dataSource.state(),
    };

    let defaultLayout: string | null = null;
    if (newLayoutIsDefault) {
      defaultLayout = newLayoutName;
    } else if (newLayoutName === currentLayoutSettingValue?.defaultLayout) {
      defaultLayout = null;
    } else {
      defaultLayout = currentLayoutSettingValue?.defaultLayout
        ? currentLayoutSettingValue?.defaultLayout
        : null;
    }

    let postPutBodyValue: LayoutSettings;
    if (currentLayoutSettingValue?.layoutsList) {
      postPutBodyValue = currentLayoutSettingValue;
      postPutBodyValue.layoutsList[newLayoutName] = newLayoutProps;
      postPutBodyValue.defaultLayout = defaultLayout;
    } else {
      postPutBodyValue = {
        defaultLayout: defaultLayout,
        layoutsList: {
          [newLayoutName]: newLayoutProps,
        },
      };
    }

    setPreferencesSaved(false);

    setPostRequestBody(
      JSON.stringify({
        name: layoutSettingName,
        value: JSON.stringify(postPutBodyValue),
      })
    );
  };

  useEffect(() => {
    setDataSource(null);
  }, [fields]);

  useEffect(() => {
    if (dataSource === null) {
      let tempFields = fields ? JSON.parse(JSON.stringify(fields)) : undefined;
      if (tempFields) {
        for (let i = 0; i < fields.length; i++) {
          if (fields[i]["customizeText"]) {
            tempFields[i]["customizeText"] = fields[i].customizeText;
          }
        }
      }

      setDataSource(
        new PivotGridDataSource({
          fields: tempFields,
          store: data ? JSON.parse(JSON.stringify(data)) : undefined,
        })
      );
    }
  }, [fields, data, dataSource]);

  const [postRequestBody, setPostRequestBody] = useState<any>(false);

  let ifWorkerTimeId = "";
  if (authUser.currentProfile?.type === ProfileType.WORKER_TIME) {
    ifWorkerTimeId = `/${authUser.currentProfile.subjectId}`;
  }

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${
      profilesRoutesDictionary[authUser.currentProfile?.type]
    }${ifWorkerTimeId}/preferences`,
    method: "POST",
    body: postRequestBody,
    setBody: setPostRequestBody,
    contentType: "application/json",
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (postRequestBody) {
      fetchAgainPostData();
    }
  }, [postRequestBody, fetchAgainPostData]);

  useEffect(() => {
    if (
      !fetchingStatePostData.isFetching &&
      (fetchingStatePostData.response?.status === 200 ||
        fetchingStatePostData.response?.status === 201) &&
      !preferencesSaved
    ) {
      setSnackbarState([true, "success", t("layout_saved")]);
      setTimeout(() => {
        setIfUpdateSetting(true);
        setPreferencesSaved(true);
      }, 500);
    } else if (fetchingStatePostData.response?.status === 400) {
      setSnackbarState([
        true,
        "error",
        erro400getTranslatedErrorString(
          fetchingStatePostData.response.resJson.errors,
          t
        ),
      ]);
    } else if (fetchingStatePostData.response?.isError) {
      setSnackbarState([true, "error", t("something_went_wrong")]);
    }
  }, [
    fetchingStatePostData.response,
    fetchingStatePostData.isFetching,
    t,
    preferencesSaved,
  ]);

  useEffect(() => {
    if (ifSettingsUpdated) {
      setIfUpdateSetting(false);
    }
  }, [ifSettingsUpdated]);

  const restoreLayoutCallBack = useCallback(() => {
    setNewLayoutName("");
    setNewLayoutIsDefault(false);
    setCurrentLayoutName(null);
    setDataSource(null);
  }, []);

  const removeLayoutCallBack = useCallback(
    (layoutName: string) => {
      let currentLayoutSettingValue: LayoutSettings | undefined = settings[
        layoutSettingName
      ]?.value
        ? JSON.parse(settings[layoutSettingName].value)
        : undefined;

      if (currentLayoutSettingValue === undefined) return;

      let newLayoutsList: {
        [key in string]: LayoutSettingsItem;
      } = {};

      Object.keys(currentLayoutSettingValue.layoutsList).forEach(
        (layoutNameLocal) => {
          if (
            layoutNameLocal !== layoutName &&
            currentLayoutSettingValue?.layoutsList
          ) {
            newLayoutsList[layoutNameLocal] =
              currentLayoutSettingValue.layoutsList[layoutNameLocal];
          }
        }
      );

      let postPutBodyValue: LayoutSettings;
      postPutBodyValue = {
        defaultLayout:
          currentLayoutSettingValue?.defaultLayout === layoutName
            ? null
            : currentLayoutSettingValue.defaultLayout,
        layoutsList: newLayoutsList,
      };

      if (currentLayoutName === layoutName) {
        restoreLayoutCallBack();
      }

      setPostRequestBody(
        JSON.stringify({
          name: layoutSettingName,
          value: JSON.stringify(postPutBodyValue),
        })
      );
    },
    [layoutSettingName, settings, currentLayoutName, restoreLayoutCallBack]
  );

  useEffect(() => {
    if (dataSource === null) return;
    if (ifUpdateSetting) return;

    if (!settings[layoutSettingName]) {
      setIfSettingAppliedOnInitial(true);
      return;
    }

    let layoutSettingValue: LayoutSettings | undefined = undefined;
    let layoutSettingValueRaw = settings[layoutSettingName]?.value;
    if (layoutSettingValueRaw) {
      layoutSettingValue = JSON.parse(layoutSettingValueRaw);
    }
    if (layoutSettingValue?.defaultLayout === undefined) {
      setIfSettingAppliedOnInitial(true);
      return;
    }

    let selectLayoutItemsLocal: SelectLayoutItemsInterface[] = [];
    Object.keys(layoutSettingValue.layoutsList).forEach((itemName) => {
      selectLayoutItemsLocal.push({
        optionName: (
          <div className="tableLayoutsList">
            <div
              className={
                itemName === layoutSettingValue?.defaultLayout
                  ? "defaultLayout"
                  : ""
              }
            >
              {itemName}
            </div>
            <div>
              <Tooltip title={t("delete")}>
                <div
                  onClick={async (e) => {
                    e.stopPropagation();
                    let buttonId = await confirmRef.current({
                      text: `${t(
                        "are_you_sure_you_want_to_delete_layout"
                      )} "${itemName}"?`,
                      buttons: [
                        { buttonName: t("delete"), buttonId: 0 },
                        { buttonName: t("cancel"), buttonId: 1 },
                      ],
                    });
                    if (buttonId === 0) {
                      removeLayoutCallBack(itemName);
                    }
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  className={"faTimesCircleTable"}
                >
                  <div>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        ),
        onClick: () => {
          setCurrentLayoutName(itemName);
          setNewLayoutName(itemName);
        },
      });
    });

    setSelectLayoutItems((prev) => {
      return [prev[0], ...selectLayoutItemsLocal];
    });

    if (isObjectEmpty(layoutSettingValue?.layoutsList)) {
      setIfSettingAppliedOnInitial(true);
      return;
    }

    if (currentLayoutName === null || currentLayoutName === "") {
      setIfSettingAppliedOnInitial(true);
      return;
    }

    let layoutToApply: LayoutSettingsItem =
      layoutSettingValue.layoutsList[currentLayoutName];

    if (!layoutToApply?.name) {
      return;
    }

    setNewLayoutName(layoutToApply.name);
    setCurrentLayoutName(layoutToApply.name);

    if (layoutToApply.data) {
      if (
        fields &&
        fields.length > 0 &&
        layoutToApply?.data?.fields &&
        layoutToApply.data.fields.length > 0
      ) {
        for (let i = 0; i < fields.length; i++) {
          if (fields[i]["customizeText"]) {
            for (let j = 0; j < layoutToApply.data.fields.length; j++) {
              if (
                layoutToApply.data.fields[j].dataField === fields[i].dataField
              ) {
                layoutToApply.data.fields[j]["customizeText"] =
                  fields[i]["customizeText"];
                break;
              }
            }
          }
        }
      }

      dataSource.state(layoutToApply.data);
    }

    setIfSettingAppliedOnInitial(true);
  }, [
    currentLayoutName,
    ifUpdateSetting,
    layoutSettingName,
    removeLayoutCallBack,
    settings,
    t,
    dataSource,
    fields,
  ]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.toolbar}>
        <div className={styles.customActionsColumn}>{customActionsColumn}</div>
        <div className={styles.actionsColumn}>
          <div>
            {!fetchingStatePostData.isFetching ? (
              <SelectMenu
                name={t("actions")}
                items={[
                  {
                    optionName: t("save_the_layout"),
                    onClick: () => {
                      setNewLayoutNameIsOpenPopup(true);
                    },
                  },
                ]}
              />
            ) : (
              <div className="confirmWaiting">
                <CircularProgress size={22} />
              </div>
            )}
          </div>

          <div>
            {!fetchingStatePostData.isFetching ? (
              <SelectMenu
                name={
                  currentLayoutName === null
                    ? t("initial_layout")
                    : currentLayoutName
                }
                items={selectLayoutItems}
              />
            ) : (
              <div className="confirmWaiting">
                <CircularProgress size={22} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {ifSettingAppliedOnInitial && dataSource !== null && (
          <PivotGrid
            dataSource={dataSource}
            texts={{
              total: `'{0}' ${t("total_time")}`,
              grandTotal: `${t("total_time")}`,
              noData: t("no_data"),
            }}
            allowSortingBySummary={true}
            allowFiltering={true}
            showBorders={true}
            showColumnTotals={false}
            showRowTotals={false}
          >
            <FieldChooser enabled={true} height={600} />
            <FieldPanel
              showColumnFields={true}
              showDataFields={true}
              showFilterFields={true}
              showRowFields={true}
              allowFieldDragging={true}
              visible={true}
              texts={{
                columnFieldArea: t("drop_column_fields_here"),
                dataFieldArea: t("drop_data_fields_here"),
                rowFieldArea: t("drop_row_fields_here"),
                filterFieldArea: t("drop_filter_fields_here"),
              }}
            />
            <Export enabled={true} />
          </PivotGrid>
        )}
      </div>

      <NewLayoutNamePopup
        isOpen={newLayoutNameIsOpenPopup}
        setIsOpen={setNewLayoutNameIsOpenPopup}
        saveLayout={saveLayout}
        newLayoutName={newLayoutName}
        setNewLayoutName={setNewLayoutName}
        newLayoutIsDefault={newLayoutIsDefault}
        setNewLayoutIsDefault={setNewLayoutIsDefault}
      />

      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </div>
  );
};

export default PivotTable;
