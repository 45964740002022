import { useTranslation } from "react-i18next";
import styles from "./VisualizationObjectStates.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import SelectFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/SelectField/selectFIeld";
import useConfirm from "../../../../../hooks/useConfirm/useConfirm";
import { Grid } from "@mui/material";

const VisualizationObjectStatesItem: React.FC<any> = ({
  key,
  fields,
  index,
  control,
  register,
  setValue,
  remove,
  errors,
  getValues,
  watch,
  visualizationObjectsStatesGroups,
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();

  return (
    <div className={styles.statesMainContainer}>
      <div className={styles.stateItemTitle}>
        <p>
          {t("state")}
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              let currentItemId = watch(`states.${index}.id`);
              let currentItemVirtualId = watch(`states.${index}.virtualId`);

              let layers = watch("layers");
              let actions = watch("actions");

              let exists = false;

              if (layers && layers.length > 0) {
                for (let i = 0; i < layers.length; i++) {
                  for (let j = 0; j < layers[i].states.length; j++) {
                    if (
                      layers[i].states[j].id === currentItemId ||
                      layers[i].states[j].id === currentItemVirtualId
                    ) {
                      exists = true;
                      break;
                    }
                  }
                }
              }

              if (actions && actions.length > 0) {
                for (let i = 0; i < actions.length; i++) {
                  for (let j = 0; j < actions[i].states.length; j++) {
                    if (
                      actions[i].states[j].id === currentItemId ||
                      actions[i].states[j].id === currentItemVirtualId
                    ) {
                      exists = true;
                      break;
                    }
                  }
                }
              }

              if (!exists) {
                remove(index);
              } else {
                confirm({
                  typeDialog: "info",
                  text: t(
                    "cant_delete_state_because_it_is_used_in_layers_or_actions"
                  ),
                  buttons: [{ buttonName: t("ok").toUpperCase(), buttonId: 0 }],
                });
              }
            }}
          />
        </p>
      </div>
      <div className={styles.stateItem}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <div className={styles.inputWithTitleInRow}>
              <div className={styles.inputTitleInRow}>{t("name")}:</div>
              <div className={styles.inputFieldInRow}>
                <TextFieldReactHookForm
                  id={`states.${index}.name`}
                  name={`states.${index}.name`}
                  control={control}
                  label={t("name")}
                  required={true}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={styles.inputWithTitleInRow}>
              <div className={styles.inputTitleInRow}>{t("condition")}:</div>
              <div className={styles.inputFieldInRow}>
                <TextFieldReactHookForm
                  id={`states.${index}.condition`}
                  name={`states.${index}.condition`}
                  control={control}
                  label={t("condition")}
                  required={true}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={3}>
            <div className={styles.inputWithTitleInRow}>
              <div className={styles.inputTitleInRow}>{t("group")}:</div>
              <div className={styles.inputFieldInRow}>
                <SelectFieldReactHookForm
                  id={`states.${index}.group`}
                  name={`states.${index}.group`}
                  control={control}
                  label={t("choose_group")}
                  options={visualizationObjectsStatesGroups || []}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  getOptionSelected={(option, value) => {
                    return option.id === value.id;
                  }}
                  setValue={setValue}
                  watch={watch}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default VisualizationObjectStatesItem;
