import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
  faFileExport,
  faFileImport,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HomePageSMSAdministrator = () => {
  const { t } = useTranslation();

  const [operationInProgress, setOperationInProgress] = useState<boolean>(true);

  const handleExport = () => {};

  const handleImport = () => {};

  const handleClear = () => {};

  useEffect(() => {
    setOperationInProgress(true);
  }, []);

  return (
    <div>
      <TopBanner pathName={t("home_page")} />
      <div className="flexAndCenter">
        <div className={styles.dashboardMainContainer}>
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
                border: "1px solid #ccc",
                borderRadius: 2,
                backgroundColor: "#f5f5f5",
                maxWidth: "300px", // Zwiększona szerokość boxa
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: 2 }}>
                {t("visualization_data")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleExport}
                sx={{ marginBottom: 1, width: "100%" }}
                startIcon={<FontAwesomeIcon icon={faFileExport} />}
              >
                {t("export_all")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleImport}
                sx={{ marginBottom: 1, width: "100%" }}
                startIcon={<FontAwesomeIcon icon={faFileImport} />}
              >
                {t("import_all")}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleClear}
                sx={{ width: "100%" }}
                startIcon={<FontAwesomeIcon icon={faTrashAlt} />}
              >
                {t("clear_all")}
              </Button>

              {operationInProgress && (
                <Box
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <CircularProgress size={20} sx={{ marginRight: 1 }} />
                  <Typography variant="body2">
                    {t("operation_in_progress")}
                  </Typography>
                </Box>
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageSMSAdministrator;
