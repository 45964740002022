import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "./advancedNavbar.module.scss";
import Dropdown from "./Dropdown";
import { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colorNameDictionary } from "../ProfilesRouting/profilesDictionaries";
import { selectAuthUser } from "../../reducers/session";
import { useAppSelector } from "../../store/hooks";

const AdvancedNavbarMenuItems = ({ items, depthLevel }) => {
  const location = useLocation();
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef<any>();

  const isActive = location.pathname === items.url;

  const toggleDropdown = () => {
    setDropdown((prev) => !prev);
  };
  const authUser = useAppSelector(selectAuthUser);
  const profileColor = colorNameDictionary[authUser.currentProfile?.type];

  return (
    <li className={styles["menu-items"]} ref={ref}>
      {items.url ? (
        <div className={styles.menuItem}>
          <div
            onClick={() => {
              if (items.collapsable) {
                toggleDropdown();
              }
            }}
          >
            <Link
              className={`${styles.singleLink} ${
                isActive && styles.singleLinkSelected
              }`}
              to={`${items.url + "?menuItemName=" + items.title}`}
            >
              <span className={styles.menuItemContainer}>
                <div 
                  style={isActive ? {color: profileColor} : {}} 
                  className={styles.menuItemIcon}>
                    {items.icon ? items.icon : null}
                </div>
                <span className={styles.menuItemLabel}>{items.title}</span>
              </span>
            </Link>
          </div>

          <div>
            {items.submenu && (
              <button
                onClick={() => {
                  toggleDropdown();
                }}
                style={isActive ? {color: profileColor} : {}}
                className={styles.menuItemExpandCollapseButton}
              >
                {dropdown ? (
                  <FontAwesomeIcon
                    icon={faMinus}
                    className={styles.menuItemExpandCollapseButtonIcon}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={styles.menuItemExpandCollapseButtonIcon}
                  />
                )}
              </button>
            )}
          </div>
        </div>
      ) : (
        <div
          className={styles.menuItem}
          onClick={() => {
            toggleDropdown();
          }}
        >
          <div>
            <span className={styles.menuItemContainer}>
              {items.icon ? items.icon : null}
              <span className={styles.menuItemLabel}>{items.title}</span>
            </span>
          </div>

          <div>
            {items.submenu && (
              <button className={styles.menuItemExpandCollapseButton}>
                {dropdown ? (
                  <FontAwesomeIcon
                    icon={faMinus}
                    className={styles.menuItemExpandCollapseButtonIcon}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={styles.menuItemExpandCollapseButtonIcon}
                  />
                )}
              </button>
            )}
          </div>
        </div>
      )}

      {items.submenu && (
        <Dropdown
          depthLevel={depthLevel}
          submenus={items.submenu}
          dropdown={dropdown}
        />
      )}
    </li>
  );
};

export default AdvancedNavbarMenuItems;
