import { useDrag } from "react-dnd";
import styles from "./VisualizationObjectsExplorerObjectItem.module.scss";

const VisualizationObjectsExplorerLayerItem: React.FC<
  IVisualizationObjectsExplorerLayerItem
> = ({ id, name, type, groupItemsNumber, isSelected, setSelectedLayer }) => {
  const [, dragVisualizationObjectLayerItem] = useDrag({
    type: "VISUALIZATION_OBJECT_LAYER_ITEM",
    item: { id, type: "VISUALIZATION_OBJECT_LAYER_ITEM" },
  });

  return (
    <div
      ref={dragVisualizationObjectLayerItem}
      className={styles["layer-item-container"]}
      style={{ backgroundColor: isSelected ? "yellow" : "" }}
      onClick={() => {
        setSelectedLayer(id);
      }}
    >
      <div className={styles["layer-item-icon"]}>
        {type === 0 && groupItemsNumber === 0 && (
          <img src="/images/image_icon_24x24.png" alt="" />
        )}
        {type === 1 && groupItemsNumber === 0 && (
          <img src="/images/graph_icon_24x24.png" alt="" />
        )}
        {type === 2 && groupItemsNumber === 0 && (
          <img src="/images/text_icon_24x24.png" alt="" />
        )}
        {groupItemsNumber !== 0 && (
          <img src="/images/group_of_objects_24x24.png" alt="" />
        )}
      </div>
      <div className={styles["layer-item-name"]}>{name}</div>
    </div>
  );
};

export default VisualizationObjectsExplorerLayerItem;
