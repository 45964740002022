import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useRefreshData from "../../../../hooks/useRefreshData/useRefreshData";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import moment from "moment";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";

const ContentOperationLogs = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const [startDate, setStartDate] = useState<Date>(
    getBeginningOfDate(new Date(), "Day")
  );
  const [endDate, setEndDate] = useState<Date>(
    getEndOfDate(new Date(), "Day", { extraDays: 7 })
  );

  const columns = [
    { name: "time", title: t("time"), type: "date_hours" },
    { name: "typeName", title: t("the_type_of_operation") },
    { name: "description", title: t("description_of_operation") },
    { name: "acSuperiorLogin", title: t("performer_of_operation") },
  ];

  const [
    acOperationLogsRows,
    fetchingStateACOperationLogs,
    fetchAgainACOperationLogs,
  ] = useFetchAndSetGET<ACOperationLog[]>({
    path:
      `superior-ac/ac-operation-logs?dateFrom=` +
      computeDateRangeFetch(startDate) +
      "&dateTo=" +
      computeDateRangeFetch(endDate),
    startFetchOnInitial: false,
  });

  useEffect(() => {
    fetchAgainACOperationLogs();
  }, [startDate, endDate, fetchAgainACOperationLogs]);

  const { refreshingTime, handleChangeRefreshingTime } = useRefreshData(
    fetchAgainACOperationLogs
  );

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "time") {
      return row.time ? moment(row.time).format("YYYY-MM-DD HH:mm:ss") : "";
    }

    return row[columnName];
  }, []);

  return (
    <>
      <div>
        <TopBanner pathName={t("operation_logs")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            darkTheme={true}
            keyboardDateTimePicker={true}
          />
        </div>
        <div>
          <TableComponentMemo
            columns={columns}
            rows={acOperationLogsRows}
            layoutSettingName="layoutACSuperiorACOperationLogs"
            fetchingState={{
              fetchAgain: fetchAgainACOperationLogs,
              isError: fetchingStateACOperationLogs.isError,
              isFetching: fetchingStateACOperationLogs.isFetching,
            }}
            refreshingButton={{
              ifShow: true,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
            customExcelCellValue={customExportCellValue}
            customPdfCellValue={customExportCellValue}
            tableExportInfo={{
              dateFrom: startDate,
              dateTo: endDate,
              createdBy: authUser.displayedName,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ContentOperationLogs;
