import React from "react";
import ReactDOM from "react-dom";
import "./css/index.scss";
import App from "./App";
import { i18n } from "./lang/i18n";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { I18nextProvider } from "react-i18next";
import { KeyboardStateProvider } from "./HelpersFunctions/KeyboardStateProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <KeyboardStateProvider>
          <QueryClientProvider client={queryClient}>
            <DndProvider backend={HTML5Backend}>
              <App />
            </DndProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </KeyboardStateProvider>
      </I18nextProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
