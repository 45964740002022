import { useCallback, useEffect, useState } from "react";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import Row from "../../../../helpersComponents/PopupHelpers/row";
import RowInput from "../../../../helpersComponents/PopupHelpers/rowInput";
import RowTitle from "../../../../helpersComponents/PopupHelpers/rowTitle";
import TwoColumnsRowsContainer from "../../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import erro400getTranslatedErrorString from "../../../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";

const AddEditVisualizationObjectsGroupsCategoriesPopupContent: React.FC<
  any
> = ({
  rowId,
  control,
  setValue,
  setErrorMessage,
  successCallback,
  handleSubmit,
  setIsFetching,
  disableSuccessSnackbar = false,
}) => {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const successCallbackAndClose = useCallback(
    (response) => {
      let tempId: any = null;

      if (response.location) {
        tempId = parseInt(response.location.split("/").pop());
      }

      successCallback(tempId);
    },
    [successCallback]
  );

  const [
    visualizationObjectsGroupsCategory,
    fetchingStateVisualizationObjectsGroupsCategory,
    fetchAgainVisualizationObjectsGroupsCategory,
  ] = useFetchAndSetGET({
    path: `sms-administrator/visualization-objects-groups-categories-library/${rowId}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (rowId) {
      fetchAgainVisualizationObjectsGroupsCategory();
    }
  }, [rowId, fetchAgainVisualizationObjectsGroupsCategory]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t, setErrorMessage]
  );

  const [
    fetchingStateAddEditVisualizationObjectsGroupsCategory,
    fetchAgainAddEditVisualizationObjectsGroupsCategory,
  ] = useFetchOtherThanGET({
    path: `sms-administrator/visualization-objects-groups-categories-library${
      rowId ? "/" + rowId : ""
    }`,
    method: rowId ? "PUT" : "POST",
    contentType: "application/json",
    body: bodyRequest,
    setBody: setBodyRequest,
    successCallback: successCallbackAndClose,
    errorCallback,
    disableSuccessSnackbar: disableSuccessSnackbar === true,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditVisualizationObjectsGroupsCategory();
    }
  }, [bodyRequest, fetchAgainAddEditVisualizationObjectsGroupsCategory]);

  useEffect(() => {
    setIsFetching(
      fetchingStateAddEditVisualizationObjectsGroupsCategory.isFetching
    );
  }, [fetchingStateAddEditVisualizationObjectsGroupsCategory, setIsFetching]);

  const onSubmit = (data: any) => {
    let tempOrder = parseInt(data.order);

    let jsonDataToSend: any = {
      name: data.name,
      order: isNaN(tempOrder) ? 0 : tempOrder,
    };

    setBodyRequest(JSON.stringify(jsonDataToSend));
  };

  useEffect(() => {
    if (
      rowId &&
      !fetchingStateVisualizationObjectsGroupsCategory.isFetching &&
      fetchingStateVisualizationObjectsGroupsCategory.response?.status === 200
    ) {
      setValue("name", visualizationObjectsGroupsCategory.name);
      setValue("order", visualizationObjectsGroupsCategory.order);
    }
  }, [
    fetchingStateVisualizationObjectsGroupsCategory.isFetching,
    fetchingStateVisualizationObjectsGroupsCategory.response?.status,
    visualizationObjectsGroupsCategory?.name,
    visualizationObjectsGroupsCategory?.order,
    rowId,
    setValue,
  ]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateVisualizationObjectsGroupsCategory.isFetching
        }
        isError={fetchingStateVisualizationObjectsGroupsCategory.isError}
        setIfFetchAgain={() => {
          if (fetchingStateVisualizationObjectsGroupsCategory.isError) {
            fetchAgainAddEditVisualizationObjectsGroupsCategory();
          }
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="add-edit-visualization-object-category-form"
          noValidate
        >
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("name")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"name"}
                  control={control}
                  label={t("name")}
                  required={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("order")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"order"}
                  control={control}
                  label={t("order")}
                  required={true}
                  type="number"
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>
        </form>
      </LoadingWrapper>
    </>
  );
};

export default AddEditVisualizationObjectsGroupsCategoriesPopupContent;
