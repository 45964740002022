import { useEffect, useState } from "react";
import VisualizationObjectsExplorerObjectItem from "./VisualizationObjectsExplorerObjectItem";
import styles from "./VisualizationObjectsExplorer.module.scss";
import { useSMSVisualizationDataContext } from "../../../../../HelpersFunctions/SMSVisualizationDataProvider";

interface IProps {
  items: ISMSVisualizationObject[];
  collapseAll: boolean;
  setCollapseAll: React.Dispatch<React.SetStateAction<boolean>>;
}

const VisualizationObjectsExplorer: React.FC<IProps> = ({
  items,
  collapseAll,
  setCollapseAll,
}) => {
  const [expandedObjects, setExpandedObjects] = useState<number[]>([]);
  const [selectedLayer, setSelectedLayer] = useState<number>(-1);

  const { imagesData } = useSMSVisualizationDataContext();

  const handleExpandObject = (objectId: number) => {
    if (expandedObjects.includes(objectId)) {
      setExpandedObjects((prev) => prev.filter((el) => el !== objectId));
    } else {
      setExpandedObjects((prev) => [...prev, objectId]);
    }
  };

  useEffect(() => {
    setExpandedObjects([]);
    setCollapseAll(false);
    setSelectedLayer(-1);
  }, [collapseAll, setCollapseAll]);

  const groupLayersAndReturn = (
    layers: ISMSVisualizationObjectLayer[]
  ): ISMSVisualizationObjectGroupLayer[] => {
    const groupedMap = new Map();

    // Zliczanie elementów w grupach i znajdowanie elementu z najmniejszym 'order'
    layers.forEach((layer) => {
      if (layer.group !== null) {
        if (!groupedMap.has(layer.group)) {
          groupedMap.set(layer.group, { layer: layer, count: 1 });
        } else {
          const groupData = groupedMap.get(layer.group);
          groupData.count++;
          if (layer.order < groupData.layer.order) {
            groupData.layer = layer;
          }
        }
      }
    });

    // Tworzenie wynikowej tablicy
    const result = layers
      .filter((layer) => layer.group === null)
      .map<ISMSVisualizationObjectGroupLayer>((layer) => ({
        ...layer,
        groupItemsNumber: 0,
      }));

    // Dodawanie elementów zgrupowanych z dodanym polem 'groupItemsNumber'
    groupedMap.forEach((groupData) => {
      result.push({ ...groupData.layer, groupItemsNumber: groupData.count });
    });

    // Sortowanie wynikowej tablicy po polu 'order' rosnąco
    result.sort((a, b) => a.order - b.order);

    return result;
  };

  return (
    <div className={styles["main-container"]}>
      {items?.map((el) => (
        <VisualizationObjectsExplorerObjectItem
          key={el.id}
          id={el.id}
          name={el.name}
          description={el.description}
          icon={
            el.imageId
              ? imagesData?.find((e) => e.id === el.imageId) || null
              : null
          }
          layers={groupLayersAndReturn(el.layers)}
          isExpanded={expandedObjects.includes(el.id)}
          onExpand={handleExpandObject}
          selectedLayer={selectedLayer}
          setSelectedLayer={setSelectedLayer}
        />
      ))}
    </div>
  );
};

export default VisualizationObjectsExplorer;
