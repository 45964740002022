import { useEffect } from "react";
import compareDatesIgnoringTime from "../../../../HelpersFunctions/dateAndTime/compareDatesIgnoringTime";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getDaysInMonth from "../../../../HelpersFunctions/dateAndTime/getDaysInMonth";
import { selectAdvancedOptions } from "../../../../reducers/advancedOptions";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";

export default function useCreateGrid({
  isGridCreated,
  fetchedSchedules,
  allWorkers,
  selectedDate,
  selectedWorkersHelperCreatingGrid,
  setGrid,
  setIsGridCreated,
  setGridInitialTemplate,
  setScheduleDataLoaded,
}) {
  const advancedOptions = useAppSelector(selectAdvancedOptions);
  const settings = useAppSelector(selectSettings);

  useEffect(() => {
    if (isGridCreated) return;
    if (fetchedSchedules === null || allWorkers === undefined) return;

    let gridLocal: GridTemplate[][] = [];
    //// creating header row
    let headerRow: GridTemplate[] = [{ cellValue: "Pracownicy" }];

    for (let i: number = 1; i <= getDaysInMonth(selectedDate); i++) {
      let cellDate = new Date(getBeginningOfDate(selectedDate, "Day"));
      cellDate.setDate(i);
      let weekName = cellDate.toLocaleDateString("pl", {
        weekday: "short",
      });

      headerRow.push({
        cellValue: `${i} ${weekName}`,
      });
    }
    gridLocal.push(headerRow);

    //////// rest rows
    selectedWorkersHelperCreatingGrid.forEach((workerId, index) => {
      let workerSelected = allWorkers.find((worker) => worker.id === workerId);
      let evidenceNumber = "";
      if (
        settings.UkryjNumerEwidencyjnyRCPUzytkownikHarmonogramy?.value !== "1"
      ) {
        evidenceNumber = ` [${workerSelected.evidenceNumber}]`;
      }

      let singleRow: GridTemplate[] = [
        {
          cellValue: `${workerSelected?.firstName} ${workerSelected?.lastName} ${evidenceNumber}`,
          workerId: workerId,
          isSelected: false,
        },
      ];
      for (let i: number = 1; i <= getDaysInMonth(selectedDate); i++) {
        let cellBackground: string | undefined = undefined;
        // let disableSelect: boolean = false;
        let currentDate = new Date();

        let planningWorkingTimeLock = advancedOptions
          .WebPlanowanieCzasuPracyBlokuj?.value
          ? Number(advancedOptions.WebPlanowanieCzasuPracyBlokuj.value)
          : 0;

        currentDate.setDate(currentDate.getDate() + planningWorkingTimeLock);

        if (currentDate.getFullYear() > selectedDate.getFullYear()) {
          cellBackground = "rgb(230, 230, 230)";
        } else if (
          currentDate.getFullYear() === selectedDate.getFullYear() &&
          (currentDate.getMonth() > selectedDate.getMonth() ||
            (currentDate.getMonth() === selectedDate.getMonth() &&
              currentDate.getDate() > i - 1))
        ) {
          cellBackground = "rgb(230, 230, 230)";
        }

        let cellDate = new Date(selectedDate);
        cellDate.setDate(i);
        let cellValue: GridTemplate = {
          cellValue: "brak",
          isSelected: false,
          cellBackground,
          day: getBeginningOfDate(cellDate, "Day"),
        };

        let foundSchedule = fetchedSchedules.find(
          (schedule) =>
            schedule.workerId === workerId &&
            compareDatesIgnoringTime(new Date(schedule.day), cellDate)
        );

        if (foundSchedule) {
          cellValue.cellValue = foundSchedule.schemaName;
          cellValue.schemaInfo = foundSchedule;
          cellValue.dayFunction = foundSchedule.dayFunction;
        }

        singleRow.push(cellValue);
      }
      gridLocal.push(singleRow);
    });
    setGrid(gridLocal);
    setIsGridCreated(true);

    let clonedGrid = JSON.parse(JSON.stringify(gridLocal));
    setGridInitialTemplate(clonedGrid);
    setScheduleDataLoaded(true);
  }, [
    selectedDate,
    fetchedSchedules,
    selectedWorkersHelperCreatingGrid,
    allWorkers,
    isGridCreated,
    setGrid,
    setIsGridCreated,
    setGridInitialTemplate,
    setScheduleDataLoaded,
    advancedOptions.WebPlanowanieCzasuPracyBlokuj?.value,
    settings.UkryjNumerEwidencyjnyRCPUzytkownikHarmonogramy?.value,
  ]);
}
