const doFetch = async (path: string) => {
  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  let language = JSON.parse(
    localStorage.getItem("languageAndOptionId") || "{}"
  );

  const res = await fetch(window.globalConfig.API_URL + "/api/" + path, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Authorization: "bearer " + authUser.token,
      "Accept-Language": language ? language[0] : "pl",
    },
  });
  if (res.ok) {
    return res.status;
  } else if (res.status === 400) {
    return res.json();
  }
  throw res.status;
};

export default doFetch;
