import "./bonuses.scss";
import React, { useState, useEffect } from "react";
// import StyledBlock from "../../../helpersComponents/StyledBlock/styledBlock"
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { DatePickerStyled } from "../../../helpersComponents/MaterialUi";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";

function Bonuses() {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const [rows, setRows] = useState<object[]>([]);
  const [columns, setColumns] = useState([]);
  // const columns = [
  //     { name: 'room', title: t('room') },
  //     { name: 'days', title: t('daysKeys') },
  //     { name: 'fromTime', title: t('fromTimeKeys') },
  //     { name: 'toTime', title: t('toTimeKeys') },
  // ]

  const [isFetching, setIsFetching] = useState(true);
  const [legende, setLegende] = useState<JSX.Element>(<div></div>);
  const [date, setDate] = useState(new Date());

  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = authUser.currentProfile.subjectId;
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/time-workers/` +
      JSON.parse(localStorage.getItem("authUser") || "{}").currentProfile
        .subjectId +
      "/bonuses?Year=" +
      date.getFullYear() +
      "&Month=" +
      (date.getMonth() + 1),
    ifFetchAgain
  );

  useEffect(() => {
    setIsError(false);
    setIfFetchAgain(true);
    setIsFetching(true); // circ
  }, [date]);

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (data === null) return;

    let columnsLocal: any = [];
    data.columns.forEach((columnName) => {
      columnsLocal.push({ name: columnName, title: columnName });
    });
    let dataModified: any = [];
    data.data.forEach((row, index) => {
      let rowModified = { id: index };
      row.forEach((rowColumn, index) => {
        rowModified[columnsLocal[index].name] = rowColumn;
      });
      dataModified.push(rowModified);
    });

    let legendLocal = (
      <div>
        {data.legende.split(";").map((value, index) => {
          let legenValue = value;
          if (value.startsWith(" ")) legenValue = value.substring(1);
          return (
            <div className="legendeItemBonuses" key={index}>
              {legenValue}
            </div>
          );
        })}
      </div>
    );
    setLegende(legendLocal);

    setColumns(columnsLocal);
    setRows(dataModified);
    setIsFetching(false);
  }, [data, error, t]);

  return (
    <>
      <TopBanner pathName={t("bonuses")} />
      <div className="flexAndCenter">
        <div className="selectDateDarkGlobal">
          <div className="selectYear">
            <div className="inputTitleBonuses">{t("select_date")}</div>
            <div className="selectYearInputBonuses">
              <DatePickerStyled
                view={["year", "month"]}
                format="yyyy-MM"
                date={date}
                setDate={setDate}
                openTo="month"
                darkTheme={true}
                width={"100%"}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setIfFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching && !isError && (
                <TableComponent
                  layoutSettingName="layoutWorkerTimeSummaryBonuses"
                  rows={rows}
                  title={data?.legende ? legende : undefined}
                  columns={columns}
                  ifEdit={false}
                  ifAdd={false}
                  ifDelete={false}
                  tableExportInfo={{
                    dateFrom: getBeginningOfDate(date, "Year"),
                    dateTo: getEndOfDate(date, "Year"),
                    createdBy: authUser.displayedName,
                  }}
                />
              )}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Bonuses;
