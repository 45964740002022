import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import ButtonStyled from "../../helpersComponents/Button/button";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import SingleTrainingRoot from "./SingleTraining/singleTrainingRoot";
import styles from "./styles.module.scss";
import { ProfileType } from "../../../enums/profileType";
import { ACSuperiorPermission } from "../../../enums/ACSuperiorPermission";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";

const SwtichStyled = withStyles({
  switchBase: {
    color: "#1c84c6",
    "&$checked": {
      color: "#1c84c6",
    },
    "&$checked + $track": {
      backgroundColor: "#1c84c6",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function TrainingsRoot() {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const history = useHistory();
  const [isActiveSwitchState, setIsActiveSwitchState] = useState(true);

  const splitTrainings = useCallback((trainings: TrainingObjectbrief[]) => {
    let active: TrainingObjectbrief[] = [];
    let inActive: TrainingObjectbrief[] = [];
    trainings.forEach((training) => {
      if (training.isActive) {
        active.push(training);
      } else {
        inActive.push(training);
      }
    });
    return {
      active,
      inActive,
    };
  }, []);

  ////////// fetch types of visists
  const [typesOfVisits, fetchingStateTypesOfVisitst, fetchAgainTypesOfVisitst] =
    useFetchAndSetGET<TypeOfVisit[]>({
      path: `superior-ac/types-of-visits`,
    });

  const [trainings, fetchingStateTrainings, fetchAgainTrainings] =
    useFetchAndSetGET<{
      active: TrainingObjectbrief[];
      inActive: TrainingObjectbrief[];
    }>({
      path: `superior-ac/trainings`,
      modifyResponseCallback: splitTrainings,
    });

  const isPreview =
    authUser?.currentProfile?.type === ProfileType.SUPERIOR_AC &&
    authUser?.currentProfile?.permission === ACSuperiorPermission.PREVIEW;

  return (
    <>
      <TopBanner
        pathName={t("sk_training")}
        extraElementNextToPath={
          !isPreview ? (
            <ButtonStyled
              onClick={() => {
                history.push({
                  pathname: `/superior-ac/trainings/add`,
                });
              }}
            >
              {t("add")}
            </ButtonStyled>
          ) : null
        }
      />

      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isError={
              fetchingStateTrainings.isError ||
              fetchingStateTypesOfVisitst.isError
            }
            isLodadingProgress={
              fetchingStateTrainings.isFetching ||
              fetchingStateTypesOfVisitst.isFetching
            }
            setIfFetchAgain={() => {
              if (fetchingStateTrainings.isError) {
                fetchAgainTrainings();
              }

              if (fetchingStateTypesOfVisitst.isError) {
                fetchAgainTypesOfVisitst();
              }
            }}
          >
            {trainings && (
              <div>
                <div className="SwtichActive">
                  {t("inactive_2")}
                  <SwtichStyled
                    checked={isActiveSwitchState}
                    onChange={() =>
                      setIsActiveSwitchState(!isActiveSwitchState)
                    }
                    name="checkedA"
                  />
                  {t("active_2")}
                </div>
                <div className={styles.trainingsContainer}>
                  {trainings[isActiveSwitchState ? "active" : "inActive"].map(
                    (training) => {
                      if (training.isActive === isActiveSwitchState) {
                        return (
                          <SingleTrainingRoot
                            key={training.id}
                            trainingData={training}
                            typesOfVisits={typesOfVisits}
                            isPreview={isPreview}
                          />
                        );
                      }
                      return null;
                    }
                  )}
                </div>
                {trainings[isActiveSwitchState ? "active" : "inActive"]
                  .length === 0 && (
                  <div className={styles.noTrainings}>{t("none")}</div>
                )}
              </div>
            )}
          </LoadingWrapper>
        </div>
      </div>
    </>
  );
}
