interface IProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  mainContainertClassName?: string;
  childContainertClassName?: string;
}

const TabPanel: React.FC<IProps> = ({
  children,
  index,
  value,
  mainContainertClassName,
  childContainertClassName,
}) => {
  return (
    <div
      className={mainContainertClassName}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <div className={childContainertClassName}>{children}</div>
      )}
    </div>
  );
};

export default TabPanel;
