import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../helpersComponents/PopupHelpers";
import PopupContentCompany from "./PopupContentCompany";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import { ProfileType } from "../../../enums/profileType";
import { ACSuperiorPermission } from "../../../enums/ACSuperiorPermission";

interface IProps {
  isOpen: boolean;
  closePopup: () => void;
  successCallback: () => void;
  rowId?: number;
}

const PopupCompany: React.FC<IProps> = ({
  isOpen,
  closePopup,
  successCallback,
  rowId,
}) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const [requestBody, setRequestBody] = useState<false | any>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const onSubmit = (values: IAddEditCompany) => {
    setRequestBody(JSON.stringify(values));
  };

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `superior-ac/ac-companies${rowId !== undefined ? `/${rowId}` : ""}`,
    method: rowId !== undefined ? "PUT" : "POST",
    body: requestBody,
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
    contentType: "application/json",
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  const clearErrorMessageAndClosePoup = () => {
    setErrorMessage("");
    closePopup();
  };

  const isPreview =
    authUser?.currentProfile?.type === ProfileType.SUPERIOR_AC &&
    authUser?.currentProfile?.permission === ACSuperiorPermission.PREVIEW;

  return (
    <DialogStyled open={isOpen} onClose={clearErrorMessageAndClosePoup}>
      <DialogTitleStyled
        title={
          isPreview
            ? t("company_preview")
            : rowId
            ? t("editing_company")
            : t("adding_company")
        }
      />
      <DialogContentStyled>
        <PopupContentCompany rowId={rowId} onSubmit={onSubmit} />
      </DialogContentStyled>
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={clearErrorMessageAndClosePoup}
        ifHideConfirm={isPreview}
        onCancelText={isPreview ? t("close") : t("cancel")}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupCompany;
