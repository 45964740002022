import "./numberWithDescription.scss";

interface Props {
  parametrs: Array<ObjectInterface>;
}

const isValueNegative = (value) => {
  if (value && value.toString().includes("-")) {
    return true;
  }

  return false;
};

const TimeWithDescriptionSmall2: React.FC<Props> = (props) => {
  return (
    <div className="numberWithDescriptionSmall2">
      <div>
        <div className="elementsSmall2">
          {props.parametrs.map((value, inex) => {
            return (
              <div key={inex} className="containerSmall2">
                <div className="vauleSmall2">
                  <span
                    className={
                      value.colorNegative && isValueNegative(value.time)
                        ? "colorNegative"
                        : ""
                    }
                  >
                    {value.time} {value.unit}
                  </span>
                </div>
                <div className="descriptionSmall2">{value.description}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TimeWithDescriptionSmall2;
