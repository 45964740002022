import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import addDaysToDate from "../../../HelpersFunctions/dateAndTime/addDaysToDate";
import { DatePickerFormik, TextFieldFormik } from "../FormikInputs";
import CheckboxWithLabelFormik from "../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import GetCardTagPopupRow from "../GetCardTagPopupRow/getCardTagPopupRow";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../PopupHelpers";
import ReadIdCardNumberFormikPopupRow from "../ReadIdCardNumberFormikPopupRow/readIdCardNumberFormikPopupRow";
import styles from "./styles.module.scss";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import { ProfileType } from "../../../enums/profileType";

interface Props extends ReadIdCardNumberFormikPopupRowInterface {
  guestIndex: number;
  arrayHelpers: FieldArrayRenderProps;
  numberOfGuests: number;
  isPopupOpen: boolean;
  guestsArray: GuestsObject[];
  guestsOnBlacklist: any;
}

export default function GuestForm({
  guestIndex,
  arrayHelpers,
  numberOfGuests,
  isPopupOpen,
  guestsArray,
  guestsOnBlacklist,
  ...restProps
}: Props): ReactElement {
  const { t } = useTranslation();
  const { setFieldValue } = restProps;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const successCallbackIdReader = useCallback(
    (readerResponse) => {
      setFieldValue(
        `guestsArray.${guestIndex}.firstName`,
        readerResponse.fields.firstName
      );
      setFieldValue(
        `guestsArray.${guestIndex}.lastName`,
        readerResponse.fields.lastName
      );

      setDataChanged(new Date().getTime());
    },
    [setFieldValue, guestIndex]
  );

  const yesterday = addDaysToDate(new Date(), -1);

  const { values } = useFormikContext<PopupEntryUnauthorizedFormValues>();

  const [guestOnBlackListError, setGuestOnBlackListError] =
    useState<boolean>(false);

  useEffect(() => {
    if (guestsOnBlacklist) {
      let foundGuest = guestsOnBlacklist.find(
        (el) =>
          el.firstName.toLowerCase() ===
            guestsArray[guestIndex].firstName.toLowerCase() &&
          el.lastName.toLowerCase() ===
            guestsArray[guestIndex].lastName.toLowerCase()
      );

      if (foundGuest) {
        setGuestOnBlackListError(true);
      } else {
        setGuestOnBlackListError(false);
      }
    }
  }, [guestsOnBlacklist, guestsArray, guestIndex]);

  useEffect(() => {}, []);

  const [dataChanged, setDataChanged] = useState<number>(new Date().getTime());
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const [trainingExpiryDateUrl, setTrainingExpiryDateUrl] =
    useState<any>(false);

  const [foundTrainingExpiryDate, , fetchAgainTrainingExpiryDate] =
    useFetchAndSetGET({
      path: `${trainingExpiryDateUrl}`,
      startFetchOnInitial: false,
    });

  useEffect(() => {
    if (trainingExpiryDateUrl) {
      fetchAgainTrainingExpiryDate();
    }
  }, [trainingExpiryDateUrl, fetchAgainTrainingExpiryDate]);

  useEffect(() => {
    if (foundTrainingExpiryDate && foundTrainingExpiryDate.expiryDate) {
      let tempDate = new Date(foundTrainingExpiryDate.expiryDate);
      if (
        !(
          tempDate.getFullYear() === 1 &&
          tempDate.getMonth() === 0 &&
          tempDate.getDate() === 1
        )
      ) {
        setFieldValue(
          `guestsArray.${guestIndex}.trainingExpiryDate`,
          foundTrainingExpiryDate.expiryDate
        );
      } else {
        setFieldValue(`guestsArray.${guestIndex}.trainingExpiryDate`, null);
      }
    }
  }, [foundTrainingExpiryDate, guestIndex, setFieldValue]);

  useEffect(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      const firstName = guestsArray[guestIndex].firstName;
      const lastName = guestsArray[guestIndex].lastName;
      const dateOfBirth = guestsArray[guestIndex].dateOfBirth;
      const idNumber = guestsArray[guestIndex].idNumber;

      let profileName = "reception";
      if (profileType === ProfileType.SECURITY) {
        profileName = "security";
      }

      let url = `${profileName}/guest-training-date-expiry?firstName=${firstName}&lastName=${lastName}`;

      if (dateOfBirth) {
        const year = dateOfBirth.getFullYear();
        let month =
          dateOfBirth.getMonth() + 1 > 9
            ? `${dateOfBirth.getMonth() + 1}`
            : `0${dateOfBirth.getMonth() + 1}`;
        let day =
          dateOfBirth.getDate() > 9
            ? `${dateOfBirth.getDate()}`
            : `0${dateOfBirth.getDate()}`;
        url += `&dateOfBirth=${year}-${month}-${day}`;
      }

      if (idNumber) {
        url += `&idNumber=${idNumber}`;
      }

      if (firstName && lastName && (dateOfBirth || idNumber)) {
        setTrainingExpiryDateUrl(url);
      }

      setTrainingExpiryDateUrl(url);
    }, 1000);
  }, [dataChanged, guestIndex, guestsArray, profileType]);

  return (
    <>
      <div className={styles.guestTitle}>
        <b>
          {t("person")} {guestIndex + 1}
        </b>
        {numberOfGuests > 1 ? (
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              arrayHelpers.remove(guestIndex);
            }}
          />
        ) : null}
        {guestOnBlackListError ? (
          <span className={styles.guestOnBlackList}>
            {t("guest_from_blacklist")}!
          </span>
        ) : (
          ""
        )}
      </div>
      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle>{t("first_name")}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t("first_name")}
              name={`guestsArray.${guestIndex}.firstName`}
              type="text"
              required={true}
              ifToUpperCaseShipsAndPorts={true}
              multiline
              onChangeExtra={(e) => {
                setDataChanged(new Date().getTime());
                setFieldValue(
                  `guestsArray.${guestIndex}.firstName`,
                  e.target.value
                );
              }}
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle>{t("last_name")}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t("last_name")}
              name={`guestsArray.${guestIndex}.lastName`}
              type="text"
              required={true}
              ifToUpperCaseShipsAndPorts={true}
              multiline
              onChangeExtra={(e) => {
                setDataChanged(new Date().getTime());
                setFieldValue(
                  `guestsArray.${guestIndex}.lastName`,
                  e.target.value
                );
              }}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>
      <TwoColumnsRowsContainer>
        <GetCardTagPopupRow
          setFieldValue={setFieldValue}
          name={`guestsArray.${guestIndex}.cardNumber`}
          componentId={`entryUnathorized/guestsArray.${guestIndex}.cardNumber`}
          isPopupOpen={isPopupOpen}
          checkForTagsInOtherInputs={{
            thisInputIndex: guestIndex,
            allInputs: guestsArray,
          }}
          required={!guestsArray[guestIndex].bh3}
          mask={"****************"}
        />

        <ReadIdCardNumberFormikPopupRow
          setFieldValue={setFieldValue}
          setFieldError={restProps.setFieldError}
          setFieldTouched={restProps.setFieldTouched}
          successCallback={successCallbackIdReader}
          name={`guestsArray.${guestIndex}.idNumber`}
          dateOfBirthFieldName={`guestsArray.${guestIndex}.dateOfBirth`}
          required={true}
        />
      </TwoColumnsRowsContainer>
      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle>{t("date_of_birth")}:</RowTitle>
          <RowInput>
            <DatePickerFormik
              label={t("date_of_birth")}
              name={`guestsArray.${guestIndex}.dateOfBirth`}
              maxDate={yesterday}
              view={["year", "month", "date"]}
              format="dd-MM-yyyy"
              onChangeExtra={(e) => {
                setDataChanged(new Date().getTime());
                setFieldValue(
                  `guestsArray.${guestIndex}.dateOfBirth`,
                  e.target.value
                );
              }}
            />
          </RowInput>
        </Row>

        <Row>
          <RowTitle>{t("email")}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t("email")}
              name={`guestsArray.${guestIndex}.email`}
              type="text"
              ifToUpperCaseShipsAndPorts={true}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      {(values.typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK ||
        values.typeOfVisit?.id === TypeOfVisit.REVISION) && (
        <TwoColumnsRowsContainer>
          <Row>
            <RowTitle>{t("unnecessary_training")}:</RowTitle>
            <RowInput>
              <CheckboxWithLabelFormik
                name={`guestsArray.${guestIndex}.isTrainingNotRequired`}
                defaultValue={false}
                label={<span className="chekbox">{t("yes")}</span>}
                ifCircle={true}
              />
            </RowInput>
          </Row>

          {!values.guestsArray[`${guestIndex}`].isTrainingNotRequired && (
            <Row>
              <RowTitle>{t("set_guest_training_date_expiry")}:</RowTitle>
              <RowInput>
                <DatePickerFormik
                  label={t("training_expiry_date")}
                  name={`guestsArray.${guestIndex}.trainingExpiryDate`}
                  view={["year", "month", "date"]}
                  minDate={new Date()}
                  format="dd-MM-yyyy"
                  required={false}
                />
              </RowInput>
            </Row>
          )}
        </TwoColumnsRowsContainer>
      )}

      <CheckboxWithLabelFormik
        name={`guestsArray.${guestIndex}.bh3`}
        ifCircle={true}
        label={<span>{"BH3"}</span>}
      />
    </>
  );
}
