/*eslint-disable no-eval */

export function getValuesNamesFromCondition(condition: string): string[] {
  const regex = /\$VALUES\['(.*?)'\]/g;
  const matches: string[] = [];
  let match;

  while ((match = regex.exec(condition)) !== null) {
    if (!matches.includes(match[1])) {
      matches.push(match[1]);
    }
  }

  return matches;
}

export function calculateVisualizationObjectValue(
  mqttValue: any,
  condition: string,
  property: string
): number | string | boolean {
  // Function to process condition
  function processCondition(condition) {
    // Remove excess spaces
    condition = condition.replace(/\s+/g, " ").trim();

    // Replace new keywords to javascript keywords
    condition = condition
      .replace(/\bTRUE\b/g, "true")
      .replace(/\bFALSE\b/g, "false")
      .replace(/\bAND\b/g, "&&")
      .replace(/\bOR\b/g, "||")
      .replace(/\bXOR\b/g, "^");

    // Replace single = characters with == characters
    condition = condition.replace(/([^><!=])=/g, "$1==");

    // Adding "this" before the operators if there is no value left on the left
    condition = condition.replace(/([><=!]=?)/g, " this $1");

    return condition;
  }

  // Function to evaluate condition
  function evaluateCondition(value, condition) {
    // Process condition
    condition = processCondition(condition);

    // Replace "this" to value
    condition = condition.replace(/\bthis\b/g, JSON.stringify(value));

    // Replace single = characters with == characters
    condition = condition.replace(/([^><!=])=/g, "$1==");

    // Evaluate condition
    try {
      return eval(condition);
    } catch (error: any) {
      throw new Error("Incorrect condition: " + error.message);
    }
  }

  // MQTT value processing
  let value;

  if (
    typeof mqttValue === "string" ||
    typeof mqttValue === "number" ||
    typeof mqttValue === "boolean"
  ) {
    value = mqttValue;
  } else if (typeof mqttValue === "object" && mqttValue !== null && property) {
    value = mqttValue[property];
  } else {
    throw new Error(
      "mqttValue must be a number, string, logical value, or object with a property"
    );
  }

  // If there is a condition, evaluate it
  if (condition) {
    return evaluateCondition(value, condition);
  }

  // If there is no condition, return value
  return value;
}

export function calculateVisualizationObjectState(
  condition: string,
  objectValuesMap: Map<string, number | string | boolean>
): boolean {
  // Function to process condition
  function processCondition(condition) {
    // Remove excess spaces
    condition = condition.replace(/\s+/g, " ").trim();

    // Replace new keywords to JavaScript keywords
    condition = condition
      .replace(/\bTRUE\b/g, "true")
      .replace(/\bFALSE\b/g, "false")
      .replace(/\bAND\b/g, "&&")
      .replace(/\bOR\b/g, "||")
      .replace(/\bXOR\b/g, "^");

    // Replace single = characters with == characters
    condition = condition.replace(/([^><!=])=/g, "$1==");

    return condition;
  }

  // Function to evaluate condition
  function evaluateCondition(condition, objectValuesMap) {
    // Process condition
    condition = processCondition(condition);

    // Replace $VALUES['key'] with the corresponding value from objectValuesMap
    condition = condition.replace(/\$VALUES\['(\w+)'\]/g, (match, key) => {
      if (objectValuesMap.has(key)) {
        return JSON.stringify(objectValuesMap.get(key));
      } else {
        throw new Error(`Key ${key} not found in objectValuesMap`);
      }
    });

    // Evaluate condition
    try {
      return eval(condition);
    } catch (error: any) {
      throw new Error("Incorrect condition: " + error.message);
    }
  }

  // If there is a condition, evaluate it
  if (condition) {
    return evaluateCondition(condition, objectValuesMap);
  }

  // If there is no condition, return false by default
  return false;
}
