import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import ErrorLabel from "../ErrorLabel/errorLabel";
import TimePickerStyled from "../../MaterialUi/TimePicker/timePicker";

const TimeMemo = React.memo(
  ({ value, props, onChange, required, disabled, isError }: any) => {
    return (
      <TimePickerStyled
        value={value}
        onChange={(newValue) => {
          const tempEvent = {
            target: {
              name: props?.name,
              value: newValue,
            },
          };
          onChange(tempEvent);
        }}
        {...props}
        width={"100%"}
        isError={!!isError}
        required={required}
        disabled={disabled}
        view={
          props.view && props.view.length > 0
            ? props.view
            : ["hours", "minutes"]
        }
      />
    );
  },
  (prev, curr) => {
    if (prev.isError !== curr.isError) return false;
    if (prev.value !== curr.value) return false;
    if (prev.props.disabled !== curr.props.disabled) return false;
    return true;
  }
);

export default function TimePickerFieldReactHookForm({
  name,
  label,
  showTopLabel,
  control,
  onChangeCustom,
  required,
  disabled,
  ...props
}: any): ReactElement {
  return (
    <div>
      {showTopLabel && (
        <div>
          <b>{label}:</b>
        </div>
      )}

      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => {
          return (
            <>
              <TimeMemo
                value={value}
                required={required}
                props={props}
                onChange={(value) => {
                  onChange(value);
                }}
                disabled={disabled}
                isError={!!error}
              />

              {error && error.message && (
                <ErrorLabel errorName={error?.message} />
              )}
            </>
          );
        }}
      />
    </div>
  );
}
