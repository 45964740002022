import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ActionsColumn } from "./type";
import styles from "./ConfirmRemoveActions.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEdit,
  faEye,
  faPrint,
  faRedo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

let Cell: any = TableEditColumn.Cell;

const ConfirmRemoveActions: any = ({
  row,
  actionsData,
  setPopupUpdatedState,
  rowMenu,
  paddingValue,
}: any) => {
  const { t } = useTranslation();
  let actionsDataLocal: ActionsColumn = actionsData;
  return (
    <Cell
      className={styles.tableActionsButtons}
      style={{
        padding: paddingValue !== undefined ? paddingValue : "",
      }}
    >
      <div className={styles.container}>
        <div className={styles.childContainer}>
          <div>
            {actionsDataLocal.redoButton?.customComponent &&
            actionsDataLocal.redoButton.ifShow ? (
              <>{actionsDataLocal.redoButton.customComponent(row)}</>
            ) : (
              <Button
                style={{
                  display: actionsDataLocal.redoButton?.ifShow ? "" : "none",
                  minWidth: "0px",
                }}
                onClick={() => {
                  if (actionsDataLocal.redoButton?.onClick) {
                    actionsDataLocal.redoButton.onClick(row);
                  } else {
                    setPopupUpdatedState({
                      isOpen: true,
                      rowData: row,
                    });
                  }
                }}
              >
                {actionsDataLocal.redoButton?.name ? (
                  <FontAwesomeIcon icon={faRedo} size="lg" title={t("redo")} />
                ) : (
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="lg"
                    title={t("confirm")}
                  />
                )}
              </Button>
            )}
          </div>
          <div>
            {actionsDataLocal.removeButton?.customComponent &&
            actionsDataLocal.removeButton.ifShow ? (
              <>{actionsDataLocal.removeButton.customComponent(row)}</>
            ) : (
              <Button
                style={{
                  display: actionsDataLocal.removeButton?.ifShow ? "" : "none",
                  minWidth: "0px",
                }}
                onClick={() => {
                  if (actionsDataLocal.removeButton?.onClick)
                    actionsDataLocal.removeButton.onClick(row);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  size="lg"
                  title={
                    actionsDataLocal.removeButton?.name
                      ? actionsDataLocal.removeButton.name
                      : t("remove")
                  }
                />
              </Button>
            )}
          </div>
          <div>
            {actionsDataLocal.printButton?.customComponent &&
            actionsDataLocal.printButton.ifShow ? (
              <>{actionsDataLocal.printButton.customComponent(row)}</>
            ) : (
              <Button
                style={{
                  display: actionsDataLocal.printButton?.ifShow ? "" : "none",
                  minWidth: "0px",
                }}
                onClick={() => {
                  if (actionsDataLocal.printButton?.onClick)
                    actionsDataLocal.printButton.onClick(row);
                }}
              >
                <FontAwesomeIcon
                  icon={faPrint}
                  size="lg"
                  title={
                    actionsDataLocal.printButton?.name
                      ? actionsDataLocal.printButton.name
                      : t("print")
                  }
                  style={{ color: "#990000" }}
                />
              </Button>
            )}
          </div>
          <div>
            {actionsDataLocal.editButton?.customComponent &&
            actionsDataLocal.editButton.ifShow ? (
              <>{actionsDataLocal.editButton.customComponent(row)}</>
            ) : (
              <Button
                style={{
                  display: actionsDataLocal.editButton?.ifShow ? "" : "none",
                  minWidth: "0px",
                }}
                onClick={() => {
                  if (actionsDataLocal.editButton?.onClick) {
                    actionsDataLocal.editButton.onClick(row);
                  } else {
                    setPopupUpdatedState({
                      isOpen: true,
                      rowData: row,
                    });
                  }
                }}
              >
                {actionsDataLocal.editButton?.name ? (
                  <FontAwesomeIcon
                    icon={faEdit}
                    size="lg"
                    title={t("edit")}
                    style={{ color: "#54BD23" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="lg"
                    title={t("confirm")}
                  />
                )}
              </Button>
            )}
          </div>
          <div>
            {actionsDataLocal.previewButton?.customComponent &&
            actionsDataLocal.previewButton.ifShow ? (
              <>{actionsDataLocal.previewButton.customComponent(row)}</>
            ) : (
              <Button
                style={{
                  display: actionsDataLocal.previewButton?.ifShow ? "" : "none",
                  minWidth: "0px",
                }}
                onClick={() => {
                  if (actionsDataLocal.previewButton?.onClick) {
                    actionsDataLocal.previewButton.onClick(row);
                  } else {
                    setPopupUpdatedState({
                      isOpen: true,
                      rowData: row,
                    });
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faEye}
                  size="lg"
                  title={
                    actionsDataLocal.previewButton?.name
                      ? actionsDataLocal.previewButton.name
                      : t("preview")
                  }
                  style={{ color: "#4287f5" }}
                />
              </Button>
            )}
          </div>
          <div>
            {actionsDataLocal.customButton1?.customComponent &&
            actionsDataLocal.customButton1.ifShow ? (
              <>{actionsDataLocal.customButton1.customComponent(row)}</>
            ) : (
              <Button
                style={{
                  display: actionsDataLocal.customButton1?.ifShow ? "" : "none",
                  minWidth: "0px",
                  ...actionsDataLocal.customButton1?.styles,
                }}
                onClick={() => {
                  if (actionsDataLocal.customButton1?.onClick) {
                    actionsDataLocal.customButton1.onClick(row);
                  } else {
                    setPopupUpdatedState({
                      isOpen: true,
                      rowData: row,
                    });
                  }
                }}
              >
                {actionsDataLocal?.customButton1?.icon
                  ? actionsDataLocal.customButton1.icon
                  : actionsDataLocal?.customButton1?.name
                  ? actionsDataLocal.customButton1.name
                  : t("confirm")}
              </Button>
            )}
          </div>
          <div>
            {actionsDataLocal.customButton2?.customComponent &&
            actionsDataLocal.customButton2.ifShow ? (
              <>{actionsDataLocal.customButton2.customComponent(row)}</>
            ) : (
              <Button
                style={{
                  display: actionsDataLocal.customButton2?.ifShow ? "" : "none",
                  minWidth: "0px",
                  ...actionsDataLocal.customButton2?.styles,
                }}
                onClick={() => {
                  if (actionsDataLocal.customButton2?.onClick) {
                    actionsDataLocal.customButton2.onClick(row);
                  } else {
                    setPopupUpdatedState({
                      isOpen: true,
                      rowData: row,
                    });
                  }
                }}
              >
                {actionsDataLocal?.customButton2?.icon
                  ? actionsDataLocal.customButton2.icon
                  : actionsDataLocal?.customButton2?.name
                  ? actionsDataLocal.customButton2.name
                  : t("confirm")}
              </Button>
            )}
          </div>
          {rowMenu?.ifShow && (
            <div className={styles.menuContainer}>{rowMenu.menu(row)}</div>
          )}
        </div>
      </div>
    </Cell>
  );
};
export default ConfirmRemoveActions;
