import React, { useCallback, useEffect, useState } from "react";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import { useTranslation } from "react-i18next";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import compareDatesIgnoringTime from "../../../HelpersFunctions/dateAndTime/compareDatesIgnoringTime";
import { ProfileType } from "../../../enums/profileType";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

let TableCell: any = Table.Cell;

const TableCellComponent = ({ value, style, ...restProps }: any) => {
  let cellStyles = {};
  let cellValue;
  if (
    restProps.column.name === "name" ||
    restProps.column.name === "organizationalUnits"
  ) {
    cellValue = value;
  } else {
    cellValue = value?.dayName;
    cellStyles = { textAlign: "center" };
  }

  return (
    <TableCell
      {...restProps}
      style={{
        backgroundColor: value?.color,
        ...cellStyles,
        ...style,
      }}
      //className={styles.borderLeft}
    >
      <span>{cellValue}</span>
    </TableCell>
  );
};

interface IProps {
  dateFrom: Date;
  dateTo: Date;
  onlyRequests: boolean;
}

function AbsenceOfCoworkers({ dateFrom, dateTo, onlyRequests }: IProps) {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const authUserId = authUser.currentProfile.subjectId;
  const profileType = authUser.currentProfile.type;

  const modifyResponseCallback = useCallback(
    (response) => {
      let columnsLocal: { name: string; title: string }[] = [
        { name: "name", title: t("name") },
        { name: "organizationalUnits", title: t("organizational_units") },
      ];

      let rowsLocal: any[] = [];
      let startDay = dateFrom.getDate();
      let endDay = dateTo.getDate();

      /////////////////////////////////// create columns
      for (let i = startDay; i <= endDay; i++) {
        let currentIterationDate = new Date(dateFrom);
        currentIterationDate.setDate(i);
        let dayTitleString = `${i} ${currentIterationDate.toLocaleString(
          t("scheduler_language"),
          {
            weekday: "short",
          }
        )}`;
        columnsLocal.push({ name: i.toString(), title: dayTitleString });
      }

      setColumns(columnsLocal);

      //////////////////////////////// create rows
      response.forEach((worker) => {
        let row = {
          id: worker.timeWorkerId,
          name: `${worker.lastName} ${worker.firstName}`,
          organizationalUnits: worker.organizationalUnit,
        };

        for (let i = startDay; i <= endDay; i++) {
          let currentIterationDate = new Date(dateFrom);
          currentIterationDate.setDate(i);
          let daydata = worker.absences.find((absence) => {
            return compareDatesIgnoringTime(
              new Date(absence.date),
              currentIterationDate
            );
          });
          row[i] = daydata;
        }
        rowsLocal.push(row);
      });

      setRows(rowsLocal);
    },
    [dateFrom, dateTo, t]
  );

  const [, fetchingStateAbsencesOfCoworkers, fetchAgainAbsencesOfCoworkers] =
    useFetchAndSetGET({
      path:
        profileType === ProfileType.SUPERIOR_TIME
          ? `superior-time/coworkers-absences?dateFrom=${convertDateFetch(
              dateFrom
            )}&dateTo=${convertDateFetch(
              getEndOfDate(dateTo, "Month")
            )}&onlyRequests=${onlyRequests}`
          : `worker-time/${authUserId}/coworkers-absences?dateFrom=${convertDateFetch(
              dateFrom
            )}&dateTo=${convertDateFetch(
              getEndOfDate(dateTo, "Month")
            )}&onlyRequests=${onlyRequests}`,
      startFetchOnInitial: false,
      modifyResponseCallback: modifyResponseCallback,
    });

  useEffect(() => {
    fetchAgainAbsencesOfCoworkers();
  }, [fetchAgainAbsencesOfCoworkers, dateFrom, dateTo, onlyRequests]);

  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<{ name: string; title: string }[]>([]);

  useEffect(() => {
    fetchAgainAbsencesOfCoworkers();
  }, [dateFrom, dateTo, onlyRequests, fetchAgainAbsencesOfCoworkers]);

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (!isNaN(parseInt(columnName))) {
      return row[columnName].dayName;
    }

    return row[columnName];
  }, []);

  return (
    <>
      <TableComponent
        layoutSettingName={
          profileType === ProfileType.SUPERIOR_TIME
            ? "layoutTimeSuperiorAbsenceOfCoworkers"
            : "layoutTimeWorkerAbsenceOfCoworkers"
        }
        rows={rows || []}
        columns={columns || []}
        leftColumns={["name", "organizationalUnits"]}
        tableCellComponent={TableCellComponent}
        fetchingState={{
          isError: fetchingStateAbsencesOfCoworkers.isError,
          isFetching: fetchingStateAbsencesOfCoworkers.isFetching,
          fetchAgain: fetchAgainAbsencesOfCoworkers,
        }}
        customExcelCellValue={customExportCellValue}
        customPdfCellValue={customExportCellValue}
        tableExportInfo={{
          dateFrom: dateFrom,
          dateTo: dateTo,
          createdBy: authUser.displayedName,
        }}
      />
    </>
  );
}

export default AbsenceOfCoworkers;
