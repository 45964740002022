import { CircularProgress } from "@material-ui/core";
import Button from "../../../helpersComponents/Button/button";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import MultiSelect from "../../../helpersComponents/MultiSelect/MultiSelect";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import styles from "./timesSpentInRooms.module.scss";
import { EmployeeTimeSpentInRoomDataItem, RoomItem } from "./type";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";

function TimesSpentInRooms() {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);

  const [ifFetch, setIfFetch] = useState<boolean>(false);
  const [errorMessagge, setErrorMessagge] = useState<string>("");

  const [rooms, setRooms] = useState<RoomItem[]>([]);
  const [selectedRooms, setSelectedRooms] = useState<number[]>([]);

  const [allWorkers, setAllWorkers] = useState<
    { [key in string]: any }[] | undefined
  >();
  const [allWorkersSelected, setAllWorkersSelected] = useState<boolean>(false);

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Month")
  );

  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Month"));
  const [rows, setRows] = useState<EmployeeTimeSpentInRoomDataItem[]>([]);

  const columns = [
    { name: "employee", title: t("employee") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "personnelNumber", title: t("personnel_number") },
    { name: "socialSecurityNumber", title: t("social_security_number") },
    { name: "user1", title: "User1" },
    { name: "entryRoom", title: t("entry_room") },
    { name: "exitRoom", title: t("exit_room") },
    { name: "timeFrom", title: t("time_from"), type: "date_hours" },
    { name: "timeTo", title: t("time_to"), type: "date_hours" },
    { name: "timeDuration", title: t("time_duration") },
  ];

  const layoutSettingName = "layoutSuperiorTimeTimesSpentInRoomsSelectWorkers";

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  const [fetchedRooms, roomsFetchingState, roomsFetchAgain] = useFetchAndSetGET(
    {
      path: `superior-time/rooms`,
      startFetchOnInitial: false,
    }
  );

  const [timesSpentInRoomsBody, setTimesSpentInRoomsBody] = useState<
    false | string
  >(false);

  const timesSpentInRoomssuccessCallback = useCallback((data) => {
    let retData: EmployeeTimeSpentInRoomDataItem[] = [];
    data.resJson.data.forEach((el) => {
      let newItem: EmployeeTimeSpentInRoomDataItem = { ...el };
      newItem.employee = `${el.firstName} ${el.lastName}`;
      newItem.timeDuration = convertMinsToHrsMins(el.timeDurationInMinutes);
      retData.push(newItem);
    });

    setRows(retData);
    setTimesSpentInRoomsBody(false);
  }, []);

  const [timesSpentInRoomsFetchingState, timesSpentInRoomsFetchAgain] =
    useFetchOtherThanGET({
      path: `superior-time/time-spent-in-rooms`,
      method: "POST",
      contentType: "application/json",
      body: timesSpentInRoomsBody,
      setBody: setTimesSpentInRoomsBody,
      successCallback: timesSpentInRoomssuccessCallback,
      disableErrorSnackbar: true,
      disableSuccessSnackbar: true,
    });

  useEffect(() => {
    roomsFetchAgain();
  }, [roomsFetchAgain]);

  useEffect(() => {
    if (fetchedRooms) {
      setRooms(fetchedRooms);
    }
  }, [fetchedRooms]);

  useEffect(() => {
    if (timesSpentInRoomsBody) {
      timesSpentInRoomsFetchAgain();
    }
  }, [timesSpentInRoomsBody, timesSpentInRoomsFetchAgain]);

  useEffect(() => {
    if (timesSpentInRoomsFetchingState.isError) {
      setIfFetch(false);
      setErrorMessagge(t("something_went_wrong"));
      return;
    }

    setIfFetch(false);
  }, [
    timesSpentInRoomsFetchingState.isError,
    timesSpentInRoomsFetchingState.response,
    t,
  ]);

  return (
    <>
      <TopBanner pathName={t("times_spent_in_rooms")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName={layoutSettingName}
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            setAllWorkersSelected={setAllWorkersSelected}
            pageSize={10}
          />
        </div>
        <div>
          {rooms && !roomsFetchingState.isFetching && (
            <DatePickerRange
              view={["year", "month", "date"]}
              format="dd-MM-yyyy"
              date={startDate}
              date2={endDate}
              setDate={setStartDate}
              setDate2={setEndDate}
              darkTheme={true}
              confirmButtonRight={
                <div className={styles.roomsAndButtonsContainer}>
                  <div className={styles.datepickerOptions}>
                    <MultiSelect
                      label={"Pomieszczenie"}
                      label_id="pomieszczenie"
                      items={rooms}
                      selectedItems={selectedRooms}
                      setSelectedItems={setSelectedRooms}
                    />
                  </div>

                  <div className={styles.getDataButton}>
                    {ifFetch ? (
                      <CircularProgress size={25} />
                    ) : (
                      <div>
                        <Button
                          onClick={() => {
                            if (selectedRooms.length === 0) {
                              setErrorMessagge(t("no_room_selected"));
                              return;
                            }

                            setIfFetch(true);

                            let selectedWorkersLocal = [...selectedWorkers];
                            if (selectedWorkers.length === 0 && allWorkers) {
                              allWorkers.forEach((worker) => {
                                selectedWorkersLocal.push(worker.id);
                              });
                            }

                            let requestObj = {
                              dateFrom: convertDateFetch(startDate),
                              dateTo: convertDateFetch(endDate),
                              timeWorkersIds: allWorkersSelected
                                ? selectedWorkersLocal
                                : selectedWorkers,
                              rooms:
                                selectedRooms.length === rooms.length
                                  ? []
                                  : rooms
                                      .filter((el) =>
                                        selectedRooms.includes(el.id)
                                      )
                                      .map((el) => el.name),
                              roomSearchMethod: 0,
                              source: "TimeSuperiorTimeSpentInRooms",
                            };

                            setTimesSpentInRoomsBody(
                              JSON.stringify(requestObj)
                            );
                            setErrorMessagge("");
                            updateLastSelectedWorkers();
                          }}
                        >
                          {t("get_data")}
                        </Button>
                        <div className={styles.errorMessagge}>
                          {errorMessagge}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            ></DatePickerRange>
          )}
        </div>

        <div>
          {!roomsFetchingState.isFetching && (
            <LoadingWrapper
              isLodadingProgress={timesSpentInRoomsFetchingState.isFetching}
              isError={timesSpentInRoomsFetchingState.isError}
              setIfFetchAgain={timesSpentInRoomsFetchAgain}
            >
              <TableComponent
                layoutSettingName="layoutSuperiorTimeTimesSpentInRoomsData"
                ifHideWholeToolbalr={true}
                rows={rows}
                columns={columns}
                fetchingState={{
                  fetchAgain: () => {
                    timesSpentInRoomsFetchAgain();
                  },
                  isError: timesSpentInRoomsFetchingState.isError,
                  isFetching: timesSpentInRoomsFetchingState.isFetching,
                }}
                tableExportInfo={{
                  dateFrom: startDate,
                  dateTo: endDate,
                  createdBy: authUser.displayedName,
                }}
              />
            </LoadingWrapper>
          )}
        </div>
      </div>
    </>
  );
}

export default TimesSpentInRooms;
