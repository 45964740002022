import MuiAccordion from "@material-ui/core/Accordion";
import { withStyles } from "@material-ui/styles";

const Accordion = withStyles({
    root: {
      '&.Mui-disabled': {
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.05)', 
      },
    }
  })(MuiAccordion);

  export default Accordion;