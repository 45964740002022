import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextFieldStyled } from "..";

function AutocompleteStyled(props) {
  const {
    error,
    isError,
    ownCheckIfThereIsError,
    required,
    helperText,
    width,
    darkTheme,
    ...restprops
  } = props;

  return (
    <Autocomplete
      renderInput={(params) => {
        return (
          <TextFieldStyled
            {...params}
            error={error}
            ownCheckIfThereIsError={ownCheckIfThereIsError}
            helperText={helperText}
            label={restprops.label}
            isError={isError}
            required={required}
            width={width}
            darkTheme={darkTheme}
          />
        );
      }}
      {...restprops}
    />
  );
}

export default AutocompleteStyled;
