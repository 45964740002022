import React from "react";
import { DialogContentStyled } from "../../../helpersComponents/PopupHelpers";
import FormTabs from "./Forms/FormTabs";

interface IProps {
  rowId?: number;
  onSubmit: (values: any) => void;
  isPreview?: boolean;
}

const PopupContentGroups: React.FC<IProps> = ({
  rowId,
  onSubmit,
  isPreview,
}) => {
  return (
    <div>
      <DialogContentStyled>
        <FormTabs rowId={rowId} onSubmit={onSubmit} isPreview={isPreview} />
      </DialogContentStyled>
    </div>
  );
};

export default PopupContentGroups;
