import ReportsToDownloadContent from "./ReportsToDownloadContent";

const ReportsToDownload = () => {
  return (
    <div>
      <ReportsToDownloadContent />
    </div>
  );
};

export default ReportsToDownload;
