import { Button } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import BusinessExitsPopup from "./BusinessExitsPopup";
import moment from "moment";

function ContentBusinessExits() {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1, 0, 0, 0)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31, 0, 0)
  );

  const authUserId = authUser.currentProfile.subjectId;

  const columns = [
    { name: "date", title: t("event_time"), type: "date_hours" },
    { name: "purposeOfExit", title: t("purpose_of_the_exit") },
    { name: "statusTranslated", title: t("request_status") },
    {
      name: "statusModifiedAt",
      title: t("date_of_changing_status"),
      type: "date_hours",
    },
    { name: "approvedBy", title: t("approved_by") },
  ];

  const businessExistsResponseCallback = useCallback(
    (responseData: BusinessExit[]) => {
      const modified = responseData?.map((row: BusinessExit) => {
        return {
          ...row,
          purposeOfExit: row.purposeOfExit ? row.purposeOfExit : "-",
          approvedBy: row.approvedBy ? row.approvedBy : "-",
          statusModifiedAt:
            row.statusModifiedAt === "0001-01-01T00:00:00"
              ? null
              : row.statusModifiedAt,
          statusTranslated: t(statusDictionary[row.status]),
        };
      });
      return modified;
    },
    [t]
  );

  const [
    businessExists,
    fetchingStateBusinessExists,
    fetchAgainBusinessExists,
  ] = useFetchAndSetGET<BusinessExit[]>({
    path:
      `worker-time/${authUserId}/business-exits?DateFrom=` +
      computeDateRangeFetch(startDate, "T00:00:00") +
      "&DateTo=" +
      computeDateRangeFetch(endDate),
    modifyResponseCallback: businessExistsResponseCallback,
  });

  const [popupInfo, setPopupInfo] = useState<{
    open: boolean;
    rowId: number | undefined;
  }>({
    open: false,
    rowId: undefined,
  });

  useEffect(() => {
    fetchAgainBusinessExists();
  }, [startDate, endDate, fetchAgainBusinessExists]);

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "date") {
      return row.date ? moment(row.date).format("YYYY-MM-DD HH:mm:ss") : "";
    }

    if (columnName === "statusModifiedAt") {
      return row.statusModifiedAt
        ? moment(row.statusModifiedAt).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    return row[columnName];
  }, []);

  return (
    <>
      <TopBanner pathName={t("business_exits")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
          />
        </div>
        <div>
          <TableComponent
            layoutSettingName="layoutWorkerTimeRequestsBusinessExits"
            rows={businessExists}
            columns={columns}
            ifEdit={true}
            actionsColumnUpdated={{
              editButton: {
                ifShow: true,
                customComponent: (row) => {
                  return row.status === 1 ? (
                    <Button
                      onClick={() => {
                        setPopupInfo({
                          open: true,
                          rowId: row.id,
                        });
                      }}
                    >
                      {t("edit")}
                    </Button>
                  ) : (
                    <></>
                  );
                },
              },
              successCallbackPopup: fetchAgainBusinessExists,
              popup: BusinessExitsPopup,
            }}
            fetchingState={{
              fetchAgain: fetchAgainBusinessExists,
              isError: fetchingStateBusinessExists.isError,
              isFetching: fetchingStateBusinessExists.isFetching,
            }}
            customExcelCellValue={customExportCellValue}
            customPdfCellValue={customExportCellValue}
            tableExportInfo={{
              dateFrom: startDate,
              dateTo: endDate,
              createdBy: authUser.displayedName,
            }}
          />

          {popupInfo.open && (
            <BusinessExitsPopup
              isOpen={popupInfo.open}
              rowId={popupInfo.rowId}
              closePopup={() => {
                setPopupInfo((prevState) => {
                  return {
                    ...prevState,
                    open: false,
                  };
                });
              }}
              successCallback={fetchAgainBusinessExists}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ContentBusinessExits;
