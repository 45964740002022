import { totalSecondsDiff } from "../../../HelpersFunctions/dateAndTime/timeDiffs";

const filterEvents = (events) => {
  let filteredEvents = events.filter(
    (event) =>
      event.typeId === 1 ||
      event.typeId === 11 ||
      event.typeId === 8 ||
      event.typeId === 18
  );
  return filteredEvents;
};

const reduceEntryReturnLastEvent = (events) => {
  let result: any = null;

  while (
    events &&
    events.length > 0 &&
    (events[events.length - 1].typeId === 1 ||
      events[events.length - 1].typeId === 11)
  ) {
    result = { ...events[events.length - 1] };
    events.splice(-1);
  }

  return result;
};

const computeWorkedTimeToday = (events, date = new Date()) => {
  let workedTimeToday: number = 0;
  let currentEntry: any = null;
  let currentExit: any = null;

  if (events && events.length > 0) {
    // Filtrujemy zdarzenia
    let filteredEvents = filterEvents(events);

    // Sortujemy zdarzenia rosnąco
    filteredEvents = [...filteredEvents].sort((a, b) => {
      return new Date(a.time) > new Date(b.time) ? 1 : -1;
    });

    if (filteredEvents) {
      //ustawia date która mamy aktualnie ( roznica wynosi 0)
      var tempDate =
        reduceEntryReturnLastEvent(filteredEvents)?.time ?? new Date(date);

      //dodajemy czas jednokrotnie
      workedTimeToday = totalSecondsDiff(date, tempDate);

      let added = 0;
      let lastExit;

      // Odwracamy tablicę
      filteredEvents.reverse();

      filteredEvents.forEach((event) => {
        if (event.typeId === 1 || event.typeId === 11) {
          currentEntry = event;
        } else if (event.typeId === 8 || event.typeId === 18) {
          currentExit = event;
        }

        // obsługa sytacji gdy są dwa wejścia po sobie
        if (currentEntry != null && currentExit == null && lastExit != null) {
          workedTimeToday -= added; // odejmujemy to co ostatnio dodalismy
          added = totalSecondsDiff(lastExit.time, currentEntry.time); // nowa wartosc ktora bedzie dodana
          workedTimeToday += added; // dodajemy wartosc
          currentEntry = null; // wracamy do sytuacji poczatkowej
          currentExit = null;
          lastExit = null;
        }

        // dodajemy roznice
        if (currentEntry != null && currentExit != null) {
          added = totalSecondsDiff(currentExit.time, currentEntry.time);
          workedTimeToday += added;
          currentEntry = null;
          lastExit = currentExit;
          currentExit = null;
        }
      });
    }
  }

  return workedTimeToday;
};

export { computeWorkedTimeToday, filterEvents, reduceEntryReturnLastEvent };
