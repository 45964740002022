import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { Link, useLocation, useParams } from "react-router-dom";
import styles from "./building.module.scss";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { useCallback, useEffect, useState } from "react";
import useImageLoader from "../../../hooks/fetchHooks/useImageLoader/useImageLoader";
import { useTranslation } from "react-i18next";

const BuildingSMSUser = (props) => {
  const { t } = useTranslation();
  const routeParams: any = useParams();
  const location = useLocation();
  const queryString = location.search;
  const queryParams = new URLSearchParams(queryString);
  const [imageUrls, setImageUrls] = useState([]);
  const { imagesData } = useImageLoader(imageUrls);

  const modifyBuildingResponseCallback = useCallback(
    (responseData: ISMSBuilding) => {
      let urls: any = [];

      if (responseData.floors) {
        for (let i = 0; i < responseData.floors.length; i++) {
          urls.push(`sms-user/download-map/${responseData.floors[i].mapId}`);
        }
      }

      setImageUrls(urls);
      return responseData;
    },
    []
  );

  const [building, , fetchAgainBuilding] = useFetchAndSetGET<ISMSBuilding>({
    path: `sms-user/buildings/${routeParams.id}`,
    modifyResponseCallback: modifyBuildingResponseCallback,
  });

  useEffect(() => {
    if (routeParams.id) {
      fetchAgainBuilding();
    }
  }, [routeParams.id, fetchAgainBuilding]);

  return (
    <div>
      <TopBanner pathName={queryParams.get("menuItemName")} />
      <div className="flexAndCenter">
        <div className={styles["image-grid"]}>
          {imagesData &&
            building?.floors?.map((floor, index) => {
              let floorMapUrl = `sms-user/download-map/${floor.mapId}`;

              let foundMap = imagesData.find((el) => el.url === floorMapUrl);

              return (
                <div key={index} className={styles["image-item"]}>
                  <Link
                    to={`/sms-user/building-floors/${floor.id}?menuItemName=${floor.name}`}
                    className={styles["custom-link"]}
                  >
                    <figure>
                      <figcaption>{floor.name}</figcaption>
                      {foundMap?.size ? (
                        <img
                          src={foundMap.data}
                          alt={`${t("floor_map")}: ${floor.name}`}
                        />
                      ) : (
                        <img
                          src={"no-image.jpg"}
                          alt={`${t("floor_map")}: ${floor.name}`}
                        />
                      )}
                    </figure>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default BuildingSMSUser;
