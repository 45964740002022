export function totalHoursDiff(date1: Date, date2: Date): number {
  var diff = (new Date(date2).getTime() - new Date(date1).getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(diff);
}

export function totalMinutesDiff(date1: Date, date2: Date): number {
  var diff = (new Date(date2).getTime() - new Date(date1).getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export function totalSecondsDiff(date1: Date, date2: Date): number {
  var diff = (new Date(date2).getTime() - new Date(date1).getTime()) / 1000;
  return Math.abs(Math.round(diff));
}
