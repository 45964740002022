import AddRoundedIcon from '@material-ui/icons/AddRounded';
import {Button, ButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
    padding: '0px',
    borderRadius: '5px',
    backgroundColor: '#73ba73',
    '&:hover': {
      backgroundColor: '#569c56',
    }
  },
})

export const AddLabelButton = (props: ButtonProps) => {
  const classes = useStyles();
  return (
    <div style={{
      paddingInline: '5px',
    }}>
    <Button {...props} 
      size='small'
      variant='contained'
      color="primary"
      disableElevation
      fullWidth
      className={classes.root}
      >
        <AddRoundedIcon fontSize='medium'/>
    </Button>
    </div>
  )
}
