import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../../Button/button";
import { useTranslation } from "react-i18next";
import { formatSizeInBytes } from "../../../../HelpersFunctions/stringUtils";
import styles from "./uploadFileFIeld.module.scss";
import IconButton from "@material-ui/core/IconButton/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Controller } from "react-hook-form";

export default function UploadFileFieldReactHookForm({
  register,
  name,
  label,
  showTopLabel,
  control,
  setValue,
  getValues,
  displayMode = 0,
  watch,
  onChangeExtra,
  ...props
}: any): ReactElement {
  const { t } = useTranslation();
  const fileUploadFieldRef = useRef<HTMLInputElement>();
  const { ref: registerRef } = register(name);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [imagePreview, setImagePreview] = useState<any>(null);

  const handleFileUploadButtonClick = () => {
    fileUploadFieldRef.current?.click();
  };

  const handleFileSelect = useCallback(
    (e) => {
      const files = e.target.files;

      if (onChangeExtra) {
        onChangeExtra(e);
      }

      let tempSelectedFiles: any = Array.from(files) || [];
      setValue(name, tempSelectedFiles.length > 0 ? tempSelectedFiles : null);
      setSelectedFiles([...tempSelectedFiles]);
    },
    [name, onChangeExtra, setValue]
  );

  useEffect(() => {
    let value = getValues(name);
    let tempSelectedFiles: any = [];

    if (value) {
      tempSelectedFiles = Array.from(value) || [];
      setSelectedFiles([...tempSelectedFiles]);
    }
  }, [getValues, name]);

  const watchedValue = watch(name);

  useEffect(() => {
    if (watchedValue && Object.entries(watchedValue).length > 0) {
      setSelectedFiles([...watchedValue]);
    }
  }, [watchedValue]);

  useEffect(() => {
    if (displayMode === 1 && selectedFiles && selectedFiles.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          const { result } = e.target;
          if (result) {
            setImagePreview(result);
          }
        }
      };

      reader.readAsDataURL(selectedFiles[0]);
    }
  }, [displayMode, selectedFiles]);

  return (
    <div>
      {showTopLabel && (
        <div>
          <b>{label}:</b>
        </div>
      )}

      <div>
        <div>
          <Button type="button" onClick={handleFileUploadButtonClick}>
            {t("select_file")}
          </Button>
        </div>

        <div>
          {displayMode === 1 && selectedFiles.length === 1 && (
            <div className={styles.imagePreviewContainer}>
              {imagePreview && (
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "100px" }}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedFiles([]);
                      setValue(name, null);
                    }}
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "-7px",
                      background: "red",
                      borderRadius: "50%",
                      border: "none",
                      width: "30px",
                      height: "30px",
                      color: "white",
                      fontSize: "20px",
                      cursor: "pointer",
                      opacity: "0.7",
                    }}
                    onMouseEnter={(e: any) => {
                      e.target.style.backgroundColor = "darkred";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.backgroundColor = "red";
                    }}
                  >
                    &times;
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
          formState,
        }) => (
          <>
            <input
              ref={(e: any) => {
                registerRef(e);
                fileUploadFieldRef.current = e;
              }}
              type="file"
              hidden={true}
              onChange={(e) => {
                onChange();
                handleFileSelect(e);
              }}
              {...props}
              name={name}
            />
            {error && <p style={{ color: "red" }}>{error.message}</p>}
          </>
        )}
      />

      {displayMode === 0 && (
        <ul className={styles.selectedFilesList}>
          {selectedFiles &&
            selectedFiles.map((selectedFile, index) => {
              return (
                <>
                  <li>
                    <span className={styles.selectedFileName}>
                      {selectedFile.name}
                    </span>
                    <span className={styles.selectedFileSize}>
                      {formatSizeInBytes(selectedFile.size)}
                    </span>

                    <IconButton
                      onClick={() => {
                        let cancelFile = selectedFiles.findIndex(
                          (cancelFile) => cancelFile.name === selectedFile.name
                        );

                        let newFiles = [...selectedFiles];
                        newFiles.splice(cancelFile, 1);
                        setSelectedFiles(newFiles);
                        setValue(name, newFiles.length > 0 ? newFiles : null);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        size="sm"
                        color="red"
                        title={t("delete")}
                      />
                    </IconButton>
                  </li>
                </>
              );
            })}
        </ul>
      )}
    </div>
  );
}
