import { useQuery, useQueryClient } from "@tanstack/react-query";
import fetchClient from "../../../api/fetchClient";
import { ProfileType } from "../../../enums/profileType";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import { useEffect } from "react";

const fetchSMSVisualizationObjectLayerInstances = async (
  profileType: ProfileType
): Promise<ISMSVisualizationObjectLayerInstance[]> => {
  const endpoint =
    profileType === ProfileType.SMS_ADMINISTRATOR
      ? "sms-administrator/visualization-objects-layers-instances"
      : "sms-user/visualization-objects-layers-instances";

  return fetchClient<ISMSVisualizationObjectLayerInstance[]>(endpoint, {
    useAuth: true,
  });
};

const useFetchSMSVisualizationObjectLayerInstances = () => {
  const queryClient = useQueryClient();
  const authUser = useAppSelector(selectAuthUser);
  const profileType = authUser.currentProfile?.type;

  useEffect(() => {
    if (
      profileType === ProfileType.SMS_ADMINISTRATOR ||
      profileType === ProfileType.SMS_USER
    ) {
      queryClient.prefetchQuery(
        ["SMSVisualizationObjectLayerInstances", profileType],
        () =>
          fetchSMSVisualizationObjectLayerInstances(profileType as ProfileType)
      );
    }
  }, [profileType, queryClient]);

  return useQuery<ISMSVisualizationObjectLayerInstance[], Error>(
    ["SMSVisualizationObjectLayerInstances", profileType],
    () => fetchSMSVisualizationObjectLayerInstances(profileType as ProfileType),
    {
      enabled:
        profileType !== undefined &&
        (profileType === ProfileType.SMS_ADMINISTRATOR ||
          profileType === ProfileType.SMS_USER),
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export default useFetchSMSVisualizationObjectLayerInstances;
