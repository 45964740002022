import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import AddEditBuildingPopup from "./Popups/AddEditBuildingPopup";
import RowDetailsBuilding from "./rowDetailsBuilding";
import { smsCompleteImageToBlob } from "../../../HelpersFunctions/imagesHelpers";
import { useSMSVisualizationDataContext } from "../../../HelpersFunctions/SMSVisualizationDataProvider";

const BuildingsSMSAdministrator = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [buildingIdToRemove, setBuildingIdToRemove] = useState(false);
  const { imagesData, imagesLoading } = useSMSVisualizationDataContext();

  const [imagesMap, setImagesMap] = useState<Map<number, ISMSCompleteImage>>(
    new Map()
  );

  useEffect(() => {
    if (!imagesLoading && imagesData && imagesData.length > 0) {
      const tempImagesMap = imagesData.reduce((acc, obj) => {
        acc.set(obj.id, obj);
        return acc;
      }, new Map());

      setImagesMap(tempImagesMap);
    }
  }, [imagesLoading, imagesData]);

  const columns = [
    {
      name: "file",
      title: t("preview"),
      customFormatter: (row) => {
        if (imagesMap && row.imageId && imagesMap.has(row.imageId)) {
          return (
            <img
              src={URL.createObjectURL(
                smsCompleteImageToBlob(imagesMap.get(row.imageId)!)
              )}
              style={{ maxWidth: "100px" }}
              alt=""
            />
          );
        }

        return null;
      },
    },
    { name: "name", title: t("name") },
    { name: "description", title: t("description") },
    { name: "order", title: t("order") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "editedBy", title: t("edited") },
    { name: "editedAt", title: t("data_edited"), type: "date_hours" },
  ];

  const [buildings, fetchingStateBuildings, fetchAgainBuildings] =
    useFetchAndSetGET({
      path: `sms-administrator/buildings`,
      startFetchOnInitial: true,
    });

  const [, fetchAgainRemoveBuildingGroup] = useFetchOtherThanGET({
    path: `sms-administrator/buildings/${buildingIdToRemove}`,
    method: "DELETE",
    body: buildingIdToRemove,
    setBody: setBuildingIdToRemove,
    successCallback: fetchAgainBuildings,
  });

  useEffect(() => {
    if (buildingIdToRemove) {
      fetchAgainRemoveBuildingGroup();
    }
  }, [buildingIdToRemove, fetchAgainRemoveBuildingGroup]);

  return (
    <div>
      <TopBanner pathName={t("buildings")} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStateBuildings.isFetching}
            isError={fetchingStateBuildings.isError}
            setIfFetchAgain={() => {
              fetchAgainBuildings();
            }}
          >
            {!fetchingStateBuildings.isFetching && (
              <TableComponent
                layoutSettingName="layoutSMSAdministratorBuildings"
                rows={buildings || []}
                columns={columns}
                rowDetail={RowDetailsBuilding}
                actionsColumnUpdated={{
                  popup: (props) => <AddEditBuildingPopup {...props} />,
                  successCallbackPopup: fetchAgainBuildings,
                  addButton: {
                    ifShow: true,
                  },
                  removeButton: {
                    ifShow: true,
                    name: t("delete"),
                    onClick: async (item) => {
                      let selectedbuttonId = await confirm({
                        text: `${t("confirm_delete_building")}`,
                        buttons: [
                          { buttonName: t("remove"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });

                      if ((await selectedbuttonId) === 0) {
                        setBuildingIdToRemove(item.id);
                      }
                    },
                  },
                  editButton: {
                    ifShow: true,
                    name: t("edit"),
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default BuildingsSMSAdministrator;
