import "./notificationList.scss";
import React, { useCallback, useEffect, useState } from "react";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import AddNotificationPopup from "../../../helpersComponents/Notifications/AddNotificationPopup";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import CircularProgress from "@material-ui/core/CircularProgress";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import useFetchPatch from "../../../../hooks/fetchHooks/patch/useFetchPatch";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import { arrivalTypes } from "../../../../Constants/arrivalTypes";
import { Button } from "@material-ui/core";
import useFetchDelete from "../../../../hooks/fetchHooks/delete/useFetchDelete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import OwnButton from "../../../helpersComponents/Button/button";
import useFetchPostFormData from "../../../../hooks/fetchHooks/post/useFetchPostFormData";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { saveAs } from "file-saver";
import NotificationRowDetail from "../../../helpersComponents/Notifications/NotificationRowDetail";
import { Button as MaterialUIButton } from "@material-ui/core";
import moment from "moment";
import { selectSettings } from "../../../../reducers/settings";

let Cell: any = TableEditColumn.Cell;

function NotificationListContent() {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1, 0, 0, 0)
  );
  // const [startDate, setStartDate] = useState(new Date(new Date().getFullYear() - 1, 11, 16));
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31, 0, 0)
  );
  const [fetchAgain, setFetchAgain] = useState(true);
  const [dataModified, setDataModified] = useState<any>([]);
  const [isFetching, setIsFetching] = useState(true);
  const authUserId = authUser.currentProfile.subjectId;
  const notificationsResponse = useFetchGet(
    `worker-time/${authUserId}/notifications?DateFrom=` +
      computeDateRangeFetch(startDate, "T00:00:00") +
      "&DateTo=" +
      computeDateRangeFetch(endDate),
    fetchAgain
  );

  const settings = useAppSelector(selectSettings);
  const [selectedFile, setSelectedFile] = useState<any>(false);
  const [postFileBody, setPostFileBody] = useState<boolean>(false);
  const [importFileCircualProgress, setImportFileCircualProgress] =
    useState<boolean>(false);

  let postFileRequest = useFetchPostFormData(
    `worker-time/${authUserId}/notifications-import`,
    postFileBody
  );
  useEffect(() => {
    if (postFileRequest.data?.status === 200) {
      setSnackbarState([true, "success", t("successful_import")]);
      setFetchAgain(true);
      setIsFetching(true); // circ
      setSelectedFile(false);
      setPostFileBody(false);
    } else if (postFileRequest.data?.status === 400) {
      let error = "";
      Object.keys(postFileRequest.data.errors).forEach((errorObject) => {
        postFileRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setIsFetching(false); // circ
      setSnackbarState([true, "error", error]);
    } else if (postFileRequest.error !== null) {
      setIsFetching(false); // circ
      setSnackbarState([true, "error", t("something_went_wrong")]);
    }
    setImportFileCircualProgress(false);
  }, [postFileRequest, t]);

  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);
  const [newStatusBody, setNewStatusBody] = useState<any>(false);
  const [idUpdateStatus, setIdUpdateStatus] = useState();
  const responseStatusUpdate = useFetchPatch(
    `worker-time/${authUserId}/notifications/` + idUpdateStatus,
    newStatusBody
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState();
  const [ifFetchDelete, setIfFetchDelete] = useState<boolean>(false);
  const responseStatusDelete = useFetchDelete(
    `worker-time/${authUserId}/notifications/` + idDelete,
    ifFetchDelete
  );

  const getColumns = useCallback(() => {
    let tempColumns = [
      { name: "arrivalDate", title: t("date_of_arrival"), type: "date_hours" },
      { name: "guestString", title: t("guest") },
      { name: "typeOfVisit", title: t("type_of_visit") },
      { name: "parkingName", title: t("parking_lot") },
      { name: "isProvisioningString", title: t("prepare_meal") },
      { name: "arrivalString", title: t("confirm_arrival") },
      { name: "statusTranslated", title: t("status") },
    ];

    if (settings.WebAnonseUkryjDateDoAwizacji?.value !== "1") {
      tempColumns.splice(1, 0, {
        name: "dateTo",
        title: t("date_date_to"),
        type: "date_hours",
      });
    }

    return tempColumns;
  }, [settings.WebAnonseUkryjDateDoAwizacji?.value, t]);

  const columns = getColumns();

  const ActionColumn: React.FC = ({
    children,
    row,
    classes,
    ...restProps
  }: any) => {
    return (
      <Cell row={restProps.row} className="tableCellEditCancelStyleGlobal">
        <MaterialUIButton
          style={{
            maxWidth: "30px",
            maxHeight: "30px",
            minWidth: "30px",
            minHeight: "30px",
          }}
          onClick={() => {
            updateStatus(row.id);
          }}
        >
          <FontAwesomeIcon icon={faCheck} size="lg" title={t("confirm_v2")} />
        </MaterialUIButton>

        {children[1]}
      </Cell>
    );
  };

  const updateStatus = (id) => {
    setNewStatusBody([
      {
        op: "replace",
        path: "/meetingApproval",
        value: true,
      },
    ]);
    setIdUpdateStatus(id);
    setIsFetching(true); // circ
  };

  useEffect(() => {
    if (responseStatusUpdate.data?.status === 200) {
      setSnackbarState([true, "success", t("notification_confirmed")]);
      setFetchAgain(true);
      setIsFetching(true); // circ
    } else if (responseStatusUpdate.data?.status === 400) {
      let error = "";
      Object.keys(responseStatusUpdate.data.errors).forEach((errorObject) => {
        responseStatusUpdate.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setIsFetching(false); // circ
      setSnackbarState([true, "error", error]);
    } else if (responseStatusUpdate.error !== null) {
      setIsFetching(false); // circF
      setSnackbarState([true, "error", t("something_went_wrong")]);
    }
  }, [responseStatusUpdate, t]);

  useEffect(() => {
    if (responseStatusDelete.data === 200) {
      setSnackbarState([true, "success", t("notification_deleted")]);
      setFetchAgain(true);
      setIsFetching(true); // circ
    } else if (responseStatusDelete.data?.status === 400) {
      let error = "";
      Object.keys(responseStatusDelete.data.errors).forEach((errorObject) => {
        responseStatusDelete.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setSnackbarState([true, "error", error]);
      setIsFetching(false); // circ
    } else if (responseStatusDelete.error !== null) {
      setSnackbarState([true, "error", t("something_went_wrong")]);
      setIsFetching(false); // circ
    }
    setIfFetchDelete(false);
  }, [responseStatusDelete, t]);

  useEffect(() => {
    setFetchAgain(true);
    setIsFetching(true); // circ
  }, [startDate, endDate]);

  useEffect(() => {
    if (notificationsResponse.error !== null) {
      setIsError(true);
      setFetchAgain(false);
      return;
    }
    if (notificationsResponse.data === null) return;
    let data = notificationsResponse.data;

    data.forEach((element, index) => {
      data[index].statusTranslated = t(
        statusDictionary[data[index].requestStatus]
      );
      data[index].arrivalDateString = new Date(
        element.arrivalDate
      ).toLocaleString(t("scheduler_language"), {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });

      data[index].dateToString = new Date(element.dateTo).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );

      data[index].createdAtString = new Date(element.createdAt).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );
      data[index].approvedAtString = element.approvedAt
        ? new Date(element.approvedAt).toLocaleString(t("scheduler_language"), {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })
        : null;
      data[index].guestString =
        element.guestFirstName + " " + element.guestLastName;
      data[index].isProvisioningString = element.isProvisioning
        ? t("yes")
        : t("no");
      data[index].arrivalString = t(arrivalTypes[element.arrival]);
      data[index].meetingApprovalString = element.meetingApproval
        ? t("confirmed")
        : t("lack_of_confirmation");
    });

    setDataModified(data);
    setIsFetching(false);
    setFetchAgain(false);
  }, [notificationsResponse, t]);

  const commitChanges = ({ added, changed, deleted }) => {
    if (deleted) {
      let row: any = dataModified.find((row) => row.id === deleted[0]);
      setIdDelete(row.id);
      setIfFetchDelete(true);
    } else if (added) {
      setFetchAgain(true);
      setSnackbarState([true, "success", t("notification_added")]);
    }
    setIsFetching(true); // circ
  };

  const modifyResponseCallback = useCallback((response) => {
    saveAs(response, `Anonse_przyklad.xlsx`);
  }, []);

  const [, , fetchAgainExampleFile] = useFetchAndSetGET({
    path: `worker-time/${authUserId}/example-files?fileName=Anonse_przyklad.xlsx`,
    startFetchOnInitial: false,
    ifBlob: true,
    modifyResponseCallback: modifyResponseCallback,
  });

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "arrivalDate") {
      return row.arrivalDate
        ? moment(row.arrivalDate).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    return row[columnName];
  }, []);

  return (
    <>
      <TopBanner pathName={t("list_announcements")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
          >
            <div className="importHeader">
              <div>
                <b>{t("import_from_file")}</b>
              </div>

              <div>
                <Button
                  onClick={() => {
                    fetchAgainExampleFile();
                  }}
                >
                  <div className="exampleFileNotifications">
                    (<div>{t("example_file_to_import")}</div>
                    <div>
                      <FontAwesomeIcon icon={faArrowDown} />
                    </div>
                    )
                  </div>
                </Button>
              </div>
            </div>
            <div className="fileInput">
              <div>
                <input
                  type="file"
                  className="inputFile"
                  accept={".xls, .xlsx"}
                  onClick={(e: any) => (e.target.value = null)}
                  onChange={(e) => {
                    if (!e.target.files) return;
                    if (!e.target.files?.[0]) {
                      return setSelectedFile(false);
                    }
                    setSelectedFile(e.target.files);
                  }}
                />
              </div>
              {selectedFile ? (
                <div className="selectedFile">
                  <div>
                    <b>{selectedFile[0].name}</b>{" "}
                    {(selectedFile[0].size * 0.001).toFixed(2)} kB
                  </div>
                  <div>
                    {!importFileCircualProgress ? (
                      <OwnButton
                        onClick={() => {
                          if (!selectedFile) return;
                          let formdata: any = new FormData();
                          formdata.append("attachments", selectedFile[0]);
                          setIsFetching(true); // circ
                          setPostFileBody(formdata);
                          setImportFileCircualProgress(true);
                        }}
                      >
                        {t("import")}
                      </OwnButton>
                    ) : (
                      <div className="circularProgressImport">
                        <CircularProgress size={25} />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </DatePickerRange>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={isFetching}
            isError={isError}
            setIfFetchAgain={setFetchAgain}
            setIsError={setIsError}
          >
            {!isFetching && !isError && (
              <TableComponent
                layoutSettingName="layoutWorkerTimeNotificationList"
                rows={dataModified}
                columns={columns}
                rowDetail={NotificationRowDetail}
                popup={AddNotificationPopup}
                ifEdit={true}
                ifAdd={true}
                ifDelete={true}
                deleteCommandName={t("delete")}
                commitChanges={commitChanges}
                actionColumn={ActionColumn}
                customExcelCellValue={customExportCellValue}
                customPdfCellValue={customExportCellValue}
                tableExportInfo={{
                  dateFrom: startDate,
                  dateTo: endDate,
                  createdBy: authUser.displayedName,
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default NotificationListContent;
