import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import DialogStyled from "../../../helpersComponents/PopupHelpers/dialogStyled";
import { DialogContentStyled } from "../../../helpersComponents/PopupHelpers/dialogContentStyled";
import DialogTitleStyled from "../../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/styles";
import AddEditVisualizationObjectPopupContent from "./AddEditVisualizationObjectPopupContent";
import SMSVisualizationObjectActionType from "../../../../enums/SMS/SMSVisualizationObjectActionType";

const AddEditVisualizationObjectPopup: React.FC<any> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const schema = Yup.object({
    name: Yup.string().required(t("field_required")),
    description: Yup.string(),
    icon: Yup.mixed().nullable(),

    values: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().required(t("field_required")),
        topic: Yup.string().required(t("field_required")),
        condition: Yup.string(),
        property: Yup.string(),
      })
    ),
    states: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().required(t("field_required")),
        condition: Yup.string().required(t("field_required")),
      })
    ),
    actions: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t("field_required")),
        type: Yup.object().nullable().required(t("field_required")),
        order: Yup.string().required(t("field_required")),
        topic: Yup.string()
          .nullable()
          .when("type.id", {
            is: SMSVisualizationObjectActionType.MQTT_ACTION,
            then: Yup.string().nullable().required(t("field_required")),
          }),
        value: Yup.string()
          .nullable()
          .when("type.id", {
            is: SMSVisualizationObjectActionType.MQTT_ACTION,
            then: Yup.string().nullable().required(t("field_required")),
          }),
        urlAddress: Yup.string()
          .nullable()
          .when("type.id", {
            is: SMSVisualizationObjectActionType.LINK_ACTION,
            then: Yup.string().nullable().required(t("field_required")),
          }),
        username: Yup.string().nullable(),
        password: Yup.string().nullable(),
        openURLInNewWindow: Yup.boolean(),

        states: Yup.array()
          .nullable()
          .test(
            "atLeastOneStateRequired",
            t("at_least_one_state_required"),
            (value) => {
              return value ? value?.length >= 1 : false;
            }
          )
          .required(t("field_required")),
      })
    ),
    layers: Yup.array()
      .of(
        Yup.object().shape({
          isAnchoredLayer: Yup.boolean(),
          baseLayerId: Yup.number(),
          name: Yup.string().required(t("field_required")),
          type: Yup.object().nullable().required(t("field_required")),
          states: Yup.array()
            .nullable()
            .test(
              "atLeastOneStateRequired",
              t("at_least_one_state_required"),
              (value) => {
                return value ? value?.length >= 1 : false;
              }
            )
            .required(t("field_required")),
          order: Yup.string().required(t("field_required")),
          group: Yup.string().nullable(),
          image: Yup.object()
            .nullable()
            .when("type.id", {
              is: 0,
              then: Yup.object().nullable().required(t("field_required")),
            }),
        })
      )
      .test(
        "atLeastOneLayerRequired",
        t("at_least_one_layer_required"),
        (value) => {
          return value ? value?.length >= 1 : false;
        }
      ),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      name: "",
      description: "",
      icon: null,
      values: [],
      states: [],
      layers: [],
      actions: [],
    },
  });

  const {
    fields: values,
    append: appendValue,
    remove: removeValue,
  } = useFieldArray({
    control,
    name: "values",
  });

  const {
    fields: states,
    append: appendState,
    remove: removeState,
  } = useFieldArray({
    control,
    name: "states",
  });

  const {
    fields: actions,
    append: appendAction,
    remove: removeAction,
  } = useFieldArray({
    control,
    name: "actions",
  });

  const {
    fields: layers,
    append: appendLayer,
    remove: removeLayer,
    insert: insertLayer,
  } = useFieldArray({
    control,
    name: "layers",
  });

  const useStyles = makeStyles(() => ({
    dialog: {
      width: 1600,
      minHeight: 800,
      margin: 10,
    },
  }));

  const classes = useStyles();
  return (
    <DialogStyled
      maxWidth={false}
      fullWidth={true}
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {
          reset();
        },
        onEnter: () => {
          setErrorMessage("");
        },
      }}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitleStyled
        title={t("visualization_object")}
        subtitle={
          rowId
            ? t("editing_visualization_object")
            : t("adding_visualization_object")
        }
      />

      <DialogContentStyled>
        <AddEditVisualizationObjectPopupContent
          rowId={rowId}
          successCallback={successCallback}
          register={register}
          control={control}
          handleSubmit={handleSubmit}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          values={values}
          appendValue={appendValue}
          removeValue={removeValue}
          states={states}
          appendState={appendState}
          removeState={removeState}
          layers={layers}
          appendLayer={appendLayer}
          removeLayer={removeLayer}
          insertLayer={insertLayer}
          actions={actions}
          appendAction={appendAction}
          removeAction={removeAction}
          errors={errors}
          setIsFetching={setIsFetching}
          setErrorMessage={setErrorMessage}
          clearErrors={clearErrors}
        />
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onConfirmText={rowId ? t("confirm") : t("add")}
        onCancel={() => {
          closePopup();
        }}
        isLoading={isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default AddEditVisualizationObjectPopup;
