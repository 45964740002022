import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { SMSVisualizationMode } from "../../../enums/SMS/SMSVisualizationMode";
import SMSVisualization from "../../helpersComponents/SMS/SMSVisualization/SMSVisualization";
import styles from "./BuildingFloorView.module.scss";

const BuildingFloorSMSUser = (props) => {
  const routeParams: any = useParams();
  const location = useLocation();
  const queryString = location.search;
  const queryParams = new URLSearchParams(queryString);
  const [mapId, setMapId] = useState<number | null>(null);

  const modifyBuildingFloorResponseCallback = useCallback(
    (responseData: ISMSBuildingFloor) => {
      setMapId(responseData.mapId);
      return responseData;
    },
    []
  );

  const [, , fetchAgainBuildingFloor] = useFetchAndSetGET<ISMSBuildingFloor>({
    path: `sms-user/buildings-floors/${routeParams.id}`,
    modifyResponseCallback: modifyBuildingFloorResponseCallback,
  });

  useEffect(() => {
    if (routeParams.id) {
      fetchAgainBuildingFloor();
    }
  }, [routeParams.id, fetchAgainBuildingFloor]);

  return (
    <div>
      <TopBanner pathName={queryParams.get("menuItemName")} />
      <div className="flexAndCenter">
        <div className={styles.visualizationContainer}>
          <SMSVisualization mode={SMSVisualizationMode.NORMAL} mapId={mapId} />
        </div>
      </div>
    </div>
  );
};

export default BuildingFloorSMSUser;
