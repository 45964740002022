import { useTranslation } from "react-i18next";
import styles from "./VisualizationObjectBasicData.module.scss";
import TwoColumnsRowsContainer from "../../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import Row from "../../../../helpersComponents/PopupHelpers/row";
import RowTitle from "../../../../helpersComponents/PopupHelpers/rowTitle";
import RowInput from "../../../../helpersComponents/PopupHelpers/rowInput";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import SelectFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/SelectField/selectFIeld";
import { ListItemIcon, ListItemText, TextField } from "@mui/material";
import { smsCompleteImageToBlob } from "../../../../../HelpersFunctions/imagesHelpers";
import { useSMSVisualizationDataContext } from "../../../../../HelpersFunctions/SMSVisualizationDataProvider";

const VisualizationObjectBasicData = ({
  fields,
  append,
  control,
  register,
  setValue,
  getValues,
  watch,
  visualizationObjectsGroups,
  ...props
}) => {
  const { t } = useTranslation();
  const { imagesData } = useSMSVisualizationDataContext();

  return (
    <div className={styles.basicDataContainer}>
      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={"100px"}>{t("name")}:</RowTitle>
          <RowInput>
            <TextFieldReactHookForm
              name={"name"}
              control={control}
              label={t("object_name")}
              required={true}
              multiline
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={"100px"}>{t("group")}:</RowTitle>
          <RowInput>
            <SelectFieldReactHookForm
              id={`groups`}
              name={`groups`}
              control={control}
              label={t("choose_group")}
              options={visualizationObjectsGroups || []}
              getOptionLabel={(option) => {
                return option.name;
              }}
              getOptionSelected={(option, value) => {
                return option.id === value.id;
              }}
              setValue={setValue}
              watch={watch}
              disableCloseOnSelect
              multiple
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={"100px"}>{t("description")}:</RowTitle>
          <RowInput>
            <TextFieldReactHookForm
              name={"description"}
              control={control}
              label={t("object_description")}
              required={false}
              multiline
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={"100px"}>{t("icon")}:</RowTitle>
          <RowInput>
            <SelectFieldReactHookForm
              id={`icon`}
              name={`icon`}
              control={control}
              label={t("choose_image")}
              required={true}
              options={imagesData}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={t("choose_image")}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: params.inputProps.value && (
                        <div className={styles.imageListItemContainer}>
                          <img
                            src={URL.createObjectURL(
                              smsCompleteImageToBlob(getValues("icon"))
                            )}
                            alt={params.inputProps.value}
                            style={{
                              width: 64,
                              height: 64,
                              marginTop: 10,
                              marginRight: 8,
                            }}
                          />
                        </div>
                      ),
                    }}
                  />
                );
              }}
              renderOption={(props, option) => {
                return (
                  <li>
                    <div className={styles.imageListItemContainer}>
                      <ListItemIcon>
                        <img
                          src={URL.createObjectURL(
                            smsCompleteImageToBlob(props)
                          )}
                          alt={option.label}
                          style={{ width: 64, height: 64 }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={styles.imageListItemLabel}
                        primary={props.fileName}
                      />
                    </div>
                  </li>
                );
              }}
              getOptionLabel={(option) => {
                return option.name;
              }}
              getOptionSelected={(option, value) => {
                return option.id === value.id;
              }}
              setValue={setValue}
              watch={watch}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>
    </div>
  );
};

export default VisualizationObjectBasicData;
