import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../../../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import DialogStyled from "../../../../../../helpersComponents/PopupHelpers/dialogStyled";
import { DialogContentStyled } from "../../../../../../helpersComponents/PopupHelpers/dialogContentStyled";
import DialogTitleStyled from "../../../../../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TwoColumnsRowsContainer from "../../../../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import Row from "../../../../../../helpersComponents/PopupHelpers/row";
import RowTitle from "../../../../../../helpersComponents/PopupHelpers/rowTitle";
import RowInput from "../../../../../../helpersComponents/PopupHelpers/rowInput";
import styles from "./GoToVisualizationObjectPopup.module.scss";

interface IFormInput {
  visualizationObjectInstanceId: string;
}

interface IProps {
  isOpen: boolean;
  visualizationObjects: ISMSVisualizationObjectToGo[];
  closePopup: () => void;
  successCallback?: (objectToGo: ISMSVisualizationObjectToGo) => void;
  container?: React.RefObject<HTMLElement>;
}

const GoToVisualizationObjectPopup: React.FC<IProps> = ({
  isOpen,
  visualizationObjects,
  closePopup,
  successCallback,
  container,
}) => {
  const { t } = useTranslation();

  const successCallbackAndClose = useCallback(
    (objectToGo) => {
      if (successCallback) successCallback(objectToGo);
      closePopup();
    },
    [successCallback, closePopup]
  );

  const schema = yup.object({
    visualizationObjectInstanceId: yup
      .string()
      .nullable()
      .required(t("field_required")),
  });

  const { handleSubmit, reset, register } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    let id = parseInt(data.visualizationObjectInstanceId);
    let objToGo = visualizationObjects.find((el) => el.id === id);
    successCallbackAndClose(objToGo);
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {
          reset();
        },
        onEnter: () => {},
      }}
      aria-labelledby="form-dialog-title"
      container={container?.current || undefined}
    >
      <DialogTitleStyled title={t("select_object")} />

      <DialogContentStyled>
        <form onSubmit={handleSubmit(onSubmit)} id="submitForm" noValidate>
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>
                <span className={styles.eventInfoLabel}>{t("object")}:</span>
              </RowTitle>
              <RowInput>
                <div className={styles["custom-select-wrapper"]}>
                  <select {...register("visualizationObjectInstanceId")}>
                    {visualizationObjects.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>
        </form>
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onConfirmText={t("confirm")}
        onCancel={() => {
          closePopup();
        }}
        isLoading={false}
      />
    </DialogStyled>
  );
};

export default GoToVisualizationObjectPopup;
