import { useCallback, useEffect, useState } from "react";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import Row from "../../../../helpersComponents/PopupHelpers/row";
import RowInput from "../../../../helpersComponents/PopupHelpers/rowInput";
import RowTitle from "../../../../helpersComponents/PopupHelpers/rowTitle";
import TwoColumnsRowsContainer from "../../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import erro400getTranslatedErrorString from "../../../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";

const AddEditVisualizationObjectsStatesGroupsPopupContent: React.FC<any> = ({
  rowId,
  control,
  setValue,
  setErrorMessage,
  successCallback,
  handleSubmit,
  setIsFetching,
}) => {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<any>(false);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
  }, [successCallback]);

  const [
    visualizationObjectsStatesGroup,
    fetchingStateVisualizationObjectsStatesGroup,
    fetchAgainVisualizationObjectsStatesGroup,
  ] = useFetchAndSetGET({
    path: `sms-administrator/visualization-objects-states-groups-library/${rowId}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (rowId) {
      fetchAgainVisualizationObjectsStatesGroup();
    }
  }, [rowId, fetchAgainVisualizationObjectsStatesGroup]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t, setErrorMessage]
  );

  const [
    fetchingStateAddEditVisualizationObjectsStatesGroup,
    fetchAgainAddEditVisualizationObjectsStatesGroup,
  ] = useFetchOtherThanGET({
    path: `sms-administrator/visualization-objects-states-groups-library${
      rowId ? "/" + rowId : ""
    }`,
    method: rowId ? "PUT" : "POST",
    contentType: "application/json",
    body: bodyRequest,
    setBody: setBodyRequest,
    successCallback: successCallbackAndClose,
    errorCallback,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditVisualizationObjectsStatesGroup();
    }
  }, [bodyRequest, fetchAgainAddEditVisualizationObjectsStatesGroup]);

  useEffect(() => {
    setIsFetching(
      fetchingStateAddEditVisualizationObjectsStatesGroup.isFetching
    );
  }, [fetchingStateAddEditVisualizationObjectsStatesGroup, setIsFetching]);

  const onSubmit = (data: any) => {
    let tempOrder = parseInt(data.order);

    let jsonDataToSend: any = {
      name: data.name,
      order: isNaN(tempOrder) ? 0 : tempOrder,
    };

    setBodyRequest(JSON.stringify(jsonDataToSend));
  };

  useEffect(() => {
    if (
      rowId &&
      !fetchingStateVisualizationObjectsStatesGroup.isFetching &&
      fetchingStateVisualizationObjectsStatesGroup.response?.status === 200
    ) {
      setValue("name", visualizationObjectsStatesGroup.name);
      setValue("order", visualizationObjectsStatesGroup.order);
    }
  }, [
    fetchingStateVisualizationObjectsStatesGroup.isFetching,
    fetchingStateVisualizationObjectsStatesGroup.response?.status,
    rowId,
    setValue,
    visualizationObjectsStatesGroup,
  ]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateVisualizationObjectsStatesGroup.isFetching
        }
        isError={fetchingStateVisualizationObjectsStatesGroup.isError}
        setIfFetchAgain={() => {
          if (fetchingStateVisualizationObjectsStatesGroup.isError) {
            fetchAgainVisualizationObjectsStatesGroup();
          }
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} id="submitForm" noValidate>
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("name")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"name"}
                  control={control}
                  label={t("name")}
                  required={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("order")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"order"}
                  control={control}
                  label={t("order")}
                  required={true}
                  type="number"
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>
        </form>
      </LoadingWrapper>
    </>
  );
};

export default AddEditVisualizationObjectsStatesGroupsPopupContent;
