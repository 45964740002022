import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import UsersManagementContent from "../../helpersComponents/UserManagement/UsersManagementContent";

const SwitchStyled = withStyles({
  switchBase: {
    color: "#f8a12f",
    "&$checked": {
      color: "#f8a12f",
    },
    "&$checked + $track": {
      backgroundColor: "#f8a12f",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#f8a12f",
    "$checked$checked + &": {
      backgroundColor: "#f8a12f",
    },
  },
})(Switch);

const UsersManagementPfso = () => {
  return (
    <div>
      <UsersManagementContent
        pathUsers="pfso/ac-users"
        pathUserPermissions="pfso/ac-user-permissions"
        pathReaders="pfso/readers"
        pathCompanies="pfso/ac-companies"
        pathDepartments="pfso/departments"
        pathZones="pfso/zones"
        pathGroups="pfso/groups?isActive=true"
        pathPolicies="pfso/policies?isActive=true"
        layoutSettingName="layoutPfsoACUsersManagementRecords"
        SwitchStyled={SwitchStyled}
      />
    </div>
  );
};

export default UsersManagementPfso;
